import { lazy, Suspense, useEffect, useState } from "react";

import FloatingForm from "../../../common/floating-form/FloatingForm";
import Layout from "../../../App";
import { Link, NavLink } from "react-router-dom";
import BannerImage from "./bannerImage.png";
import TextImage from "./textImage.png";
import { useVisibleHeader } from "../../../common/header/HeaderVisibilityContext";
import OfferContact from "../OfferContact";
import OfferBlueBanner from "../OfferBlueBanner";
import OfferMainHeading from "../OfferMainHeading";
import OfferProcess from "../OfferProcess";
import OfferInTheNews from "../OfferInTheNews";
import OfferCaseStudies from "../OfferCaseStudies";
import { SlideInFromBottom } from "../Animations";
import { useIsMobile } from "../../../../helpers/useIsMobile";
import OfferSkeleton from "../OfferSkeleton";
import Oversite from "../../../../assets/img/oligamy/home-case-studies/oversite.webp";
import WGMI from "../../../../assets/img/oligamy/home-case-studies/wgmi.webp";
import Land from "../../../../assets/img/oligamy/home-case-studies/land.webp";
import Palto from "../../../../assets/img/oligamy/home-case-studies/palto.webp";
import { Helmet } from "react-helmet-async";

const WebDevelopmentTopVideoComponent = lazy(() =>
  import("./WebDevelopmentTopVideoComponent")
);
function WebDevelopment() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const isMobile = useIsMobile("(max-width: 500px)");
  const caseStudiesData = [
    {
      thumbnail: Oversite,
      title: "Oversite",
      url: "/design/oversite-web",
      tag1: "Web app",
    },
    {
      thumbnail: WGMI,
      title: "WGMI",
      url: "/design/wgmi",
      tag1: "Landing Page",
      tag2: "Web app",
      tag2: "Web3",
    },
    {
      thumbnail: Land,
      title: "Landing page",
      url: "/design/metaverse",
      tag1: "Landing Page",
      tag2: "Web app",
      tag3: "Web3",
    },
    {
      thumbnail: Palto,
      title: "Palto",
      url: "/design/palto",
      tag2: "Landing Page",
    },
  ];
  const processSteps = [
    {
      number: "01",
      title: "Consultations",
      content:
        "We start with in-depth consultations to understand your goals and ideas, laying solid foundations for success.",
    },
    {
      number: "02",
      title: "Technology selection",
      content:
        "We assist in selecting the right technologies tailored to your needs and business objectives.",
    },
    {
      number: "03",
      title: "Design",
      content:
        "Our design team creates an action plan, merging your vision with industry best practices.",
    },
    {
      number: "04",
      title: "Development",
      content:
        "An experienced team of programmers transforms the project into reality, ensuring high quality.",
    },
    {
      number: "05",
      title: "Implementation",
      content:
        "We conduct smooth and seamless implementation to fully realize your vision and make it accessible to users.",
    },
  ];

  const [isQuickNavFixed, setIsQuickNavFixed] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const el = document.querySelector(".offer-quick-nav");
      if (!el) return;
      const elOffset = el.offsetTop;

      const onScroll = () => {
        if (elOffset - 84 <= window.scrollY - 84) {
          setIsQuickNavFixed(true);
        } else {
          setIsQuickNavFixed(false);
        }
      };

      window.removeEventListener("scroll", onScroll);
      window.addEventListener("scroll", onScroll, { passive: true });
    };

    const checkScrollPosition = () => {
      if (window.scrollY === 0) {
        handleScroll();
      }
    };

    checkScrollPosition();

    const onScroll = () => {
      if (window.scrollY === 0) {
        handleScroll();
        window.removeEventListener("scroll", onScroll);
      }
    };

    window.addEventListener("scroll", onScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const { isVisible } = useVisibleHeader();

  // Metadata
  const pageDescription =
    "Transform your online presence with Oligamy Software’s custom web development services. We specialize in creating high-quality websites using the latest technologies, including blockchain, AI, and mobile development.";

  const pageTitle =
    "Oligamy Software - Web Development Services | Custom Web Solutions";

  return (
    <Layout isAlternative={true}>
      <FloatingForm />

      <>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta property="og:description" content={pageDescription} />
          <meta property="og:url" content={window.location.href} />{" "}
          {/* Use the current page URL */}
          <meta property="og:type" content="website" />
          {/* Twitter Card tags for Twitter */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDescription} />
        </Helmet>
        <div className=" bg-[#FDFDFD]">
          <main
            className=" page-section    "
            onClick={() => {
              document
                .querySelector(".main-nav")
                .className.includes("slidenav") &&
                document.querySelector(".remove").click();
            }}
          >
            <OfferMainHeading title={"Develop products"} />
            {/* desktop */}
            {!isMobile && (
              <section
                className={`offer-quick-nav offer-quick-nav-special z-[12] pt-[36px] ${
                  isVisible ? "offset" : ""
                } ${isQuickNavFixed ? "isFixed" : ""}`}
              >
                <nav className="container flex">
                  <NavLink to={`/offer/ai-solutions`}>AI Solutions</NavLink>
                  <NavLink to={`/offer/blockchain-development`}>
                    Blockchain development
                  </NavLink>
                  <NavLink onClick={scrollTop} to={`/offer/web-development`}>
                    Web development
                  </NavLink>
                  <NavLink to="/offer/design-implementation-service">
                    Design implementation service
                  </NavLink>
                </nav>
              </section>
            )}
            {/* mobile */}
            {isMobile && (
              <section
                className={`offer-quick-nav z-[12] pt-[36px] ${
                  isVisible ? "offset" : ""
                } ${isQuickNavFixed ? "isFixed" : ""}`}
              >
                <nav className="container flex">
                  <NavLink to="/offer/web-development" onClick={scrollTop}>
                    Web development
                  </NavLink>
                  <NavLink to="/offer/design-implementation-service">
                    Design implementation service
                  </NavLink>
                  <NavLink to="/offer/blockchain-development">
                    Blockchain development
                  </NavLink>
                  <NavLink to="/offer/ai-solutions">AI Solutions</NavLink>
                </nav>
              </section>
            )}
            <section className="container ">
              <div className="offer-title-card mt-[72px]">
                <div className="500px:min-h-[220px] 767px:min-h-[180px] 991px:min-h-[260px] 1199px:min-h-[340px] 1399px:min-h-[420px] min-h-[550px] 500px:mb-[72px] 1199px:mb-[80px] mb-[96px]">
                  <Suspense fallback={OfferSkeleton()}>
                    <WebDevelopmentTopVideoComponent />
                  </Suspense>
                </div>
                <div className="flex flex-col justify-between">
                  <SlideInFromBottom>
                    <h2 className="500px:text-[29px] 767px:text-[45px] 991px:text-[52px] text-[61px] text-[#0e2933]">
                      <span className="leading-[120%]">Web development </span>
                      services: comprehensive solutions for your online presence
                    </h2>
                  </SlideInFromBottom>
                  <SlideInFromBottom>
                    <div className="description mt-[56px] flex flex-col items-end">
                      <div className=" max-w-[670px]">
                        <p className="text-[#565656]">
                          At our core, we are pioneers in the development of web
                          and mobile applications. Our team of experienced
                          software engineers specializes in a wide range of
                          cutting-edge technologies, ensuring that your online
                          presence is not only functional but exceptional. With
                          a foundation built on knowledge and innovation, we pay
                          special attention to detail and commit to excellence
                          to bring your vision to life.
                        </p>
                        <Link
                          to="/contact"
                          className=" inline-block mt-[32px] bg-[#3fb7e2] py-[16px] px-[32px] font-[500] text-[19px] rounded-[8px] text-[#173d46] hover:text-[#fbfbfb] transition-colors duration-500"
                        >
                          Contact us
                        </Link>
                      </div>
                    </div>
                  </SlideInFromBottom>
                </div>
              </div>
            </section>
            <OfferBlueBanner
              higlightedText={"Comprehensive solutions. "}
              restText={
                "Our services cover the entire range of needs on the Internet - from creating websites to online stores and advanced applications. Whatever you need, we have the skills and experience to turn your ideas into reality."
              }
            />
            <section className=" offer-text-image-left 991px:gap-12  flex flex-row-reverse items-center justify-between 500px:mt-[72px] 1199px:mt-[80px] mt-[96px] container ">
              <div className="left max-w-[555px]  space-y-10">
                <SlideInFromBottom>
                  <h3 className="500px:text-[29px] text-[45px] 1199px:text-[38px] text-left text-[#0e2933]">
                    Tailored technologies
                  </h3>
                </SlideInFromBottom>
                <SlideInFromBottom>
                  <div className="flex flex-col gap-2">
                    <p className="500px:text-[19px] text-[25px] text-[#0e2933]">
                      We understand that every project is unique, which is why
                      we tailor our technology stack to meet your specific
                      requirements.
                    </p>
                    <p className="text-[#565656]">
                      From frontend frameworks like{" "}
                      <span className="text-[#3FB7E2]">
                        React.js, React native, Vue.js
                      </span>
                      , and <span className="text-[#3FB7E2]">Angular</span> to
                      backend solutions utilizing{" "}
                      <span className="text-[#3FB7E2]">
                        Python, Ruby on Rails
                      </span>
                      , and <span className="text-[#3FB7E2]">Node.js</span>, we
                      leverage the latest tools and techniques to deliver
                      unparalleled results. Our expertise also includes low-code
                      development, cloud services, and much more, ensuring that
                      your project is equipped with top-quality solutions.
                    </p>
                  </div>
                  <Link
                    to="/contact"
                    className=" inline-block mt-[32px] bg-[#3fb7e2] py-[16px] px-[32px] font-[500] text-[19px] rounded-[8px] text-[#173d46] hover:text-[#fbfbfb] transition-colors duration-500"
                  >
                    Contact us
                  </Link>
                </SlideInFromBottom>
              </div>
              <SlideInFromBottom>
                <div className="image ">
                  <img
                    src={TextImage}
                    alt="web-development"
                    className="max-w-[440px] rounded-xl  h-[544px] "
                  />
                </div>
              </SlideInFromBottom>
            </section>
            <OfferBlueBanner
              higlightedText={"Why choose us? "}
              restText={
                "Our team consists of industry-leading experts in UI/UX design, frontend and backend development, DevOps, QA, and project management, ensuring that your project is in the best hands possible."
              }
            />
            <OfferProcess
              title={"Process"}
              description={
                'Our "Proven Process" is built on collaboration, transparency, and efficiency, ensuring that each stage of the development journey is meticulously planned and executed with the highest care.'
              }
              processSteps={processSteps}
            />
            <section className="offer-blue-text  bg-[#256c86] 500px:py-[72px] 1199px:py-[80px] py-[96px] ">
              <div className="offer-text-image-left 991px:gap-12  flex 992px:flex-row-reverse items-center justify-between container">
                <div className="left max-w-[555px]  space-y-10">
                  <SlideInFromBottom>
                    <h3 className="500px:text-[29px] 1199px:text-[38px]  text-[45px] text-left text-[#FDFDFD]">
                      Support without compromise
                    </h3>
                  </SlideInFromBottom>
                  <SlideInFromBottom>
                    <p className="text-[#EFEFEF]">
                      Our commitment to your success doesn&apos;t end with
                      deployment. We offer continuous support and maintenance to
                      ensure that your digital products function smoothly and
                      efficiently. Whether you&apos;re a startup taking your
                      first steps or an established company looking to enhance
                      your online presence, we&apos;re here to be your trusted
                      partner at every stage.
                    </p>
                    <Link
                      to="/contact"
                      className=" inline-block mt-[32px] bg-[#3fb7e2] py-[16px] px-[32px] font-[500] text-[19px] rounded-[8px] text-[#173d46] hover:text-[#fbfbfb] transition-colors duration-500"
                    >
                      Contact us
                    </Link>
                  </SlideInFromBottom>
                </div>
                <SlideInFromBottom>
                  <div className="image ">
                    <img
                      src={BannerImage}
                      alt="web-development"
                      className="max-w-[440px] h-[544px] rounded-2xl"
                    />
                  </div>
                </SlideInFromBottom>
              </div>
            </section>
            <SlideInFromBottom>
              <OfferCaseStudies caseStudiesData={caseStudiesData} />
            </SlideInFromBottom>
            <SlideInFromBottom>
              <OfferInTheNews category={"Software"} />
            </SlideInFromBottom>
            <SlideInFromBottom>
              <OfferContact
                text={
                  "Interested in learning more about our Web development services or starting a collaboration? Contact us today!"
                }
              />
            </SlideInFromBottom>
          </main>
        </div>
      </>
    </Layout>
  );
}

export default WebDevelopment;
