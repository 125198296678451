import React, { memo } from "react";

const tags = [
  "Web app",
  "Mobile app",
  "Desktop app",
  "Website",
  "Landing Page",
  "Web3",
  "Design system",
];

function AllCaseStudiesTagsWidget({ selectedBlogTag, setSelectedBlogTag }) {
  return (
    <div className={"blog-slider   500px:pl-[12px] gap-[8px]"}>
      <div
        className={`single-tag ${selectedBlogTag === "All" ? "active" : ""}`}
        onClick={() => setSelectedBlogTag("All")}
      >
        All
      </div>
      {tags.map((tag, i) => (
        <div
          key={i}
          className={`single-tag ${tags.length === i + 1 && "mr-[12px]"} ${
            selectedBlogTag === tag ? "active" : ""
          }`}
          onClick={() => setSelectedBlogTag(tag)}
        >
          {tag}
        </div>
      ))}
    </div>
  );
}

export default memo(AllCaseStudiesTagsWidget);
