import React, { useEffect, useState } from "react";
import Preloader from "../layout/Preloader";
import FloatingForm from "../common/floating-form/FloatingForm";
import Layout from "../App";

export default function PrivacyPolicy() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <Layout isAlternative={true}>
      <FloatingForm />
      {loading ? (
        <Preloader />
      ) : (
        <div
          onClick={() => {
            document
              .querySelector(".main-nav")
              .className.includes("slidenav") &&
              document.querySelector(".remove").click();
          }}
        >
          <div className="privacy-policy container">
            <h1>PRIVACY POLICY</h1>
            <div className="paragraph">
              <h2>GENERAL INFORMATION</h2>
              <p>
                This Privacy Policy (hereinafter referred to as the "Policy")
                outlines the rules for processing personal data and the use of
                cookies in the website of Oligamy Software Sp. z o.o., with its
                registered office at 38 Nowiec Street, 80-293 Gdańsk, Poland,
                registered in the Central Register and Information on Economic
                Activity (CEIDG) under the tax identification number (NIP)
                5833433323 and the statistical number REGON 389455609. The
                National Court Register (KRS) number is 0000911398.
              </p>
            </div>
            <div className="paragraph">
              <h2>DEFINITIONS</h2>
              <p>
                For the sake of clarity, we apply the following definitions:
              </p>
              <ul>
                <li>
                  "Service" or "Website" means the internet website available at{" "}
                  <a href="https://oligamy.com/">www.oligamy.com.</a>
                </li>
                <li>"Administrator" refers to Oligamy Software Sp. z o.o.</li>
                <li>
                  "User" refers to an individual or legal entity using the
                  Service.
                </li>
              </ul>
            </div>
            <div className="paragraph">
              <h2>TYPES OF PERSONAL DATA</h2>
              <p>
                The Administrator processes the following personal data of
                Users:
              </p>
              <ul>
                <li>Email address</li>
                <li>First and last name</li>
                <li>IP address of the computer</li>
              </ul>
            </div>
            <div className="paragraph">
              <h2>PURPOSE OF DATA PROCESSING</h2>
              <p>
                The personal data of Users is processed for: (a) Fulfillment of
                agreements and provision of services electronically. (b)
                Promotional and commercial activities by the Administrator.
              </p>
            </div>
            <div className="paragraph">
              <h2>LEGAL BASIS</h2>
              <p>
                Processing of personal data is carried out based on the
                following legal provisions:
              </p>
              <ul>
                <li>
                  In the case described in point (a), to fulfill an agreement or
                  take action before its conclusion.
                </li>
                <li>
                  In the case described in point (b), with the consent of the
                  User.
                </li>
              </ul>
            </div>
            <div className="paragraph">
              <h2>DATA RETENTION PERIOD</h2>
              <p>
                Personal data of Users will be stored for a period of 5 years.
                After this period, the data will be permanently deleted unless
                processing is required for other reasons arising from legal
                provisions.
              </p>
            </div>
            <div className="paragraph">
              <h2>DATA PROCESSING ENTITIES</h2>
              <p>
                Personal data of Users may be transferred to entities
                cooperating with the Administrator, such as hosting companies,
                accounting service providers, courier companies, etc. All
                entities processing personal data of Users operate under an
                agreement with the Administrator and only on his instructions.
              </p>
            </div>
            <div className="paragraph">
              <h2>USER RIGHTS</h2>
              <p>
                Users have the following rights related to the processing of
                their personal data:
              </p>
              <ul>
                <li>The right to delete personal data.</li>
                <li>The right to restrict data processing.</li>
                <li>The right to data portability.</li>
                <li>The right to receive data in a structured form.</li>
                <li>
                  The right to lodge a complaint with the supervisory authority
                  in the event of infringements.
                </li>
              </ul>
            </div>
            <div className="paragraph">
              <h2>COOKIES</h2>
              <p>
                The Service uses cookies for statistical purposes and to improve
                the quality of User service. Cookies are small text files stored
                on Users' devices to recognize them and adapt the Service to
                their individual preferences.
              </p>
            </div>
            <div className="paragraph">
              <h2>CHANGES TO THE PRIVACY POLICY</h2>
              <p>
                The Privacy Policy may change, and the current version of the
                Policy will always be available on the Website.
              </p>
            </div>
            <div className="paragraph">
              <h2>CONTACT</h2>
              <p>
                For matters related to personal data processing and to exercise
                rights related to data protection, Users can contact the
                Administrator via email at hello@oligamy.com.
              </p>
            </div>
            <div className="paragraph update-info">
              <p>Last updated: 30.10.2023</p>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
