import React from "react";

export default function ProgressBar({ progress, isProgressVisible }) {
  const progressBarStyle = {
    width: `${progress}%`,
    transition: "width 0.5s ease",
  };

  return (
    <div className="progress-bar-container w-[100%] mt-[24px]">
      <div className="progress-bar-border">
        <p className="text-[14px] font-[600] text-[#173d46] ml-[20px]">0%</p>
        <div
          className={`progress-bar-proper ${
            isProgressVisible ? "visible" : "invisible"
          }`}
          style={progressBarStyle}
        >
          <p className="text-[14px] font-[600] text-[#173d46]">{progress}%</p>
        </div>
      </div>
    </div>
  );
}
