import { SlideInFromBottom } from "./Animations";
import OfferProcessSwiper from "./OfferProcessSwiper";
function OfferProcess({
  processSteps,
  description,
  title,
  isProcessDescribed = true,
}) {
  return (
    <section
      className={` container my-[96px] 500px:my-[72px] 1199px:my-[80px]`}
    >
      <div className="offer-process ">
        <div className="upper flex justify-between">
          <SlideInFromBottom>
            <h3 className="text-[#0e2933] 1199px:text-[38px] text-[45px]">
              {title}
            </h3>
          </SlideInFromBottom>
          <SlideInFromBottom>
            <p className="text-[#565656] max-w-[670px]">{description}</p>
          </SlideInFromBottom>
        </div>
        <SlideInFromBottom>
          <div
            className={`lower mt-[96px] ${
              isProcessDescribed ? "" : "500px:mt-[48px]"
            }`}
          >
            <div className="steps-upper 500px:hidden grid grid-cols-4 gap-x-[16px] gap-y-[72px]">
              {processSteps.map((step, index) => (
                <div className="single-step w-full" key={index}>
                  <div className="upper flex gap-[16px]">
                    <h2 className="text-[34px] text-[#3fb7e2]">
                      {step.number}
                    </h2>
                    <div className="line w-full"></div>
                  </div>
                  <div className="lower mt-[24px]">
                    <p className="text-[#0e2933] font-[600]">{step.title}</p>
                    <p className="text-[#565656] mt-[12px]">{step.content}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="steps-upper-mobile hidden 500px:block">
              <OfferProcessSwiper processSteps={processSteps} />
            </div>
          </div>
        </SlideInFromBottom>
      </div>
    </section>
  );
}

export default OfferProcess;
