import Avatar1 from "../../../../assets/img/oligamy/human-approach/avatars/avatar1.png";
import Avatar2 from "../../../../assets/img/oligamy/human-approach/avatars/avatar2.png";

const HumanApproachData = [
  {
    avatar: Avatar1,
    message:
      "I’m looking for a company that will handle the entire development of my web app project.",
  },
  {
    avatar: Avatar2,
    message:
      "You can count on us for that. Let’s schedule a call to discuss your business goals. Does tomorrow at 10 AM work for you?",
  },
  {
    avatar: Avatar1,
    message: "Sounds great! See you tomorrow.",
  },
];
export default HumanApproachData;
