import React from "react";

export default function NegativeFeedback() {
  return (
    <div className="feedback negative">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M9.99984 14.1667C10.2359 14.1667 10.434 14.0867 10.594 13.9267C10.754 13.7667 10.8337 13.569 10.8332 13.3334C10.8332 13.0973 10.7532 12.8992 10.5932 12.7392C10.4332 12.5792 10.2354 12.4995 9.99984 12.5001C9.76372 12.5001 9.56567 12.5801 9.40567 12.7401C9.24567 12.9001 9.16595 13.0979 9.1665 13.3334C9.1665 13.5695 9.2465 13.7676 9.4065 13.9276C9.5665 14.0876 9.76428 14.1673 9.99984 14.1667ZM9.1665 10.8334H10.8332V5.83341H9.1665V10.8334ZM9.99984 18.3334C8.84706 18.3334 7.76373 18.1145 6.74984 17.6767C5.73595 17.239 4.854 16.6454 4.104 15.8959C3.354 15.1459 2.76039 14.264 2.32317 13.2501C1.88595 12.2362 1.66706 11.1529 1.6665 10.0001C1.6665 8.8473 1.88539 7.76397 2.32317 6.75008C2.76095 5.73619 3.35456 4.85425 4.104 4.10425C4.854 3.35425 5.73595 2.76064 6.74984 2.32341C7.76373 1.88619 8.84706 1.6673 9.99984 1.66675C11.1526 1.66675 12.2359 1.88564 13.2498 2.32341C14.2637 2.76119 15.1457 3.3548 15.8957 4.10425C16.6457 4.85425 17.2396 5.73619 17.6773 6.75008C18.1151 7.76397 18.3337 8.8473 18.3332 10.0001C18.3332 11.1529 18.1143 12.2362 17.6765 13.2501C17.2387 14.264 16.6451 15.1459 15.8957 15.8959C15.1457 16.6459 14.2637 17.2398 13.2498 17.6776C12.2359 18.1154 11.1526 18.334 9.99984 18.3334ZM9.99984 16.6667C11.8609 16.6667 13.4373 16.0209 14.729 14.7292C16.0207 13.4376 16.6665 11.8612 16.6665 10.0001C16.6665 8.13897 16.0207 6.56258 14.729 5.27091C13.4373 3.97925 11.8609 3.33341 9.99984 3.33341C8.13872 3.33341 6.56234 3.97925 5.27067 5.27091C3.979 6.56258 3.33317 8.13897 3.33317 10.0001C3.33317 11.8612 3.979 13.4376 5.27067 14.7292C6.56234 16.0209 8.13872 16.6667 9.99984 16.6667Z"
          fill="#EDFCF6"
        />
      </svg>
      <p>
        There was a problem sending your message. Please try again later or
        contact us by email at{" "}
        <a href="mailto:hello@oligamy.com">hello@oligamy.com</a>.
      </p>
    </div>
  );
}
