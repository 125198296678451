import "swiper/css/autoplay";

// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import SwiperNavButtons from "./SwiperNavButtons";
import { StaggerChildren } from "../offer/Animations";

SwiperCore.use([Navigation, Autoplay]);
function AboutTimelineSwiper({ aboutTimelineData }) {
  const oddseeSwiper = {
    loop: false,
    spaceBetween: 0,
    speed: 1200,
    slidesPerView: 1.1,
    allowTouchMove: true,
    autoplay: false,
    centeredSlides: false,
    breakpoints: {
      1400: {
        slidesPerView: 1.1,
      },
      1200: {
        spaceBetween: 0,
      },
      500: {
        slidesPerView: 1,
        spaceBetween: 200,
      },
    },
  };
  return (
    <>
      <section className="oddsee-slider billon">
        <div className="row">
          <div className="oddsee-swiper-wrapper progress-slider-wrapper">
            <StaggerChildren>
              <Swiper
                {...oddseeSwiper}
                className="swiper partner-slider progress-slider px-0 flex flex-col-reverse"
                breakpoints={{
                  1400: {
                    slidesPerView: 1.1,
                  },
                  1200: {
                    spaceBetween: 200,
                  },
                  500: {
                    slidesPerView: 1,
                    spaceBetween: 200,
                  },
                }}
              >
                <div className="title-section flex mb-[64px]">
                  <h1 className="text-[#fdfdfd] text-[45px] 767px:text-[38px]">
                    Explore the world of Oligamy Software – where excellence
                    meets innovation.
                  </h1>
                  <SwiperNavButtons />
                </div>
                <div className="swiper-wrapper">
                  {aboutTimelineData.map(({ year, events }, i) => (
                    <SwiperSlide
                      key={i}
                      className={`swiper-slide ${i === 2 && " swiper-margin"} `}
                    >
                      <div className="single-partner">
                        <div className="upper">
                          <h3>{year}</h3>
                          <div className="line"></div>
                        </div>
                        <div className="lower">
                          {events.map((event, index) => (
                            <div key={index} className="single-timestamp">
                              <h4>
                                <span>{event.date}</span> {event.title}
                              </h4>
                              <p>{event.description}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </div>
              </Swiper>
            </StaggerChildren>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutTimelineSwiper;
