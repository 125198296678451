import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import getPost from "../../../api/action.getPost";
import logError from "../../../helpers/logError";
import BlogShare from "./BlogShare";
import { Helmet } from "react-helmet-async";
import NewPostWidget from "./NewPostWidget";
import MarkdownIt from "markdown-it";
import RelatedPosts from "./RelatedPosts";
import MobileRelatedLinks from "./MobileRelatedLinks";
import BlogPostMarkdown from "./BlogPostMarkdown";
import BlogPostTags from "./BlogPostTags";
import { useIsMobile } from "../../../helpers/useIsMobile";
function BlogDetailsContent() {
  const isMobile = useIsMobile("(max-width: 991px)");
  const { id } = useParams();
  const [post, setPost] = useState();
  const [links, setLinks] = useState([]);
  useEffect(() => {
    let isMounted = true;

    const fetchPostData = async () => {
      if (id) {
        try {
          const { data } = await getPost(id);

          if (isMounted) {
            setPost(data);
          }
        } catch (err) {
          logError(err);
        }
      }
    };

    fetchPostData();

    return () => {
      isMounted = false;
    };
  }, [id]);

  useEffect(() => {
    if (post && post.attributes && post.attributes.content) {
      const markdownContent = post.attributes.content;
      extractLinks(markdownContent);
    }
  }, [post]);

  const extractLinks = (markdownContent) => {
    if (typeof markdownContent !== "string") {
      console.error("Expected content to be a string");
      return;
    }

    const md = new MarkdownIt();
    const parsedContent = md.parse(markdownContent, {});
    const extractedHeadings = [];

    parsedContent.forEach((token, index) => {
      if (token.type === "heading_open" && token.tag === "h1") {
        const titleToken = parsedContent[index + 1];
        if (titleToken && titleToken.type === "inline") {
          const hasLink = titleToken.children.some(
            (child) => child.tag === "a"
          );

          if (!hasLink) {
            const title = titleToken.content.trim();
            const id = title.toLowerCase().replace(/\s+/g, "-");
            extractedHeadings.push({
              title,
              id,
            });
          }
        }
      }
    });

    setLinks(extractedHeadings);
  };

  //Metadata

  const pageTitle = post?.attributes?.title;
  const pageDescription = post?.attributes?.content.split("\n\n")[0];
  const postTags = post?.attributes?.blog_tags?.data;
  return (
    <>
      <Helmet>
        <meta name="description" content={pageDescription} />
        {/* Open Graph Protocol (OGP) tags for Facebook and others */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content={window.location.href} />{" "}
        {/* Use the current page URL */}
        <meta property="og:type" content="website" />
        {/* Twitter Card tags for Twitter */}
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
      <div className="container">
        <div className="blog-header mt-[84px]">
          <h1
            className={
              "pt-[120px] text-[#0E2933] text-[61px] 991px:text-[45px] 500px:text-[34px] 500px:pt-[80px] "
            }
          >
            {post?.attributes?.title}
          </h1>
        </div>
      </div>
      <div className="blog-details-area mt-[80px]">
        <div className="container">
          <div className="row">
            <div className="blog-details-content col-lg-8">
              {isMobile && <MobileRelatedLinks links={links} />}
              <div className="author-date  layout2 991px:border-none border-b 991px:justify-start 991px:gap-[24px] border-[#878787]">
                <p className=" text-[#878787] mb-[16px]">
                  By {post?.attributes?.author}
                </p>
                <p className=" text-[#878787]">{post?.attributes?.date}</p>
              </div>

              <div className="post-content ">
                <BlogPostMarkdown content={post?.attributes?.content} />
                <BlogPostTags postTags={postTags} />
                <BlogShare />
              </div>
            </div>
            {!isMobile && (
              <div className=" col-lg-4 side">
                <NewPostWidget links={links} />
              </div>
            )}
          </div>
          <div className="mb-[200px]">
            <RelatedPosts postId={post?.id} postBlogTags={postTags} />
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogDetailsContent;
