import { useEffect, useState } from "react";
import TopPic from "../../../assets/img/oligamy/case-studies/palto/palto-top.png";

import WOW from "wowjs";

import AboutImage from "../../../assets/img/oligamy/case-studies/palto/about1.png";
import AboutImage2 from "../../../assets/img/oligamy/case-studies/palto/about2.png";
import AboutImage3 from "../../../assets/img/oligamy/case-studies/palto/about3.png";
import AboutImage4 from "../../../assets/img/oligamy/case-studies/palto/about4.png";
import AboutImage5 from "../../../assets/img/oligamy/case-studies/palto/about5.png";
import AboutImage6 from "../../../assets/img/oligamy/case-studies/palto/about6.png";
import Banner from "../../../assets/img/oligamy/case-studies/palto/banner.png";
import AnimatedBackground from "../../../assets/img/oligamy/case-studies/palto/animatedbackground.png";
import AnimatedBackground2 from "../../../assets/img/oligamy/case-studies/palto/animatedbackground2.png";

import FloatingForm from "../../common/floating-form/FloatingForm";
import Layout from "../../App";
import Preloader from "../../layout/Preloader";

function Palto() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  return (
    <Layout>
      <FloatingForm />
      {loading ? (
        <Preloader />
      ) : (
        <div
          onClick={() => {
            document
              .querySelector(".main-nav")
              .className.includes("slidenav") &&
              document.querySelector(".remove").click();
          }}
        >
          <section className="page-section mb-[100px]">
            <div className="position-relative">
              <div className="container max-w-[1320px]">
                <h1 className="title position-absolute  z-10 699px:text-[39px]">
                  Logo and Landing page for Palto
                </h1>
              </div>
              <img className="w-100 filter brightness-[30%]" srcSet={TopPic} />
            </div>
            <div className="container max-w-[1320px]">
              <div
                className="app-data wow animated fadeIn"
                data-wow-delay="200ms"
              >
                <h3>Client: Palto</h3>
                <h3>Year: 2024</h3>
              </div>
              <div className="about-app  991px:gap-0 gap-[24px]  mb-[72px] ">
                <div className=" text   w-full  wow animated fadeInUp">
                  <h1 className=" 991px:text-center  991px:container 991px:max-w-full 991px:mx-auto ">
                    Background Story
                  </h1>
                  <p className="500px:text-left">
                    Palto was born from the vision of an experienced Applied
                    Scientist who spent several years at Amazon. Recognizing the
                    limitations and frustrations of corporate life, especially
                    in the tech industry, he decided to leave his prestigious
                    role and start his own business. His journey from Amazon to
                    entrepreneurship inspired Palto's main mission: to offer
                    freelancers freedom, financial security, and the
                    satisfaction of doing meaningful work without typical
                    corporate constraints.
                  </p>
                </div>
                <img
                  src={AboutImage}
                  alt="Oddsee app"
                  className="wow animated fadeInUp "
                />
              </div>
              {/* SECOND IMAGE */}
              <div className="about-app  991px:gap-0 gap-[24px] second  ">
                <div className=" text  w-full  wow animated fadeInUp">
                  <h1 className=" 991px:text-center  991px:container 991px:max-w-full 991px:mx-auto ">
                    Challange
                  </h1>
                  <p className="500px:text-left">
                    The main challenge was to create a brand identity and online
                    presence that would resonate with experienced AI/ML
                    professionals and freelancers. The brand needed to convey
                    the promise of a balanced, fulfilling, and stress-free
                    professional life. The name "Palto" was chosen to evoke a
                    sense of comfort and ease, in line with the brand's
                    philosophy of "living well." Additionally, we were tasked
                    with writing the website content, and we successfully
                    completed this as well.
                  </p>
                </div>
                <img
                  src={AboutImage2}
                  alt="Oddsee app"
                  className="wow animated fadeInUp "
                />
              </div>
            </div>
            {/* END SECOND IMAGE */}
            {/* BACKGROUND */}
            <div className=" 799px:mt-[80px] mt-[144px]">
              <img
                className="w-full "
                src={AnimatedBackground}
                alt="palto-software"
              />
            </div>
            {/* END BACKGROUDN */}
            {/* ABOUT 2 */}
            <div className=" 799px:mt-[80px] container max-w-[1320px]  mt-[144px] ">
              <div className="about-app  991px:gap-0 gap-[24px]  mb-[72px]  ">
                <div className=" text   w-full  wow animated fadeInUp">
                  <h1 className=" 991px:text-center  991px:container 991px:max-w-full 991px:mx-auto ">
                    Logo Design
                  </h1>
                  <div>
                    <p className="pb-[8px] ">
                      The logo design process for Palto focused on simplicity
                      and elegance, while maintaining a modern and friendly
                      vibe. The logo includes:
                    </p>
                    <ul className="palto-text-list">
                      <li>
                        A rubber duck symbol, adding a touch of lightness and a
                        vacation-like feel, symbolizing freedom and
                        carefreeness.
                      </li>
                      <li>
                        Bold colors: blue, purple, yellow, black, and white,
                        which attract attention and exude energy and optimism.
                      </li>
                      <li>A modern font that exudes confidence and clarity.</li>
                    </ul>
                  </div>
                </div>
                <img
                  src={AboutImage3}
                  alt="Oddsee app"
                  className="wow animated fadeInUp "
                />
              </div>
              {/* SECOND IMAGE */}
              <div className="about-app  991px:gap-0 gap-[24px] second   ">
                <div className=" text  w-full  wow animated fadeInUp">
                  <h1 className=" 991px:text-center  991px:container 991px:max-w-full 991px:mx-auto ">
                    Landing page
                  </h1>
                  <div>
                    <p className="pb-[8px]">
                      The homepage was designed as a digital reflection of
                      Palto's ethos. Key elements include:
                    </p>
                    <ul className="palto-text-list">
                      <li className="mb-[2px]">
                        A compelling hero section with a bold statement
                        highlighting the freedom and benefits of joining Palto.
                      </li>
                      <li className="mb-[2px]">
                        3D elements that add depth and modernity, creating a
                        more engaging user experience.
                      </li>
                      <li className="mb-[2px]">
                        A clean, intuitive layout ensuring a smooth user
                        experience, effortlessly guiding potential clients
                        through Palto's offerings.
                      </li>
                    </ul>
                  </div>
                </div>
                <img
                  src={AboutImage4}
                  alt="Oddsee app"
                  className="wow animated fadeInUp "
                />
              </div>
            </div>
            {/* END ABOUT 2 */}
            <div className=" 799px:mt-[80px]  mt-[144px]">
              <img className="w-full " src={Banner} alt="palto-banner" />
            </div>
            {/* ABOUT 3 */}
            <div className="container max-w-[1320px]  799px:mt-[80px] mt-[144px] ">
              <div className="about-app 991px:gap-0 gap-[24px] mb-[72px]">
                <div className=" text   w-full  wow animated fadeInUp">
                  <h1 className=" 991px:text-center  991px:container 991px:max-w-full 991px:mx-auto ">
                    Visual and Content Strategy
                  </h1>
                  <div>
                    <p className="pb-[8px] ">
                      Our visual and content strategy focused on creating a
                      fresh, authentic, and relaxed atmosphere. This includes:
                    </p>
                    <ul className="palto-text-list">
                      <li>
                        High-quality photos and graphics showcasing real
                        scenarios and genuine interactions, avoiding typical
                        corporate stock images.
                      </li>
                      <li>
                        A conversational tone in the content, making information
                        accessible and engaging.
                      </li>
                      <li>
                        Emphasizing unique selling points, such as project work
                        flexibility, a support network for freelancers, and the
                        potential for higher earnings and career growth.
                      </li>
                    </ul>
                  </div>
                </div>
                <img
                  src={AboutImage5}
                  alt="Oddsee app"
                  className="wow animated fadeInUp "
                />
              </div>
              {/* SECOND IMAGE */}
              <div className="about-app second  991px:gap-0 gap-[24px] ">
                <div className=" text   w-full  wow animated fadeInUp">
                  <h1 className=" 991px:text-center  991px:container 991px:max-w-full 991px:mx-auto ">
                    Case Study Summary
                  </h1>
                  <p>
                    Palto's brand identity and homepage reflect a revolutionary
                    approach to freelancing in the AI/ML industry. By combining
                    professionalism with a laid-back, authentic vibe, Palto
                    stands out as a beacon of opportunity for technical
                    specialists seeking more than just a job. The logo and
                    digital presence embody the company's mission to offer a
                    balanced, fulfilling, and challenging career path.
                  </p>
                </div>
                <img
                  src={AboutImage6}
                  alt="Oddsee app"
                  className="wow animated fadeInUp "
                />
              </div>
            </div>
            {/* END ABOUT 3 */}
            <div className=" 799px:mt-[80px] mt-[144px]">
              <img
                className="w-full "
                src={AnimatedBackground2}
                alt="palto-software"
              />
            </div>
          </section>
        </div>
      )}
    </Layout>
  );
}

export default Palto;
