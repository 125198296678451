import React, { useRef, useEffect, useState } from "react";
import * as THREE from "three";

export default function ReadAboutAnimation() {
  const mountRef = useRef(null);
  const [rendererSize, setRendererSize] = useState({ width: 900, height: 900 });

  const targetRotationRef = useRef(-0.4);
  const currentRotationRef = useRef(-0.4);

  useEffect(() => {
    let renderer, scene, camera, mesh;

    const initializeScene = () => {
      scene = new THREE.Scene();
      camera = new THREE.PerspectiveCamera(45, 1, 0.1, 1000);
      renderer = new THREE.WebGLRenderer();
      renderer.setClearColor(new THREE.Color("#16404F"), 1);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

      if (mountRef.current) {
        mountRef.current.appendChild(renderer.domElement);
      }

      const p0 = new THREE.Vector2(0, -15);
      const p1 = new THREE.Vector2(1, 1);
      const p2 = new THREE.Vector2(2.5, 0);
      const p3 = new THREE.Vector2(0, 3);

      function getBezierPoint(p0, p1, p2, p3, t) {
        const oneMinusT = 1 - t;
        const oneMinusTSquared = oneMinusT * oneMinusT;
        const tSquared = t * t;
        const threeT = 3 * t;

        const x =
          oneMinusTSquared * oneMinusT * p0.x +
          threeT * oneMinusTSquared * p1.x +
          threeT * tSquared * p2.x +
          tSquared * t * p3.x;
        const y =
          oneMinusTSquared * oneMinusT * p0.y +
          threeT * oneMinusTSquared * p1.y +
          threeT * tSquared * p2.y +
          tSquared * t * p3.y;

        return new THREE.Vector2(x, y);
      }

      const points = [];
      const segments = 8;
      for (let i = 0; i <= segments; i++) {
        const t = i / segments;
        const point = getBezierPoint(p0, p1, p2, p3, t);
        points.push(point);
      }

      const geometry = new THREE.LatheGeometry(points, 30);

      const vertexShader = `
        varying vec3 vPos;
        void main() {
          vPos = position;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `;

      const fragmentShader = `
        uniform float time;
        varying vec3 vPos;
        void main() {
          // Adjust these values to control the wave behavior
          float waveSpeed = 2.0; // Increase to speed up the wave
          float waveLength = 1.0; // Length of the wave cycle
          float waveAmplitude = 0.5; // Amplitude of the wave effect

          // Calculate wave position based on the vertical coordinate (vPos.y) and time
          float wave = sin((vPos.y + time * waveSpeed) * waveLength) * waveAmplitude + waveAmplitude;

          vec3 color1 = vec3(0.247, 0.718, 0.886); // #3FB7E2
          vec3 color2 = vec3(1.0, 1.0, 1.0); // White
          vec3 color = mix(color1, color2, wave);

          gl_FragColor = vec4(color, 1.0);
        }
      `;

      const material = new THREE.ShaderMaterial({
        uniforms: {
          time: { value: 0.0 },
        },
        vertexShader: vertexShader,
        fragmentShader: fragmentShader,
        wireframe: true,
      });

      mesh = new THREE.Mesh(geometry, material);
      scene.add(mesh);

      camera.position.set(13, 13, 13);
      camera.lookAt(scene.position);
      camera.rotation.z = -0.4;

      const animate = () => {
        requestAnimationFrame(animate);

        currentRotationRef.current +=
          (targetRotationRef.current - currentRotationRef.current) * 0.1;
        camera.rotation.z = currentRotationRef.current;

        mesh.rotation.y += 0.002;

        material.uniforms.time.value += 0.02; // Increase the increment to speed up the wave effect

        renderer.render(scene, camera);
      };
      animate();
    };

    initializeScene();

    const calculateRotation = (width) => {
      const minWidth = 650;
      const maxWidth = 991;
      const minRotation = 0.5;
      const maxRotation = -0.4;

      if (width <= minWidth) return minRotation;
      if (width >= maxWidth) return maxRotation;

      return (
        ((width - minWidth) / (maxWidth - minWidth)) *
          (maxRotation - minRotation) +
        minRotation
      );
    };

    const handleResize = () => {
      const width = window.innerWidth;

      targetRotationRef.current = calculateRotation(width);

      let newRendererWidth, newRendererHeight;

      if (width < 400) {
        newRendererWidth = 350;
        newRendererHeight = 350;
      } else if (width < 500) {
        newRendererWidth = 400;
        newRendererHeight = 400;
      } else if (width <= 767) {
        newRendererWidth = 500;
        newRendererHeight = 500;
      } else if (width < 1200) {
        newRendererWidth = 700;
        newRendererHeight = 700;
      } else {
        newRendererWidth = 900;
        newRendererHeight = 900;
      }

      setRendererSize({ width: newRendererWidth, height: newRendererHeight });

      if (renderer && mountRef.current) {
        renderer.setSize(newRendererWidth, newRendererHeight);
        camera.aspect = newRendererWidth / newRendererHeight;
        camera.updateProjectionMatrix();
        renderer.render(scene, camera);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      if (mountRef.current) {
        mountRef.current.removeChild(renderer.domElement);
      }
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      ref={mountRef}
      style={{ width: rendererSize.width, height: rendererSize.height }}
    />
  );
}
