import React from "react";

import Graph from "../../../assets/img/oligamy/about-us/graph.svg";
import SpecialistsSvg from "../../../assets/img/oligamy/about-us/specialists_svg.svg";

import AboutTimelineSwiper from "./AboutTimelineSwiper";
import AboutTimelineSwiperMobile from "./AboutTimelineSwiperMobile";
import { StaggerChildren } from "../offer/Animations";
const aboutTimelineData = [
  {
    year: "2024",
    events: [
      {
        date: "Future.",
        title: "Unknown",
        description: "",
      },
      {
        date: "September.",
        title: "Over 60 associates",
        description:
          "Over 100 projects delivered, and ongoing. Our clients value us as a trusted partner.",
      },
      {
        date: "March.",
        title: "Over 30 proof of concepts created",
        description:
          "Helping achieve business goals for our clients by validating ideas that may shape the future.",
      },
    ],
  },
  {
    year: "2023",
    events: [
      {
        date: "November.",
        title: "Reached 35 active specialists",
        description:
          "Serving customers as development teams, consultants, tech leads, architects, and designers.",
      },
      {
        date: "August.",
        title: "Serving businesses around the globe",
        description:
          "Our specialists are engaged in endeavors across 4 continents, 6 time zones, and multiple industries.",
      },
      {
        date: "January.",
        title: "Over 35 projects delivered so far",
        description:
          "With over 10 long-term client partnerships, we continue to fulfill our business goals.",
      },
    ],
  },
  {
    year: "2022",
    events: [
      {
        date: "October.",
        title: "AI and Blockchain departments founded",
        description:
          "With ongoing team expansion, we assemble teams of specialists to meet customer needs.",
      },
      {
        date: "July.",
        title: "Over 20 actively working associates",
        description:
          "Continuing our search for talent to strengthen our team and provide exceptional value.",
      },
      {
        date: "April.",
        title: "Design department established",
        description: "Broadened our range of services into the creative area.",
      },
    ],
  },
  {
    year: "2021",
    events: [
      {
        date: "December.",
        title: "10 projects delivered",
        description:
          "With 10 projects completed and more ongoing, we continue to deliver value-centric services.",
      },
      {
        date: "September.",
        title: "Reached 10 associated specialists",
        description:
          "Expanded our areas of expertise with new associates, serving clients worldwide.",
      },
      {
        date: "May.",
        title: "Oligamy Software Founded",
        description:
          "Establishment of a company with clear values and a vision for delivering quality service.",
      },
    ],
  },
];

export default function AboutTimeline() {
  return (
    <div className="slider-section w-full bg-[#256c86] mt-[96px] py-[96px] 500px:py-[72px]">
      <div className="container">
        <div className="slides-desktop 767px:hidden">
          <AboutTimelineSwiper aboutTimelineData={aboutTimelineData} />
        </div>
        <div className="slides-mobile hidden 767px:block">
          <AboutTimelineSwiperMobile />
        </div>
        <StaggerChildren>
          <div className="lower-section flex justify-between mt-[98px] 991px:flex-col 991px:gap-[96px]">
            <div className="left max-w-[461px] 991px:max-w-[none]">
              <img src={Graph} alt="Project quantity graph" />
              <h4 className="text-[#fdfdfd] text-[34px] mt-[48px] 500px:text-[22px]">
                Over 100+ successful projects!
              </h4>
              <p className="text-[#efefef] text-[16px] leading-[150%] mt-[12px]">
                We've completed 100+ projects, bolding our expertise and
                commitment to excellent solutions. A big thank you to our
                clients for the collaborative journey toward innovation!
              </p>
            </div>
            <div className="right max-w-[441px] flex flex-col justify-end 991px:max-w-none">
              <img
                src={SpecialistsSvg}
                alt="Project quantity graph"
                className="max-w-[187px] max-h-[165px]"
              />
              <h4 className="text-[#fdfdfd] text-[34px] mt-[48px] 500px:text-[22px]">
                Specialists at Oligamy
              </h4>
              <p className="text-[#efefef] text-[16px] leading-[150%] mt-[12px]">
                With 60+ specialists on board, we cover areas such as software
                development and architecture, design, audits, management, and
                consultancy. Serving a wide range of industries including
                healthcare, fintech, production, logistics, services, and
                commerce, we position ourselves as industry leaders, ready to
                handle the toughest challenges.
              </p>
            </div>
          </div>
        </StaggerChildren>
      </div>
    </div>
  );
}
