import SearchIcon from "../../../assets/img/oligamy/blog/search.svg";

function SearchWidget({ setQuery }) {
  const getElementValue = (e) => {
    e.preventDefault();
    const value = document.getElementById("searchBar").value;
    setQuery(value);
  };

  return (
    <>
      <div className="sidebar-widget search 991px:w-full w-[360px]">
        <div className="widget-search">
          <form action="#">
            <input
              id="searchBar"
              type="text"
              name="search"
              placeholder="Search here"
            />
            <button className="search-btn" onClick={getElementValue}>
              <img src={SearchIcon} alt="" />
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default SearchWidget;
