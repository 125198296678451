import { useEffect, useState } from "react";
import TopPic from "../../../assets/img/oligamy/case-studies/mthrbrd/top.png";

import AboutImage from "../../../assets/img/oligamy/case-studies/mthrbrd/about.png";
import AboutImageTablet from "../../../assets/img/oligamy/case-studies/mthrbrd/about-tablet.png";
import ProcessImage from "../../../assets/img/oligamy/case-studies/mthrbrd/process.png";
import AboutImage2 from "../../../assets/img/oligamy/case-studies/mthrbrd/about2.png";
import AboutImage2Tablet from "../../../assets/img/oligamy/case-studies/mthrbrd/about2-tablet.png";

import EmpathyMap from "../../../assets/img/oligamy/case-studies/mthrbrd/empathy-map.png";
import EmpathyMapMobile from "../../../assets/img/oligamy/case-studies/mthrbrd/empathy-map-mobile.png";

import JourneyMap from "../../../assets/img/oligamy/case-studies/mthrbrd/journey-map.png";

import Screen1 from "../../../assets/img/oligamy/case-studies/mthrbrd/screens/screen1.png";
import Screen2 from "../../../assets/img/oligamy/case-studies/mthrbrd/screens/screen2.png";
import Screen3 from "../../../assets/img/oligamy/case-studies/mthrbrd/screens/screen3.png";
import Screen4 from "../../../assets/img/oligamy/case-studies/mthrbrd/screens/screen4.png";
import Screen5 from "../../../assets/img/oligamy/case-studies/mthrbrd/screens/screen5.png";
import Preloader from "../../layout/Preloader";
import { Gallery, Item } from "react-photoswipe-gallery";
import FloatingForm from "../../common/floating-form/FloatingForm";
import Layout from "../../App";

function Mthrbrd() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <Layout>
      <FloatingForm />
      {loading ? (
        <Preloader />
      ) : (
        <div
          onClick={() => {
            document
              .querySelector(".main-nav")
              .className.includes("slidenav") &&
              document.querySelector(".remove").click();
          }}
        >
          <section className="page-section">
            <div className="position-relative">
              <div className="container max-w-[1320px]">
                <h1 className="title position-absolute 699px:text-[39px]">
                  Swissknife for DAO
                  <br />
                  communities <br />
                </h1>
              </div>
              <img className="w-100" srcSet={TopPic} />
            </div>
            <div className="container max-w-[1320px]">
              <div className="app-data wow animated fadeIn">
                <h3>Client: MTHRBRD</h3>
                <h3>Year: 2022</h3>
              </div>
              <div className="about-app wgmi">
                <div className="text  wow animated fadeInUp">
                  <h1 className="toLeft">
                    By properly profiling users we can match user expectations
                    much easier even before personal customization.
                  </h1>
                  <p>
                    Motherboard is the all-in-one solution for managing DAO
                    activities. Made with user in mind.
                  </p>
                </div>
                <img
                  src={AboutImage}
                  alt="profiling users"
                  className="wow animated fadeInUp mthrbrd-about1 991px:hidden"
                />
                <img
                  src={AboutImageTablet}
                  alt="profiling users"
                  className="wow animated fadeInUp hidden 991px:block"
                />
              </div>
              <div className="process text animated fadeInUp">
                <h1>Process</h1>
                <div className="500px:hidden">
                  <img src={ProcessImage} alt="oddsee development process" />
                </div>
                <div className="hidden 500px:block">
                  <Gallery>
                    <Item
                      original={ProcessImage}
                      thumbnail={ProcessImage}
                      width="1190"
                      height="580"
                    >
                      {({ ref, open }) => (
                        <img ref={ref} onClick={open} src={ProcessImage} />
                      )}
                    </Item>
                  </Gallery>
                </div>
              </div>
              <div className="about-app_alt protoPersona-wrapper">
                <div className="text">
                  <h1 className="wow animate fadeInUp">Proto-Persona</h1>
                  <p>
                    Proto-personas creation is first step in discovering target
                    user group of application. As reference for future user
                    personas they serve as a starting point for intial UX
                    development.
                  </p>
                </div>
                <img
                  src={AboutImage2}
                  alt="profiling users"
                  className="wow animated fadeInUp mthrbrd-about2 991px:hidden"
                />
                <img
                  src={AboutImage2Tablet}
                  alt="profiling users"
                  className="wow animated fadeInUp hidden 991px:block"
                />
              </div>
            </div>
            <div className="empathy-map">
              <img
                src={EmpathyMap}
                alt="Empathy map"
                className="375px:hidden"
              />
              <img
                src={EmpathyMapMobile}
                alt="Empathy map"
                className="hidden 375px:block"
              />
            </div>

            <div className="container max-w-[1320px]">
              <div className="guide-user wgmi text wow animate fadeInUp">
                <h1>Customer journey map</h1>
                <p className="text-[#565656] mb-[72px] max-w-[670px] text-center 911px:max-w-[100%]">
                  By mapping user potential excitement we can properly define
                  critical user activities. Suitable engagement moderation
                  techniques can be applied for further benefit.
                </p>
                <img
                  src={JourneyMap}
                  alt="customer journey map"
                  className="wow animate fadeInUp"
                />
              </div>
              <div className="guide-user wgmi text wow animate fadeInUp screens">
                <h1 className="mb-[72px]">Screens</h1>
                <img
                  src={Screen1}
                  alt="App screen"
                  className="wow animate fadeInUp"
                />
                <img
                  src={Screen2}
                  alt="App screen"
                  className="wow animate fadeInUp shade-img"
                />
                <img
                  src={Screen3}
                  alt="App screen"
                  className="wow animate fadeInUp"
                />
                <img
                  src={Screen4}
                  alt="App screen"
                  className="wow animate fadeInUp"
                />
                <img
                  src={Screen5}
                  alt="App screen"
                  className="wow animate fadeInUp"
                />
              </div>
            </div>
          </section>
        </div>
      )}
    </Layout>
  );
}

export default Mthrbrd;
