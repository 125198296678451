import { useEffect, useState } from "react";
import Preloader from "../../layout/Preloader";
import ImageError404 from "../../../assets/img/oligamy/404/error404.png";
import { Link } from "react-router-dom";
import Layout from "../../App";

function PageNotFound(props) {
  console.log(props);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <Layout isAlternative={true}>
      {loading ? (
        <Preloader />
      ) : (
        <div
          onClick={() => {
            document
              .querySelector(".main-nav")
              .className.includes("slidenav") &&
              document.querySelector(".remove").click();
          }}
        >
          <section className="page-section landing" id="page_not_found">
            <div className="container max-w-[1320px] 1399px:h-[100vh] 991px:flex 991px:items-center 500px:block">
              <div className="mt-[150px] mb-[208px] flex flex-col text-center items-center 1399px:mb-[0] 991px:mt-[0] 500px:mt-[150px] 500px:mb-[208px] 375px:mb-[250px]">
                <img src={ImageError404} alt="404 Error" />
                <div className="max-w-[670px] flex flex-col items-center">
                  <h1 className="text-[45px] leading-[54px] tracking-[-1%] mb-[16px] mt-[34px] 500px:text-[34px] 500px:leading-[auto]">
                    Something’s missing
                  </h1>
                  <p className="text-[#565656]">
                    We apologize, but it seems like you've lost your way on our
                    website. The uniqueness you're seeking is not found here.
                    But don't worry! Our team is working to provide you with an
                    extraordinary experience. You can return to the homepage to
                    continue exploring our services or contact us if you need
                    further assistance. Thank you for your understanding!
                  </p>
                  <Link to={`${process.env.PUBLIC_URL}/`} onClick={scrollTop}>
                    <button className="error-page-btn">
                      Back to home
                      <svg width="12" height="12" viewBox="0 0 22 22">
                        <path d="M8.56832 20.528L10.1402 22.0768L21.0311 11.346L10.1402 0.615234L8.56832 2.16401L17.0043 10.4759H0.259766V12.2161H17.0043L8.56832 20.528Z" />
                      </svg>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </Layout>
  );
}

export default PageNotFound;
