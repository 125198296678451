import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { FormDataProvider } from "./components/common/floating-form/FormDataContext";
import { FormFeedbackProvider } from "./components/common/floating-form/FormFeedbackContext";
import { VisibleHeaderProvider } from "./components/common/header/HeaderVisibilityContext";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HelmetProvider>
    <React.StrictMode>
      <FormDataProvider>
        <FormFeedbackProvider>
          <VisibleHeaderProvider>
            <App />
          </VisibleHeaderProvider>
        </FormFeedbackProvider>
      </FormDataProvider>
    </React.StrictMode>
  </HelmetProvider>
);
