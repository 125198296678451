import { lazy, Suspense, useEffect, useState } from "react";
import FloatingForm from "../../../common/floating-form/FloatingForm";
import Layout from "../../../App";
import { Link, NavLink } from "react-router-dom";
import { useVisibleHeader } from "../../../common/header/HeaderVisibilityContext";
import OfferContact from "../OfferContact";
import OfferBenefits from "../OfferBenefits";
import OfferMainHeading from "../OfferMainHeading";
import OfferInTheNews from "../OfferInTheNews";
import OfferCaseStudies from "../OfferCaseStudies";
import { SlideInFromBottom, StaggerChildren } from "../Animations";
import { useIsMobile } from "../../../../helpers/useIsMobile";
import OfferSkeleton from "../OfferSkeleton";
import Palto from "../../../../assets/img/oligamy/home-case-studies/palto.webp";
import Dredging from "../../../../assets/img/oligamy/home-case-studies/dredging-operations.webp";
import Oversite from "../../../../assets/img/oligamy/home-case-studies/oversite.webp";
import FilipPuzyr from "../../../../assets/img/oligamy/home-case-studies/filip-puzyr.webp";
import { Helmet } from "react-helmet-async";
const StaffAugmentationTopVideoComponent = lazy(() =>
  import("./StaffAugmentationTopVideoComponent")
);
const BENEFITS_DATA = [
  {
    title: "Top-Tier talent",
    description:
      "Access a vast pool of seasoned professionals with expertise in a wide range of technologies and industries. Our experts undergo rigorous vetting to ensure they meet our high standards of excellence.",
  },
  {
    title: "Seamless integration",
    description:
      "Our staff augmentation process is designed to seamlessly integrate our experts into your existing team and workflows. Benefit from a cohesive team dynamic and streamlined collaboration.",
  },
  {
    title: "Tailored matchmaking",
    description:
      "We take the time to understand your project requirements and culture to find the perfect match for your team. Rest assured that you'll be paired with a professional who possesses the skills and experience necessary to excel in their role.",
  },
  {
    title: "On-demand support",
    description:
      "Whether you need a developer, designer, tester, or project manager, we have just the right person for the job. With our staff augmentation services, you can quickly augment your team with the expertise you need to succeed.",
  },
];
function StaffAugmentation() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const isMobile = useIsMobile("(max-width: 500px)");
  const [isQuickNavFixed, setIsQuickNavFixed] = useState(false);
  const caseStudiesData = [
    {
      thumbnail: Palto,
      title: "Palto",
      url: "/design/palto",
      tag1: "Landing Page",
    },
    {
      thumbnail: Dredging,
      title: "Dredging Operations",
      url: "/design/dredging-operations",
      tag1: "Desktop app",
    },
    {
      thumbnail: FilipPuzyr,
      title: "Filip Puzyr",
      url: "/design/filip-puzyr",
      tag1: "Website",
    },
    {
      thumbnail: Oversite,
      title: "Oversite",
      url: "/design/oversite-web",
      tag1: "Web app",
    },
  ];
  useEffect(() => {
    const handleScroll = () => {
      const el = document.querySelector(".offer-quick-nav");
      if (!el) return;
      const elOffset = el.offsetTop;

      const onScroll = () => {
        if (elOffset - 84 <= window.scrollY - 84) {
          setIsQuickNavFixed(true);
        } else {
          setIsQuickNavFixed(false);
        }
      };

      window.removeEventListener("scroll", onScroll);
      window.addEventListener("scroll", onScroll, { passive: true });
    };

    const checkScrollPosition = () => {
      if (window.scrollY === 0) {
        handleScroll();
      }
    };

    checkScrollPosition();

    const onScroll = () => {
      if (window.scrollY === 0) {
        handleScroll();
        window.removeEventListener("scroll", onScroll);
      }
    };

    window.addEventListener("scroll", onScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const { isVisible } = useVisibleHeader();

  // Metadata
  const pageDescription =
    "Expand your team with Oligamy Software’s staff augmentation services. We provide expert developers in AI, blockchain, and custom web/mobile development, ensuring high-quality software and innovative solutions for your business.";

  const pageTitle =
    "Oligamy Software - Staff Augmentation Services | Boost Your Development Team";

  return (
    <Layout isAlternative={true}>
      <FloatingForm />
      <>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta property="og:description" content={pageDescription} />
          <meta property="og:url" content={window.location.href} />{" "}
          {/* Use the current page URL */}
          <meta property="og:type" content="website" />
          {/* Twitter Card tags for Twitter */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDescription} />
        </Helmet>
        <div className="bg-[#FDFDFD]">
          <main
            className="page-section "
            onClick={() => {
              document
                .querySelector(".main-nav")
                .className.includes("slidenav") &&
                document.querySelector(".remove").click();
            }}
          >
            <OfferMainHeading title={"Cooperation models"} />
            {/* desktop */}
            {!isMobile && (
              <section
                className={`offer-quick-nav z-[12] pt-[36px] ${
                  isVisible ? "offset" : ""
                } ${isQuickNavFixed ? "isFixed" : ""}`}
              >
                <nav className="container flex  ">
                  <NavLink to="/offer/digital-acceleration">
                    Digital acceleration
                  </NavLink>
                  <NavLink to="/offer/dedicated-teams">Dedicated teams</NavLink>
                  <NavLink to="/offer/staff-augmentation" onClick={scrollTop}>
                    Staff augmentation
                  </NavLink>
                </nav>
              </section>
            )}
            {/* mobile */}
            {isMobile && (
              <section
                className={`offer-quick-nav z-[12] pt-[36px] ${
                  isVisible ? "offset" : ""
                } ${isQuickNavFixed ? "isFixed" : ""}`}
              >
                <nav className="container flex  ">
                  <NavLink to="/offer/staff-augmentation" onClick={scrollTop}>
                    Staff augmentation
                  </NavLink>
                  <NavLink to="/offer/dedicated-teams">Dedicated teams</NavLink>
                  <NavLink to="/offer/digital-acceleration">
                    Digital acceleration
                  </NavLink>
                </nav>
              </section>
            )}
            <section className="container ">
              <div className="offer-title-card mt-[72px]">
                <div className="500px:min-h-[220px] 767px:min-h-[180px] 991px:min-h-[260px] 1199px:min-h-[340px] 1399px:min-h-[420px] min-h-[550px] 500px:mb-[72px] 1199px:mb-[80px] mb-[96px]">
                  <Suspense fallback={OfferSkeleton()}>
                    <StaffAugmentationTopVideoComponent />
                  </Suspense>
                </div>

                <div className="flex flex-col justify-between">
                  <SlideInFromBottom>
                    <h2 className="500px:text-[29px] 767px:text-[45px] 991px:text-[52px] text-[61px] text-[#0e2933]">
                      Elevate your team with
                      <span className="leading-[120%]">
                        {" "}
                        staff augmentation
                      </span>
                    </h2>
                  </SlideInFromBottom>
                  <SlideInFromBottom>
                    <div className="description mt-[56px] flex flex-col items-end">
                      <div className=" max-w-[670px]">
                        <p className="text-[#565656]">
                          At Oligamy Software, we understand that sometimes you
                          need extra support to meet project demands or bridge
                          skill gaps. That's where our Staff Augmentation
                          services come in. With flexible cooperation models
                          tailored to your needs, we provide the expertise you
                          need, when you need it.
                        </p>
                        <Link
                          to="/contact"
                          className=" inline-block mt-[32px] bg-[#3fb7e2] py-[16px] px-[32px] font-[500] text-[19px] rounded-[8px] text-[#173d46] hover:text-[#fbfbfb] transition-colors duration-500"
                        >
                          Contact us
                        </Link>
                      </div>
                    </div>
                  </SlideInFromBottom>
                </div>
              </div>
            </section>
            {/* BLUE BANNER #1 */}
            <section className=" bg-[#256C86]  500px:mt-[72px] 1199px:mt-[80px] mt-[96px] ">
              <div className="699px:px-4  1399px:max-w-[1040px] 1199px:max-w-[860px] 991px:max-w-[620px] 767px:max-w-[440px] 699px:max-w-[508px] max-w-[1296px] 599px:mx-0 mx-auto">
                <div className="sample-solutions  flex flex-col items-center 500px:py-[72px] 1199px:py-[80px] py-[96px]">
                  <StaggerChildren>
                    <h3 className="500px:text-[29px]  1199px:text-[38px] text-[45px] text-[#FDFDFD] max-w-[812px] text-center">
                      Flexible cooperation models
                    </h3>
                  </StaggerChildren>
                  <div className="solutions-wrapper mt-[72px] grid grid-cols-2 gap-[24px] ">
                    <SlideInFromBottom>
                      <div className=" bg-[#3FB7E2] rounded-[16px] p-[24px] 767px:h-fit h-full">
                        <h4 className="text-[#FDFDFD] 500px:text-[29px] text-[34px]">
                          Scalable solutions
                        </h4>
                        <p className="text-[#EFEFEF] mt-[16px]">
                          Our staff augmentation services are designed to adapt
                          to your changing needs. Whether you need additional
                          resources for a short-term project or ongoing support,
                          we've got you covered.
                        </p>
                      </div>
                    </SlideInFromBottom>
                    <SlideInFromBottom>
                      <div className=" bg-[#3FB7E2] rounded-[16px] p-[24px] 767px:h-fit h-full">
                        <h4 className="text-[#FDFDFD] 500px:text-[29px] text-[34px]">
                          Customized engagement
                        </h4>
                        <p className="text-[#EFEFEF] mt-[16px]">
                          We offer flexible engagement models to suit your
                          preferences and budget. From full-time dedicated
                          resources to part-time specialists, choose the
                          arrangement that works best for your team.
                        </p>
                      </div>
                    </SlideInFromBottom>
                  </div>
                </div>
              </div>
            </section>
            {/* END BLUE BANNER #1 */}

            <OfferBenefits
              benefits={BENEFITS_DATA}
              text={"Take advantage of our experts"}
            />
            <SlideInFromBottom>
              <OfferCaseStudies caseStudiesData={caseStudiesData} />
            </SlideInFromBottom>
            <SlideInFromBottom>
              <OfferInTheNews category={"Software"} />
            </SlideInFromBottom>
            <SlideInFromBottom>
              <OfferContact
                text={
                  "Interested in learning more about our staff augmentation or starting a collaboration? Contact us today!"
                }
              />
            </SlideInFromBottom>
          </main>
        </div>
      </>
    </Layout>
  );
}

export default StaffAugmentation;
