import React, { useEffect, useState } from "react";
import Preloader from "../layout/Preloader";
import FloatingForm from "../common/floating-form/FloatingForm";
import Layout from "../App";
export default function LicensesPage() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const licenses = [
    {
      label: "1. CC Attribution License. Section on the home page: ",
      href: "https://sketchfab.com/3d-models/jarvis-00682d59ea1f41fa86fcae20035a262a",
    },
    {
      label: "2. CC Attribution License. Section on the home page: ",
      href: "https://sketchfab.com/3d-models/ethereum-logo-6c568bbd6ff4407a82643ce543626b4f",
    },
    {
      label: "3. CC Attribution License. Section on the home page: ",
      href: "https://sketchfab.com/3d-models/freebie-lowpoly-people-3c57a85ef5464aa7973f466383d69c48",
    },
  ];
  return (
    <Layout isAlternative={true}>
      <FloatingForm />
      {loading ? (
        <Preloader />
      ) : (
        <div
          onClick={() => {
            document
              .querySelector(".main-nav")
              .className.includes("slidenav") &&
              document.querySelector(".remove").click();
          }}
        >
          <div className="privacy-policy container ">
            <h1>Licenses used on the website</h1>
            <div className="paragraph ">
              <p className="mb-[40px]">
                We prioritize transparency and respect for copyright laws on our
                website. We use various licenses that govern how the materials
                available on our site, such as texts, graphics, photos, or
                software, can be used. Below, you’ll find a description of the
                licenses applied and the rules for using our content.
              </p>
              <div className="767px:pb-0 pb-[250px]">
                <h2 className="mb-[40px]">List of licenses and places</h2>
                {licenses.map((licence, i) => (
                  <div key={i} className="">
                    <p>
                      {licence.label}
                      <a
                        className="licenses-link"
                        target="_blank"
                        href={licence.href}
                      >
                        Link.
                      </a>
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
