import React from "react";
import { Link } from "react-router-dom";

export default function AbandonForm({ handleAbandonFormToggle }) {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="abandon-form-background">
      <div className="abandon-form">
        <div className="upper flex justify-between">
          <h1 className="text-[#0e2933] text-[25px]">Important notice</h1>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            onClick={handleAbandonFormToggle}
            className="cursor-pointer"
          >
            <g opacity="0.7">
              <path
                d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                fill="#565656"
              />
            </g>
          </svg>
        </div>
        <p>
          After clicking the "Go to homepage" button, all entered data in the
          form will be permanently erased, and you will be redirected back to
          the home page.
        </p>
        <p>Are you sure you want to proceed?</p>
        <div className="lower flex gap-[32px] justify-end mt-[48px]">
          <Link
            to={`${process.env.PUBLIC_URL}/`}
            onClick={scrollTop}
            className="flex"
          >
            <button className="abandon-goback">Yes, go back</button>
          </Link>
          <button className="abandon-cancel" onClick={handleAbandonFormToggle}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
