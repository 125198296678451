import React, { useEffect } from "react";
import FirstStep from "./form-steps/FirstStep";
import SecondStep from "./form-steps/SecondStep";
import ThirdStep from "./form-steps/ThirdStep";
import FourthStep from "./form-steps/FourthStep";
import FifthStep from "./form-steps/FifthStep";

export default function Questionnaire({
  progress,
  setProgress,
  currentStep,
  setCurrentStep,
  userResponses,
  setUserResponses,
}) {
  const handleNextStep = () => {
    // Increment the current step and progress when the user clicks the "Next" button
    const newProgress = progress + 20;

    setCurrentStep((prevStep) => prevStep + 1);

    // Scroll to the top of the page
    window.scrollTo({ top: 80, behavior: "smooth" });

    // Check if the new progress value exceeds 100
    if (newProgress <= 100) {
      setProgress(newProgress);
    }
  };

  const handlePreviousStep = () => {
    // Increment the current step and progress when the user clicks the "Next" button
    const newProgress = progress - 20;

    setCurrentStep((prevStep) => prevStep - 1);

    // Scroll to the top of the page
    window.scrollTo({ top: 80, behavior: "smooth" });

    // Check if the new progress value exceeds 100
    if (newProgress <= 100) {
      setProgress(newProgress);
    }
  };

  // Function to render the current step based on the state
  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <FirstStep
            onNextStep={handleNextStep}
            setUserResponses={setUserResponses}
            onPreviousStep={handlePreviousStep}
            userResponses={userResponses}
          />
        );
      case 2:
        return (
          <SecondStep
            onNextStep={handleNextStep}
            setUserResponses={setUserResponses}
            onPreviousStep={handlePreviousStep}
            userResponses={userResponses}
          />
        );
      case 3:
        return (
          <ThirdStep
            onNextStep={handleNextStep}
            setUserResponses={setUserResponses}
            onPreviousStep={handlePreviousStep}
            userResponses={userResponses}
          />
        );
      case 4:
        return (
          <FourthStep
            onNextStep={handleNextStep}
            setUserResponses={setUserResponses}
            onPreviousStep={handlePreviousStep}
            userResponses={userResponses}
          />
        );
      case 5:
        return (
          <FifthStep
            onNextStep={handleNextStep}
            setUserResponses={setUserResponses}
            onPreviousStep={handlePreviousStep}
            userResponses={userResponses}
          />
        );
      default:
        // You can handle other cases or set a default behavior
        return null;
    }
  };

  return <div className="questionnaire-container">{renderCurrentStep()}</div>;
}
