function OfferMainHeading({ title }) {
  return (
    <section className="container">
      <div className="offer-title mt-[155px]">
        <h1 className="text-[#0e2933] text-[95px]">{title}</h1>
      </div>
    </section>
  );
}

export default OfferMainHeading;
