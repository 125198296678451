import { useState, useEffect } from "react";
import Footer from "../common/Footer";
import Header from "../common/header/Header";
import HomePageOne from "../page/home/HomePageOne";
import Preloader from "./Preloader";
import { Helmet } from "react-helmet-async";
import FloatingForm from "../common/floating-form/FloatingForm";
import { useIsMobile } from "../../helpers/useIsMobile";
import FooterMobile from "../common/footer/FooterMobile";
import FooterDesktop from "../common/footer/FooterDesktop";
function MainLayout() {
  const [loading, setLoading] = useState(false);
  const isMobile = useIsMobile("(max-width: 768px)");
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  // Metadata
  const pageDescription =
    "Oligamy Software delivers innovative blockchain, AI, and custom web/mobile development solutions, offering high-quality software and digital design.";

  const pageTitle =
    "Oligamy Software | Blockchain, AI, Web & Mobile Dev Solutions";

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={pageDescription} />
            <meta property="og:description" content={pageDescription} />
            <meta property="og:url" content={window.location.href} />{" "}
            {/* Use the current page URL */}
            <meta property="og:type" content="website" />
            {/* Twitter Card tags for Twitter */}
            <meta name="twitter:title" content={pageTitle} />
            <meta name="twitter:description" content={pageDescription} />
          </Helmet>
          <Header isAlternative={false} />
          <div>
            <HomePageOne />
            <FloatingForm />
            {isMobile ? <FooterMobile /> : <FooterDesktop />}
          </div>
        </>
      )}
    </>
  );
}

export default MainLayout;
