import { createContext, useContext, useState } from "react";

const VisibleHeaderContext = createContext();

export const VisibleHeaderProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(true);

  return (
    <VisibleHeaderContext.Provider value={{ isVisible, setIsVisible }}>
      {children}
    </VisibleHeaderContext.Provider>
  );
};

export const useVisibleHeader = () => {
  const context = useContext(VisibleHeaderContext);
  if (!context) {
    throw new Error(
      "useVisibleHeader must be used within a VisibleHeaderProvider"
    );
  }
  return context;
};
