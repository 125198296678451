import { Link } from "react-router-dom";
import { StaggerChildren } from "./Animations";

function OfferBlueBanner({
  higlightedText,
  restText,
  withButton,
  buttonLabel,
}) {
  return (
    <section className="offer-blue-text  bg-[#256c86]  500px:mt-[72px] 1199px:mt-[80px]  mt-[96px] 500px:py-[72px] 1199px:py-[80px] py-[96px] ">
      <StaggerChildren>
        <div className="container">
          <span className=" 1199px:text-[40px] 500px:text-[34px] text-[45px] text-[#fdfdfd]">
            <span className="leading-[120%]">{higlightedText}</span> {restText}
          </span>
        </div>
        {withButton && (
          <div className="container mt-[46px]">
            <Link
              to="/contact"
              className=" inline-block  bg-[#3fb7e2] py-[16px] px-[32px] font-[500] text-[19px] rounded-[8px] text-[#173d46] hover:text-[#fbfbfb] transition-colors duration-500"
            >
              {buttonLabel}
            </Link>
          </div>
        )}
      </StaggerChildren>
    </section>
  );
}

export default OfferBlueBanner;
