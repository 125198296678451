import { lazy, Suspense, useEffect, useState } from "react";
import FloatingForm from "../../../common/floating-form/FloatingForm";
import Layout from "../../../App";
import { Link, NavLink } from "react-router-dom";
import About2Img from "./about2.png";
import { useVisibleHeader } from "../../../common/header/HeaderVisibilityContext";
import OfferContact from "../OfferContact";
import OfferProcess from "../OfferProcess";
import OfferBlueBanner from "../OfferBlueBanner";
import OfferMainHeading from "../OfferMainHeading";
import OfferInTheNews from "../OfferInTheNews";
import OfferCaseStudies from "../OfferCaseStudies";
import { SlideInFromBottom } from "../Animations";
import OfferSkeleton from "../OfferSkeleton";
// import Oligamy from "../../../../assets/img/oligamy/home-case-studies/oligamy.webp";
import Palto from "../../../../assets/img/oligamy/home-case-studies/palto.webp";
import WGMI from "../../../../assets/img/oligamy/home-case-studies/wgmi.webp";
import Land from "../../../../assets/img/oligamy/home-case-studies/land.webp";
import BillonBattery from "../../../../assets/img/oligamy/home-case-studies/billon-battery.webp";
import { Helmet } from "react-helmet-async";

const UiUxTopVideoComponent = lazy(() => import("./UiUxTopVideoComponent"));

function UIUXDesign() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const caseStudiesData = [
    // {
    //   thumbnail: Oligamy,
    //   title: "Oligamy",
    //   url: "/design/oligamy",
    //   tag1: "Website",
    // },
    {
      thumbnail: Palto,
      title: "Palto",
      url: "/design/palto",
      tag1: "Landing Page",
    },
    {
      thumbnail: WGMI,
      title: "WGMI",
      url: "/design/wgmi",
      tag1: "Landing Page",
      tag2: "Web app",
      tag3: "Web3",
    },
    {
      thumbnail: Land,
      title: "Landing page",
      url: "/design/metaverse",
      tag1: "Landing Page",
      tag2: "Web app",
      tag3: "Web3",
    },
    {
      thumbnail: BillonBattery,
      title: "Product passport",
      url: "/design/product-passport",
      tag1: "Web app",
    },
  ];
  const processSteps = [
    {
      number: "01",
      title: "Understanding business and goals",
      content:
        "We start by thoroughly understanding your company, business goals, and customer expectations.",
    },
    {
      number: "02",
      title: "Research and analysis",
      content:
        "We conduct in-depth market research and competitor analysis to identify the best UX/UI strategies for your product.",
    },
    {
      number: "03",
      title: "Prototyping",
      content:
        "We create user interface prototypes that allow for early testing and iterative improvement of solutions.",
    },
    {
      number: "04",
      title: "Testing",
      content:
        "We conduct user testing to verify the effectiveness of our solutions and make necessary adjustments.",
    },
    {
      number: "05",
      title: "Implementation and optimization",
      content:
        "After the project approval, we proceed with implementation and monitoring to ensure continuous optimization and adaptation to changing needs.",
    },
  ];

  const BENEFITS_DATA = [
    {
      title: "Increased customer satisfaction",
      description:
        "We create interfaces that are not only functional but also enjoyable to use, resulting in customer satisfaction and their willingness to return.",
    },
    {
      title: "Increased conversions",
      description:
        "By understanding user needs and behaviors, we design interfaces that effectively lead to orders and transactions, increasing your business revenues.",
    },
    {
      title: "Efficiency optimization",
      description:
        "Our UX/UI solutions aim to streamline processes, reduce the time needed for actions, and minimize errors, saving time and money.",
    },
    {
      title: "Building trust",
      description:
        "Aesthetic and functional design builds trust with customers in your brand, which can be crucial in making purchasing decisions.",
    },
  ];

  const [isQuickNavFixed, setIsQuickNavFixed] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const el = document.querySelector(".offer-quick-nav");
      if (!el) return;
      const elOffset = el.offsetTop;

      const onScroll = () => {
        if (elOffset - 84 <= window.scrollY - 84) {
          setIsQuickNavFixed(true);
        } else {
          setIsQuickNavFixed(false);
        }
      };

      window.removeEventListener("scroll", onScroll);
      window.addEventListener("scroll", onScroll, { passive: true });
    };

    const checkScrollPosition = () => {
      if (window.scrollY === 0) {
        handleScroll();
      }
    };

    checkScrollPosition();

    const onScroll = () => {
      if (window.scrollY === 0) {
        handleScroll();
        window.removeEventListener("scroll", onScroll);
      }
    };

    window.addEventListener("scroll", onScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const { isVisible } = useVisibleHeader();

  // Metadata
  const pageDescription =
    "Elevate your digital experience with Oligamy Software’s UI/UX design services. We specialize in intuitive, high-quality designs for web and mobile platforms, integrating AI, blockchain, and custom development to create innovative solutions.";

  const pageTitle =
    "Oligamy Software - UI/UX Design Services | Enhance User Experience";

  return (
    <Layout isAlternative={true}>
      <FloatingForm />

      <>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta property="og:description" content={pageDescription} />
          <meta property="og:url" content={window.location.href} />{" "}
          {/* Use the current page URL */}
          <meta property="og:type" content="website" />
          {/* Twitter Card tags for Twitter */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDescription} />
        </Helmet>
        <div className="bg-[#FDFDFD]">
          <main
            className="page-section "
            onClick={() => {
              document
                .querySelector(".main-nav")
                .className.includes("slidenav") &&
                document.querySelector(".remove").click();
            }}
          >
            <OfferMainHeading title={"Design solutions"} />

            <section
              className={`offer-quick-nav z-[12] pt-[36px] ${
                isVisible ? "offset" : ""
              } ${isQuickNavFixed ? "isFixed" : ""}`}
            >
              <nav className="container">
                <NavLink to={`/offer/ui-ux`} onClick={scrollTop}>
                  UX/UI Design
                </NavLink>
              </nav>
            </section>
            <section className="container ">
              <div className="offer-title-card mt-[72px]">
                <div className="500px:min-h-[220px] 767px:min-h-[180px] 991px:min-h-[260px] 1199px:min-h-[340px] 1399px:min-h-[420px] min-h-[550px] 500px:mb-[72px] 1199px:mb-[80px] mb-[96px]">
                  <Suspense fallback={OfferSkeleton()}>
                    <UiUxTopVideoComponent />
                  </Suspense>
                </div>

                <div className="single-row flex justify-between">
                  <SlideInFromBottom>
                    <h2 className="text-[45px] 1199px:text-[38px] 500px:text-[29px] text-[#0E2933]">
                      Why invest in <br className="500px:hidden" />
                      UX/UI Design?
                    </h2>
                  </SlideInFromBottom>
                  <SlideInFromBottom>
                    <div className="description flex flex-col items-end">
                      <div className=" max-w-[670px]">
                        <p className="text-[#565656]">
                          Excellent UX/UI design is a key element of success for
                          any application or website. With intuitive and
                          aesthetic user interfaces, you can effectively
                          attract, engage, and retain customers, building
                          loyalty and increasing conversions.
                        </p>
                        <Link
                          to="/contact"
                          className=" inline-block mt-[32px] bg-[#3fb7e2] py-[16px] px-[32px] font-[500] text-[19px] rounded-[8px] text-[#173d46] hover:text-[#fbfbfb] transition-colors duration-500"
                        >
                          Contact us
                        </Link>
                      </div>
                    </div>
                  </SlideInFromBottom>
                </div>
              </div>
              <div className="offer-text-image-left  991px:gap-[3rem] w-[100%] flex flex-row-reverse items-center justify-between mt-[192px] 500px:mt-[144px] 1199px:mt-[160px]">
                <div className="left max-w-[555px]">
                  <SlideInFromBottom>
                    <h3 className="text-[45px] 1199px:text-[38px] 500px:text-[29px] text-left text-[#0e2933] pb-[40px]">
                      <span>Benefits</span> for your business:
                    </h3>
                  </SlideInFromBottom>
                  {BENEFITS_DATA.map(({ title, description }, id) => (
                    <SlideInFromBottom key={id}>
                      <h5 className="text-[#0e2933] mt-[16px] text-left normal-case text-[25px]">
                        {title}
                      </h5>
                      <p className="text-[#565656] mt-[8px]">{description}</p>
                    </SlideInFromBottom>
                  ))}
                </div>
                <SlideInFromBottom>
                  <div className="image">
                    <img src={About2Img} alt="" className="max-w-[100%]" />
                  </div>
                </SlideInFromBottom>
              </div>
            </section>
            <OfferBlueBanner
              withButton={true}
              higlightedText={"Our philosophy. "}
              buttonLabel={"I want to contact you"}
              restText={
                "We believe that effective UX/UI design requires a combination of creativity with a deep understanding of user needs and business goals."
              }
            />
            <OfferProcess
              title={"UX/UI design process"}
              processSteps={processSteps}
              isProcessDescribed={false}
            />
            <div className="500px:mt-[72px] 1199px:mt-[80px] mt-24">
              <SlideInFromBottom>
                <OfferCaseStudies caseStudiesData={caseStudiesData} />
              </SlideInFromBottom>
            </div>
            <SlideInFromBottom>
              <OfferInTheNews category={"UI/UX"} />
            </SlideInFromBottom>
            <SlideInFromBottom>
              <OfferContact
                text={
                  "Interested in learning more about our UX/UI design services or starting a collaboration? Contact us today!"
                }
              />
            </SlideInFromBottom>
          </main>
        </div>
      </>
    </Layout>
  );
}

export default UIUXDesign;
