import React, { useEffect, useState } from "react";
import Layout from "../../App";
import FooterForm from "../../common/FooterForm";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export default function ContactPage() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  // Metadata
  const pageDescription =
    "Contact Oligamy Software for expert services in blockchain, AI, and custom web/mobile development. Reach out to us for high-quality software and innovative digital design solutions tailored to your business.";

  const pageTitle =
    "Oligamy Software - Contact Us | Get in Touch for Innovative Solutions";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content={window.location.href} />{" "}
        {/* Use the current page URL */}
        <meta property="og:type" content="website" />
        {/* Twitter Card tags for Twitter */}
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
      <Layout isAlternative={true}>
        {/* {loading ? (
        <Preloader />
      ) : (
        <div
          className="creasoft-wrap"
          onClick={() => {
            document
              .querySelector(".main-nav")
              .className.includes("slidenav") &&
              document.querySelector(".remove").click();
          }}
        > */}
        <div className="contact-page container mt-[228px] mb-[200px] 500px:mt-[160px]">
          <div className="contact-section flex justify-between gap-[40px] 991px:flex-col 991px:gap-[100px] 500px:gap-[72px]">
            <div className="left max-w-[670px]">
              <div className="description">
                <h1 className="text-[45px] text-[#0e2933]">Get in Touch!</h1>
                <p className="text-[#565656] mt-[16px]">
                  We're excited that you're interested in partnering with
                  Oligamy Software. Contact us using the form below and check
                  out our social media profiles on Behance and LinkedIn.
                </p>
              </div>
              <div className="form mt-[56px]">
                <FooterForm />
              </div>
            </div>
            <div className="right">
              <div className="contact-info bg-[#efefef] p-[58px] rounded-[16px] 500px:p-[32px]">
                <h4 className="text-[25px] text-[#0e2933] 500px:text-[19px]">
                  Contacts
                </h4>
                <ul className="text-[#565656]">
                  <li className="mt-[24px]">Oligamy Software sp. z o.o.</li>
                  <li className="mt-[16px]">38 Nowiec Street</li>
                  <li>80-293, Gdańsk, Poland</li>
                  <a href="mailto:hello@oligamy.com">
                    <li className="mt-[16px]">hello@oligamy.com</li>
                  </a>
                  <li className="mt-[16px]">NIP 5833433323</li>
                  <li>Regon 389455609</li>
                  <li>KRS 0000911398</li>
                </ul>
                <h4 className="mt-[56px] text-[25px] text-[#0e2933] 500px:text-[19px]">
                  Follow us
                </h4>
                <div className="socials-icons flex gap-[24px] mt-[24px]">
                  <a href="https://www.behance.net/oligamydesign">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1 6.75V25.25H9.9375C10.7656 25.25 16.4688 25.082 16.4688 19.875C16.4688 16.6211 14.2305 15.5156 13.0938 15.1875C13.9297 14.793 15.5938 13.8555 15.5938 11.3438C15.5938 9.94531 15.3945 6.75 9.6875 6.75H1ZM21.375 8.46875V10.1562H28.2812V8.46875H21.375ZM5.0625 9.90625H8.875C9.23438 9.90625 11.6562 9.71484 11.6562 12.0312C11.6562 14.0117 9.90625 14.2188 9.1875 14.2188H5.0625V9.90625ZM25 11.6875C19.7773 11.6875 18.6875 16.2227 18.6875 18.1875C18.6875 24.0195 23.4688 24.6875 25 24.6875C29.1289 24.6875 30.3008 22.0156 30.7812 20.5312H27.7812C27.6641 20.9141 26.7891 22.1562 25.125 22.1562C22.3359 22.1562 22.0938 19.9766 22.0938 19H30.9688C31.1484 15.625 29.6719 11.6875 25 11.6875ZM24.9062 14.2188C25.7383 14.2188 26.4102 14.4609 26.8125 14.9062C27.2148 15.3555 27.5117 16.0117 27.5938 16.875H22.0938C22.1094 16.6367 22.1641 16.3633 22.25 16.0625C22.3359 15.7539 22.4688 15.4609 22.6875 15.1875C22.9062 14.918 23.2109 14.6836 23.5625 14.5C23.9219 14.3125 24.3672 14.2188 24.9062 14.2188ZM5.0625 17H9.46875C10.3438 17 12.4062 17.1367 12.4062 19.6562C12.4062 22.0977 9.80859 22.0938 9.40625 22.0938H5.0625V17Z" />
                    </svg>
                  </a>
                  <a href="https://www.linkedin.com/company/oligamy/">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M8.64258 4C7.18358 4 6 5.18163 6 6.64062C6 8.09963 7.18263 9.30859 8.64062 9.30859C10.0986 9.30859 11.2832 8.09963 11.2832 6.64062C11.2832 5.18263 10.1016 4 8.64258 4ZM21.5352 11C19.3162 11 18.0465 12.1605 17.4375 13.3145H17.373V11.3105H13V26H17.5566V18.7285C17.5566 16.8125 17.7013 14.9609 20.0723 14.9609C22.4093 14.9609 22.4434 17.1456 22.4434 18.8496V26H26.9941H27V17.9316C27 13.9836 26.1512 11 21.5352 11ZM6.36328 11.3105V26H10.9238V11.3105H6.36328Z" />
                    </svg>
                  </a>
                  <a href="https://dribbble.com/OligamyDesign">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M16 4C9.38281 4 4 9.38281 4 16C4 22.6172 9.38281 28 16 28C22.6172 28 28 22.6172 28 16C28 9.38281 22.6172 4 16 4ZM16 6C18.5352 6 20.832 6.95312 22.5938 8.5C22.4062 8.76172 22.1523 9.07812 21.75 9.46875C20.8945 10.2969 19.5273 11.293 17.4375 12.0625C16.0234 9.44922 14.6367 7.48438 13.7188 6.25C14.4492 6.08203 15.2148 6 16 6ZM11.75 6.9375C12.5703 8.01172 14.0312 10.0039 15.5 12.6562C11.4336 13.7344 7.70312 13.8711 6.21875 13.875C6.88281 10.7852 8.96094 8.24219 11.75 6.9375ZM23.9688 9.96875C25.1875 11.582 25.9453 13.5781 26 15.75C25.1094 15.5508 23.7969 15.3555 22.0938 15.3438C21.207 15.3398 20.2031 15.4023 19.125 15.5312C18.875 14.9609 18.6055 14.4102 18.3438 13.875C20.5859 13.0156 22.1133 11.8828 23.125 10.9062C23.4688 10.5703 23.7383 10.2617 23.9688 9.96875ZM16.4062 14.4688C16.6367 14.9336 16.8711 15.4102 17.0938 15.9062C12.8203 17.0898 9.75 20.7148 8.4375 22.5312C6.92578 20.7812 6 18.5 6 16C6 15.957 6 15.918 6 15.875C7.35156 15.8906 11.6484 15.7969 16.4062 14.4688ZM22.0938 17.3125C23.8789 17.3125 25.1133 17.5625 25.8438 17.75C25.3789 20.4141 23.875 22.6992 21.75 24.1875C21.3516 21.7969 20.6953 19.5234 19.9062 17.4375C20.6953 17.3594 21.4414 17.3125 22.0938 17.3125ZM17.875 17.7812C18.7734 20.0625 19.5273 22.5664 19.9062 25.2188C18.7109 25.7227 17.3828 26 16 26C13.707 26 11.5898 25.2305 9.90625 23.9375C10.9336 22.5312 13.9531 18.7891 17.875 17.7812Z" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="anchors mt-[104px] flex gap-[20px] 767px:flex-col 767px:gap-[50px] 500px:mt-[72px] 500px:gap-[64px]">
            <div className="left max-w-[556px]">
              <h2 className="text-[34px] text-[#0e2933] 500px:text-[22px]">
                Download our offer
              </h2>
              <p className="text-[#565656] mt-[16px]">
                Feel free to download our offer in PDF format. Explore our
                innovative solutions!
              </p>
              <div className="offer-download_btn mt-[32px]">
                <a href="/download/oligamy_hello.pdf" download>
                  Download offer
                </a>
              </div>
            </div>
            <div className="right max-w-[556px]">
              <h2 className="text-[34px] text-[#0e2933] 500px:text-[22px]">
                Fill out the brief form
              </h2>
              <p className="text-[#565656] mt-[16px]">
                We want to better understand your needs. Use the brief form to
                help us provide you with a personalized project estimate.
              </p>
              <div className="offer-download_btn mt-[32px]">
                <Link to="/design/expectations-brief">
                  Fill out the Brief Form
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* </div>
      )} */}
      </Layout>
    </>
  );
}
