import HeroArea from "./HeroArea";
import HumanApproach from "./HumanApproach/HumanApproach";
import ReadAboutUs from "./ReadAboutUs/ReadAboutUs";
import CaseStudiesHome from "./CaseStudiesHome/CaseStudiesHome";
import InTheNews from "./InTheNews/InTheNews";
import Scene from "./HomeAnimation/Scene";

function HomePageOne() {
  return (
    <>
      <div className=" homepage">
        <HeroArea />
        <div className=" bg-[#FDFDFD]">
          <Scene />
          <HumanApproach />
          <ReadAboutUs />
          <CaseStudiesHome />
          <InTheNews />
        </div>
      </div>
    </>
  );
}

export default HomePageOne;
