import { lazy, Suspense, useEffect, useState } from "react";
import FloatingForm from "../../../common/floating-form/FloatingForm";
import Layout from "../../../App";
import { NavLink } from "react-router-dom";
import { useVisibleHeader } from "../../../common/header/HeaderVisibilityContext";
import OfferContact from "../OfferContact";
import OfferProcess from "../OfferProcess";
import OfferBlueBannerService from "../OfferBlueBannerService";
import OfferMainHeading from "../OfferMainHeading";
import OfferInTheNews from "../OfferInTheNews";
import OfferCaseStudies from "../OfferCaseStudies";
import { SlideInFromBottom } from "../Animations";
import { useIsMobile } from "../../../../helpers/useIsMobile";
import Billon from "../../../../assets/img/oligamy/home-case-studies/billon.webp";
import WGMI from "../../../../assets/img/oligamy/home-case-studies/wgmi.webp";
import Land from "../../../../assets/img/oligamy/home-case-studies/land.webp";
import OversiteMobile from "../../../../assets/img/oligamy/home-case-studies/oversite- mobile.webp";

const BlockchainDevelopmentTopImgComponent = lazy(() =>
  import("./BlockchainDevelopmentTopImgComponent")
);
import OfferSkeleton from "../OfferSkeleton";
import { Helmet } from "react-helmet-async";
const SingleSolution = ({ title, subtitle, description }) => (
  <div className="single-solution bg-[#efefef] rounded-[16px] p-[24px]">
    <span className="text-[#3fb7e2] text-[95px] 991px:text-[75px] 500px:text-[61px]">
      {title}
    </span>
    <p className="text-[#6c6c6c] mt-[16px]">
      <span className="leading-[120%] font-[600] text-[#6c6c6c]">
        {subtitle}
      </span>{" "}
      - {description}
    </p>
  </div>
);
function BlockchainDevelopment() {
  const isMobile = useIsMobile("(max-width: 500px)");
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const caseStudiesData = [
    {
      thumbnail: Billon,
      title: "Billon App",
      url: "/design/document-reader",
      tag1: "Web app",
      tag2: "Web3",
      tag3: "Design system",
    },
    {
      thumbnail: WGMI,
      title: "WGMI",
      url: "/design/wgmi",
      tag1: "Landing Page",
      tag2: "Web app",
      tag2: "Web3",
    },
    {
      thumbnail: Land,
      title: "Landing page",
      url: "/design/metaverse",
      tag1: "Landing Page",
      tag2: "Web app",
      tag3: "Web3",
    },
    {
      thumbnail: OversiteMobile,
      title: "Oversite Mobile",
      url: "/design/oversite-mobile-app",
      tag1: "Mobile app",
    },
  ];
  const solutions = [
    {
      title: "DeFi",
      subtitle: "DeFi Platforms",
      description:
        "developing decentralized finance platforms for secure and transparent transactions.",
    },
    {
      title: "DAO",
      subtitle: "DAO Development",
      description:
        "implementing autonomous organizations, enabling communities to make collective decisions.",
    },
    {
      title: "NFT",
      subtitle: "NFT Marketplace",
      description:
        "designing and developing marketplaces for non-fungible tokens, allowing creative talents to monetize their works.",
    },
    {
      title: "DApp",
      subtitle: "Decentralized Applications",
      description:
        "creating decentralized applications for enhanced user privacy and control over data.",
    },
  ];
  const processSteps = [
    {
      number: "01",
      title: "Consultations",
      content:
        "We start with in-depth consultations to understand your goals and ideas, laying solid foundations for success.",
    },
    {
      number: "02",
      title: "Technology selection",
      content:
        "We assist in selecting the right technologies tailored to your needs and business objectives.",
    },
    {
      number: "03",
      title: "Development",
      content:
        "An experienced team of programmers transforms the project into reality, ensuring high quality.",
    },
    {
      number: "04",
      title: "Implementation",
      content:
        "We conduct smooth and seamless implementation to fully realize your vision and make it accessible to users.",
    },
  ];

  const services = [
    {
      number: "01",
      title: "Smart contracts",
      content:
        "We create smart contracts on popular platforms like Ethereum, Polygon, Solana etc., ensuring secure and efficient solutions.",
    },
    {
      number: "02",
      title: "Blockchain Audit",
      content:
        "Conducting comprehensive audits for smart contracts and decentralized applications across multiple blockchains, including Ethereum and Solana.",
    },
    {
      number: "03",
      title: "Web3 integration",
      content:
        "Providing seamless integration of websites with blockchain technology, covering both front-end and back-end aspects.",
    },
  ];

  const [isQuickNavFixed, setIsQuickNavFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const el = document.querySelector(".offer-quick-nav");
      if (!el) return;
      const elOffset = el.offsetTop;

      const onScroll = () => {
        if (elOffset - 84 <= window.scrollY - 84) {
          setIsQuickNavFixed(true);
        } else {
          setIsQuickNavFixed(false);
        }
      };

      window.removeEventListener("scroll", onScroll);
      window.addEventListener("scroll", onScroll, { passive: true });
    };

    const checkScrollPosition = () => {
      if (window.scrollY === 0) {
        handleScroll();
      }
    };

    checkScrollPosition();

    const onScroll = () => {
      if (window.scrollY === 0) {
        handleScroll();
        window.removeEventListener("scroll", onScroll);
      }
    };

    window.addEventListener("scroll", onScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const { isVisible } = useVisibleHeader();

  // Metadata
  const pageDescription =
    "Develop secure blockchain solutions with Oligamy Software. We specialize in blockchain, AI, and custom web/mobile development, delivering high-quality software and innovative digital solutions for decentralized systems.";

  const pageTitle =
    "Oligamy Software - Blockchain Development Services | Secure Blockchain Solutions";

  return (
    <Layout isAlternative={true}>
      <FloatingForm />

      <>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta property="og:description" content={pageDescription} />
          <meta property="og:url" content={window.location.href} />{" "}
          {/* Use the current page URL */}
          <meta property="og:type" content="website" />
          {/* Twitter Card tags for Twitter */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDescription} />
        </Helmet>
        <div className=" bg-[#FDFDFD] ">
          <main
            className="page-section"
            onClick={() => {
              document
                .querySelector(".main-nav")
                .className.includes("slidenav") &&
                document.querySelector(".remove").click();
            }}
          >
            <OfferMainHeading title={"Develop products"} />
            {/* desktop */}
            {!isMobile && (
              <section
                className={`offer-quick-nav offer-quick-nav-special z-[12] pt-[36px] ${
                  isVisible ? "offset" : ""
                } ${isQuickNavFixed ? "isFixed" : ""}`}
              >
                <nav className="container">
                  <NavLink to={`/offer/ai-solutions`}>AI Solutions</NavLink>
                  <NavLink
                    to={`/offer/blockchain-development`}
                    onClick={scrollTop}
                  >
                    Blockchain development
                  </NavLink>
                  <NavLink to={`/offer/web-development`}>
                    Web development
                  </NavLink>
                  <NavLink to="/offer/design-implementation-service">
                    Design implementation service
                  </NavLink>
                </nav>
              </section>
            )}
            {/* mobile */}
            {isMobile && (
              <section
                className={`offer-quick-nav z-[12] pt-[36px] ${
                  isVisible ? "offset" : ""
                } ${isQuickNavFixed ? "isFixed" : ""}`}
              >
                <nav className="container">
                  <NavLink
                    to={`/offer/blockchain-development`}
                    onClick={scrollTop}
                  >
                    Blockchain development
                  </NavLink>
                  <NavLink to={`/offer/web-development`}>
                    Web development
                  </NavLink>
                  <NavLink to={`/offer/ai-solutions`}>AI Solutions</NavLink>
                  <NavLink to="/offer/design-implementation-service">
                    Design implementation service
                  </NavLink>
                </nav>
              </section>
            )}
            <section className="container">
              <div className="offer-title-card  mt-[72px] ">
                <div className="500px:min-h-[220px] 767px:min-h-[180px] 991px:min-h-[260px] 1199px:min-h-[340px] 1399px:min-h-[420px] min-h-[550px]">
                  <Suspense fallback={OfferSkeleton()}>
                    <BlockchainDevelopmentTopImgComponent />
                  </Suspense>
                </div>
                <div className="flex justify-between">
                  <SlideInFromBottom>
                    <h2 className=" 500px:text-[29px] 767px:text-[45px] 991px:text-[52px] text-[61px] text-[#0e2933]">
                      <span className="leading-[120%]">
                        Blockchain development.
                      </span>{" "}
                      Achieve success in the world of decentralized finance and
                      blockchain technology.
                    </h2>
                  </SlideInFromBottom>
                </div>
              </div>
            </section>
            <OfferBlueBannerService
              services={services}
              title={"Our service"}
              description={
                "We offer a comprehensive range of blockchain development services, including:"
              }
            />
            <div className="sample-solutions overflow-y-hidden 500px:mt-[72px] 1199px:mt-[80px] mt-[96px]  flex flex-col items-center container ">
              <SlideInFromBottom>
                <h3 className="text-[45px] 1199px:text-[38px] 500px:text-[29px] text-[#0e2933] max-w-[670px] text-center">
                  <span className="leading-[120%]">Sample solutions.</span> Our
                  previous projects include:
                </h3>
              </SlideInFromBottom>
              <SlideInFromBottom>
                <div className=" solutions-wrapper mt-[72px] grid grid-cols-2 gap-[24px] ">
                  {solutions.map((solution, index) => (
                    <SingleSolution
                      key={index}
                      title={solution.title}
                      subtitle={solution.subtitle}
                      description={solution.description}
                    />
                  ))}
                </div>
              </SlideInFromBottom>
            </div>
            <div className="500px:pt-[72px] 1199px:pt-[80px] pt-[96px] ">
              <OfferProcess
                processSteps={processSteps}
                title={"Process"}
                description={
                  "Our development process is based on agile methodologies, ensuring short feedback cycles. We present progress at least once a week, enabling quick verification of work and adjustments to meet your needs. We guarantee that every investment is carefully monitored to deliver value aligned with your expectations."
                }
              />
            </div>
            <div className="500px:mt-[72px] 1199px:mt-[80px] mt-[96px] ">
              <SlideInFromBottom>
                <OfferCaseStudies caseStudiesData={caseStudiesData} />
              </SlideInFromBottom>
            </div>
            <SlideInFromBottom>
              <OfferInTheNews category={"Blockchain"} />
            </SlideInFromBottom>
            <SlideInFromBottom>
              <OfferContact
                text={
                  "Interested in learning more about our blockchain development services or starting a collaboration? Contactus today!"
                }
              />
            </SlideInFromBottom>
          </main>
        </div>
      </>
    </Layout>
  );
}

export default BlockchainDevelopment;
