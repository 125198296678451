import { useEffect, useState } from "react";
import TopPic from "../../../assets/img/oligamy/case-studies/oddsee/oddsee-top.png";

import { Gallery, Item } from "react-photoswipe-gallery";

import WOW from "wowjs";

import AboutImage from "../../../assets/img/oligamy/case-studies/oddsee/about.png";
import AboutImageTablet from "../../../assets/img/oligamy/case-studies/oddsee/about-tablet.png";
import ProcessImage from "../../../assets/img/oligamy/case-studies/oddsee/process.png";
import AboutImage2 from "../../../assets/img/oligamy/case-studies/oddsee/about2.png";
import AboutImage2Tablet from "../../../assets/img/oligamy/case-studies/oddsee/about2-tablet.png";
import AboutImage3 from "../../../assets/img/oligamy/case-studies/oddsee/about3.png";
import AboutImage3Tablet from "../../../assets/img/oligamy/case-studies/oddsee/about3-tablet.png";

import Line1 from "../../../assets/img/oligamy/case-studies/oddsee/line1.png";
import Line2 from "../../../assets/img/oligamy/case-studies/oddsee/line2.png";
import Line3 from "../../../assets/img/oligamy/case-studies/oddsee/line3.png";

import Line1Tablet from "../../../assets/img/oligamy/case-studies/oddsee/line1-tablet.png";
import Line2Tablet from "../../../assets/img/oligamy/case-studies/oddsee/line2-tablet.png";
import Line3Tablet from "../../../assets/img/oligamy/case-studies/oddsee/line3-tablet.png";

import WireframesImage from "../../../assets/img/oligamy/case-studies/oddsee/wireframes.png";

import GuideUser from "../../../assets/img/oligamy/case-studies/oddsee/guideUser.png";

import LandingMcBook from "../../../assets/img/oligamy/case-studies/oddsee/landing-intro/mcbook.png";
import IpadLanding from "../../../assets/img/oligamy/case-studies/oddsee/ipadAlt.png";

import Showcase1 from "../../../assets/img/oligamy/case-studies/oddsee/showcase1.png";
import Showcase2 from "../../../assets/img/oligamy/case-studies/oddsee/showcase2.png";
import Showcase3 from "../../../assets/img/oligamy/case-studies/oddsee/showcase3.png";

import Preloader from "../../layout/Preloader";

import OddseeSwiper from "./OddseeSwiper";
import FloatingForm from "../../common/floating-form/FloatingForm";
import Layout from "../../App";

function Oddsee() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  return (
    <Layout>
      <FloatingForm />
      {loading ? (
        <Preloader />
      ) : (
        <div
          onClick={() => {
            document
              .querySelector(".main-nav")
              .className.includes("slidenav") &&
              document.querySelector(".remove").click();
          }}
        >
          <section className="page-section">
            <div className="position-relative">
              <div className="container max-w-[1320px]">
                <h1 className="title position-absolute 699px:text-[39px]">
                  Oddsee is not just
                  <br />
                  another betting app <br />{" "}
                </h1>
              </div>
              <img className="w-100" srcSet={TopPic} />
            </div>
            <div className="container max-w-[1320px]">
              <div
                className="app-data wow animated fadeIn"
                data-wow-delay="200ms"
              >
                <h3>Client: Oddsee</h3>
                <h3>Year: 2022</h3>
              </div>
              <div className="about-app">
                <div className="text  wow animated fadeInUp">
                  <h1 className="toLeft">
                    Oddsee is a new game formula called Betting Tournaments
                  </h1>
                  <p>
                    Oddsee is an online platform with betting tournaments where
                    users compete in predicting the results of sports events.
                    Being safer alternative to classic sports betting, Oddsee
                    brings the fun of social competition. Instead of trying to
                    beat the bookmaker in a unfair game, participants compete in
                    turn based tournament where the winnings structure is known
                    in advance and the only fee if any is tournament entry fee.{" "}
                  </p>
                </div>
                <img
                  src={AboutImage}
                  alt="Oddsee app"
                  className="wow animated fadeInUp 991px:hidden"
                />
                <img
                  src={AboutImageTablet}
                  alt="Oddsee app"
                  className="wow animated fadeInUp hidden 991px:block"
                />
              </div>
              <div className="process text animated fadeInUp">
                <h1>Process</h1>
                <div className="500px:hidden">
                  <img src={ProcessImage} alt="oddsee development process" />
                </div>
                <div className="hidden 500px:block">
                  <Gallery>
                    <Item
                      original={ProcessImage}
                      thumbnail={ProcessImage}
                      width="1190"
                      height="580"
                    >
                      {({ ref, open }) => (
                        <img ref={ref} onClick={open} src={ProcessImage} />
                      )}
                    </Item>
                  </Gallery>
                </div>
              </div>
              <div className="about-app_alt">
                <div className="text">
                  <h1 className=" wow animated fadeInUp toLeft">
                    When competing in tournament your main tools are statistics
                    and the list of possible events
                  </h1>
                  <p className=" wow animated fadeInUp">
                    Every sport has plenty of them. Presenting large amounts of
                    various data is key challange, it should be done in pleasant
                    and efficient way. <br />
                    <br /> Oddsee is unique when it comes to sports betting.
                    <br />
                    Despite obvious simialrities to bookmaker apps Oddsee ideas
                    should be well presented to enchance differences.
                  </p>
                </div>
                <img
                  src={AboutImage2}
                  alt="Oddsee app"
                  className="wow animated fadeInUp 991px:hidden"
                />
                <img
                  src={AboutImage2Tablet}
                  alt="Oddsee app"
                  className="wow animated fadeInUp hidden 991px:block"
                />
              </div>
            </div>
            <div className="wireframes wow animated fadeInUp">
              <img
                className="wireframes-static 500px:hidden"
                src={WireframesImage}
                alt="oddsee-wireframes"
              />
              <div className="wireframes-swiper hidden 500px:block">
                <OddseeSwiper />
              </div>
            </div>
            <div className="container max-w-[1320px]">
              <div className="about-app mt-[140px] 991px:mt-[72px]">
                <div className="text  wow animated fadeInUp">
                  <h1 className="toLeft">Style guide</h1>
                  <p>
                    Consistent styling makes using the app a pleasant
                    experience. When dealing with huge portions of information
                    to display, visual separation done right provides clear and
                    smooth experience. Colors are one of the most basic tools of
                    expression. Understanding how we can use them to guide user
                    through app can ease the initial friction.
                  </p>
                </div>
                <img
                  className="alt  wow animated fadeInUp oddsee-phones2 911px:hidden"
                  src={AboutImage3}
                  alt="Oddsee app"
                />
                <img
                  className="wow animated fadeInUp hidden 911px:block"
                  src={AboutImage3Tablet}
                  alt="Oddsee app"
                />
                <div className="img-description  wow animated fadeInUp hidden 991px:block">
                  <h2>
                    Tournaments are social events, competitors are real people.
                    We should indicate in a clear way with who you compete and
                    how competition is going. App key features have to be
                    visible.
                  </h2>
                </div>
              </div>
              <div className="description">
                <div className="img-line wow animated fadeInUp">
                  <img
                    src={Line1}
                    alt="Oddsee Style"
                    className="991px:hidden"
                  />
                  <img
                    src={Line2}
                    alt="Oddsee Style"
                    className="991px:hidden"
                  />
                  <img
                    src={Line3}
                    alt="Oddsee Style"
                    className="991px:hidden"
                  />

                  <img
                    src={Line1Tablet}
                    alt="Oddsee Style"
                    className="hidden 991px:block"
                  />
                  <img
                    src={Line2Tablet}
                    alt="Oddsee Style"
                    className="hidden 991px:block"
                  />
                  <img
                    src={Line3Tablet}
                    alt="Oddsee Style"
                    className="hidden 991px:block"
                  />
                </div>
                <div className="img-description  wow animated fadeInUp 991px:hidden">
                  <h2>
                    Tournaments are social events, competitors are real people.
                    We should indicate in a clear way with who you compete and
                    how competition is going. App key features have to be
                    visible.
                  </h2>
                </div>
              </div>
              <div className="guide-user guide-user-oddsee text wow animated fadeInUp">
                <h1 className="oddsee-gallery-header">
                  Guide user through data by highlighting accents, making it
                  easier to digest.
                </h1>
                <img
                  src={GuideUser}
                  alt="Oddsee app overview"
                  className="wow animated fadeInUp"
                />
              </div>
            </div>
            <div className="guide-user guide-user-oddsee guide-user-oddsee-landing text wow animated fadeInUp">
              <div className="landing-intro-background">
                <div className="sec-title white layout2 oddsee oddsee-landing max-w-[555px] 991px:w-[100%] flex flex-col items-center">
                  <h1 className="text-[34px] mb-[0px] landing-intro-header">
                    Oddsee landing page
                  </h1>
                  <p className="text-[16px] text-center">
                    Place where exciting journey starts
                  </p>
                </div>
                <img
                  src={LandingMcBook}
                  alt="Oddsee Landing Page"
                  className="wow animated fadeInUp max-w-[100%] pb-[80px]"
                />
              </div>
            </div>
            <div className="container max-w-[1320px]">
              <div className="guide-user guide-user-oddsee text wow animated fadeInUp">
                <img
                  src={IpadLanding}
                  alt="Oddsee Landing Ipad"
                  className="wow animated fadeInUp max-w-[100%]"
                />
              </div>
              <div className="img-line line-showcase wow animated fadeInUp mb-[300px] 1249px:mb-[250px] 899px:mb-[180px] 449px:mb-[50px]">
                <div className="showcase-img-wrapper">
                  <img src={Showcase1} alt="Oddsee Landing Showcase" />
                </div>
                <div className="showcase-img-wrapper">
                  <img src={Showcase2} alt="Oddsee Landing Showcase" />
                </div>
                <div className="showcase-img-wrapper">
                  <img src={Showcase3} alt="Oddsee Landing Showcase" />
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </Layout>
  );
}

export default Oddsee;
