import BlogStandardWrap from "./BlogStandardWrap";
import Preloader from "../../layout/Preloader";
import { useEffect, useState } from "react";
import FloatingForm from "../../common/floating-form/FloatingForm";
import Layout from "../../App";
import getPosts from "../../../api/action.getPosts";
import sortBlogPosts from "../../../helpers/sortBlogPosts";
import logError from "../../../helpers/logError";
import TagsWidget from "./TagsWidget";
import SearchWidget from "./SearchWidget";
import { Helmet } from "react-helmet-async";
function BlogStandard() {
  const [loading, setLoading] = useState(false);
  const [selectedBlogTag, setSelectedBlogTag] = useState("All");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const PAGE_SIZE = 9;

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const [posts, setPosts] = useState([]);

  // Posts fetching
  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        const rawPosts = await getPosts();
        if (isMounted) {
          if (rawPosts.error) {
            throw "No posts";
          }
          setPosts(sortBlogPosts(rawPosts));
        }
      } catch (err) {
        logError(err);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  const [currentPosts, setCurrentPosts] = useState(posts);

  // Set currentPosts when posts change
  useEffect(() => {
    setCurrentPosts(posts);
  }, [posts]);

  // Filter posts by selected blog tag
  useEffect(() => {
    if (selectedBlogTag === "All") {
      setCurrentPosts(posts);
    } else {
      setCurrentPosts(
        posts.filter((post) =>
          post.attributes.blog_tags.data.some(
            (tag) => tag.attributes.Name === selectedBlogTag
          )
        )
      );
    }
  }, [selectedBlogTag, posts]);

  // Search Bar Functionality
  const [query, setQuery] = useState("");
  useEffect(() => {
    if (query === "") {
      setCurrentPosts(posts);
    } else {
      setCurrentPosts(
        posts.filter((post) =>
          post?.attributes?.title.toLowerCase().includes(query.toLowerCase())
        )
      );
    }
  }, [query, posts]);

  useEffect(() => {
    setHasMore(currentPosts.length > page * PAGE_SIZE);
  }, [currentPosts, page]);

  const fetchMoreData = () => {
    if (hasMore) {
      setPage((prev) => prev + 1);
    }
  };
  const visiblePosts = currentPosts.slice(0, page * PAGE_SIZE);

  // Metadata
  const pageDescription =
    "Explore the latest insights on digital design and development on the Oligamy Software blog. Learn about trends in blockchain, AI, web, and mobile development, and discover innovative solutions to enhance your digital strategy.";

  const pageTitle =
    "Oligamy Software - Design Blog | Insights on Digital Design & Development";

  return (
    <Layout isAlternative={true}>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={pageDescription} />
            <meta property="og:description" content={pageDescription} />
            <meta property="og:url" content={window.location.href} />{" "}
            {/* Use the current page URL */}
            <meta property="og:type" content="website" />
            {/* Twitter Card tags for Twitter */}
            <meta name="twitter:title" content={pageTitle} />
            <meta name="twitter:description" content={pageDescription} />
          </Helmet>

          <div
            onClick={() => {
              document
                .querySelector(".main-nav")
                .className.includes("slidenav") &&
                document.querySelector(".remove").click();
            }}
          >
            <FloatingForm />
            <div className="container">
              <div className="blog-header mt-[84px]">
                <h1
                  className={
                    "pt-[120px] text-[95px] text-[#0e2933] leading-[120%] tracking-[-4.75px] 991px:text-[70px] 767px:text-[60px] 500px:text-[52px]"
                  }
                >
                  Blog
                </h1>
              </div>
            </div>
            <div className="blog-widgets  mt-[40px] flex gap-[50px] justify-between items-center 500px:px-0  container 991px:flex-col-reverse 991px:gap-[40px] 991px:items-start 500px:mt-[56px]">
              <TagsWidget
                setPage={setPage}
                selectedBlogTag={selectedBlogTag}
                setSelectedBlogTag={setSelectedBlogTag}
              />
              <div className="search-wrapper 991px:px-[16px] 991px:container">
                <SearchWidget setQuery={setQuery} />
              </div>
            </div>
            <BlogStandardWrap
              visiblePosts={visiblePosts}
              hasMore={hasMore}
              fetchMoreData={fetchMoreData}
              currentPosts={currentPosts}
            />
          </div>
        </>
      )}
    </Layout>
  );
}

export default BlogStandard;
