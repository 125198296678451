import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function FooterDesktopContent(props) {
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -50;
    const yOffsetAlt = -100;
    if (el.id === "about") {
      window.scrollTo({ top: yCoordinate + yOffsetAlt, behavior: "smooth" });
    } else {
      window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
    }
  };
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <>
      <div className={props.footerAddclass} id="contact">
        <div className="container 1199px:max-w-[100%]">
          <div className="footer-top">
            <div className="row">
              <div className="col-md-3 col-lg-3 col-xl-3 flex justify-center 441px:justify-start">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <HashLink smooth to="#top">
                      <img
                        src={process.env.PUBLIC_URL + "/img/oligamy/logo.svg"}
                        alt="FooterLogo"
                      />
                    </HashLink>
                  </div>
                  <p>
                    Programming is something we do every day – and something
                    we've done forever.
                  </p>
                  <div className="socials-wrapper">
                    <h3>See us here</h3>
                    <div className="socials-buttons">
                      <a
                        href="https://www.behance.net/oligamydesign"
                        target="_blank"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                        >
                          <path d="M0.75 5.56641V19.4414H7.45312C8.07422 19.4414 12.3516 19.3154 12.3516 15.4102C12.3516 12.9697 10.6729 12.1406 9.82031 11.8945C10.4473 11.5986 11.6953 10.8955 11.6953 9.01172C11.6953 7.96289 11.5459 5.56641 7.26562 5.56641H0.75ZM16.0312 6.85547V8.12109H21.2109V6.85547H16.0312ZM3.79688 7.93359H6.65625C6.92578 7.93359 8.74219 7.79004 8.74219 9.52734C8.74219 11.0127 7.42969 11.168 6.89062 11.168H3.79688V7.93359ZM18.75 9.26953C14.833 9.26953 14.0156 12.6709 14.0156 14.1445C14.0156 18.5186 17.6016 19.0195 18.75 19.0195C21.8467 19.0195 22.7256 17.0156 23.0859 15.9023H20.8359C20.748 16.1895 20.0918 17.1211 18.8438 17.1211C16.752 17.1211 16.5703 15.4863 16.5703 14.7539H23.2266C23.3613 12.2227 22.2539 9.26953 18.75 9.26953ZM18.6797 11.168C19.3037 11.168 19.8076 11.3496 20.1094 11.6836C20.4111 12.0205 20.6338 12.5127 20.6953 13.1602H16.5703C16.582 12.9814 16.623 12.7764 16.6875 12.5508C16.752 12.3193 16.8516 12.0996 17.0156 11.8945C17.1797 11.6924 17.4082 11.5166 17.6719 11.3789C17.9414 11.2383 18.2754 11.168 18.6797 11.168ZM3.79688 13.2539H7.10156C7.75781 13.2539 9.30469 13.3564 9.30469 15.2461C9.30469 17.0771 7.35645 17.0742 7.05469 17.0742H3.79688V13.2539Z" />
                        </svg>
                      </a>
                      <a
                        href="https://dribbble.com/OligamyDesign"
                        target="_blank"
                      >
                        <svg
                          width="18"
                          height="19"
                          viewBox="0 0 18 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 0.503906C4.03711 0.503906 0 4.54102 0 9.50391C0 14.4668 4.03711 18.5039 9 18.5039C13.9629 18.5039 18 14.4668 18 9.50391C18 4.54102 13.9629 0.503906 9 0.503906ZM9 2.00391C10.9014 2.00391 12.624 2.71875 13.9453 3.87891C13.8047 4.0752 13.6143 4.3125 13.3125 4.60547C12.6709 5.22656 11.6455 5.97363 10.0781 6.55078C9.01758 4.59082 7.97754 3.11719 7.28906 2.19141C7.83691 2.06543 8.41113 2.00391 9 2.00391ZM5.8125 2.70703C6.42773 3.5127 7.52344 5.00684 8.625 6.99609C5.5752 7.80469 2.77734 7.90723 1.66406 7.91016C2.16211 5.59277 3.7207 3.68555 5.8125 2.70703ZM14.9766 4.98047C15.8906 6.19043 16.459 7.6875 16.5 9.31641C15.832 9.16699 14.8477 9.02051 13.5703 9.01172C12.9053 9.00879 12.1523 9.05566 11.3438 9.15234C11.1562 8.72461 10.9541 8.31152 10.7578 7.91016C12.4395 7.26562 13.585 6.41602 14.3438 5.68359C14.6016 5.43164 14.8037 5.2002 14.9766 4.98047ZM9.30469 8.35547C9.47754 8.7041 9.65332 9.06152 9.82031 9.43359C6.61523 10.3213 4.3125 13.04 3.32812 14.4023C2.19434 13.0898 1.5 11.3789 1.5 9.50391C1.5 9.47168 1.5 9.44238 1.5 9.41016C2.51367 9.42188 5.73633 9.35156 9.30469 8.35547ZM13.5703 10.4883C14.9092 10.4883 15.835 10.6758 16.3828 10.8164C16.0342 12.8145 14.9062 14.5283 13.3125 15.6445C13.0137 13.8516 12.5215 12.1465 11.9297 10.582C12.5215 10.5234 13.0811 10.4883 13.5703 10.4883ZM10.4062 10.8398C11.0801 12.5508 11.6455 14.4287 11.9297 16.418C11.0332 16.7959 10.0371 17.0039 9 17.0039C7.28027 17.0039 5.69238 16.4268 4.42969 15.457C5.2002 14.4023 7.46484 11.5957 10.4062 10.8398Z"
                            fill="#FDFDFD"
                          />
                        </svg>
                      </a>
                      <a
                        href="https://www.linkedin.com/company/oligamy/"
                        target="_blank"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                        >
                          <path d="M6.48193 3.50391C5.38768 3.50391 4.5 4.39013 4.5 5.48438C4.5 6.57862 5.38697 7.48535 6.48047 7.48535C7.57397 7.48535 8.4624 6.57862 8.4624 5.48438C8.4624 4.39088 7.57618 3.50391 6.48193 3.50391ZM16.1514 8.75391C14.4871 8.75391 13.5349 9.62425 13.0781 10.4897H13.0298V8.98682H9.75V20.0039H13.1675V14.5503C13.1675 13.1133 13.2759 11.7246 15.0542 11.7246C16.8069 11.7246 16.8325 13.3631 16.8325 14.6411V20.0039H20.2456H20.25V13.9526C20.25 10.9916 19.6134 8.75391 16.1514 8.75391ZM4.77246 8.98682V20.0039H8.19287V8.98682H4.77246Z" />
                        </svg>
                      </a>
                      {/* <a href="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                        >
                          <path d="M14.4404 2.00391C11.4839 2.00391 9.75 3.56554 9.75 7.12354V10.2539H6V14.0039H9.75V23.0039H13.5V14.0039H16.5L17.25 10.2539H13.5V7.75781C13.5 6.41756 13.9371 5.75391 15.1948 5.75391H17.25V2.15771C16.8945 2.10971 15.8587 2.00391 14.4404 2.00391Z" />
                        </svg>
                      </a>
                      <a href="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                        >
                          <path d="M18.901 1.65723H22.581L14.541 10.8472L24 23.3502H16.594L10.794 15.7662L4.156 23.3502H0.474L9.074 13.5202L0 1.65823H7.594L12.837 8.59023L18.901 1.65723ZM17.61 21.1482H19.649L6.486 3.74423H4.298L17.61 21.1482Z" />
                        </svg>
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-xl-3 flex justify-center  441px:justify-start">
                <div className="footer-widget">
                  <h4>Quick Links</h4>
                  <ul className="footer-menu">
                    <li>
                      <Link to="/" onClick={scrollTop}>
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to="/design/case-studies">All case studies</Link>
                    </li>
                    <li>
                      <Link to="/about-us" onClick={scrollTop}>
                        About
                      </Link>
                    </li>
                    <li>
                      <Link to="/career" onClick={scrollTop}>
                        Career
                      </Link>
                    </li>
                    <li>
                      <Link to="/design/blog" onClick={scrollTop}>
                        Blog
                      </Link>
                    </li>
                    <li>
                      <a href="/download/oligamy_hello.pdf" download>
                        Downloadable offer
                      </a>
                    </li>
                    <li>
                      <Link to="/contact" onClick={scrollTop}>
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-xl-3 flex justify-center 441px:justify-start">
                <div className="footer-widget">
                  <h4>Our Services</h4>
                  <ul className="footer-menu">
                    <li>
                      <Link
                        to={`${process.env.PUBLIC_URL}/offer/ai-validation`}
                        onClick={scrollTop}
                      >
                        Explore & Validate Ideas
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${process.env.PUBLIC_URL}/offer/ai-solutions`}
                        onClick={scrollTop}
                      >
                        Develop Products
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${process.env.PUBLIC_URL}/offer/digital-acceleration`}
                        onClick={scrollTop}
                      >
                        Cooperation Models
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${process.env.PUBLIC_URL}/offer/ui-ux`}
                        onClick={scrollTop}
                      >
                        Design Solutions
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-xl-3 flex justify-center 441px:justify-start">
                <div className="footer-widget">
                  <h4>Contacts</h4>
                  <ul className="footer-menu">
                    <li>Oligamy Software sp. z o.o.</li>
                    <li>
                      Nowiec 38,
                      <br />
                      80-293 Gdańsk, Poland
                    </li>
                    <li>
                      <a href="tel:+48515512796">+48 515 512 796</a>
                      <a href="mailto:hello@oligamy.com">hello@oligamy.com</a>
                    </li>
                    <li>
                      NIP: 5833433323 <br />
                      Regon: 389455609 <br />
                      KRS: 0000911398
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="copy-txt container">
              <span>
                Designed and powered by{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                >
                  <path
                    d="M17 8.32245L15.4584 7.78924L14.9959 6.85972V8.49538L15.7597 8.85566L13.6294 9.99054L13.5383 5.55911L6.93384 0L7.18611 2.25535L8.52803 2.9651L6.93034 3.01554L9.55111 8.74037L8.22321 6.75163L6.68157 7.14073L7.87284 6.22563L7.22465 5.25287L0.196208 11.3668L2.12325 12.0477L0 11.8964V15.6109L0.970528 15.9964L2.60326 13.3231L2.22486 15.1678L2.7434 15.2758L4.11686 12.9376L6.7972 13.6798L7.39984 15.1245L8.04452 15.0489L7.14056 11.0786L9.54411 16L10.3289 15.6541L10.2133 10.3076L8.08656 9.13668L10.4165 9.76717L10.6022 6.10313L10.8755 9.86084L12.8306 8.70074L11.4711 10.0842L11.8601 11.2047L13.9167 11.6298L17 9.26278L16.993 8.32245H17ZM11.5657 6.61833L11.8075 6.48863L12.5293 8.08827L11.5622 6.61833H11.5657Z"
                    fill="#EFEFEF"
                  />
                </svg>{" "}
                Oligamy Software
              </span>
              <div className="flex gap-[40px]">
                <span className="privacy-policy-link">
                  <Link
                    to={`${process.env.PUBLIC_URL}/privacy-policy`}
                    onClick={scrollTop}
                  >
                    Privacy policy
                  </Link>
                </span>
                <span className="privacy-policy-link">
                  <Link
                    to={`${process.env.PUBLIC_URL}/licenses`}
                    onClick={scrollTop}
                  >
                    Licenses
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FooterDesktopContent;
