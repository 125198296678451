import { Link } from "react-router-dom";
import NewGeometryParticles from "./NewGeometryParticles";
import { useEffect, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { useIsMobileLandscape } from "../../../../helpers/useIsMobileLandscape";

const getTransformY = (progress) => {
  if (progress < 0.33) {
    return `translateY(0%)`;
  } else if (progress < 0.66) {
    return `translateY(-100%)`;
  } else {
    return `translateY(-200%)`;
  }
};

const getLinkUrl = (progress) => {
  if (progress < 0.33) {
    return "/offer/ai-validation";
  } else if (progress < 0.66) {
    return "/offer/blockchain-validation";
  } else {
    return "/offer/digital-acceleration";
  }
};

const Scene = () => {
  const [progress, setProgress] = useState(0);
  const [isFixed, setIsFixed] = useState(false);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [inSection, setInSection] = useState(false);
  const [isAboveSection, setIsAboveSection] = useState(true);
  const [finishedSection, setFinishedSection] = useState(false);
  const isMobileLandscape = useIsMobileLandscape();
  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById("offer");
      const hero = document.querySelector(".hero-area");
      if (section && hero) {
        const rect = section.getBoundingClientRect();
        setIsAboveSection(rect.top > 0);
        setIsFixed(rect.top <= 0 && rect.bottom > window.innerHeight);
        setIsAtEnd(rect.bottom <= window.innerHeight);
        setFinishedSection(rect.bottom >= window.innerHeight);
        const sectionTop = section.getBoundingClientRect().top;
        const sectionBottom = section.getBoundingClientRect().bottom;
        const windowHeight = window.innerHeight;
        setInSection(sectionTop <= 0 && sectionBottom >= windowHeight);
        if (sectionTop <= 0 && sectionBottom >= windowHeight) {
          const heroHeight = hero.offsetHeight;
          const sectionHeight = section.offsetHeight - window.innerHeight;
          const scrolled = window.scrollY - heroHeight;
          const progress = Math.min(Math.max(scrolled / sectionHeight, 0), 1);
          setProgress(progress);
        } else if (sectionTop >= windowHeight) {
          setProgress(0);
        } else if (sectionBottom <= 0) {
          setProgress(1);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const sectionNumbers = {
    box: "01. ",
    torus: "02. ",
    sphere: "03. ",
  };
  const sectionTexts = {
    box: "Artificial Intelligence",
    torus: "Blockchain",
    sphere: "Customer Experience",
  };
  const sectionDescriptions = {
    box: "We provide comprehensive Artificial Intelligence services, covering everything from strategy development, through AI model training, integrations up to ongoing solution optimization. Our approach includes understanding the domain, defining business cases, and deploying and refining AI solutions for maximum impact.",
    torus:
      "Secure greater utility and decentralization of your transactions and processes with the expertise of our Blockchain Team. We specialize in complex blockchain solutions and create smart contracts for various protocols. Leveraging our experience in decentralized ecosystems, we drive your websites to the Web3, providing endless possibilities for your projects.",
    sphere:
      "We are premium professionals that will cover your back from hello world to final deployment. Our over 17 years of experience in the tech industry allow us to provide quality assurance and project management, guaranteeing that your project receives the highest level of expertise and is in good hands. We value partnership, and provide efficient solutions.",
  };
  return (
    <div
      id="offer"
      className="flex  1199px:h-[500vh] 1399px:h-[450vh] h-[400vh] relative "
    >
      <div className="sticky top-0 left-0 1199px:w-[100vh] w-[70vw] overflow-hidden h-[100vh] ">
        <Canvas
          className="w-full h-full "
          camera={{ position: [10, 10, 35], fov: 50 }}
        >
          <NewGeometryParticles progress={progress} />
        </Canvas>
      </div>
      <div
        className={`${
          isMobileLandscape ? "w-[60%]" : "1199px:w-full w-[555px]"
        } h-screen flex flex-col 1199px:px-[12px] 1199px:pb-[10px] 1199px:justify-end justify-center 1199px:right-[0px]  right-[10%] ${
          isAboveSection
            ? "absolute top-0"
            : isFixed
            ? "fixed top-0"
            : isAtEnd
            ? "absolute bottom-0"
            : "relative"
        } `}
      >
        <div className="relative h-[56px] overflow-hidden mb-[24px]">
          <div
            className={`${
              isMobileLandscape
                ? "text-[29px]"
                : "500px:text-[22px] 1199px:text-[36px] text-[45px]"
            } text-[#173D46] h-[56px] absolute w-full`}
            style={{
              transform: inSection
                ? getTransformY(progress)
                : isAboveSection
                ? "translateY(0%)"
                : finishedSection
                ? "translateY(-200%)"
                : "translateY(-200%)",
              transition: "transform 0.5s ease",
            }}
          >
            <div className="h-[56px] flex items-center">
              <span> {sectionNumbers["box"]} </span>&nbsp;
              <h2>{sectionTexts["box"]}</h2>
            </div>
            <div className="h-[56px] flex items-center">
              <span> {sectionNumbers["torus"]} </span>&nbsp;
              <h2>{sectionTexts["torus"]}</h2>
            </div>
            <div className="h-[56px] flex items-center">
              <span> {sectionNumbers["sphere"]} </span>&nbsp;
              <h2>{sectionTexts["sphere"]}</h2>
            </div>
          </div>
        </div>

        <div
          className={`${
            isMobileLandscape
              ? " h-[190px]"
              : " 441px:h-[190px] 569px:h-[165px] 799px:h-[120px] 1199px:h-[95px] h-[120px] "
          } relative overflow-hidden`}
        >
          <div
            className={` ${
              isMobileLandscape
                ? " h-[190px]"
                : " 441px:h-[190px] 569px:h-[165px] 799px:h-[120px] 1199px:h-[95px] h-[120px] "
            } absolute w-full`}
            style={{
              transform: inSection
                ? getTransformY(progress)
                : isAboveSection
                ? "translateY(0%)"
                : finishedSection
                ? "translateY(-200%)"
                : "translateY(-200%)",
              transition: "transform 0.5s ease",
            }}
          >
            <div className="mb-[56px] flex flex-col ">
              <div>
                <p
                  className={`${
                    isMobileLandscape
                      ? " h-[190px]"
                      : " 441px:h-[190px] 569px:h-[165px] 799px:h-[120px] 1199px:h-[95px] h-[120px] "
                  }  text-[#565656]`}
                >
                  {sectionDescriptions["box"]}
                </p>
              </div>
              <div>
                <p
                  className={`${
                    isMobileLandscape
                      ? " h-[190px]"
                      : " 441px:h-[190px] 569px:h-[165px] 799px:h-[120px] 1199px:h-[95px] h-[120px] "
                  }  text-[#565656]`}
                >
                  {sectionDescriptions["torus"]}
                </p>
              </div>
              <div>
                <p
                  className={`${
                    isMobileLandscape
                      ? " h-[190px]"
                      : " 441px:h-[190px] 569px:h-[165px] 799px:h-[120px] 1199px:h-[95px] h-[120px] "
                  }  text-[#565656]`}
                >
                  {sectionDescriptions["sphere"]}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Link
            to={getLinkUrl(progress)}
            className="inline-block mt-[32px] bg-[#3fb7e2] py-[16px] px-[32px] font-[500] text-[19px] rounded-[8px] text-[#173d46] hover:text-[#fbfbfb] transition-colors duration-500"
          >
            See details
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Scene;
