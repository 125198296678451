import { useEffect, useState } from "react";
import TopPic from "../../../assets/img/oligamy/case-studies/metaverse/top.png";

import Team from "../../../assets/img/oligamy/case-studies/metaverse/metaverse-mcbook.png";

import Line1 from "../../../assets/img/oligamy/case-studies/metaverse/line1.png";
import Line2 from "../../../assets/img/oligamy/case-studies/metaverse/line2.png";
import Line3 from "../../../assets/img/oligamy/case-studies/metaverse/line3.png";

import Line1Tablet from "../../../assets/img/oligamy/case-studies/metaverse/line1-tablet.png";
import Line2Tablet from "../../../assets/img/oligamy/case-studies/metaverse/line2-tablet.png";
import Line3Tablet from "../../../assets/img/oligamy/case-studies/metaverse/line3-tablet.png";

import LongPreview from "../../../assets/img/oligamy/case-studies/metaverse/metaverse-long.png";
import Preloader from "../../layout/Preloader";
import FloatingForm from "../../common/floating-form/FloatingForm";
import Layout from "../../App";

function Mataverse() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <Layout>
      <FloatingForm />
      {loading ? (
        <Preloader />
      ) : (
        <div
          onClick={() => {
            document
              .querySelector(".main-nav")
              .className.includes("slidenav") &&
              document.querySelector(".remove").click();
          }}
        >
          <section className="page-section landing">
            <div className="position-relative">
              <div className="container max-w-[1320px]">
                <h1 className="title position-absolute 699px:text-[39px]">
                  Build your own special place in
                  <br className="519px:hidden" />
                  this unique metaverse
                </h1>
              </div>
              <img className="w-100" srcSet={TopPic} />
            </div>
            <div className="container max-w-[1320px]">
              <div className="process landing text wow animated fadeInUp">
                <img src={Team} alt="Team-McBook" />
              </div>
              <div className="text mt-[146px] 799px:mt-[74px]">
                <h1 className="text-center">
                  Responsive design makes mobile experience a pure pleasure
                </h1>
                <div className="img-line wow animate fadeInUp">
                  <img
                    src={Line1}
                    alt="Landing preview"
                    className="911px:hidden"
                  />
                  <img
                    src={Line2}
                    alt="Landing preview"
                    className="911px:hidden"
                  />
                  <img
                    src={Line3}
                    alt="Landing preview"
                    className="911px:hidden"
                  />

                  <img
                    src={Line1Tablet}
                    alt="Landing preview"
                    className="hidden 911px:block"
                  />
                  <img
                    src={Line2Tablet}
                    alt="Landing preview"
                    className="hidden 911px:block"
                  />
                  <img
                    src={Line3Tablet}
                    alt="Landing preview"
                    className="hidden 911px:block"
                  />
                </div>
              </div>
            </div>
            <div className="guide-user text wow animate fadeInUp">
              <img
                src={LongPreview}
                alt="Long landing preview"
                className="wow animate fadeInUp max-w-[100%] 1399px:max-w-[100%]"
              />
            </div>
          </section>
        </div>
      )}
    </Layout>
  );
}

export default Mataverse;
