import React, { useEffect, useState } from "react";
import dataInTheNews from "../home/InTheNews/dataInTheNews";
import { Link } from "react-router-dom";
import getLastestPosts from "../../../api/action.getLastestPosts";
import logError from "../../../helpers/logError";
import { isFresh } from "../../../helpers/inTheNewsHelpers";

export default function CaseStudiesInTheNews() {
  const [posts, setPosts] = useState([]);

  // Posts fetching
  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        const rawPosts = await getLastestPosts();
        if (isMounted) {
          if (rawPosts.error) {
            throw new Error("No posts");
          }

          setPosts(rawPosts);
        }
      } catch (err) {
        logError(err);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <section
      className={
        "in-the-news-section 767px:mb-[96px] mb-[201px] pb-[144px] bg-[#235F6E] 767px:pb-[96px] 767px:pt-[72px] pt-[96px]"
      }
    >
      <div className="container">
        <h2
          className={
            "text-[52px] text-[#FDFDFD] 767px:text-[38px] 500px:text-[29px]"
          }
        >
          In the news
        </h2>

        <div className="news-container mt-[40px] relative z-[10]">
          {posts.length === 0 ? (
            <div className=" text-[#FDFDFD]">
              There is no posts at the moment.
            </div>
          ) : (
            posts.map(({ id, attributes }, index) => (
              <Link to={`/design/blog-post/${id}`} key={index}>
                <div className="single-news design-news cursor-pointer flex justify-between border-b-[1px] border-[#EFEFEF] pb-[56px] px-[32px] 991px:flex-col 991px:gap-[40px] 500px:pb-[40px] 500px:px-[16px]">
                  <div className="left self-start flex gap-[12px]">
                    <p
                      className={
                        "text-default text-[#EFEFEF] text-[25px] 500px:text-[19px]"
                      }
                    >
                      {attributes.blog_tags.data[0].attributes.Name}
                    </p>
                    {isFresh(attributes.createdAt) && (
                      <div
                        className={`tag  bg-[#3FB7E2] text-[#FDFDFD] text-[12px] px-[8px] py-[4px] self-center rounded-[100px]`}
                      >
                        Newest
                      </div>
                    )}
                  </div>
                  <div className="right flex gap-[75px] items-baseline 991px:items-center 500px:gap-[24px]">
                    <p
                      className={
                        "text-default text-[#EFEFEF] text-[34px] max-w-[607px] 500px:text-[22px]"
                      }
                    >
                      {attributes.title}
                    </p>
                    <svg
                      className="991px:min-w-[24px]"
                      width="30"
                      height="24"
                      viewBox="0 0 30 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="svg-design"
                        d="M18.3984 0.476562L16.6016 2.27344L25.0781 10.75H0V13.25H25.0781L16.6016 21.7266L18.3984 23.5234L29.0234 12.8984L29.8828 12L29.0234 11.1016L18.3984 0.476562Z"
                      />
                    </svg>
                  </div>
                </div>
              </Link>
            ))
          )}
        </div>
      </div>
    </section>
  );
}
