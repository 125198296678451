import React from "react";

export default function PositiveFeedback() {
  return (
    <div className="feedback positive">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <g clipPath="url(#clip0_2595_33)">
          <path
            d="M10 0C4.47719 0 0 4.47719 0 10C0 15.5231 4.47719 20 10 20C15.5231 20 20 15.5231 20 10C20 4.47719 15.5231 0 10 0ZM10 18.7697C5.17531 18.7697 1.25 14.8247 1.25 9.99996C1.25 5.17527 5.17531 1.24996 10 1.24996C14.8247 1.24996 18.75 5.17529 18.75 9.99996C18.75 14.8246 14.8247 18.7697 10 18.7697ZM13.9909 6.34094L8.12373 12.245L5.48154 9.60281C5.23748 9.35875 4.84186 9.35875 4.59748 9.60281C4.35342 9.84688 4.35342 10.2425 4.59748 10.4866L7.69092 13.5803C7.93498 13.8241 8.33061 13.8241 8.57498 13.5803C8.60311 13.5522 8.62719 13.5215 8.64906 13.4897L14.8753 7.22498C15.1191 6.98092 15.1191 6.58529 14.8753 6.34094C14.6309 6.09688 14.2353 6.09688 13.9909 6.34094Z"
            fill="#EDFCF6"
          />
        </g>
        <defs>
          <clipPath id="clip0_2595_33">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <p>
        The message has been successfully sent! We will try to answer it as
        quickly as possible.
      </p>
    </div>
  );
}
