import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import "./index.css";

// Sub Pages

import CaseStudies from "./components/page/CaseStudies/CaseStudies";

import Blog from "./components/page/BlogStandard/BlogStandard";

import PrivacyPolicy from "./components/page/PrivacyPolicy";

// Case Studies

import Oddsee from "./components/page/CaseStudies/Oddsee";
import Wgmi from "./components/page/CaseStudies/Wgmi";
import Mthrbrd from "./components/page/CaseStudies/mthrbrd";
import Metaverse from "./components/page/CaseStudies/Metaverse";
import Billon from "./components/page/CaseStudies/Billon";
import PageNotFound from "./components/page/error/PageNotFound";
import BlogDetails from "./components/page/BlogDetails/BlogDetails";

import ProductPassport from "./components/page/CaseStudies/ProductPassport";
import BriefForm from "./components/page/brief-form/BriefForm";
import FilipPuzyr from "./components/page/CaseStudies/FilipPuzyr";
import AboutUs from "./components/page/about-us/AboutUs";
import Career from "./components/page/career/Career";
import ContactPage from "./components/page/contact/ContactPage";
import Palto from "./components/page/CaseStudies/Palto";
import DredgingOperations from "./components/page/CaseStudies/DredgingOperations";
import OversiteWeb from "./components/page/CaseStudies/OversiteWeb";
import OversiteMobileApp from "./components/page/CaseStudies/OversiteMobileApp";
import Oligamy from "./components/page/CaseStudies/Oligamy";
import AllCaseStudies from "./components/page/all-case-studies/AllCaseStudies";
import OfferWrapper from "./components/page/offer/OfferWrapper";
import LicensesPage from "./components/page/Licenses";

function App() {
  return (
    <>
      <BrowserRouter basename="/">
        <Switch>
          {/*main layout*/}
          <Route exact path="/" component={MainLayout} />
          {/* all inner page load layout component */}
          <Switch>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/design/oddsee`}
              component={Oddsee}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/design/wgmi`}
              component={Wgmi}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/design/mthrbrd`}
              component={Mthrbrd}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/design`}
              component={CaseStudies}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/design/case-studies`}
              component={AllCaseStudies}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/design/blog`}
              component={Blog}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/design/blog-post/:id`}
              component={BlogDetails}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/design/metaverse`}
              component={Metaverse}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/design/document-reader`}
              component={Billon}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/design/filip-puzyr`}
              component={FilipPuzyr}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/design/palto`}
              component={Palto}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/design/dredging-operations`}
              component={DredgingOperations}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/design/oversite-web`}
              component={OversiteWeb}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/design/oversite-mobile-app`}
              component={OversiteMobileApp}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/design/oligamy`}
              component={Oligamy}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/design/product-passport`}
              component={ProductPassport}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/design/expectations-brief`}
              component={BriefForm}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/privacy-policy`}
              component={PrivacyPolicy}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/licenses`}
              component={LicensesPage}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/about-us`}
              component={AboutUs}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/career`}
              component={Career}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/offer/:slug`}
              component={OfferWrapper}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/contact`}
              component={ContactPage}
            />
            <Route props={{ test: "kacper" }} component={PageNotFound} />
          </Switch>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
