import React from "react";
import CaseStudiesHomeSwiper from "../home/CaseStudiesHome/CaseStudiesHomeSwiper";
import { Link } from "react-router-dom";

function OfferCaseStudies({ caseStudiesData }) {
  return (
    <section
      className={
        "case-studies-home mb-[192px] 500px:mb-[144px] 1199px:mb-[160px] "
      }
    >
      <div className="container">
        <div className="title relative z-[10] flex items-center justify-between py-[96px] 767px:justify-center 500px:py-[72px] 1199px:py-[80px]">
          <h3
            className={
              "text-[#0E2933] 500px:text-[29px] text-[45px] 1199px:text-[38px]"
            }
          >
            Our case studies
          </h3>
          <Link
            to={"/design/case-studies"}
            className="primary-link 767px:hidden"
          >
            See all case studies
          </Link>
        </div>
      </div>
      <div className="swiper-container">
        <CaseStudiesHomeSwiper
          caseStudiesData={caseStudiesData}
          offerPage={true}
        />
      </div>
      <div className="mobile-button-wrapper hidden 767px:block mt-[72px] text-center">
        <Link to={"/design/case-studies"} className="primary-link ">
          See all case studies
        </Link>
      </div>
    </section>
  );
}
export default OfferCaseStudies;
