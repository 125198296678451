import "swiper/css/autoplay";

import Ipad1 from "../../../assets/img/oligamy/case-studies/billon/ipad-mobile/1.png";
import Ipad2 from "../../../assets/img/oligamy/case-studies/billon/ipad-mobile/2.png";
import Ipad3 from "../../../assets/img/oligamy/case-studies/billon/ipad-mobile/3.png";
import Ipad4 from "../../../assets/img/oligamy/case-studies/billon/ipad-mobile/4.png";

// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
SwiperCore.use([Navigation, Autoplay]);
function BillonSwiper() {
  const oddseeSwiper = {
    loop: false,
    spaceBetween: 0,
    speed: 1200,
    slidesPerView: 1.5,
    allowTouchMove: true,
    autoplay: false,
    centeredSlides: false,
  };
  return (
    <>
      <section className="oddsee-slider billon">
        <div className="row">
          <div className="oddsee-swiper-wrapper">
            <Swiper {...oddseeSwiper} className="swiper partner-slider">
              <div className="swiper-wrapper">
                <SwiperSlide className="swiper-slide">
                  <div className="single-partner">
                    <img src={Ipad1} alt="" className="" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <div className="single-partner second">
                    <img src={Ipad2} alt="" className="" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide third">
                  <div className="single-partner">
                    <img src={Ipad3} alt="" className="" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide fourth">
                  <div className="single-partner">
                    <img src={Ipad4} alt="" className="" />
                  </div>
                </SwiperSlide>
              </div>
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
}

export default BillonSwiper;
