import { useEffect, useState } from "react";
import TopPic from "../../../assets/img/oligamy/case-studies/oversite-mobile-app/top.png";

import WOW from "wowjs";

import AboutImage from "../../../assets/img/oligamy/case-studies/oversite-mobile-app/about1.png";
import AboutImage2 from "../../../assets/img/oligamy/case-studies/oversite-mobile-app/about2.png";
import AboutImage3 from "../../../assets/img/oligamy/case-studies/oversite-mobile-app/about3.png";
import AboutImage4 from "../../../assets/img/oligamy/case-studies/oversite-mobile-app/about4.png";
import Video from "../../../assets/img/oligamy/case-studies/oversite-mobile-app/videoc.mp4";
import Bg1 from "../../../assets/img/oligamy/case-studies/oversite-mobile-app/bg1.png";
import Bg2 from "../../../assets/img/oligamy/case-studies/oversite-mobile-app/bg2.png";
import AnimatedBackground from "../../../assets/img/oligamy/case-studies/oversite-mobile-app/animatedbackground.png";
import AnimatedBackground2 from "../../../assets/img/oligamy/case-studies/oversite-mobile-app/animatedbackground2.png";

import FloatingForm from "../../common/floating-form/FloatingForm";
import Layout from "../../App";
import Preloader from "../../layout/Preloader";

function OversiteMobileApp() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  return (
    <Layout>
      <FloatingForm />
      {loading ? (
        <Preloader />
      ) : (
        <div
          onClick={() => {
            document
              .querySelector(".main-nav")
              .className.includes("slidenav") &&
              document.querySelector(".remove").click();
          }}
        >
          <section className="page-section mb-[100px]">
            <div className="position-relative">
              <div className="container max-w-[1320px]">
                <h1 className="title z-10 position-absolute 699px:text-[39px]">
                  Redesigning the Oversite Mobile App
                </h1>
              </div>
              <img className="w-100 filter brightness-[30%] " srcSet={TopPic} />
            </div>
            <div className="container  max-w-[1320px]">
              <div
                className="app-data wow animated fadeIn"
                data-wow-delay="200ms"
              >
                <h3>Client: Oversite</h3>
                <h3>Year: 2024</h3>
              </div>
              <div className="about-app 991px:gap-0 gap-[24px]    mb-[72px] ">
                <div className=" text   w-full  wow animated fadeInUp">
                  <h1 className=" 991px:text-center  991px:container 991px:max-w-full 991px:mx-auto ">
                    Transforming the visual aspect of HR Tech
                  </h1>
                  <p>
                    Oversite Mobile is an innovative application aimed at HR
                    specialists to help select the best candidates for
                    construction projects. Our team at [Software House Name] had
                    the pleasure of conducting a comprehensive visual redesign
                    of this app, while preserving its existing functionalities.
                    Below you will find the details of our work on this project.
                  </p>
                </div>
                <img
                  src={AboutImage}
                  alt="Oddsee app"
                  className="wow animated fadeInUp "
                />
              </div>
              {/* SECOND IMAGE */}
              <div className="about-app second  991px:gap-0 gap-[24px]  ">
                <div className=" text  w-full  wow animated fadeInUp">
                  <h1 className=" 991px:text-center  991px:container 991px:max-w-full 991px:mx-auto ">
                    Project Goal
                  </h1>
                  <p>
                    The primary goal was to refresh the visual aspect of the
                    application to make it more modern, intuitive, and
                    user-friendly. The aesthetic improvement aimed not only to
                    enhance user experience but also to strengthen the
                    professional image of the app.
                  </p>
                </div>
                <img
                  src={AboutImage2}
                  alt="Oddsee app"
                  className="wow animated fadeInUp "
                />
              </div>
            </div>
            {/* END SECOND IMAGE */}
            {/* GRAY BG */}
            <div className="container max-w-[1320px] 799px:mt-[80px] mt-[144px]">
              <div className="bg-[#A9A9A9] p-8 h-fit rounded-[16px] flex justify-between items-end">
                <div>
                  <p>Before</p>
                  <img src={Bg1} alt="" className="rounded-[16px]" />
                </div>
                <div>
                  <p>After</p>
                  <img src={Bg2} alt="" className="rounded-[16px]" />
                </div>
              </div>
            </div>
            {/* END GRAY BG */}

            {/* ABOUT 2 */}
            <div className="container max-w-[1320px] 799px:mt-[80px] mt-[144px]">
              <div className="about-app  991px:gap-0 gap-[24px]   mb-[72px] ">
                <div className=" text   w-full  wow animated fadeInUp">
                  <h1 className=" 991px:text-center  991px:container 991px:max-w-full 991px:mx-auto ">
                    Design Process
                  </h1>
                  <div>
                    <ul className="dredging-text-list  ">
                      <li className="flex gap-[4px] ml-[5px] oversite-web-about-margin">
                        <div className="text-[#3FB7E2]">1.</div>
                        <div>
                          <span className="text-[#3FB7E2]">
                            Analysis of the existing design:{" "}
                          </span>{" "}
                          We analyzed the current look of the application,
                          identifying its strengths and weaknesses.
                        </div>
                      </li>
                      <li className="flex gap-[4px] ml-[5px] oversite-web-about-margin">
                        <div className="text-[#3FB7E2]">2.</div>
                        <div>
                          <span className="text-[#3FB7E2]">Prototyping: </span>{" "}
                          We created several initial prototypes, which were then
                          tested with the target group.
                        </div>
                      </li>
                      <li className="flex gap-[4px] ml-[5px] oversite-web-about-margin">
                        <div className="text-[#3FB7E2]">3.</div>
                        <div>
                          <span className="text-[#3FB7E2]">
                            {" "}
                            Implementation:{" "}
                          </span>
                          We implemented the new design, ensuring that every
                          change was consistent with the original functionality
                          of the app.
                        </div>
                      </li>
                      <li className="flex gap-[4px] ml-[5px] oversite-web-about-margin">
                        <div className="text-[#3FB7E2]">4.</div>
                        <div>
                          <span className="text-[#3FB7E2]">
                            Testing and Optimization:{" "}
                          </span>{" "}
                          We conducted usability tests to ensure that the new
                          look not only appears better but also enhances the
                          user experience.
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <img
                  src={AboutImage3}
                  alt="Oddsee app"
                  className="wow animated fadeInUp "
                />
              </div>
              {/* SECOND IMAGE */}
              <div className="about-app 991px:gap-0 gap-[24px]     second">
                <div className=" text   w-full  wow animated fadeInUp">
                  <h1 className=" 991px:text-center  991px:container 991px:max-w-full 991px:mx-auto ">
                    Key Changes
                  </h1>
                  <div>
                    <ul className="dredging-text-list  ">
                      <li className="flex gap-[4px] ml-[5px] oversite-web-about-margin">
                        <div className="text-[#3FB7E2]">1.</div>
                        <div>
                          <span className="text-[#3FB7E2]">
                            Modern Interface:{" "}
                          </span>{" "}
                          Introduction of cleaner lines, better contrasts, and
                          modern icons.
                        </div>
                      </li>
                      <li className="flex gap-[4px] ml-[5px] oversite-web-about-margin">
                        <div className="text-[#3FB7E2]">2.</div>
                        <div>
                          <span className="text-[#3FB7E2]">
                            Improved Navigation:{" "}
                          </span>{" "}
                          Easier navigation through the app with more intuitive
                          layouts and navigation.
                        </div>
                      </li>
                      <li className="flex gap-[4px] ml-[5px] oversite-web-about-margin">
                        <div className="text-[#3FB7E2]">3.</div>
                        <div>
                          <span className="text-[#3FB7E2]">
                            Optimized Forms:{" "}
                          </span>
                          Simplification of the form-filling process, which is
                          crucial for HR professionals.
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <img
                  src={AboutImage4}
                  alt="Oddsee app"
                  className="wow animated fadeInUp "
                />
              </div>
            </div>
            <div className="  799px:mt-[80px] mt-[144px]">
              <img
                className="w-full "
                src={AnimatedBackground}
                alt="palto-software"
              />
            </div>
            <div>
              <video className=" " playsInline loop autoPlay muted alt="">
                <source src={Video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="container max-w-[1320px] 799px:mt-[80px] mt-[144px]">
              <div className="about-app  991px:gap-0 gap-[24px]  mb-[72px] ">
                <div className=" text   w-full  wow animated fadeInUp">
                  <h1 className=" 991px:text-center  991px:container 991px:max-w-full 991px:mx-auto ">
                    Results
                  </h1>
                  <p>
                    The new design was very well received by users. The
                    application gained in clarity and aesthetics, contributing
                    to greater user satisfaction and improved efficiency in
                    daily use.
                  </p>
                  <p className="pt-[8px]">
                    We are proud of the results of this transformation and
                    believe that Oversite Mobile now serves its users even
                    better. We invite you to check out the before and after
                    visualizations below.
                  </p>
                </div>
                <img
                  src={AboutImage}
                  alt="Oddsee app"
                  className="wow animated fadeInUp "
                />
              </div>
            </div>
            <div className=" 799px:mt-[80px] mt-[144px]">
              <img
                className="w-full "
                src={AnimatedBackground2}
                alt="palto-software"
              />
            </div>
          </section>
        </div>
      )}
    </Layout>
  );
}

export default OversiteMobileApp;
