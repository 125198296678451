import React, { useState, useEffect, useRef } from "react";
import FormContent from "./FormContent";
import { useIsMobile } from "../../../helpers/useIsMobile";
import { useOnOutsideClick } from "../../../hooks/useOnOutsideClick";
import { FiPhoneCall } from "react-icons/fi";
import ConfirmCallModal from "./ConfirmCallModal";

export default function FloatingForm() {
  const [isFloatingFormOpened, setIsFloatingFormOpened] = useState(false);
  const [isTransitionComplete, setIsTransitionComplete] = useState(false);
  const [isFeedbackDisplayed, setIsFeedbackDisplayed] = useState(false);
  const [isShaking, setIsShaking] = useState(false);
  const [confirmCall, setConfirmCall] = useState(false);
  const ref = useOnOutsideClick(() =>
    setIsFloatingFormOpened(!isFloatingFormOpened)
  );
  const isMobile = useIsMobile("(max-width: 500px)");

  const handleConfirmCall = () => {
    setIsTransitionComplete(false);
    setIsShaking(false);
    setConfirmCall(!confirmCall);
  };
  const handleFloatingFormToggle = () => {
    setIsTransitionComplete(false);
    setIsShaking(false);
    setIsFloatingFormOpened(!isFloatingFormOpened);
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 500 && confirmCall) {
        setConfirmCall(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [confirmCall]);
  useEffect(() => {
    if (confirmCall) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [confirmCall]);
  useEffect(() => {
    if (isFloatingFormOpened && isMobile) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isFloatingFormOpened, isMobile]);

  useEffect(() => {
    const timeoutDuration = 200;
    const timeoutId = setTimeout(() => {
      setIsTransitionComplete(true);
    }, timeoutDuration);
    return () => clearTimeout(timeoutId);
  }, [isFloatingFormOpened]);

  useEffect(() => {
    if (isFloatingFormOpened && window.innerWidth < 500) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    if (isFloatingFormOpened && window.innerWidth < 1000 && isLandscape) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isFloatingFormOpened]);

  const [isLandscape, setIsLandscape] = useState(
    window.innerWidth > window.innerHeight
  );

  useEffect(() => {
    const handleResize = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };

    // Initial setup
    handleResize();

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Remove the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //shaking animation
  useEffect(() => {
    const triggerShakingEffect = () => {
      setIsShaking(true);
      setTimeout(() => {
        setIsShaking(false);
      }, 12000);
    };
    const timeTillAnimationStart = 30000;
    const intervalId = setInterval(
      triggerShakingEffect,
      timeTillAnimationStart
    );

    const initialTimeout = setTimeout(() => {
      triggerShakingEffect();
    }, timeTillAnimationStart);

    return () => {
      clearInterval(intervalId);
      clearTimeout(initialTimeout);
    };
  }, []);

  return (
    <div>
      {confirmCall && window.innerWidth < 500 && (
        <ConfirmCallModal onClose={() => setConfirmCall(false)} />
      )}
      {isFloatingFormOpened && window.innerWidth < 500 && (
        <div className="white-overlay" onClick={handleFloatingFormToggle} />
      )}
      {isFloatingFormOpened && window.innerWidth < 1000 && isLandscape && (
        <div className="white-overlay" onClick={handleFloatingFormToggle} />
      )}

      <div
        className="floating-form"
        style={{
          ...(window.innerWidth < 500 && isFloatingFormOpened
            ? {
                bottom: 0,
                right: 0,
                borderRadius: "16px 16px 0 0",
                zIndex: 1000,
              }
            : {}),
          ...(window.innerWidth < 1000 && isFloatingFormOpened && isLandscape
            ? {
                bottom: 0,
                right: 0,
                borderRadius: "16px 16px 0 0",
                zIndex: 1000,
              }
            : {}),
        }}
      >
        {isFloatingFormOpened ? (
          <div
            ref={ref}
            className="opened"
            style={{ height: isFeedbackDisplayed ? 250 : 700 }}
          >
            {isTransitionComplete ? (
              <>
                {isLandscape && window.innerWidth < 1000 ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row-reverse",
                      }}
                    >
                      <div className="closeBtn-wrapper">
                        <div
                          className="closeBtn"
                          onClick={handleFloatingFormToggle}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <g opacity="0.7">
                              <path
                                d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                                fill="#EFEFEF"
                              />
                            </g>
                          </svg>
                        </div>
                      </div>
                      <h2 style={{ width: "2500px" }}>
                        Creating better experiences together. Contact us.
                      </h2>
                    </div>
                    <FormContent
                      setIsFeedbackDisplayed={setIsFeedbackDisplayed}
                      isLandscape={isLandscape}
                    />
                  </>
                ) : (
                  <>
                    <div className="closeBtn-wrapper">
                      <div
                        className="closeBtn"
                        onClick={handleFloatingFormToggle}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g opacity="0.7">
                            <path
                              d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                              fill="#EFEFEF"
                            />
                          </g>
                        </svg>
                      </div>
                    </div>
                    <h2>Creating better experiences together. Contact us.</h2>
                    <FormContent
                      setIsFeedbackDisplayed={setIsFeedbackDisplayed}
                      isLandscape={isLandscape}
                    />
                  </>
                )}
              </>
            ) : null}
          </div>
        ) : (
          <>
            {isMobile ? (
              <div
                onClick={handleConfirmCall}
                className={`closed ${isShaking ? "animatedform" : ""}`}
              >
                <span>
                  <FiPhoneCall
                    className={`${isShaking ? "shaking" : ""} text-[24px]`}
                    color="#FDFDFD"
                  />
                </span>
              </div>
            ) : (
              <div
                className={`closed ${isShaking ? "animatedform" : ""}`}
                onClick={handleFloatingFormToggle}
              >
                <svg
                  className={isShaking ? "shaking" : ""}
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="23"
                  viewBox="0 0 23 23"
                  fill="none"
                >
                  <path
                    d="M1 1H22V17H14L6 21V17H1V1ZM6 9H7V10H6V9ZM11 9H12V10H11V9ZM16 9H17V10H16V9Z"
                    stroke="#FDFDFD"
                    strokeWidth="2"
                  />
                </svg>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
