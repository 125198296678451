import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";

export default function HeroAreaSphere() {
  const colorChangeIntervalRef = useRef(null);
  const isBlack = useRef(true);
  const [sphereSize, setSphereSize] = useState(3.5);
  const [segments, setSegments] = useState({ width: 32, height: 32 });

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;
      if (width > 1350) {
        setSphereSize(3.5);
        setSegments({ width: 22, height: 22 });
      } else if (width > 767) {
        setSphereSize(3.5);
        setSegments({ width: 22, height: 22 });
      } else if (width > 600) {
        setSphereSize(3);
        setSegments({ width: 22, height: 22 });
      } else {
        setSphereSize(2);
        setSegments({ width: 14, height: 14 });
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0x16404f);

    const geometry = new THREE.SphereGeometry(
      sphereSize,
      segments.width,
      segments.height
    );

    const wireframeMaterial = new THREE.MeshBasicMaterial({
      color: 0x3fb7e2,
      wireframe: true,
    });

    const wireframeMesh = new THREE.Mesh(geometry, wireframeMaterial);
    scene.add(wireframeMesh);

    const positions = geometry.attributes.position.array.slice();

    const dotsGeometry = new THREE.BufferGeometry();
    const dotsPositions = new Float32Array(positions.length);
    const shiftDistance = 0.02;

    for (let i = 0; i < positions.length; i += 3) {
      const vertex = new THREE.Vector3(
        positions[i],
        positions[i + 1],
        positions[i + 2]
      );
      const normal = vertex.clone().normalize();
      vertex.addScaledVector(normal, shiftDistance);
      dotsPositions[i] = vertex.x;
      dotsPositions[i + 1] = vertex.y;
      dotsPositions[i + 2] = vertex.z;
    }

    const colors = new Float32Array(positions.length).fill(1);

    dotsGeometry.setAttribute(
      "position",
      new THREE.BufferAttribute(dotsPositions, 3)
    );
    dotsGeometry.setAttribute("color", new THREE.BufferAttribute(colors, 3));

    const dotsMaterial = new THREE.PointsMaterial({
      vertexColors: true,
      size: 0.2,
    });

    const dots = new THREE.Points(dotsGeometry, dotsMaterial);
    scene.add(dots);

    const camera = new THREE.PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    camera.position.set(8, 8, 8);
    camera.lookAt(0, 0, 0);
    wireframeMesh.translateOnAxis(new THREE.Vector3(1, 0, 0), 2);
    dots.translateOnAxis(new THREE.Vector3(1, 0, 0), 2);
    camera.rotation.z = -Math.PI / 1000;

    const canvas = document.querySelector(".webgl");
    const renderer = new THREE.WebGLRenderer({ canvas, antialias: true });
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    const updateRendererSize = () => {
      let width = window.innerWidth;
      let height = window.innerHeight;
      if (width < 500) {
        width = window.innerWidth;
        height = window.innerHeight / 1.4;
      } else {
        width = window.innerWidth / 1.3;
        height = window.innerHeight;
      }
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
    };

    updateRendererSize();
    window.addEventListener("resize", updateRendererSize);

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const cameraNewPosition = 8 - scrollPosition * 0.0035;
      camera.position.set(
        cameraNewPosition,
        cameraNewPosition,
        cameraNewPosition
      );
    };

    window.addEventListener("scroll", handleScroll);

    const render = () => {
      renderer.render(scene, camera);
    };

    const animate = () => {
      requestAnimationFrame(animate);

      const scrollPosition = window.scrollY;
      const rotationSpeed = 0.003 + scrollPosition * 0.000001;

      wireframeMesh.rotation.y += rotationSpeed;

      for (let i = 0; i < dots.geometry.attributes.position.count; i++) {
        const vector = new THREE.Vector3(
          dots.geometry.attributes.position.getX(i),
          dots.geometry.attributes.position.getY(i),
          dots.geometry.attributes.position.getZ(i)
        );
        vector.applyAxisAngle(new THREE.Vector3(0, 1, 0), rotationSpeed);
        dots.geometry.attributes.position.setXYZ(
          i,
          vector.x,
          vector.y,
          vector.z
        );
      }
      dots.geometry.attributes.position.needsUpdate = true;

      render();
    };

    const changeDotColor = () => {
      const duration = 1000;
      const startColor = isBlack.current ? 0x3fb7e2 : 0xffffff;
      const endColor = isBlack.current ? 0xffffff : 0x3fb7e2;
      const startTime = Date.now();

      const animateColor = () => {
        const currentTime = Date.now();
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1);

        const interpolatedColor = new THREE.Color().lerpColors(
          new THREE.Color(startColor),
          new THREE.Color(endColor),
          progress
        );

        dotsMaterial.color = interpolatedColor;

        if (progress < 1) {
          requestAnimationFrame(animateColor);
        } else {
          isBlack.current = !isBlack.current;
        }
      };

      animateColor();
    };

    animate();
    colorChangeIntervalRef.current = setInterval(changeDotColor, 1500);

    return () => {
      window.removeEventListener("resize", updateRendererSize);
      window.removeEventListener("scroll", handleScroll);
      clearInterval(colorChangeIntervalRef.current);
      scene.remove(wireframeMesh);
      scene.remove(dots);
      scene.remove(camera);
      geometry.dispose();
      wireframeMaterial.dispose();
      dotsGeometry.dispose();
      dotsMaterial.dispose();
    };
  }, [sphereSize, segments]);

  return (
    <canvas className="webgl fixed top-[0] right-0 399px:top-[-10%]"></canvas>
  );
}
