import "swiper/css/autoplay";

import Wireframe1 from "../../../assets/img/oligamy/case-studies/oddsee/wireframes/1.png";
import Wireframe2 from "../../../assets/img/oligamy/case-studies/oddsee/wireframes/2.png";
import Wireframe3 from "../../../assets/img/oligamy/case-studies/oddsee/wireframes/3.png";

// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
SwiperCore.use([Navigation, Autoplay]);
function OddseeSwiper() {
  const oddseeSwiper = {
    loop: false,
    spaceBetween: 0,
    speed: 1200,
    slidesPerView: 1.5,
    allowTouchMove: true,
    autoplay: false,
    centeredSlides: true,
  };
  return (
    <>
      <section className="oddsee-slider">
        <div className="sec-title white layout2 oddsee max-w-[555px] 991px:w-[100%] 500px:px-[16px] flex flex-col items-center">
          <h1 className="text-[34px] text-[#fdfdfd] mb-[24px] 500px:text-[24px]">
            Wireframes
          </h1>
          <p className="text-[16px] text-center">
            Getting the essentials right before moving to styling.&nbsp;
            <br className="399px:hidden" />
            Prototype and test the ideas in a fast paced way.
          </p>
        </div>
        <div className="row">
          <div className="oddsee-swiper-wrapper">
            <Swiper {...oddseeSwiper} className="swiper partner-slider">
              <div className="swiper-wrapper">
                <SwiperSlide className="swiper-slide">
                  <div className="single-partner">
                    <img
                      src={Wireframe1}
                      alt="azure-img"
                      className="pt-[25px] 474px:pt-[24px]"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <div className="single-partner second">
                    <img
                      src={Wireframe2}
                      alt="aws-img"
                      className="pt-[22px] 375px:pt-[21px]"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide third">
                  <div className="single-partner">
                    <img
                      src={Wireframe3}
                      alt="react-img"
                      className="pt-[3px] 474px:pt-[4px] 449px:pt-[5px] 441px:pt-[6px] 399px:pt-[7px] 375px:pt-[8px]"
                    />
                  </div>
                </SwiperSlide>
              </div>
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
}

export default OddseeSwiper;
