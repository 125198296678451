import Preloader from "../../layout/Preloader";
import { useEffect, useState } from "react";
import FloatingForm from "../../common/floating-form/FloatingForm";
import Layout from "../../App";
import data from "../home/CaseStudiesHome/dataCaseStudiesHomeSwiper";
import { Link } from "react-router-dom";
import AllCaseStudiesTagsWidget from "./AllCaseStudiesTagsWidget";
import { Helmet } from "react-helmet-async";

function AllCaseStudies() {
  const [loading, setLoading] = useState(false);
  const [selectedBlogTag, setSelectedBlogTag] = useState("All");

  // Metadata

  const pageTitle =
    "Oligamy Software | Design Case Studies | Innovative Web & Mobile Solutions";
  const pageDescription =
    "Explore detailed case studies of Oligamy Software’s design projects. Learn how we implement blockchain, AI, and custom web/mobile development to deliver high-quality, innovative digital design solutions.";

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const filteredData =
    selectedBlogTag === "All"
      ? data
      : data.filter(
          (item) =>
            item.tag1 === selectedBlogTag ||
            item.tag2 === selectedBlogTag ||
            item.tag3 === selectedBlogTag
        );

  return (
    <Layout isAlternative={true}>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <Helmet>
            <meta name="description" content={pageDescription} />
            {/* Open Graph Protocol (OGP) tags for Facebook and others */}
            <title>{pageTitle}</title>
            <meta property="og:description" content={pageDescription} />
            <meta property="og:url" content={window.location.href} />{" "}
            {/* Use the current page URL */}
            <meta property="og:type" content="website" />
            {/* Twitter Card tags for Twitter */}
            <meta name="twitter:title" content={pageTitle} />
            <meta name="twitter:description" content={pageDescription} />
          </Helmet>

          <div
            className="pb-[150px]"
            onClick={() => {
              document
                .querySelector(".main-nav")
                .className.includes("slidenav") &&
                document.querySelector(".remove").click();
            }}
          >
            <FloatingForm />
            <div className="">
              <div className="blog-header container mt-[84px]">
                <h1
                  className={
                    "pt-[120px] text-[95px] text-[#0e2933] leading-[120%] tracking-[-4.75px] 991px:text-[70px] 767px:text-[60px] 500px:text-[52px]"
                  }
                >
                  All case studies
                </h1>
              </div>
              <div className="blog-widgets mt-[40px] 500px:px-0 container  items-center    500px:mt-[56px]">
                <AllCaseStudiesTagsWidget
                  selectedBlogTag={selectedBlogTag}
                  setSelectedBlogTag={setSelectedBlogTag}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 container mt-[72px] gap-x-[20px]">
              {filteredData.map((item, i) => (
                <div
                  className="991px:col-span-2 col-span-1 relative overflow-hidden group "
                  key={i + item.title}
                >
                  <Link to={`${process.env.PUBLIC_URL}${item.url}`}>
                    <div className="absolute z-[10] flex gap-[8px] top-[20px] left-[24px]">
                      {item.tag1 && (
                        <div className="bg-[#FDFDFD80]/50 px-[16px] py-[8px] rounded-[100px] text-[12px] text-[#16404F]">
                          {item.tag1}
                        </div>
                      )}
                      {item.tag2 && (
                        <div className="bg-[#FDFDFD80]/50 px-[16px] py-[8px] rounded-[100px] text-[12px] text-[#16404F]">
                          {item.tag2}
                        </div>
                      )}
                      {item.tag3 && (
                        <div className="bg-[#FDFDFD80]/50 px-[16px] py-[8px] rounded-[100px] text-[12px] text-[#16404F]">
                          {item.tag3}
                        </div>
                      )}
                    </div>
                    <div className="overflow-hidden rounded-[24px]">
                      <img
                        className="w-full h-auto  transform transition-transform duration-500 ease-in-out group-hover:scale-110"
                        src={item.thumbnail}
                        alt="Thumbnail"
                      />
                    </div>
                  </Link>
                  <div className="blog-inner">
                    <Link to={`${process.env.PUBLIC_URL}${item.url}`}>
                      <div className="767px:text-[22px] text-[34px] flex gap-3 items-center 500px:mt-[24px] mt-[32px] mb-[56px] 500px:mb-[40px] text-[#0E2933] ">
                        <h4 className="truncate ">{item.title}</h4>
                        <svg
                          width="30"
                          height="25"
                          viewBox="0 0 30 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.3984 0.976562L16.6016 2.77344L25.0781 11.25H0V13.75H25.0781L16.6016 22.2266L18.3984 24.0234L29.0234 13.3984L29.8828 12.5L29.0234 11.6016L18.3984 0.976562Z"
                            fill="#0E2933"
                          />
                        </svg>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </Layout>
  );
}

export default AllCaseStudies;
