import { ImSpinner } from "react-icons/im";

function BlogLoader() {
  return (
    <div className="h-[50px]  mx-auto flex justify-center 991px:col-span-1 1199px:col-span-2 col-span-3">
      {/* <p className="text-[#0e2933]">Loading...</p> */}
      <ImSpinner className="text-[#173D46] w-10 h-10 animate-spin-blog " />
    </div>
  );
}
export default BlogLoader;
