import { useEffect, useState } from "react";
import Preloader from "../../layout/Preloader";
import FloatingForm from "../../common/floating-form/FloatingForm";
import logError from "../../../helpers/logError";
import getJobOffers from "../../../api/action.getJobOffers";
import Layout from "../../App";
import CareerAccordion from "./CareerAccordion";
import CareerBenefits from "./CareerBenefits";
import { Helmet } from "react-helmet-async";

function Career() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const [extendedOffer, setExtendedOffer] = useState(null);

  const isJobOffersInUrl =
    window.location.pathname.endsWith("/career") &&
    window.location.hash === "#job-offers";

  const [jobOffers, setJobOffers] = useState([]);
  useEffect(() => {
    let isMounted = true;

    (async () => {
      try {
        const rawJobOffers = await getJobOffers();
        if (isMounted) {
          if (rawJobOffers.error) {
            throw "No job offers";
          }
          const sortedJobOffers = rawJobOffers.sort((a, b) => {
            const dateA = new Date(a.attributes.publishedAt);
            const dateB = new Date(b.attributes.publishedAt);
            return dateB - dateA;
          });

          setJobOffers(sortedJobOffers);
        }
      } catch (err) {
        logError(err);
      }
    })();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleExtendedOfferChange = (index) => {
    const elementId = index.toString();

    if (extendedOffer === elementId) {
      setExtendedOffer(null);
    } else {
      setExtendedOffer(elementId);
    }
  };

  useEffect(() => {
    if (isJobOffersInUrl) {
      setTimeout(() => {
        const element = document.getElementById("job-offers");
        if (element) {
          const elementPosition =
            element.getBoundingClientRect().top + window.pageYOffset;

          window.scrollTo({
            top: elementPosition - 100,
            behavior: "smooth",
          });
        }
      }, 1200);
    }
  }, []);

  // Metadata
  const pageDescription =
    "Explore career opportunities at Oligamy Software. Join our team of experts in blockchain, AI, and custom web/mobile development, and contribute to delivering high-quality, innovative software solutions.";

  const pageTitle =
    "Oligamy Software - Careers | Join Our Expert Team in Blockchain & AI";

  return (
    <Layout>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={pageDescription} />
            <meta property="og:description" content={pageDescription} />
            <meta property="og:url" content={window.location.href} />{" "}
            {/* Use the current page URL */}
            <meta property="og:type" content="website" />
            {/* Twitter Card tags for Twitter */}
            <meta name="twitter:title" content={pageTitle} />
            <meta name="twitter:description" content={pageDescription} />
          </Helmet>
          <div
            className=""
            onClick={() => {
              document
                .querySelector(".main-nav")
                .className.includes("slidenav") &&
                document.querySelector(".remove").click();
            }}
          >
            <FloatingForm />

            <section className="page-section-offer mb-[237px]">
              <div className="about-us-title-section bg-[#235F6E] pb-[124px] pt-[156px] 500px:pt-[187px]">
                <div className="container">
                  <h1 className="text-[95px] text-[#FDFDFD] leading-[120%] tracking-[-4.75px] 991px:text-[70px] 767px:text-[60px] 500px:text-[39px] 500px:tracking-[-1.56px]">
                    <span className="tracking-[-4.75px] 500px:tracking-[-1.56px]">
                      Join our team
                    </span>{" "}
                    at <br />
                    Oligamy Software!
                  </h1>
                  <div className="lower-title w-full flex flex-col items-end 991px:items-start mt-[56px] 991px:justify-normal 500px:mt-[48px]">
                    <div>
                      <p className="text-[#FDFDFD] text-[25px] leading-[120%] max-w-[670px] 500px:text-[19px]">
                        At Oligamy Software, we believe that a team of
                        enthusiasts and professionals creates a unique
                        atmosphere, supporting innovation and development. We
                        invite you to experience working in an environment where
                        your skills are valued, and creativity is a key element
                        of success.
                      </p>
                      <a href="mailto:hello@oligamy.com?subject=subject&body=body filler">
                        <button className="mt-[48px] reference-btn self-start">
                          Join our team
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <CareerBenefits />
              <CareerAccordion
                jobOffers={jobOffers}
                extendedOffer={extendedOffer}
                onhandleExtendedOfferChange={handleExtendedOfferChange}
              />
              <div className="contact-us-section container mt-[192px] 767px:mt-[176px] flex justify-between gap-[20px] 991px:flex-col">
                <h2 className="text-[#0e2933] text-[45px] leading-[120%] 500px:text-[29px]">
                  Contact Us!
                </h2>
                <p className="text-[#565656] text-[16px] leading-[150%] max-w-[670px]">
                  If you have questions about a career at Oligamy Software or
                  would like to discuss employment opportunities, contact us:{" "}
                  <a
                    href="mailto:careers@oligamy.com"
                    className="underline text-[#3fb7e2] transition-[0.3s] hover:underline"
                  >
                    careers@oligamy.com
                  </a>
                  <br />
                  <br />
                  We are ready for a shared journey in the world of technology,
                  where your skills are crucial!
                </p>
              </div>
            </section>
          </div>
        </>
      )}
    </Layout>
  );
}

export default Career;
