import React, { useEffect, useRef, useState } from "react";
import humanApproachData from "./humanApproachData";
import BackgroundImage from "../../../../assets/img/oligamy/human-approach/background.svg";
import { motion, useScroll, useTransform } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function HumanApproach() {
  const targetRef = useRef(null);
  const [isLandscapeMobile, setIsLandscapeMobile] = useState(false);

  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  const checkLandscapeMobile = () => {
    const isLandscape = window.innerWidth > window.innerHeight;
    const isMobile = isMobileDevice();
    setIsLandscapeMobile(isMobile && isLandscape);
  };

  useEffect(() => {
    window.addEventListener("resize", checkLandscapeMobile);
    checkLandscapeMobile();

    return () => window.removeEventListener("resize", checkLandscapeMobile);
  }, []);

  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end end"],
  });

  return (
    <section
      className={`human-approach-section ${
        isLandscapeMobile ? "pb-[144px]" : "h-[300vh] min-h-[100vh]"
      } 1249px:mt-[126px] 991px:min-h-[0] relative z-[2] pb-[144px} 500px:pb-[72px] 500px:mt-[0]`}
      ref={targetRef}
    >
      <div
        className={`animation-wrapper ${
          isLandscapeMobile ? "" : "sticky h-screen"
        } top-[50px] 991px:top-[20px] 767px:top-[50px] 500px:top-0`}
      >
        <img
          src={BackgroundImage}
          alt=""
          className={`absolute z-[-1] max-w-[782px] top-[0] right-[0] 500px:hidden ${
            isLandscapeMobile ? "1249px:hidden" : ""
          }`}
        />
        <img
          src={BackgroundImage}
          alt=""
          className={`absolute z-[-1] max-w-[782px] bottom-[-10%] left-[-300px] 500px:hidden ${
            isLandscapeMobile ? "1249px:hidden" : ""
          }`}
        />
        <div
          className={`container relative ${
            isLandscapeMobile ? "" : "911px:top-1/2 911px:translate-y-[-50%]"
          }`}
        >
          <h1
            className={
              "text-[52px] text-[#0E2933] text-center mb-[72px] 767px:text-[38px] 0px:mb-[56px] 500px:text-[29px]"
            }
          >
            The human approach
          </h1>
          <MessagesWrapper
            scrollYProgress={scrollYProgress}
            isLandscapeMobile={isLandscapeMobile}
          />
        </div>
      </div>
    </section>
  );
}

const MessagesWrapper = ({ scrollYProgress, isLandscapeMobile }) => {
  const totalMessages = humanApproachData.length;
  const animationEndPoint = 0.9;
  const minBreak = 0.1;
  const totalAvailableScroll = animationEndPoint - totalMessages * minBreak;
  const durationPerMessage = totalAvailableScroll / totalMessages;

  const [completedAnimations, setCompletedAnimations] = useState({});

  const handleAnimationComplete = (index) => {
    setCompletedAnimations((prev) => ({ ...prev, [index]: true }));
  };

  return (
    <div className="messages-wrapper">
      {humanApproachData.map(({ avatar, message }, index) => (
        <MessageComponent
          key={index}
          avatar={avatar}
          message={message}
          index={index}
          scrollYProgress={scrollYProgress}
          totalMessages={totalMessages}
          durationPerMessage={durationPerMessage}
          minBreak={minBreak}
          isLandscapeMobile={isLandscapeMobile}
          previousCompleted={
            index === 0 || completedAnimations[index - 1] || false
          }
          onAnimationComplete={() => handleAnimationComplete(index)}
        />
      ))}
    </div>
  );
};

const MessageComponent = ({
  avatar,
  message,
  index,
  scrollYProgress,
  totalMessages,
  durationPerMessage,
  minBreak,
  isLandscapeMobile,
  previousCompleted,
  onAnimationComplete,
}) => {
  const writingContainerRef = useRef(null);
  const messageContainerRef = useRef(null);
  const [messageVisible, setMessageVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 500);

  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: "0px 0px -20% 0px",
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 500);
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (inView && previousCompleted) {
      setTimeout(() => {
        if (writingContainerRef.current) {
          writingContainerRef.current.style.display = "none";
          setMessageVisible(true);
          onAnimationComplete();
        }
      }, 700); // Adjust this timeout as needed
    }
  }, [inView, previousCompleted, onAnimationComplete]);

  const startPoint = index * (durationPerMessage + minBreak);
  const endPoint = startPoint + durationPerMessage;

  const messageMoveUp = useTransform(
    scrollYProgress,
    [startPoint, endPoint],
    ["100vh", "0vh"]
  );

  const messageContainerStyle = {
    width: isMobile ? (messageVisible ? "auto" : 0) : "auto",
    height: isMobile ? (messageVisible ? "auto" : 0) : "auto",
    opacity: messageVisible ? 1 : 0,
    transition: isMobile
      ? "opacity 1s ease, width 0s ease, height 0s ease"
      : "opacity 1s ease",
  };

  return (
    <motion.div
      className="single-message relative"
      initial={{ opacity: 0 }}
      animate={{ opacity: inView && previousCompleted ? 1 : 0 }}
      transition={{ duration: 0.5 }}
      ref={ref}
      style={{
        y: isLandscapeMobile ? "0vh" : messageMoveUp,
      }}
    >
      <div className="avatar-container">
        <img src={avatar} alt="message avatar" />
      </div>
      <motion.div
        className="message-container writing"
        initial={{ opacity: 0 }}
        animate={{ opacity: inView && previousCompleted ? 1 : 0 }}
        transition={{ duration: 0.2 }}
        ref={writingContainerRef}
      >
        <div className="writing-animation">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="highlighted-dot"></div>
        </div>
      </motion.div>
      <motion.div
        className="message-container message"
        initial={{ opacity: 0 }}
        animate={{ opacity: messageVisible ? 1 : 0 }}
        transition={{ opacity: { duration: 0.2 } }}
        ref={messageContainerRef}
        style={messageContainerStyle}
      >
        <p dangerouslySetInnerHTML={{ __html: message }}></p>
      </motion.div>
    </motion.div>
  );
};
