import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

export default function FloatingEstimation() {
  const [blur, setBlur] = useState(0);
  const [opacity, setOpacity] = useState(1);
  const [display, setDisplay] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollTop = window.scrollY;
      const inTheNewsElement = document.querySelector(".in-the-news-section");

      if (inTheNewsElement) {
        const inTheNewsOffsetTop = inTheNewsElement.offsetTop;
        const inTheNewsHeight = inTheNewsElement.offsetHeight;
        const inTheNewsBottom = inTheNewsOffsetTop + inTheNewsHeight;

        const startBlurPoint = inTheNewsOffsetTop + inTheNewsHeight * 0.5;
        const endBlurPoint = inTheNewsBottom;

        if (scrollTop + windowHeight >= startBlurPoint) {
          const progress =
            (scrollTop + windowHeight - startBlurPoint) /
            (endBlurPoint - startBlurPoint);

          const blurValue = Math.min(progress * 20, 20);
          const opacityValue = Math.max(1 - progress, 0);

          setBlur(blurValue);
          setOpacity(opacityValue);
          setDisplay(true);
        } else {
          setBlur(0);
          setOpacity(1);
          setDisplay(true);
        }

        if (scrollTop + windowHeight > inTheNewsBottom) {
          setDisplay(false);
        } else if (scrollTop + windowHeight <= inTheNewsBottom) {
          setDisplay(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (!display) return null;

  return (
    <div
      className="floating-estimation"
      style={{
        filter: `blur(${blur}px)`,
        opacity: opacity,
        transition: "filter 0.3s, opacity 0.3s",
      }}
    >
      <h3 className="big">Shape your vision, design with us!</h3>
      <h3 className="small">Shape your vision!</h3>
      <Link
        to={`${process.env.PUBLIC_URL}/design/expectations-brief`}
        onClick={scrollTop}
      >
        <button>Estimate project</button>
      </Link>
    </div>
  );
}
