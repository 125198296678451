import {
  SlideInFromBottom,
  SlideInFromLeft,
  StaggerChildren,
} from "./Animations";

function OfferBenefits({ benefits, text, firstWordBlue, blueWord, restWord }) {
  return (
    <section className=" container 500px:my-[72px] 1199px:my-[80px] my-[96px]">
      <StaggerChildren>
        {firstWordBlue ? (
          <h3 className=" text-[45px] 1199px:text-[38px] 500px:text-[29px] 500px:text-left 991px:text-center text-start   ">
            <span className="text-[#3FB7E2]">{blueWord}</span>
            {restWord}
          </h3>
        ) : (
          <h3 className=" 500px:text-[29px] 1199px:text-[38px] 500px:text-start 991px:text-center text-start    text-[45px]">
            {text}
          </h3>
        )}
      </StaggerChildren>
      <SlideInFromBottom>
        <div className="pt-[56px] grid grid-cols-12">
          <ul className="991px:col-span-12 991px:col-start-0 1199px:col-start-4 1199px:col-span-8 1399px:col-start-6 1399px:col-span-8 col-start-6 col-span-6 flex flex-col gap-4 max-w-[670px]">
            {benefits.map(({ title, description }) => (
              <li key={title} className="flex gap-2 flex-col">
                <h5 className="500px:text-[19px] text-[25px] normal-case text-left text-[#0E2933] ">
                  {title}
                </h5>
                <p className="text-[#565656]">{description}</p>
              </li>
            ))}
          </ul>
        </div>
      </SlideInFromBottom>
    </section>
  );
}
export default OfferBenefits;
