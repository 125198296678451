import React, { useState } from "react";

export default function FifthStep({
  onNextStep,
  setUserResponses,
  onPreviousStep,
  userResponses,
}) {
  const [textareaContent, setTextareaContent] = useState(
    userResponses.step5.textareaValue || null
  );
  const [emailAddress, setEmailAddress] = useState(
    userResponses.step5.emailValue || null
  );
  const [clickedNext, setClickedNext] = useState(false);

  const handleTextareaChange = (event) => {
    setTextareaContent(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmailAddress(event.target.value);
  };

  const isValidEmail = () => {
    // Implement your email validation logic here
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(emailAddress);
  };

  const handleNextStep = () => {
    setClickedNext(true);

    if (isValidEmail()) {
      setUserResponses((prevUserResponses) => ({
        ...prevUserResponses,
        step5: {
          ...prevUserResponses.step5,
          textareaValue: textareaContent,
          emailValue: emailAddress,
        },
      }));
      onNextStep();
    }
  };

  const handlePreviousStep = () => {
    onPreviousStep();
  };

  return (
    <div className="fifth-step">
      <h4 className="text-[25px] tracking-[-0.25px] text-[#173d46] mb-[16px] 500px:text-[22px]">
        Leave your contact details and, if you want, describe your needs.
      </h4>
      <input
        type="text"
        placeholder="Your email address"
        value={emailAddress}
        onChange={handleEmailChange}
      />
      <textarea
        className="mt-[16px]"
        type="textarea"
        placeholder="Your description (optional)"
        value={textareaContent}
        onChange={handleTextareaChange}
      />
      <div
        className={`error-message ${
          clickedNext && !isValidEmail() ? "active" : ""
        }`}
      >
        <p>Please share a valid email address</p>
      </div>
      <div className="buttons-wrapper large">
        <button className="cancel-btn" onClick={handlePreviousStep}>
          Previous step
        </button>

        <button className="next-step-btn" onClick={handleNextStep}>
          Send the form
        </button>
      </div>
      <div className="buttons-wrapper small">
        <div className="buttons">
          <button className="cancel-btn" onClick={handlePreviousStep}>
            Previous step
          </button>
          <button className="next-step-btn" onClick={handleNextStep}>
            Send the form
          </button>
        </div>
      </div>
    </div>
  );
}
