import Billon from "../../../../assets/img/oligamy/home-case-studies/billon.webp";
import Oddsee from "../../../../assets/img/oligamy/home-case-studies/oddsee.webp";
import Mu6Label from "../../../../assets/img/oligamy/home-case-studies/mu6label.webp";
import WGMI from "../../../../assets/img/oligamy/home-case-studies/wgmi.webp";
import Land from "../../../../assets/img/oligamy/home-case-studies/land.webp";
import BillonBattery from "../../../../assets/img/oligamy/home-case-studies/billon-battery.webp";
import FilipPuzyr from "../../../../assets/img/oligamy/home-case-studies/filip-puzyr.webp";
import Dredging from "../../../../assets/img/oligamy/home-case-studies/dredging-operations.webp";
import Palto from "../../../../assets/img/oligamy/home-case-studies/palto.webp";
import Oversite from "../../../../assets/img/oligamy/home-case-studies/oversite.webp";
import OversiteMobile from "../../../../assets/img/oligamy/home-case-studies/oversite- mobile.webp";
import Oligamy from "../../../../assets/img/oligamy/home-case-studies/oligamy.webp";

const data = [
  {
    thumbnail: Billon,
    title: "Billon App",
    url: "/design/document-reader",
    tag1: "Web app",
    tag2: "Web3",
    tag3: "Design system",
  },
  {
    thumbnail: Oddsee,
    title: "Oddsee is not just another lorem ipsum sit dolor amet",
    url: "/design/oddsee",
    tag1: "Mobile app",
  },
  {
    thumbnail: BillonBattery,
    title: "Product passport",
    url: "/design/product-passport",
    tag1: "Web app",
  },
  {
    thumbnail: WGMI,
    title: "WGMI",
    url: "/design/wgmi",
    tag1: "Landing Page",
    tag2: "Web app",
    tag2: "Web3",
  },
  {
    thumbnail: Land,
    title: "Landing page",
    url: "/design/metaverse",
    tag1: "Landing Page",
    tag2: "Web app",
    tag3: "Web3",
  },
  {
    thumbnail: FilipPuzyr,
    title: "Filip Puzyr",
    url: "/design/filip-puzyr",
    tag1: "Website",
  },
  {
    thumbnail: Palto,
    title: "Palto",
    url: "/design/palto",
    tag1: "Landing Page",
  },
  {
    thumbnail: Dredging,
    title: "Dredging Operations",
    url: "/design/dredging-operations",
    tag1: "Desktop app",
  },
  {
    thumbnail: Oversite,
    title: "Oversite",
    url: "/design/oversite-web",
    tag1: "Web app",
  },
  {
    thumbnail: OversiteMobile,
    title: "Oversite Mobile",
    url: "/design/oversite-mobile-app",
    tag1: "Mobile app",
  },
  // {
  //   thumbnail: Oligamy,
  //   title: "Oligamy",
  //   url: "/design/oligamy",
  //   tag1: "Website",
  // },
];

export default data;
