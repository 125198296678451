const sortBlogPosts = (data) => {
  if (!Array.isArray(data)) {
    // Handle the case where 'data' is not an array
    return [];
  }

  const withoutIndex = [];

  data.forEach((item) => {
    withoutIndex.push(item);
  });

  withoutIndex.sort(
    (a, b) => new Date(b.attributes.date) - new Date(a.attributes.date)
  );

  return [...withoutIndex];
};

export default sortBlogPosts;
