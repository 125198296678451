import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const BlogPostMarkdown = ({ content }) => {
  const HeadingRenderer = ({ level, children }) => {
    const childArray = React.Children.toArray(children);
    const text =
      childArray.length > 0 && typeof childArray[0] === "string"
        ? childArray[0]
        : "";
    const id = text?.toLowerCase()?.replace(/\s+/g, "-");

    return React.createElement(`h${level}`, { id }, children);
  };

  const LinkRenderer = ({ href, children }) => {
    return (
      <a
        href={href}
        target="_blank"
        onClick={(e) => {
          if (href.startsWith("#")) {
            e.preventDefault();
            const targetElement = document.getElementById(href.substring(1));
            if (targetElement) {
              window.scrollTo({
                top: targetElement.offsetTop,
                behavior: "smooth",
              });
            }
          }
        }}
      >
        {children}
      </a>
    );
  };

  return (
    <ReactMarkdown
      linkTarget="_blank"
      transformImageUri={(uri) => `${process.env.REACT_APP_STRAPI_URL}${uri}`}
      remarkPlugins={[remarkGfm]}
      components={{
        h1: HeadingRenderer,
        a: LinkRenderer,
      }}
    >
      {content}
    </ReactMarkdown>
  );
};

export default BlogPostMarkdown;
