import { useState, useEffect } from "react";
import { useIsMobileLandscape } from "../../../../helpers/useIsMobileLandscape";

const useResponsiveScaleAndPosition = (model) => {
  const [scale, setScale] = useState(10);
  const [position, setPosition] = useState([0, 0, 0]);
  const isLandscape = useIsMobileLandscape();
  useEffect(() => {
    const updateScaleAndPosition = () => {
      const width = window.innerWidth;

      let newScale;
      let newPosition;
      if (model === "eth") {
        if (isLandscape) {
          newScale = 10;
          newPosition = [0, 0, 0];
        } else if (width < 600) {
          newScale = 10;
          newPosition = [0, 6, 0];
        } else if (width >= 600 && width < 900) {
          newScale = 10;
          newPosition = [1, 6, 0];
        } else if (width >= 900 && width < 1200) {
          newScale = 10;
          newPosition = [5, 6, 0];
        } else {
          newScale = 10;
          newPosition = [-4, 0, 0];
        }
      } else if (model === "brain") {
        if (isLandscape) {
          newScale = 10.5;
          newPosition = [0, 0, 0];
        } else if (width < 600) {
          newScale = 10.5;
          newPosition = [0, 5, 0];
        } else if (width >= 600 && width < 900) {
          newScale = 10.5;
          newPosition = [1, 5, 0];
        } else if (width >= 900 && width < 1200) {
          newScale = 10.5;
          newPosition = [5, 6, 0];
        } else {
          newScale = 10.5;
          newPosition = [-4, 0, 0];
        }
      } else if (model === "laptop") {
        if (isLandscape) {
          newScale = 2.25;
          newPosition = [-5, -2, 0];
        } else if (width < 600) {
          newScale = 2.25;
          newPosition = [-5, 3.5, 0];
        } else if (width >= 600 && width < 900) {
          newScale = 2.25;
          newPosition = [-4, 4, 0];
        } else if (width >= 900 && width < 1200) {
          newScale = 2.25;
          newPosition = [0, 4, 0];
        } else {
          newScale = 2.25;
          newPosition = [-10, -3, 0];
        }
      } else if (model === "points") {
        if (isLandscape) {
          newPosition = [0, 0, 0];
        } else if (width < 600) {
          newPosition = [0, 5, 0];
        } else if (width >= 600 && width < 900) {
          newPosition = [1, 5, 0];
        } else if (width >= 900 && width < 1200) {
          newPosition = [4.5, 5, 0];
        } else {
          newPosition = [-4, 0, 0];
        }
      }

      setScale(newScale);
      setPosition(newPosition);
    };

    window.addEventListener("resize", updateScaleAndPosition);

    updateScaleAndPosition();

    return () => {
      window.removeEventListener("resize", updateScaleAndPosition);
    };
  }, [isLandscape]);

  return { scale, position };
};

export default useResponsiveScaleAndPosition;
