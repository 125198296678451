import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import dropdownOptions from "./dropdownOptions";

export default function MobileMenu({
  sidebar,
  handleNavClick,
  scrollWithOffset,
}) {
  const [mappedOption, setMappedOption] = useState(false);

  const handleMobileMenuSubmenu = (option) => {
    setMappedOption(option);
  };

  return (
    <div className={`mobile-menu-list ${sidebar ? "opened" : "closed"}`}>
      <div className="menu-items">
        {mappedOption === false ? (
          <div className="main">
            <NavLink
              id="linkhero"
              to={`/`}
              onClick={handleNavClick}
              exact={true}
            >
              <p>Home</p>
            </NavLink>
            <div
              id="linkdesign"
              onClick={() => handleMobileMenuSubmenu("design")}
              className="has-sub-menu"
            >
              <p>Design</p>
              <svg
                width="12"
                height="10"
                viewBox="0 0 12 10"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M7.35938 0.390625L6.64062 1.10938L10.0312 4.5H0V5.5H10.0312L6.64062 8.89062L7.35938 9.60938L11.6094 5.35938L11.9531 5L11.6094 4.64062L7.35938 0.390625Z" />
              </svg>
            </div>
            <div
              id="linkoffer"
              onClick={() => handleMobileMenuSubmenu("offer")}
              className="has-sub-menu"
            >
              <p>Offer</p>
              <svg
                width="12"
                height="10"
                viewBox="0 0 12 10"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M7.35938 0.390625L6.64062 1.10938L10.0312 4.5H0V5.5H10.0312L6.64062 8.89062L7.35938 9.60938L11.6094 5.35938L11.9531 5L11.6094 4.64062L7.35938 0.390625Z" />
              </svg>
            </div>
            <div
              id="linkaboutus"
              onClick={() => handleMobileMenuSubmenu("about")}
              className="has-sub-menu"
            >
              <p>About</p>
              <svg
                width="12"
                height="10"
                viewBox="0 0 12 10"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M7.35938 0.390625L6.64062 1.10938L10.0312 4.5H0V5.5H10.0312L6.64062 8.89062L7.35938 9.60938L11.6094 5.35938L11.9531 5L11.6094 4.64062L7.35938 0.390625Z" />
              </svg>
            </div>
            <NavLink
              exact={true}
              to={`${process.env.PUBLIC_URL}/design/blog`}
              onClick={handleNavClick}
            >
              <p>Blog</p>
            </NavLink>
            <NavLink to="/contact" onClick={handleNavClick}>
              <p>Contact</p>
            </NavLink>
          </div>
        ) : null}
        {mappedOption !== false && (
          <>
            <div
              className="back-to-menu"
              onClick={() => handleMobileMenuSubmenu(false)}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.64062 3.39062L7.35938 4.10938L3.96875 7.5H14V8.5H3.96875L7.35938 11.8906L6.64062 12.6094L2.39062 8.35938L2.04688 8L2.39062 7.64062L6.64062 3.39062Z"
                  fill="#6C6C6C"
                />
              </svg>

              <p>Back to main menu</p>
            </div>
            <div className="sub-menu">
              <h5>{mappedOption}</h5>
              {dropdownOptions[mappedOption][0].isGrouped
                ? dropdownOptions[mappedOption][0].groups.map(
                    (option, index) => (
                      <div className="group" key={index}>
                        <p className="group-title">{option.name}</p>
                        {option.items.map((item, index) => (
                          <NavLink
                            exact={true}
                            key={index}
                            onClick={handleNavClick}
                            to={`${process.env.PUBLIC_URL}${item.url}`}
                          >
                            <p>{item.title}</p>
                          </NavLink>
                        ))}
                      </div>
                    )
                  )
                : dropdownOptions[mappedOption][0].items.map(
                    (option, index) => (
                      <NavLink
                        exact={true}
                        key={index}
                        onClick={handleNavClick}
                        to={`${process.env.PUBLIC_URL}${option.url}`}
                      >
                        <p>{option.title}</p>
                      </NavLink>
                    )
                  )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
