import React from "react";
import { Link } from "react-router-dom";
import ReadAboutAnimation from "./ReadAboutAnimation";

export default function ReadAboutUs() {
  return (
    <section
      className={
        "read-about-us-section relative z-[2] min-h-[724px] bg-[#16404F] flex items-center overflow-hidden"
      }
    >
      <div className={"container relative 1600px:static"}>
        <div className="text-wrapper max-w-[555px] relative z-[2] 767px:pb-[280px] 767px:pt-[96px]">
          <h2
            className={
              "text-[52px] leading-[62.4px] text-[#FDFDFD] 767px:text-[38px] 767px:leading-[38px] 500px:text-[29px] 500px:leading-[34.8px]"
            }
          >
            Innovative approach to <span>Blockchain and AI</span> at Oligamy
            Software
          </h2>
          <p className={"text-[#EFEFEF] text-[16px] mt-[24px]"}>
            Our experienced team excels in comprehensive software development,
            integrating cutting-edge technologies such as Blockchain and AI with
            top-tier skills in web development and UX, providing innovative
            solutions for your projects.
          </p>
          <Link to="/about-us">
            <button className="mt-[56px] primary-button">Read about us</button>
          </Link>
        </div>
        <div className="animation-wrapper absolute bottom-[-70%] right-[-10%] 1600px:bottom-[-20%] 1600px:right-[-10%] 767px:right-[0] transition-[0.5s] 650px:right-[50%] 650px:translate-x-[50%] 449px:bottom-[-10%]">
          <ReadAboutAnimation />
        </div>
      </div>
    </section>
  );
}
