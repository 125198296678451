import { Link } from "react-router-dom";

function RelatedBlogStandarLayoutTwo(props) {
  return (
    <>
      <div className="relative container">
        <div className="overflow-hidden rounded-[16px]">
          <Link
            to={`${process.env.PUBLIC_URL}/design/blog-post/${props.postID}`}
          >
            {props.postTag && (
              <div className="absolute text-[12px] leading-[18px] text-[#0E2933] z-[5] rounded-[100px] top-[16px] left-[24px] py-[8px] px-[16px] bg-[#3FB7E2] flex justify-center">
                {props.postTag}
              </div>
            )}
            <img
              className="w-full h-[278px] rounded-[16px] transform transition-transform duration-500 ease-in-out hover:scale-110"
              style={{ objectFit: "cover" }}
              src={process.env.REACT_APP_STRAPI_URL + props.image}
              alt={props.title}
            />
          </Link>
        </div>
        <div className="blog-inner mt-[32px]">
          <div className="author-date">
            <p className="text-[#878787]">By {props.author}</p>
            <p className="text-[#878787] text-[16px]">{props.date}</p>
          </div>
          <h4 className=" related-blog-heading-hover text-[25px] leading-[30px]">
            <Link
              className="text-[#173D46] hover:text-[#173D46]"
              to={`${process.env.PUBLIC_URL}/design/blog-post/${props.postID}`}
            >
              {props.title}
            </Link>
          </h4>
        </div>
      </div>
    </>
  );
}

export default RelatedBlogStandarLayoutTwo;
