import { lazy, Suspense, useEffect, useState } from "react";
import FloatingForm from "../../../common/floating-form/FloatingForm";
import Layout from "../../../App";
import { Link, NavLink } from "react-router-dom";
import { useVisibleHeader } from "../../../common/header/HeaderVisibilityContext";
import OfferContact from "../OfferContact";
import OfferBenefits from "../OfferBenefits";
import OfferBlueBanner from "../OfferBlueBanner";
import OfferMainHeading from "../OfferMainHeading";
import OfferInTheNews from "../OfferInTheNews";
import OfferCaseStudies from "../OfferCaseStudies";
import { SlideInFromBottom, StaggerChildren } from "../Animations";
import { useIsMobile } from "../../../../helpers/useIsMobile";
import BillonBattery from "../../../../assets/img/oligamy/home-case-studies/billon-battery.webp";
import FilipPuzyr from "../../../../assets/img/oligamy/home-case-studies/filip-puzyr.webp";
import Dredging from "../../../../assets/img/oligamy/home-case-studies/dredging-operations.webp";
import OversiteMobile from "../../../../assets/img/oligamy/home-case-studies/oversite- mobile.webp";
import OfferSkeleton from "../OfferSkeleton";
import { Helmet } from "react-helmet-async";

const DigitalAccelerationTopVideoComponent = lazy(() =>
  import("./DigitalAccelerationTopVideoComponent")
);
const caseStudiesData = [
  {
    thumbnail: BillonBattery,
    title: "Product passport",
    url: "/design/product-passport",
    tag1: "Web app",
  },
  {
    thumbnail: FilipPuzyr,
    title: "Filip Puzyr",
    url: "/design/filip-puzyr",
    tag1: "Website",
  },
  {
    thumbnail: Dredging,
    title: "Dredging Operations",
    url: "/design/dredging-operations",
    tag1: "Desktop app",
  },
  {
    thumbnail: OversiteMobile,
    title: "Oversite Mobile",
    url: "/design/oversite-mobile-app",
    tag1: "Mobile app",
  },
];

const IDEAS_DATA = [
  {
    title: "Speed and flexibility",
    description: "Shorten the evaluation phase to achieve rapid time-to-value.",
  },
  {
    title: "Continuous innovation",
    description: "Constant pursuit of improvements through digital tools.",
  },
  {
    title: "Data-driven decisions",
    description: "Data analysis as the foundation for strategic decisions.",
  },
  {
    title: "Customer focus",
    description: "Priority on delivering exceptional experiences to customers.",
  },
  {
    title: "Technology adoption",
    description: "Utilizing modern technologies for operational efficiency.",
  },
  {
    title: "Flexible work methodologies",
    description: "Embracing flexible methodologies for quick adaptation.",
  },
];

const BENEFITS_DATA = [
  {
    title: "Future-proofing",
    description:
      "Digital Acceleration allows companies to quickly adapt to changing market conditions, enabling them to maintain competitiveness in the future.",
  },
  {
    title: "Innovation",
    description:
      "Through Digital Acceleration, businesses gain access to real data and modern analytical tools, enabling them to identify new growth and innovation opportunities.",
  },
  {
    title: "Financial savings",
    description:
      "Digital Acceleration enables companies to reduce operational costs by automating business processes and utilizing modern technologies.",
  },
  {
    title: "Scalability",
    description:
      "With Digital Acceleration, businesses can easily scale their operations with business growth, enabling them to quickly expand into new markets and areas of operation.",
  },
  {
    title: "Process optimization",
    description:
      "Digital Acceleration allows companies to optimize business processes through the use of modern analytical tools and task automation.",
  },
];

const SERVICES_DATA = [
  {
    number: "01",
    title: "Accelerate decision-making",
    content: "Shorten the evaluation phase to achieve rapid time-to-value.",
  },
  {
    number: "02",
    title: "Improve speed-to-value",
    content: "Shorten innovation and iteration cycles to ensure agility.",
  },
  {
    number: "03",
    title: "Speed up delivery",
    content: "Fast track innovation with tangible results delivered in months.",
  },
  {
    number: "04",
    title: "Focus on flexibility",
    content:
      "Turn to scalable, cloud-based solutions ready to adjust on the fly.",
  },
  {
    number: "05",
    title: "Delight your users",
    content:
      "Amaze digital natives with new concepts and exceptional user experience.",
  },
  {
    number: "06",
    title: "De-risk your projects",
    content:
      "Ensure you're building the right thing with Product Validation Sprints.",
  },
  {
    number: "07",
    title: "Enjoy analytics-driven savings",
    content:
      "Build resilience with tools that boost cost optimization and operational efficiency.",
  },
  {
    number: "08",
    title: "Own the change",
    content: "Gain market leadership, no matter the business landscape.",
  },
];

function DigitalAcceleration() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const isMobile = useIsMobile("(max-width: 500px)");
  const [isQuickNavFixed, setIsQuickNavFixed] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const el = document.querySelector(".offer-quick-nav");
      if (!el) return;
      const elOffset = el.offsetTop;

      const onScroll = () => {
        if (elOffset - 84 <= window.scrollY - 84) {
          setIsQuickNavFixed(true);
        } else {
          setIsQuickNavFixed(false);
        }
      };

      window.removeEventListener("scroll", onScroll);
      window.addEventListener("scroll", onScroll, { passive: true });
    };

    const checkScrollPosition = () => {
      if (window.scrollY === 0) {
        handleScroll();
      }
    };

    checkScrollPosition();

    const onScroll = () => {
      if (window.scrollY === 0) {
        handleScroll();
        window.removeEventListener("scroll", onScroll);
      }
    };

    window.addEventListener("scroll", onScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const { isVisible } = useVisibleHeader();

  // Metadata
  const pageDescription =
    "Accelerate your digital transformation with Oligamy Software. We offer innovative digital acceleration solutions, including AI, blockchain, and custom web/mobile development, to enhance your business performance.";

  const pageTitle =
    "Oligamy Software - Digital Acceleration Services | Boost Your Digital Strategy";

  return (
    <Layout isAlternative={true}>
      <FloatingForm />

      <>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta property="og:description" content={pageDescription} />
          <meta property="og:url" content={window.location.href} />{" "}
          {/* Use the current page URL */}
          <meta property="og:type" content="website" />
          {/* Twitter Card tags for Twitter */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDescription} />
        </Helmet>
        <div className="bg-[#FDFDFD]">
          <main
            className="page-section "
            onClick={() => {
              document
                .querySelector(".main-nav")
                .className.includes("slidenav") &&
                document.querySelector(".remove").click();
            }}
          >
            <OfferMainHeading title={"Cooperation models"} />
            {/* desktop */}
            {!isMobile && (
              <section
                className={`offer-quick-nav z-[12] pt-[36px] ${
                  isVisible ? "offset" : ""
                } ${isQuickNavFixed ? "isFixed" : ""}`}
              >
                <div className="container flex  ">
                  <NavLink onClick={scrollTop} to="/offer/digital-acceleration">
                    Digital acceleration
                  </NavLink>
                  <NavLink to="/offer/dedicated-teams">Dedicated teams</NavLink>
                  <NavLink to="/offer/staff-augmentation">
                    Staff augmentation
                  </NavLink>
                </div>
              </section>
            )}
            {/* mobile */}
            {isMobile && (
              <section
                className={`offer-quick-nav z-[12] pt-[36px] ${
                  isVisible ? "offset" : ""
                } ${isQuickNavFixed ? "isFixed" : ""}`}
              >
                <div className="container flex  ">
                  <NavLink to="/offer/digital-acceleration" onClick={scrollTop}>
                    Digital acceleration
                  </NavLink>
                  <NavLink to="/offer/dedicated-teams">Dedicated teams</NavLink>
                  <NavLink to="/offer/staff-augmentation">
                    Staff augmentation
                  </NavLink>
                </div>
              </section>
            )}
            <section className="container ">
              <div className="offer-title-card mt-[72px]">
                <div className="500px:min-h-[220px] 767px:min-h-[180px] 991px:min-h-[260px] 1199px:min-h-[340px] 1399px:min-h-[420px] min-h-[550px] 500px:mb-[72px] 1199px:mb-[80px] mb-[96px]">
                  <Suspense fallback={OfferSkeleton()}>
                    <DigitalAccelerationTopVideoComponent />
                  </Suspense>
                </div>

                <div className="flex flex-col justify-between">
                  <SlideInFromBottom>
                    <h2 className="500px:text-[29px] 767px:text-[45px] 991px:text-[52px] text-[61px] text-[#0e2933]">
                      <span className="leading-[120%] ">
                        Digital acceleration{" "}
                      </span>
                      - gain competitive edge in the business world
                    </h2>
                  </SlideInFromBottom>
                  <SlideInFromBottom>
                    <div className="description mt-[56px] flex flex-col items-end">
                      <div className=" max-w-[670px]">
                        <p className="text-[#565656]">
                          In today&apos;s dynamic business world, every company
                          becomes a digital player. What you need is to lead.
                          The landscape is always changing. Act ahead of the
                          times. Competitors move faster than ever. Make a leap.
                          Consumers expect top digital experiences. Deliver the
                          next big thing. Today&apos;s business environment is
                          volatile. Build resilience. What&apos;s the rush all
                          about? Answer disruptive innovation and market
                          uncertainty with digital acceleration.
                        </p>
                        <Link
                          to="/contact"
                          className=" inline-block mt-[32px] bg-[#3fb7e2] py-[16px] px-[32px] font-[500] text-[19px] rounded-[8px] text-[#173d46] hover:text-[#fbfbfb] transition-colors duration-500"
                        >
                          Contact us
                        </Link>
                      </div>
                    </div>
                  </SlideInFromBottom>
                </div>
              </div>
            </section>
            <OfferBlueBanner
              higlightedText={"Digital acceleration "}
              restText={
                "is a term that describes the mindset, approach, and iterative process necessary to succeed in a world where every business is a digital business. Focused on speed and adaptation, digital acceleration enables companies to adjust to dynamic market changes."
              }
            />
            {/* DIFFRENCES SECTION */}
            <section className=" container ">
              <div className="sample-solutions 500px:my-[72px] 1199px:my-[80px] my-[96px] flex flex-col items-center">
                <StaggerChildren>
                  <h3 className="text-[45px] 1199px:text-[38px] 500px:text-[29px] text-[#0e2933] max-w-[812px] text-center">
                    The difference between digital acceleration and digital
                    transformation
                  </h3>
                </StaggerChildren>
                <div className="solutions-wrapper mt-[72px] grid grid-cols-2 gap-[24px]">
                  <SlideInFromBottom>
                    <div className=" bg-[#efefef] rounded-[16px] p-[24px] 767px:h-fit h-full">
                      <h4 className="text-[#3fb7e2] 500px:text-[22px] text-[34px]">
                        Digital acceleration
                      </h4>
                      <p className="text-[#6c6c6c] mt-[16px]">
                        Focused on quickly adapting to changes and utilizing
                        modern technologies. It is more concentrated on rapidly
                        responding to market changes and swiftly adjusting to
                        new business conditions.
                      </p>
                    </div>
                  </SlideInFromBottom>
                  <SlideInFromBottom>
                    <div className=" bg-[#efefef] rounded-[16px] p-[24px] 767px:h-fit h-full">
                      <h4 className="text-[#3fb7e2] 500px:text-[22px] text-[34px]">
                        Digital transformation
                      </h4>
                      <p className="text-[#6c6c6c] mt-[16px]">
                        Strategic change in business models and operational
                        processes utilizing modern technologies. It involves
                        revolutionary organizational and cultural changes.
                      </p>
                    </div>
                  </SlideInFromBottom>
                </div>
              </div>
            </section>
            {/* END DIFFRENCES SECTION */}

            {/* IDEAS OF DIGITAL ACCELERTAION */}
            <div className="bg-[#256c86] text-[#FDFDFD] 500px:py-[72px] 1199px:py-[80px] py-[96px]">
              <section className="  container     ">
                <div className=" flex flex-col gap-16 ">
                  <StaggerChildren>
                    <h3 className="text-[45px] 1199px:text-[38px] 500px:text-[29px] 699px:text-left   ">
                      Key ideas of digital acceleration
                    </h3>
                  </StaggerChildren>
                  <SlideInFromBottom>
                    <div className="grid grid-cols-3 992px:grid-cols-2 699px:grid-cols-1 992px:gap-[32px]  gap-[72px] font-normal   ">
                      {IDEAS_DATA.map(({ title, description }) => (
                        <div key={title} className="flex flex-col gap-[16px]">
                          <h5 className=" text-[#EFEFEF] normal-case  text-left 500px:text-[19px] text-[25px]">
                            {title}
                          </h5>
                          <p>{description}</p>
                        </div>
                      ))}
                    </div>
                  </SlideInFromBottom>
                </div>
              </section>
            </div>
            {/* END IDEAS OF DIGITAL ACCELERTAION   */}
            <OfferBenefits
              firstWordBlue={true}
              blueWord={"Benefits "}
              restWord={"of digital acceleration"}
              benefits={BENEFITS_DATA}
            />
            {/* SERVICES */}
            <section className="offer-blue-text bg-[#256c86]  500px:py-[72px] 1199px:py-[80px] py-[96px]">
              <div className="container text-center">
                <div className="service-title text-center">
                  <StaggerChildren>
                    <h3 className="text-[45px] 1199px:text-[38px] 500px:text-[29px] text-[#fdfdfd]">
                      Why digital acceleration?
                    </h3>
                  </StaggerChildren>
                </div>
                <SlideInFromBottom>
                  <div className="services-wrapper 500px:mt-[56px]  mt-[96px] grid grid-cols-3 500px:gap-8  gap-[72px] 991px:gap-12">
                    {SERVICES_DATA.map((service, index) => (
                      <div
                        key={index}
                        className=" text-left flex flex-col gap-[16px]"
                      >
                        <span className=" 500px:text-[34px] text-[52px] text-[#3fb7e2] text-left">
                          {service.number}
                        </span>
                        <h5 className="500px:text-[19px] text-[#efefef] text-[25px] text-left normal-case">
                          {service.title}
                        </h5>
                        <p className="text-[#efefef] text-left">
                          {service.content}
                        </p>
                      </div>
                    ))}
                  </div>
                </SlideInFromBottom>
              </div>
            </section>
            <SlideInFromBottom>
              <OfferCaseStudies caseStudiesData={caseStudiesData} />
            </SlideInFromBottom>
            <SlideInFromBottom>
              <OfferInTheNews category={"AI"} />
            </SlideInFromBottom>
            <SlideInFromBottom>
              <OfferContact
                text={
                  "Interested in learning more about our digital acceleration services or starting a collaboration? Contact us today!"
                }
              />
            </SlideInFromBottom>
          </main>
        </div>
      </>
    </Layout>
  );
}

export default DigitalAcceleration;
