function BlogPostTags({ postTags }) {
  if (!postTags || postTags.length === 0) {
    return null;
  }
  return (
    <div className="mt-[56px]">
      <h2 className="text-[25px] text-[#173D46] mb-[16px]">Post tag</h2>
      <div className="blog-slider gap-2">
        {postTags?.map(({ attributes, id }) => (
          <div
            className="border border-[#D3D3D3] text-[#565656] rounded-[100px] py-2 px-4"
            key={id}
          >
            {attributes.Name}
          </div>
        ))}
      </div>
    </div>
  );
}
export default BlogPostTags;
