import React, { memo, useEffect, useState } from "react";
import getBlogTags from "../../../api/action.getBlogTags";
import logError from "../../../helpers/logError";

function TagsWidget({ selectedBlogTag, setSelectedBlogTag, setPage }) {
  const [blogTags, setBlogTags] = useState([]);

  // Blog tags fetching

  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        const rawBlogTags = await getBlogTags();
        if (isMounted) {
          if (rawBlogTags.error) {
            throw "No blog tags";
          }
          setBlogTags(rawBlogTags);
        }
      } catch (err) {
        logError(err);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);
  function handleClick(tag) {
    setPage(1);
    setSelectedBlogTag(tag);
  }
  const [currentBlogTags, setCurrentBlogTags] = useState(blogTags);

  // Set currentBlogTags when tags change
  useEffect(() => {
    setCurrentBlogTags(blogTags);
  }, [blogTags]);
  return (
    <div className={" blog-slider pl-[12px] gap-[8px] "}>
      <div
        onClick={() => setSelectedBlogTag("All")}
        className={`single-tag ${selectedBlogTag === "All" ? "active" : ""}`}
      >
        All
      </div>
      {currentBlogTags.map((tag, i) => (
        <div
          onClick={() => handleClick(tag.attributes.Name)}
          key={tag.id}
          className={`single-tag ${
            currentBlogTags.length === i + 1 && "mr-[12px]"
          } ${selectedBlogTag === tag.attributes.Name ? "active" : ""}`}
        >
          {tag.attributes.Name}
        </div>
      ))}
    </div>
  );
}
export default memo(TagsWidget);
