import { lazy, Suspense, useEffect, useState } from "react";

import FloatingForm from "../../../common/floating-form/FloatingForm";
import Layout from "../../../App";
import { Link, NavLink } from "react-router-dom";
import { useVisibleHeader } from "../../../common/header/HeaderVisibilityContext";
import OfferContact from "../OfferContact";
import OfferBenefits from "../OfferBenefits";
import OfferMainHeading from "../OfferMainHeading";
import OfferInTheNews from "../OfferInTheNews";
import OfferCaseStudies from "../OfferCaseStudies";
import { SlideInFromBottom, StaggerChildren } from "../Animations";
import { useIsMobile } from "../../../../helpers/useIsMobile";
import OfferSkeleton from "../OfferSkeleton";
import Billon from "../../../../assets/img/oligamy/home-case-studies/billon.webp";
import WGMI from "../../../../assets/img/oligamy/home-case-studies/wgmi.webp";
import Land from "../../../../assets/img/oligamy/home-case-studies/land.webp";
import OversiteMobile from "../../../../assets/img/oligamy/home-case-studies/oversite- mobile.webp";
import { Helmet } from "react-helmet-async";

const caseStudiesData = [
  {
    thumbnail: Billon,
    title: "Billon App",
    url: "/design/document-reader",
    tag1: "Web app",
    tag2: "Web3",
    tag3: "Design system",
  },
  {
    thumbnail: WGMI,
    title: "WGMI",
    url: "/design/wgmi",
    tag1: "Landing Page",
    tag2: "Web app",
    tag2: "Web3",
  },
  {
    thumbnail: Land,
    title: "Landing page",
    url: "/design/metaverse",
    tag1: "Landing Page",
    tag2: "Web app",
    tag3: "Web3",
  },
  {
    thumbnail: OversiteMobile,
    title: "Oversite Mobile",
    url: "/design/oversite-mobile-app",
    tag1: "Mobile app",
  },
];
const DedicatedTeamsTopVideoComponent = lazy(() =>
  import("./DedicatedTeamsTopVideoComponent")
);

const BENEFITS_DATA = [
  {
    title: "Ready to Go",
    description:
      "Benefit from well-coordinated teams that are primed and ready to dive into your projects from day one, saving you valuable time and resources.",
  },
  {
    title: "Streamlined communication",
    description:
      "Enjoy smooth and efficient communication channels within the team, ensuring clarity and transparency every step of the way.",
  },
  {
    title: "Unwavering commitment",
    description:
      "Rest assured that our dedicated teams are committed to the success of your projects, providing unwavering support and dedication throughout the entire process.",
  },
  {
    title: "Trusted expertise",
    description:
      "Leverage the expertise of our seasoned professionals who bring years of experience and knowledge to the table, delivering high-quality results you can rely on.",
  },
];
function DedicatedTeams() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const isMobile = useIsMobile("(max-width: 500px)");
  const [isQuickNavFixed, setIsQuickNavFixed] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const el = document.querySelector(".offer-quick-nav");
      if (!el) return;
      const elOffset = el.offsetTop;

      const onScroll = () => {
        if (elOffset - 84 <= window.scrollY - 84) {
          setIsQuickNavFixed(true);
        } else {
          setIsQuickNavFixed(false);
        }
      };

      window.removeEventListener("scroll", onScroll);
      window.addEventListener("scroll", onScroll, { passive: true });
    };

    const checkScrollPosition = () => {
      if (window.scrollY === 0) {
        handleScroll();
      }
    };

    checkScrollPosition();

    const onScroll = () => {
      if (window.scrollY === 0) {
        handleScroll();
        window.removeEventListener("scroll", onScroll);
      }
    };

    window.addEventListener("scroll", onScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const { isVisible } = useVisibleHeader();

  // Metadata
  const pageDescription =
    "Leverage Oligamy Software’s dedicated development teams for your project. Our expertise spans AI, blockchain, and custom web/mobile development, delivering high-quality, innovative solutions tailored to your business.";

  const pageTitle =
    "Oligamy Software - Dedicated Development Teams | Expert Web & Mobile Solutions";

  return (
    <Layout isAlternative={true}>
      <FloatingForm />

      <>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta property="og:description" content={pageDescription} />
          <meta property="og:url" content={window.location.href} />{" "}
          {/* Use the current page URL */}
          <meta property="og:type" content="website" />
          {/* Twitter Card tags for Twitter */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDescription} />
        </Helmet>
        <div className="  bg-[#FDFDFD]">
          <main
            className="page-section "
            onClick={() => {
              document
                .querySelector(".main-nav")
                .className.includes("slidenav") &&
                document.querySelector(".remove").click();
            }}
          >
            <OfferMainHeading title={"Cooperation models"} />
            {/* desktop */}
            {!isMobile && (
              <section
                className={`offer-quick-nav  z-[12] pt-[36px] ${
                  isVisible ? "offset" : ""
                } ${isQuickNavFixed ? "isFixed" : ""}`}
              >
                <nav className="container flex  ">
                  <NavLink to="/offer/digital-acceleration">
                    Digital acceleration
                  </NavLink>
                  <NavLink to="/offer/dedicated-teams" onClick={scrollTop}>
                    Dedicated teams
                  </NavLink>
                  <NavLink to="/offer/staff-augmentation">
                    Staff augmentation
                  </NavLink>
                </nav>
              </section>
            )}
            {/* mobile */}
            {isMobile && (
              <section
                className={`offer-quick-nav  z-[12] pt-[36px] ${
                  isVisible ? "offset" : ""
                } ${isQuickNavFixed ? "isFixed" : ""}`}
              >
                <nav className="container flex  ">
                  <NavLink to="/offer/dedicated-teams" onClick={scrollTop}>
                    Dedicated teams
                  </NavLink>
                  <NavLink to="/offer/digital-acceleration">
                    Digital acceleration
                  </NavLink>
                  <NavLink to="/offer/staff-augmentation">
                    Staff augmentation
                  </NavLink>
                </nav>
              </section>
            )}
            <section className="container">
              <div className="offer-title-card  mt-[72px]">
                <div className="500px:min-h-[220px] 767px:min-h-[180px] 991px:min-h-[260px] 1199px:min-h-[340px] 1399px:min-h-[420px] min-h-[550px]">
                  <Suspense fallback={OfferSkeleton()}>
                    <DedicatedTeamsTopVideoComponent />
                  </Suspense>
                </div>

                <div className="flex flex-col justify-between">
                  <SlideInFromBottom>
                    <h2 className="500px:text-[29px] 767px:text-[45px] 991px:text-[52px] text-[61px] text-[#0e2933]">
                      Unleash the power of
                      <span className="leading-[120%]"> dedicated teams</span>
                    </h2>
                  </SlideInFromBottom>
                  <SlideInFromBottom>
                    <div className="description mt-[56px] flex flex-col items-end">
                      <div className=" max-w-[670px]">
                        <p className="text-[#565656]">
                          At Oligamy Software, we recognize that the right team
                          can make all the difference in the success of your
                          projects. That's why we&apos;re proud to offer
                          Dedicated Teams - a solution designed to fuel your
                          projects with efficiency, expertise, and unwavering
                          commitment.
                        </p>
                        <Link
                          to="/contact"
                          className=" inline-block mt-[32px] bg-[#3fb7e2] py-[16px] px-[32px] font-[500] text-[19px] rounded-[8px] text-[#173d46] hover:text-[#fbfbfb] transition-colors duration-500"
                        >
                          Contact us
                        </Link>
                      </div>
                    </div>
                  </SlideInFromBottom>
                </div>
              </div>
            </section>
            {/* BLUE BANNER #1 */}
            <section className=" bg-[#256C86]  500px:mt-[72px] 1199px:mt-[80px] mt-[96px]">
              <div className="container">
                <div className="sample-solutions  flex flex-col items-center 500px:py-[72px] 1199px:py-[80px] py-[96px]">
                  <StaggerChildren>
                    <h3 className="text-[45px] 1199px:text-[38px] 500px:text-[29px] text-[#FDFDFD] max-w-[812px] text-center">
                      Tailored teams for your needs
                    </h3>
                  </StaggerChildren>
                  <div className="solutions-wrapper mt-[72px]  grid grid-cols-2 gap-[24px] ">
                    <SlideInFromBottom>
                      <div className=" bg-[#3FB7E2] rounded-[16px] p-[24px] 767px:h-fit h-full">
                        <h4 className="text-[#FDFDFD] 500px:text-[22px] text-[34px]">
                          Customized expertise
                        </h4>
                        <p className="text-[#EFEFEF] mt-[16px]">
                          Each team is meticulously crafted to meet your
                          specific project requirements, ensuring that you have
                          the right talent in place to achieve your goals.
                        </p>
                      </div>
                    </SlideInFromBottom>
                    <SlideInFromBottom>
                      <div className=" bg-[#3FB7E2] rounded-[16px] p-[24px] 767px:h-fit h-full">
                        <h4 className="text-[#FDFDFD] 500px:text-[22px] text-[34px]">
                          Aligned goals
                        </h4>
                        <p className="text-[#EFEFEF] mt-[16px]">
                          Our dedicated teams are fully aligned with your
                          project objectives, guaranteeing a seamless
                          integration with your existing workflows and
                          processes.
                        </p>
                      </div>
                    </SlideInFromBottom>
                  </div>
                </div>
              </div>
            </section>
            {/* END BLUE BANNER #1 */}
            <OfferBenefits
              benefits={BENEFITS_DATA}
              text={"Fuel your projects with efficiency"}
            />
            <SlideInFromBottom>
              <OfferCaseStudies caseStudiesData={caseStudiesData} />
            </SlideInFromBottom>
            <SlideInFromBottom>
              <OfferInTheNews category={"Blockchain"} />
            </SlideInFromBottom>
            <SlideInFromBottom>
              <OfferContact
                text={
                  " Interested in learning more about our dedicated teams or starting a collaboration? Contact us today!"
                }
              />
            </SlideInFromBottom>
          </main>
        </div>
      </>
    </Layout>
  );
}

export default DedicatedTeams;
