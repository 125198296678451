import React from "react";
import { Link } from "react-router-dom";
import Billon from "../../../../assets/img/oligamy/home-case-studies/billon.webp";
import BillonBattery from "../../../../assets/img/oligamy/home-case-studies/billon-battery.webp";
import Land from "../../../../assets/img/oligamy/home-case-studies/land.webp";
import OversiteMobile from "../../../../assets/img/oligamy/home-case-studies/oversite- mobile.webp";
import CaseStudiesHomeSwiper from "./CaseStudiesHomeSwiper";
export default function CaseStudiesHome() {
  const caseStudiesData = [
    {
      thumbnail: Billon,
      title: "Billon App",
      url: "/design/document-reader",
      tag1: "Web app",
      tag2: "Web3",
      tag3: "Design system",
    },
    {
      thumbnail: BillonBattery,
      title: "Product passport",
      url: "/design/product-passport",
      tag1: "Web app",
    },
    {
      thumbnail: Land,
      title: "Landing page",
      url: "/design/metaverse",
      tag1: "Landing Page",
      tag2: "Web app",
      tag3: "Web3",
    },
    {
      thumbnail: OversiteMobile,
      title: "Oversite Mobile",
      url: "/design/oversite-mobile-app",
      tag1: "Mobile app",
    },
  ];
  return (
    <section
      className={"case-studies-home mb-[182px] bg-[#FDFDFD] 767px:mb-[144px]"}
    >
      <div className="container">
        <div className="title relative z-[10] flex items-center justify-between py-[96px] 767px:justify-center 767px:pt-[72px] 767px:pb-[56px]">
          <h2 className={"text-[#0E2933] 767px:text-[38px] 500px:text-[29px]"}>
            Our case studies
          </h2>
          <Link
            to={"/design/case-studies"}
            className="primary-link 767px:hidden"
          >
            See all case studies
          </Link>
        </div>
      </div>
      <div className="swiper-container">
        <CaseStudiesHomeSwiper caseStudiesData={caseStudiesData} />
      </div>
      <div className="mobile-button-wrapper hidden 767px:block mt-[72px] text-center">
        <Link to={"/design/case-studies"} className="primary-link ">
          See all case studies
        </Link>
      </div>
    </section>
  );
}
