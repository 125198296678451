import "swiper/css/autoplay";

// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";

SwiperCore.use([Navigation, Autoplay]);
function OfferProcessSwiper({ processSteps }) {
  const oddseeSwiper = {
    loop: false,
    spaceBetween: 20,
    speed: 1200,
    slidesPerView: 1.5,
    allowTouchMove: true,
    autoplay: false,
    centeredSlides: false,
  };
  return (
    <>
      <section className="oddsee-slider billon">
        <div className="row">
          <div className="oddsee-swiper-wrapper progress-slider-wrapper">
            <Swiper
              {...oddseeSwiper}
              className="swiper partner-slider progress-slider px-0 flex flex-col-reverse"
              breakpoints={{
                992: {
                  slidesPerView: 1.5,
                },
                0: {
                  slidesPerView: 1.3,
                  spaceBetween: 30,
                },
              }}
            >
              <div className="swiper-wrapper">
                {processSteps.map((step, index) => (
                  <SwiperSlide className="swiper-slide" key={index}>
                    <div className="single-step w-full">
                      <div className="upper flex flex-row gap-[16px]">
                        <h2 className="text-[34px] text-[#3fb7e2]">
                          {step.number}
                        </h2>
                        <div className="line w-full"></div>
                      </div>
                      <div className="lower mt-[24px]">
                        <p className="text-[#0e2933] font-[600]">
                          {step.title}
                        </p>
                        <p className="text-[#565656] mt-[12px]">
                          {step.content}
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </div>
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
}

export default OfferProcessSwiper;
