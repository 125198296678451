const getLastestPosts = async (category) => {
  const url = category
    ? `${process.env.REACT_APP_STRAPI_URL}/api/blog-posts?populate=*&filters[blog_tags][Name][$eq]=${category}&pagination[page]=1&pagination[pageSize]=3&sort[0]=createdAt:desc`
    : `${process.env.REACT_APP_STRAPI_URL}/api/blog-posts?populate=*&pagination[page]=1&pagination[pageSize]=3&sort[0]=createdAt:desc`;

  const response = await fetch(url);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const responseJSON = await response.json();
  return responseJSON.data;
};

export default getLastestPosts;
