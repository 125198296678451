import { useEffect, useState } from "react";
import Preloader from "../../layout/Preloader";
import Layout from "../../App";
import FloatingForm from "../../common/floating-form/FloatingForm";
import BlogDetailsContent from "./BlogDetailsContent";
function BlogDetails() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <Layout isAlternative={true}>
      {loading ? (
        <Preloader />
      ) : (
        <div
          className=""
          onClick={() => {
            document
              .querySelector(".main-nav")
              .className.includes("slidenav") &&
              document.querySelector(".remove").click();
          }}
        >
          <FloatingForm />

          <BlogDetailsContent />
        </div>
      )}
    </Layout>
  );
}

export default BlogDetails;
