import { useEffect, useState } from "react";
import TopPic from "../../../assets/img/oligamy/case-studies/dredging-operations/top.png";

import WOW from "wowjs";

import AboutImage from "../../../assets/img/oligamy/case-studies/dredging-operations/about1.png";
import AboutImage2 from "../../../assets/img/oligamy/case-studies/dredging-operations/about2.png";
import AboutImage3 from "../../../assets/img/oligamy/case-studies/dredging-operations/about3.png";
import AboutImage4 from "../../../assets/img/oligamy/case-studies/dredging-operations/about4.png";
import AboutImage5 from "../../../assets/img/oligamy/case-studies/dredging-operations/about5.png";
import AboutImage6 from "../../../assets/img/oligamy/case-studies/dredging-operations/about6.png";

import AnimatedBackground from "../../../assets/img/oligamy/case-studies/dredging-operations/animatedbackground.png";
import AnimatedBackground2 from "../../../assets/img/oligamy/case-studies/dredging-operations/animatedbackground2.png";
import AnimatedBackground3 from "../../../assets/img/oligamy/case-studies/dredging-operations/animatedbackground3.png";
import AnimatedBackground4 from "../../../assets/img/oligamy/case-studies/dredging-operations/animatedbackground4.png";
import AnimatedBackground5 from "../../../assets/img/oligamy/case-studies/dredging-operations/animatedbackground5.png";

import Preloader from "../../layout/Preloader";
import FloatingForm from "../../common/floating-form/FloatingForm";
import Layout from "../../App";
const SOLUTIONS = [
  {
    heading: "Offline Functionality",
    description:
      "The application had to function seamlessly without internet connectivity, ensuring uninterrupted reporting in remote locations.",
  },
  {
    heading: "User-Friendly Interface",
    description:
      "The new system needed to be intuitive and easy to use, reducing the learning curve for field workers.",
  },
  {
    heading: "Data Integration",
    description:
      "Efficiently extract and transition data from existing Excel spreadsheets to the new application.",
  },
];
function DredgingOperations() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  return (
    <Layout>
      <FloatingForm />
      {loading ? (
        <Preloader />
      ) : (
        <div
          onClick={() => {
            document
              .querySelector(".main-nav")
              .className.includes("slidenav") &&
              document.querySelector(".remove").click();
          }}
        >
          <section className="page-section mb-[100px]">
            <div className="position-relative">
              <div className="container max-w-[1320px]">
                <h1 className="title position-absolute z-10  max-w-[850px] 699px:text-[39px]">
                  Desktop MVP Application for Dredging Operations
                </h1>
              </div>
              <img className="w-100 filter brightness-[30%]" srcSet={TopPic} />
            </div>
            <div className="container max-w-[1320px] ">
              <div
                className="app-data wow animated fadeIn"
                data-wow-delay="200ms"
              >
                <h3>Year: 2024</h3>
              </div>
              <div className="about-app 991px:gap-0 gap-[24px]   mb-[72px] ">
                <div className=" text   w-full  wow animated fadeInUp">
                  <h1 className=" 991px:text-center  991px:container 991px:max-w-full 991px:mx-auto ">
                    Introduction
                  </h1>
                  <p>
                    At Oligamy Software, we pride ourselves on creating tailored
                    solutions to meet the unique needs of our clients. Recently,
                    we developed a desktop MVP application designed to
                    revolutionize daily reporting for dredging operations. This
                    case study explores the challenges faced by our client, the
                    innovative solution we provided, and the significant impact
                    it has had on their operations.
                  </p>
                </div>
                <img
                  src={AboutImage}
                  alt="Oddsee app"
                  className="wow animated fadeInUp "
                />
              </div>
              {/* SECOND IMAGE */}
              <div className="about-app second  991px:gap-0 gap-[24px] ">
                <div className=" text  w-full  wow animated fadeInUp">
                  <h1 className=" 991px:text-center  991px:container 991px:max-w-full 991px:mx-auto ">
                    The Challange
                  </h1>
                  <p>
                    Our client, a leading dredging company, faced a significant
                    challenge: their field workers were relying on Excel
                    spreadsheets to document daily activities. This method was
                    cumbersome, error-prone, and inefficient, particularly in
                    remote areas without internet access. The client needed a
                    more streamlined, reliable solution that could operate
                    offline on field laptops.
                  </p>
                </div>
                <img
                  src={AboutImage2}
                  alt="Oddsee app"
                  className="wow animated fadeInUp "
                />
              </div>
            </div>
            {/* END SECOND IMAGE */}
            {/* BACKGROUND */}
            <div className=" 799px:mt-[80px] mt-[144px]">
              <img
                className="w-full "
                src={AnimatedBackground}
                alt="palto-software"
              />
            </div>
            {/* END BACKGROUDN */}
            {/* SOLUTIONS */}
            <div className="container solutions max-w-[1320px]  799px:mt-[80px] mt-[144px] grid grid-cols-12 gap-4">
              <div className="767px:col-span-12  1199px:col-span-4 col-span-6 solutions-row">
                <h1 className="text-[#256C86]  679px:text-[22px]   799px:text-[28px] text-[34px]">
                  Solution
                </h1>
              </div>
              <div className=" 767px:col-span-12 1199px:col-span-8 col-span-6">
                <div className=" ">
                  {SOLUTIONS.map((item, index) => (
                    <div
                      key={index}
                      className="mb-4 p-[24px] rounded-[16px] bg-[#EFEFEF]"
                    >
                      <h5 className="text-[25px] 679px:text-[19px] mb-2 text-[#16404F] normal-case text-left">
                        {item.heading}
                      </h5>
                      <p className="text-[#565656]">{item.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* END SOLUTIONS */}
            {/* BACKGROUND */}
            <div className="799px:mt-[80px] mt-[144px]">
              <div>
                <img
                  className="w-full "
                  src={AnimatedBackground2}
                  alt="palto-software"
                />
              </div>
              {/* END BACKGROUDN */}
              {/* BACKGROUND */}
              <div>
                <img
                  className="w-full "
                  src={AnimatedBackground3}
                  alt="palto-software"
                />
              </div>
            </div>
            {/* END BACKGROUDN */}
            {/* ABOUT 2 */}
            <div className="container max-w-[1320px] 799px:mt-[80px] mt-[144px] ">
              <div className="about-app 991px:gap-0 gap-[24px]  mb-[72px]">
                <div className=" text   w-full  wow animated fadeInUp">
                  <h1 className=" 991px:text-center  991px:container 991px:max-w-full 991px:mx-auto ">
                    Our Solution
                  </h1>
                  <div>
                    <p className="pb-[16px] ">
                      We developed a desktop MVP application that addressed
                      these key requirements and more. Here&apos;s how we did
                      it:
                    </p>
                    <ul className="dredging-text-list  ">
                      <li className="flex ml-[20px]">
                        <div>a.&nbsp;</div>
                        <div>
                          Offline Operation: Our application was designed to
                          operate fully offline, allowing users to continue
                          their work without any disruption, regardless of
                          internet availability.
                        </div>
                      </li>
                      <li className="flex ml-[20px]">
                        <div>b.&nbsp;</div>
                        <div>
                          Intuitive Design: We created a clean, user-friendly
                          interface that simplifies data entry and report
                          generation. This design minimizes errors and enhances
                          productivity.
                        </div>
                      </li>
                      <li className="flex ml-[20px]">
                        <div>c.&nbsp;</div>
                        <div>
                          Excel Integration: The application includes robust
                          tools for importing data from Excel, ensuring a smooth
                          transition and maintaining data integrity.
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <img
                  src={AboutImage3}
                  alt="Oddsee app"
                  className="wow animated fadeInUp "
                />
              </div>
              {/* SECOND IMAGE */}
              <div className="about-app second 991px:gap-0 gap-[24px]   ">
                <div className=" text  w-full  wow animated fadeInUp">
                  <h1 className=" 991px:text-center  991px:container 991px:max-w-full 991px:mx-auto ">
                    User Profiles
                  </h1>
                  <div>
                    <p className="pb-[16px]">
                      Our application serves a diverse group of professionals in
                      the dredging industry:
                    </p>
                    <ul className="palto-text-list">
                      <li className="mb-[2px]">
                        Dredge Operators: Skilled workers who manage and operate
                        dredging equipment.
                      </li>
                      <li className="mb-[2px]">
                        Project Managers: Experts in overseeing complex dredging
                        projects.
                      </li>
                      <li className="mb-[2px]">
                        Approving Authorities: Responsible for verifying and
                        approving daily reports.
                      </li>
                      <li className="mb-[2px]">
                        Field Technicians: Maintain and repair equipment in the
                        field.
                      </li>
                      <li className="mb-[2px]">
                        Administrators: Configure and manage the application and
                        data access.
                      </li>
                    </ul>
                  </div>
                </div>
                <img
                  src={AboutImage4}
                  alt="Oddsee app"
                  className="wow animated fadeInUp "
                />
              </div>
            </div>
            {/* END ABOUT 2 */}

            {/* BACKGROUND */}
            <div className="799px:mt-[80px] mt-[144px]">
              <img
                className="w-full "
                src={AnimatedBackground4}
                alt="palto-software"
              />
            </div>
            {/* END BACKGROUDN */}
            <div className="container max-w-[1320px] 799px:mt-[80px] mt-[144px] ">
              <div className="about-app 991px:gap-0 gap-[24px]  mb-[72px]">
                <div className=" text   w-full  wow animated fadeInUp">
                  <h1 className=" 991px:text-center  991px:container 991px:max-w-full 991px:mx-auto ">
                    Implementation and Results
                  </h1>
                  <div>
                    <p className="pb-[20px] ">
                      Seamless Transition: The transition from Excel to the new
                      application was smooth and efficient, thanks to the robust
                      data integration tools we developed.
                    </p>
                    <p className="pb-[20px] ">
                      Increased Efficiency: The user-friendly interface and
                      offline functionality significantly reduced the time spent
                      on daily reporting, allowing field workers to focus more
                      on their core tasks.
                    </p>
                    <p>
                      Improved Data Accuracy: With a streamlined data entry
                      process, the accuracy of daily reports improved, leading
                      to better decision-making and project management.
                    </p>
                  </div>
                </div>
                <img
                  src={AboutImage5}
                  alt="Oddsee app"
                  className="wow animated fadeInUp "
                />
              </div>
              {/* SECOND IMAGE */}
              <div className="about-app 991px:gap-0 gap-[24px]  second  ">
                <div className=" text  w-full  wow animated fadeInUp">
                  <h1 className=" 991px:text-center  991px:container 991px:max-w-full 991px:mx-auto ">
                    Conculsion
                  </h1>
                  <p>
                    The desktop MVP application we developed for our client in
                    the dredging industry has proven to be a game-changer. By
                    addressing their specific needs with innovative solutions,
                    we have enhanced their operational efficiency and accuracy.
                    This project exemplifies Oligamy Software's commitment to
                    delivering tailored, high-impact solutions for our clients.
                  </p>
                </div>
                <img
                  src={AboutImage6}
                  alt="Oddsee app"
                  className="wow animated fadeInUp "
                />
              </div>
            </div>
            {/* END ABOUT 3 */}
            <div className=" 799px:mt-[80px] mt-[144px]">
              <img
                className="w-full "
                src={AnimatedBackground5}
                alt="palto-software"
              />
            </div>
          </section>
        </div>
      )}
    </Layout>
  );
}

export default DredgingOperations;
