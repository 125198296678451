import { useEffect, useState } from "react";
import TopPic from "../../../assets/img/oligamy/case-studies/oligamy/top.png";

import WOW from "wowjs";

import AboutImage from "../../../assets/img/oligamy/case-studies/oligamy/about1.png";
import AboutImage2 from "../../../assets/img/oligamy/case-studies/oligamy/about2.png";
import AboutImage3 from "../../../assets/img/oligamy/case-studies/oligamy/about3.png";
import AboutImage4 from "../../../assets/img/oligamy/case-studies/oligamy/about4.png";
import AboutImage5 from "../../../assets/img/oligamy/case-studies/oligamy/about5.png";
import AboutImage6 from "../../../assets/img/oligamy/case-studies/oligamy/about6.png";
import Video from "../../../assets/img/oligamy/case-studies/oligamy/oligamyvidc.mp4";
import AnimatedBackground from "../../../assets/img/oligamy/case-studies/oligamy/animatedbackground.png";
import AnimatedBackground2 from "../../../assets/img/oligamy/case-studies/oligamy/animatedbackground2.png";

import FloatingForm from "../../common/floating-form/FloatingForm";
import Layout from "../../App";
import Preloader from "../../layout/Preloader";
const SOLUTIONS = [
  {
    heading: "Developing and detailing our offerings",
    pros: [
      "We identified the key elements of our offerings and developed detailed descriptions to better explain the processes and benefits associated with our services.",
      "We focused on clearly presenting the value that our clients can achieve through our solutions.",
    ],
  },
  {
    heading: "Introduction of advanced animations",
    pros: [
      "We decided to incorporate advanced animations that not only attract users' attention but also better illustrate our products and processes.",
      "The animations are designed to enrich the user experience, making interaction with our website more engaging and enjoyable.",
    ],
  },
  {
    heading: "Addition of geometric accents",
    pros: [
      "Geometric accents were introduced to emphasize the modern character of our brand and give the site a unique and cohesive look.",
      "We carefully selected shapes and colors to maintain the aesthetic of the site and ensure consistency with our image.",
    ],
  },
  {
    heading: "Enhancing the color scheme",
    pros: [
      "We changed the color palette to better reflect our brand and capture users' attention.",
      "We focused on a harmonious combination of colors that are not only aesthetic but also contrasting and readable for users.",
    ],
  },
  {
    heading: "Addition of a separate recruitment page",
    pros: [
      "We introduced a separate page dedicated to the recruitment process to facilitate the flow of information and documents for both us and potential candidates.",
      "We included application forms and detailed information about the recruitment process to make it easier for candidates to apply to our company.",
    ],
  },
  {
    heading: "Expansion of the 'About Us' page",
    pros: [
      "We increased the scope of information on the 'About Us' page to better present the history, values, and people behind our company.",
      "We introduced sections about our mission, vision, and achievements to better acquaint users with our company and its activities.",
    ],
  },
  {
    heading: "Improvement of the 'Customer expectations brief' form",
    pros: [
      "The 'Customer Expectations Brief' form was thoroughly updated to better reflect the expectations and needs of our customers.",
    ],
  },
];

function Oligamy() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  return (
    <Layout>
      <FloatingForm />
      {loading ? (
        <Preloader />
      ) : (
        <div
          onClick={() => {
            document
              .querySelector(".main-nav")
              .className.includes("slidenav") &&
              document.querySelector(".remove").click();
          }}
        >
          <section className="page-section 799px:pb-[50px] pb-[100px]">
            <div className="position-relative">
              <div className="container max-w-[1320px]">
                <h1 className="title z-10 position-absolute 699px:text-[39px]">
                  Oligamy Software - New website
                </h1>
              </div>
              <img className="w-100 filter brightness-[30%]" srcSet={TopPic} />
            </div>
            <div className="container  max-w-[1320px]">
              <div
                className="app-data wow animated fadeIn"
                data-wow-delay="200ms"
              >
                <h3>Client: Oligamy Software</h3>
                <h3>Year: 2024</h3>
              </div>
              <div className="about-app  991px:gap-0 gap-[24px]   mb-[72px] ">
                <div className=" text   w-full  wow animated fadeInUp">
                  <h1 className=" 991px:text-center  991px:container 991px:max-w-full 991px:mx-auto ">
                    Diagnosis
                  </h1>
                  <p>
                    During a thorough analysis of our digital presence, we
                    noticed an urgent need to refresh our website. Despite its
                    functionality, the previous version lacked the level of
                    sophistication necessary to reflect our position as a
                    leading player in the market. Additionally, it failed to
                    effectively communicate our offerings, engage potential
                    clients, or facilitate efficient recruitment processes.
                  </p>
                </div>
                <img
                  src={AboutImage}
                  alt="Oddsee app"
                  className="wow animated fadeInUp "
                />
              </div>
              {/* SECOND IMAGE */}
              <div className="about-app second 991px:gap-0 gap-[24px]   ">
                <div className=" text  w-full  wow animated fadeInUp">
                  <h1 className=" 991px:text-center  991px:container 991px:max-w-full 991px:mx-auto ">
                    Observation
                  </h1>
                  <p>
                    Market trends indicated a growing preference for visually
                    appealing and informative websites. Furthermore, feedback
                    from our clients emphasized the need for a clear
                    presentation of our services and their benefits. The
                    existing website, with its simple layout and minimalist
                    design, did not meet these expectations.
                  </p>
                </div>
                <img
                  src={AboutImage2}
                  alt="Oddsee app"
                  className="wow animated fadeInUp  "
                />
              </div>
            </div>
            {/* END SECOND IMAGE */}
            {/* BACKGROUND */}
            <div className=" 799px:mt-[80px] mt-[144px]">
              <img
                className="w-full "
                src={AnimatedBackground}
                alt="palto-software"
              />
            </div>
            {/* END BACKGROUDN */}
            {/* ABOUT 2 */}
            <div className="container max-w-[1320px] 799px:mt-[80px] mt-[144px]">
              <div className="about-app  991px:gap-0 gap-[24px]   mb-[72px] ">
                <div className=" text   w-full  wow animated fadeInUp">
                  <h1 className=" 991px:text-center  991px:container 991px:max-w-full 991px:mx-auto ">
                    Motivation
                  </h1>
                  <p>
                    The decision to redesign our website stemmed from our
                    determination to strengthen our market position by
                    professionally and attractively presenting our services. We
                    wanted to showcase our vision, creativity, and commitment to
                    delivering the highest quality solutions.
                  </p>
                </div>
                <img
                  src={AboutImage3}
                  alt="Oddsee app"
                  className="wow animated fadeInUp "
                />
              </div>
              {/* SECOND IMAGE */}
              <div className="about-app second  991px:gap-0 gap-[24px] ">
                <div className=" text  w-full  wow animated fadeInUp">
                  <h1 className=" 991px:text-center  991px:container 991px:max-w-full 991px:mx-auto ">
                    Preliminary analysis
                  </h1>
                  <p>
                    An analysis of our current website revealed deficiencies in
                    clearly conveying our offerings, a lack of interactivity,
                    and insufficient visual appeal. Our goal was to create a new
                    site that not only better reflected our competencies but
                    also was more engaging for users.
                  </p>
                </div>
                <img
                  src={AboutImage4}
                  alt="Oddsee app"
                  className="wow animated fadeInUp "
                />
              </div>
            </div>{" "}
            {/* BACKGROUND */}
            <div className=" 799px:mt-[80px] mt-[144px]">
              <img
                className="w-full "
                src={AnimatedBackground2}
                alt="palto-software"
              />
            </div>
            {/* END BACKGROUDN */}
            {/* SOLUTIONS */}
            <div className="container  max-w-[1320px]  solutions 799px:mt-[80px] mt-[144px] grid grid-cols-12 ">
              <div className=" 767px:col-span-12 1199px:col-span-4 col-span-6">
                <h1 className="text-[#256C86]  679px:text-[22px]   799px:text-[28px] text-[34px]">
                  Our Steps
                </h1>
              </div>
              <div className=" 767px:col-span-12 1199px:col-span-8 col-span-6">
                <div className="mt-[56px]">
                  {SOLUTIONS.map((item, index) => (
                    <div
                      key={index}
                      className="mb-4 p-[24px] rounded-[16px] bg-[#EFEFEF]"
                    >
                      <h5 className="text-[25px] 679px:text-[19px] mb-2 text-[#16404F] normal-case text-left">
                        {item.heading}
                      </h5>
                      {item.pros && (
                        <ul className="list-disc list-inside ml-[25px] text-[#565656] mt-1">
                          {item.pros.map((pro, idx) => (
                            <li key={idx}>{pro}</li>
                          ))}
                        </ul>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* END SOLUTIONS */}
            {/* VIDEO */}
            <div className="799px:mt-[80px] mt-[144px]">
              <video playsInline loop autoPlay muted alt="">
                <source src={Video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            {/* ENND VIDOE */}
            {/* ABOUT 2 */}
            <div className="container  max-w-[1320px]  799px:mt-[80px] mt-[144px] ">
              <div className="about-app 991px:gap-0 gap-[24px]    mb-[72px] ">
                <div className=" text   w-full  wow animated fadeInUp">
                  <h1 className=" 991px:text-center  991px:container 991px:max-w-full 991px:mx-auto ">
                    Why these animations, tones of expression, and accents on
                    the site
                  </h1>
                  <p>
                    We chose advanced animations to make our site more dynamic
                    and attention-grabbing. The tones of expression were
                    selected to be professional yet accessible to a wide
                    audience. Geometric accents add a modern touch, highlighting
                    our innovation and creativity.
                  </p>
                </div>
                <img
                  src={AboutImage5}
                  alt="Oddsee app"
                  className="wow animated fadeInUp "
                />
              </div>
              {/* SECOND IMAGE */}
              <div className="about-app second 991px:gap-0 gap-[24px]  ">
                <div className=" text  w-full   wow animated fadeInUp">
                  <div className="space-y-[24px]">
                    <h1 className=" 991px:text-center  991px:container 991px:max-w-full 991px:mx-auto ">
                      Conclusion
                    </h1>
                    <p>
                      The new website is just the beginning of our journey to
                      strengthen our market position. We will continue to
                      develop our services and strive for excellence in
                      communication and interaction with our clients.
                    </p>
                    <div className="space-y-[16px]">
                      <h2 className="text-[#256C86]  679px:text-[19px] text-[25px] 500px:text-left 991px:text-center  991px:container 991px:max-w-full 991px:mx-auto ">
                        What to expect in the near future
                      </h2>
                      <p>
                        In the near future, we plan further improvements to our
                        site based on user feedback and evolving market trends.
                        We will also continue to seek new ways to better meet
                        the needs of our customers.
                      </p>
                    </div>
                  </div>
                </div>
                <img
                  src={AboutImage6}
                  alt="Oddsee app"
                  className="wow animated fadeInUp "
                />
              </div>
            </div>{" "}
          </section>
        </div>
      )}
    </Layout>
  );
}

export default Oligamy;
