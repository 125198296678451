import React, { useState } from "react";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";

export default function CaseStudiesHomeSwiperNew({
  caseStudiesData,
  offerPage,
}) {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };
  return (
    <div className={"case-studies-home-swiper relative z-[3]"}>
      <Marquee speed={100} pauseOnHover>
        {caseStudiesData
          ? caseStudiesData.map(
              ({ thumbnail, title, url, tag1, tag2, tag3 }, index) => {
                return (
                  <div
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    key={index}
                    className="single-slide continuous-slide select-none 799px:max-w-[375px] max-w-[506px] mr-[20px]"
                  >
                    <Link to={url}>
                      <div className="img-wrapper max-w-[555px] rounded-[24px] relative z-[9] group">
                        <img src={thumbnail} alt="thumbnail" />
                        <div
                          className={`absolute flex gap-[8px] top-[20px] left-[24px] transition-opacity duration-500 ${
                            hoveredIndex === index ? "opacity-100" : "opacity-0"
                          }`}
                        >
                          {tag1 && (
                            <div className="bg-[#FDFDFD80]/60 px-[16px] py-[8px] rounded-[100px] text-[12px] text-[#16404F]">
                              {tag1}
                            </div>
                          )}
                          {tag2 && (
                            <div className="bg-[#FDFDFD80]/60 px-[16px] py-[8px] rounded-[100px] text-[12px] text-[#16404F]">
                              {tag2}
                            </div>
                          )}
                          {tag3 && (
                            <div className="bg-[#FDFDFD80]/60 px-[16px] py-[8px] rounded-[100px] text-[12px] text-[#16404F]">
                              {tag3}
                            </div>
                          )}
                        </div>
                      </div>
                    </Link>
                    <div className="text mt-[32px] 500px:mt-[24px]">
                      <Link to={url} className={"flex gap-[16px] items-center"}>
                        <p
                          className={`text-[#0E2933] text-[34px] leading-[40.8px] text-ellipsis whitespace-nowrap overflow-hidden ${
                            offerPage && "1199px:text-[28px]"
                          } 500px:text-[22px]`}
                        >
                          {title}
                        </p>
                        <svg
                          width="40"
                          height="41"
                          viewBox="0 0 40 41"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className={
                            "min-w-[40px] 500px:max-w-[24px] 500px:min-w-[24px]"
                          }
                        >
                          <path d="M23.3984 8.97656L21.6016 10.7734L30.0781 19.25H5V21.75H30.0781L21.6016 30.2266L23.3984 32.0234L34.0234 21.3984L34.8828 20.5L34.0234 19.6016L23.3984 8.97656Z" />
                        </svg>
                      </Link>
                    </div>
                  </div>
                );
              }
            )
          : dataCaseStudiesHomeSwiper.map(
              ({ thumbnail, title, url, tag1, tag2 }, index) => (
                <div
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  key={index}
                  className="single-slide continuous-slide select-none max-w-[555px]"
                >
                  <Link to={url}>
                    <div className="img-wrapper max-w-[555px] rounded-[24px] relative z-[9] group">
                      <img src={thumbnail} alt="thumbnail" />
                      <div
                        className={`absolute flex gap-[8px] top-[20px] left-[24px] transition-opacity duration-300 ${
                          hoveredIndex === index ? "opacity-100" : "opacity-0"
                        }`}
                      >
                        {tag1 && (
                          <div className="bg-[#FDFDFD80]/50 px-[16px] py-[8px] rounded-[100px] text-[12px] text-[#16404F]">
                            {tag1}
                          </div>
                        )}
                        {tag2 && (
                          <div className="bg-[#FDFDFD80]/50 px-[16px] py-[8px] rounded-[100px] text-[12px] text-[#16404F]">
                            {tag2}
                          </div>
                        )}
                      </div>
                    </div>
                  </Link>
                  <div className="text mt-[32px] 500px:mt-[24px]">
                    <Link to={url} className={"flex gap-[16px] items-center"}>
                      <p
                        className={`text-[#0E2933] text-[34px] leading-[40.8px] text-ellipsis whitespace-nowrap overflow-hidden ${
                          offerPage && "1199px:text-[28px]"
                        } 500px:text-[22px]`}
                      >
                        {title}
                      </p>
                      <svg
                        width="40"
                        height="41"
                        viewBox="0 0 40 41"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={
                          "min-w-[40px] 500px:max-w-[24px] 500px:min-w-[24px]"
                        }
                      >
                        <path d="M23.3984 8.97656L21.6016 10.7734L30.0781 19.25H5V21.75H30.0781L21.6016 30.2266L23.3984 32.0234L34.0234 21.3984L34.8828 20.5L34.0234 19.6016L23.3984 8.97656Z" />
                      </svg>
                    </Link>
                  </div>
                </div>
              )
            )}
      </Marquee>
    </div>
  );
}
