import { useState, useRef, useEffect } from "react";
import { useVisibleHeader } from "../../common/header/HeaderVisibilityContext";

function MobileRelatedLinks({ links }) {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);
  const divRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = (e, link) => {
    e.preventDefault();
    const targetElement = document.getElementById(link.id);
    if (targetElement) {
      const divHeight = divRef.current.getBoundingClientRect().height - 45;
      const targetPosition =
        targetElement.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: targetPosition - divHeight - 115,
        behavior: "smooth",
      });
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
    } else {
      contentRef.current.style.maxHeight = "0px";
    }
  }, [isOpen]);

  const [isQuickNavFixed, setIsQuickNavFixed] = useState(false);
  useEffect(() => {
    const el = document.querySelector(".blog-quick-nav");
    const elOffset = el.offsetTop;

    const onScroll = () => {
      if (elOffset - 20 <= window.scrollY) {
        setIsQuickNavFixed(true);
      } else setIsQuickNavFixed(false);
    };
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const { isVisible, setIsVisible } = useVisibleHeader();

  return (
    <div
      ref={divRef}
      className={` blog-quick-nav sticky top-[20px] z-10  transition-all transform duration-500  bg-[#FDFDFD] py-[16px] px-[16px] rounded-[8px] border border-[#EFEFEF] blog-dropdown-shadow mb-[32px] ${
        isVisible ? "top-[80px]" : ""
      } ${isQuickNavFixed ? "isFixed" : ""}`}
    >
      <button
        onClick={toggleDropdown}
        className="flex items-center justify-between w-full text-gray-800"
      >
        <span className="text-[19px] text-[#173D46] leading-[22.8px]">
          On this page
        </span>
        <svg
          className={`w-[24px] h-[20px] transform transition-transform duration-500 ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="#256C86"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      <div
        ref={contentRef}
        className="overflow-hidden transition-all duration-500"
        style={{ maxHeight: "0px" }}
      >
        <div className="mt-[24px] space-y-1">
          {links.length > 0 ? (
            links.map((link, i) => (
              <a
                onClick={(e) => handleLinkClick(e, link)}
                key={i}
                href={`#${link.id}`}
                className="text-[16px] block max-w-full truncate text-[#3FB7E2]"
              >
                <span>{`${i + 1}.`} </span>
                <span className="underline underline-offset-[3px] pl-0.5">
                  {link.title}
                </span>
              </a>
            ))
          ) : (
            <div>There are no navigation links.</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MobileRelatedLinks;
