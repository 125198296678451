import "swiper/css/autoplay";

// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import SwiperNavButtons from "./SwiperNavButtons";
import { StaggerChildren } from "../offer/Animations";
SwiperCore.use([Navigation, Autoplay]);
function AboutTimelineSwiperMobile() {
  const oddseeSwiper = {
    loop: false,
    spaceBetween: 0,
    speed: 1200,
    slidesPerView: 1.1,
    allowTouchMove: true,
    autoplay: false,
    centeredSlides: false,
  };
  const handleSwiper = (swiper) => {
    // Access the Swiper instance
    if (swiper) {
      // Set spaceBetween for the specific slide (index 2)
      swiper.params.slidesPerGroup = 1;
      swiper.params.spaceBetween = 30;
      swiper.update();
    }
  };
  return (
    <>
      <section className="oddsee-slider billon">
        <div className="row">
          <div className="oddsee-swiper-wrapper progress-slider-wrapper">
            <StaggerChildren>
              <Swiper
                {...oddseeSwiper}
                className="swiper partner-slider progress-slider px-0 flex flex-col-reverse"
                breakpoints={{
                  1400: {
                    slidesPerView: 1.1,
                  },
                  1200: {
                    spaceBetween: 0,
                  },
                  300: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                }}
                onSwiper={handleSwiper}
              >
                <div className="title-section flex mb-[64px] 767px:flex-col 767px:items-start  767px:gap-[32px]">
                  <h1 className="text-[#fdfdfd] text-[45px] 767px:text-[38px] 500px:text-[29px]">
                    Explore the world of Oligamy Software – where excellence
                    meets innovation.
                  </h1>
                  <SwiperNavButtons />
                </div>
                <div className="swiper-wrapper">
                  <SwiperSlide className="swiper-slide ">
                    <div className="single-partner w-full">
                      <div className="upper">
                        <h3>2024</h3>
                        <div className="line"></div>
                      </div>
                      <div className="lower">
                        <div className="single-timestamp">
                          <h4>
                            <span>Future.</span> Unknown
                          </h4>
                          <p></p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <div className="upper">
                        <div className="line"></div>
                      </div>
                      <div className="lower">
                        <div className="single-timestamp">
                          <h4>
                            <span>September.</span> Over 60 associates
                          </h4>
                          <p>
                            Over 100 projects delivered, and ongoing. Our
                            clients value us as a trusted partner.
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <div className="upper">
                        <div className="line"></div>
                      </div>
                      <div className="lower">
                        <div className="single-timestamp">
                          <h4>
                            <span>March.</span> Over 30 proof of concepts
                            created
                          </h4>
                          <p>
                            Helping achieve business goals for our clients by
                            validating ideas that may shape the future.
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <div className="upper">
                        <h3>2023</h3>
                        <div className="line"></div>
                      </div>
                      <div className="lower">
                        <div className="single-timestamp">
                          <h4>
                            <span>November.</span> Reached 35 active specialists
                          </h4>
                          <p>
                            Serving customers as development teams, consultants,
                            tech leads, architects, and designers.
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <div className="upper">
                        <div className="line"></div>
                      </div>
                      <div className="lower">
                        <div className="single-timestamp">
                          <h4>
                            <span>August.</span> Serving businesses around the
                            globe
                          </h4>
                          <p>
                            Our specialists are engaged in endeavors across 4
                            continents, 6 time zones, and multiple industries.
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <div className="upper">
                        <div className="line"></div>
                      </div>
                      <div className="lower">
                        <div className="single-timestamp">
                          <h4>
                            <span>January.</span> Over 35 projects delivered so
                            far
                          </h4>
                          <p>
                            With over 10 long-term client partnerships, we
                            continue to fulfill our business goals.
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <div className="upper">
                        <h3>2022</h3>
                        <div className="line"></div>
                      </div>
                      <div className="lower">
                        <div className="single-timestamp">
                          <h4>
                            <span>October.</span> AI and Blockchain departments
                            founded
                          </h4>
                          <p>
                            With ongoing team expansion, we assemble teams of
                            specialists to meet customer needs.
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <div className="upper">
                        <div className="line"></div>
                      </div>
                      <div className="lower">
                        <div className="single-timestamp">
                          <h4>
                            <span>July.</span> Over 20 actively working
                            associates
                          </h4>
                          <p>
                            Continuing our search for talent to strengthen our
                            team and provide exceptional value.
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <div className="upper">
                        <div className="line"></div>
                      </div>
                      <div className="lower">
                        <div className="single-timestamp">
                          <h4>
                            <span>April.</span> Design department established
                          </h4>
                          <p>
                            Broadened our range of services into the creative
                            area.
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <div className="upper">
                        <h3>2021</h3>
                        <div className="line"></div>
                      </div>
                      <div className="lower">
                        <div className="single-timestamp">
                          <h4>
                            <span>December.</span> 10 projects delivered
                          </h4>
                          <p>
                            With 10 projects completed and more ongoing, we
                            continue to deliver value-centric services.
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <div className="upper">
                        <div className="line"></div>
                      </div>
                      <div className="lower">
                        <div className="single-timestamp">
                          <h4>
                            <span>September.</span> Reached 10 associated
                            specialists
                          </h4>
                          <p>
                            Expanded our areas of expertise with new associates,
                            serving clients worldwide.
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <div className="upper">
                        <div className="line"></div>
                      </div>
                      <div className="lower">
                        <div className="single-timestamp">
                          <h4>
                            <span>May.</span> Oligamy Software Founded
                          </h4>
                          <p>
                            Establishment of a company with clear values and a
                            vision for delivering quality service.
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </div>
              </Swiper>
            </StaggerChildren>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutTimelineSwiperMobile;
