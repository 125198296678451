import { useParams } from "react-router-dom";
import AiValidation from "./ai-validation/AiValidation";
import WebDevelopment from "./web-development/WebDevelopment";
import BlockchainValidation from "./blockchain-validation/BlockchainValidation";
import UIUXDesign from "./ui-ux/ui-ux";
import BlockchainDevelopment from "./blockchain-development/BlockchainDevelopment";
import ProductDevelopment from "./product-development/ProductDevelopment";
import DesignImplementationService from "./design-implementation-service/DesignImplementationService";
import DigitalAcceleration from "./digital-acceleration/DigitalAcceleration";
import DedicatedTeams from "./dedicated-teams/DedicatedTeams";
import StaffAugmentation from "./staff-augmentation/StaffAugmentation";

function OfferWrapper() {
  const { slug } = useParams();

  return (
    <>
      {slug === "blockchain-validation" && <BlockchainValidation />}
      {slug === "ai-validation" && <AiValidation />}

      {slug === "ui-ux" && <UIUXDesign />}

      {slug === "blockchain-development" && <BlockchainDevelopment />}
      {slug === "ai-solutions" && <ProductDevelopment />}
      {slug === "design-implementation-service" && (
        <DesignImplementationService />
      )}
      {slug === "web-development" && <WebDevelopment />}

      {slug === "digital-acceleration" && <DigitalAcceleration />}
      {slug === "dedicated-teams" && <DedicatedTeams />}
      {slug === "staff-augmentation" && <StaffAugmentation />}
    </>
  );
}

export default OfferWrapper;
