import { memo } from "react";

function NewPostWidget({ links }) {
  return (
    <>
      <div className="sidebar-widget sticky transform top-[30%] ">
        <h4 className="text-[#256C86] text-[25px]">On this page</h4>
        {!links.length && <div>There are no navigation links.</div>}
        <div className="space-y-1  ">
          {links.length > 0 &&
            links.map((link, i) => (
              <a
                key={i}
                href={`#${link.id}`}
                className=" text-[16px] block max-w-full truncate  text-[#3FB7E2]   "
              >
                <span>{`${i + 1}.`} </span>
                <span className="underline underline-offset-[3px]  pl-0.5">
                  {link.title}
                </span>
              </a>
            ))}
        </div>
      </div>
    </>
  );
}

export default memo(NewPostWidget);
