function OfferContact({ text }) {
  return (
    <section className="container pb-12 ">
      <div className="offer-contact   flex justify-between">
        <h3 className="text-[#0e2933] 1199px:text-[38px] 500px:text-[29px] text-left text-[45px]">
          Contact Us!
        </h3>
        <div className="max-w-[670px]">
          <p className="text-[#565656] mt-[8px]">{text}</p>
          <p className="text-[#565656] mt-[30px] ">
            Contact us: <a href="mailto:hello@oligamy.com">hello@oligamy.com</a>
          </p>
        </div>
      </div>
    </section>
  );
}

export default OfferContact;
