import React, { useEffect, useState } from "react";
import HeroAreaSphere from "./HeroAreaSphere";

function HeroArea() {
  const [containerStyles, setContainerStyles] = useState({});
  const [animationWord, setAnimationWord] = useState("innovative");

  useEffect(() => {
    const handleResize = () => {
      const container = document.querySelector(".container");
      if (container) {
        const containerStyles = window.getComputedStyle(container);
        setContainerStyles({
          paddingLeft: containerStyles.getPropertyValue("padding-left"),
          paddingRight: containerStyles.getPropertyValue("padding-right"),
          marginLeft: containerStyles.getPropertyValue("margin-left"),
          marginRight: containerStyles.getPropertyValue("margin-right"),
        });
      }
    };

    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const animationWords = ["innovative", "creative", "efficient"];
    let index = 0;

    const intervalId = setInterval(() => {
      let word = animationWords[index];
      let timePassed = 0;

      const switchLetters = (wordIndex) => {
        let newWord = word.substring(0, wordIndex);
        for (let i = wordIndex; i < word.length; i++) {
          if (Math.random() < 0.5) {
            newWord += letters[Math.floor(Math.random() * letters.length)];
          } else {
            newWord += word[i];
          }
        }
        setAnimationWord(newWord);
      };

      let letterIndex = 0;
      const switchInterval = setInterval(() => {
        timePassed += 100;
        if (timePassed >= 2000) {
          clearInterval(switchInterval);
          setAnimationWord(word); // Ensure the word returns to original after 2 seconds
        } else {
          switchLetters(letterIndex);
          letterIndex++;
          if (letterIndex >= word.length) {
            clearInterval(switchInterval);
            setAnimationWord(word);
          }
        }
      }, 60);

      index = (index + 1) % animationWords.length;
    }, 4000);

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <section className="hero-area" id="hero">
        <div className="text-container" style={containerStyles}>
          <h3>Oligamy Software</h3>
          <h1>
            Digital Acceleration <br></br> Through{" "}
            <br className="hidden 500px:block" />
            <span className="glitching-animation">{animationWord}</span> <br />
            Software Development
            <span className="blinking-animation">_</span>
          </h1>
        </div>

        <HeroAreaSphere />
      </section>
    </>
  );
}

export default HeroArea;
