import React, { useEffect, useState } from "react";

export default function SecondStep({
  onNextStep,
  setUserResponses,
  onPreviousStep,
  userResponses,
}) {
  const [section1Option, setSection1Option] = useState(
    userResponses.step2.section1Option || null
  );
  const [section2Option, setSection2Option] = useState(
    userResponses.step2.section2Option || null
  );

  const [userLinks1, setUserLinks1] = useState(
    Array.isArray(userResponses.step2.section1Option)
      ? userResponses.step2.section1Option
      : []
  );
  const [userLinks2, setUserLinks2] = useState(
    Array.isArray(userResponses.step2.section2Option)
      ? userResponses.step2.section2Option
      : []
  );

  const [clickedNext, setClickedNext] = useState(false);

  const handleAddLink = (optionNumber) => {
    const linkInput = document.getElementById(`linkInput${optionNumber}`);
    if (linkInput) {
      const newLink = linkInput.value;
      if (newLink) {
        // Dynamically select setUserLinks based on optionNumber
        const setUserLinksFunction =
          optionNumber === 1 ? setUserLinks1 : setUserLinks2;

        setUserLinksFunction((prevLinks) => [...prevLinks, newLink]);
        linkInput.value = ""; // Clear the input field after adding the link
      }
    }
  };

  const handleDeleteLink = (index, optionNumber) => {
    // Dynamically select setUserLinks based on optionNumber
    const setUserLinksFunction =
      optionNumber === 1 ? setUserLinks1 : setUserLinks2;

    setUserLinksFunction((prevLinks) =>
      prevLinks.filter((_, i) => i !== index)
    );
  };

  const handleOptionChange = (section, option) => {
    if (section === 1) {
      setSection1Option((prevOption) =>
        prevOption === option ? null : option
      );
    } else if (section === 2) {
      setSection2Option((prevOption) =>
        prevOption === option ? null : option
      );
    }
  };

  const handleNextStep = () => {
    setClickedNext(true);
    // Validate that at least one option is selected in each section
    if (Array.isArray(section1Option) || Array.isArray(section2Option)) {
      // Display an error message or handle the lack of selection in an appropriate way
      return;
    }

    // Check if section1Option is 'yes' and either userLinks1 is not empty or it is empty
    const section1OptionValue =
      section1Option === "yes"
        ? userLinks1.length > 0
          ? userLinks1
          : "yes"
        : section1Option;

    // Check if section2Option is 'yes' and either userLinks2 is not empty or it is empty
    const section2OptionValue =
      section2Option === "yes"
        ? userLinks2.length > 0
          ? userLinks2
          : "yes"
        : section2Option;

    setUserResponses((prevUserResponses) => ({
      ...prevUserResponses,
      step2: {
        ...prevUserResponses.step2,
        section1Option: section1OptionValue,
        section2Option: section2OptionValue,
      },
    }));

    onNextStep();
  };

  const handlePreviousStep = () => {
    onPreviousStep();
  };

  useEffect(() => {
    // Check if userLinks1 is not an empty array and set section1Option to "yes"
    if (Array.isArray(userLinks1) && userLinks1.length > 0) {
      setSection1Option("yes");
    }
    if (Array.isArray(userLinks2) && userLinks2.length > 0) {
      setSection2Option("yes");
    }
  }, []);

  return (
    <div className="second-step">
      <div className="section">
        <h4 className="text-[25px] tracking-[-0.25px] text-[#173d46] mb-[16px] 500px:text-[22px]">
          Do you already have any guidelines regarding the appearance (e.g.
          logo, colors) of the project?
        </h4>
        <div className="options">
          <div className="option">
            <label htmlFor="section1yes">
              <input
                type="radio"
                name="section1"
                id="section1yes"
                checked={section1Option === "yes"}
                onChange={() => handleOptionChange(1, "yes")}
              />
              Yes
            </label>
          </div>
          {section1Option === "yes" && (
            <div className="links-wrapper">
              <div className="links-input-wrapper flex">
                <input
                  type="text"
                  name=""
                  id="linkInput1"
                  placeholder="Add link (optional)"
                />
                <button onClick={() => handleAddLink(1)} className="add-link">
                  Add
                </button>
              </div>
              {section1Option === "yes" &&
                Array.isArray(userLinks1) &&
                userLinks1.length > 0 && (
                  <div className="links">
                    {userLinks1.map((link, index) => (
                      <div className="link" key={index}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M7.33301 11.3337H4.66634C3.74412 11.3337 2.9579 11.0085 2.30767 10.3583C1.65745 9.7081 1.33256 8.9221 1.33301 8.00033C1.33301 7.0781 1.65812 6.29188 2.30834 5.64166C2.95856 4.99144 3.74456 4.66655 4.66634 4.66699H7.33301V6.00033H4.66634C4.11079 6.00033 3.63856 6.19477 3.24968 6.58366C2.86079 6.97255 2.66634 7.44477 2.66634 8.00033C2.66634 8.55588 2.86079 9.0281 3.24968 9.41699C3.63856 9.80588 4.11079 10.0003 4.66634 10.0003H7.33301V11.3337ZM5.33301 8.66699V7.33366H10.6663V8.66699H5.33301ZM8.66634 11.3337V10.0003H11.333C11.8886 10.0003 12.3608 9.80588 12.7497 9.41699C13.1386 9.0281 13.333 8.55588 13.333 8.00033C13.333 7.44477 13.1386 6.97255 12.7497 6.58366C12.3608 6.19477 11.8886 6.00033 11.333 6.00033H8.66634V4.66699H11.333C12.2552 4.66699 13.0415 4.9921 13.6917 5.64233C14.3419 6.29255 14.6668 7.07855 14.6663 8.00033C14.6663 8.92255 14.3412 9.70877 13.691 10.359C13.0408 11.0092 12.2548 11.3341 11.333 11.3337H8.66634Z"
                            fill="#3FB7E2"
                          />
                        </svg>
                        <a href={`${link}`} target="_blank">
                          {link}
                        </a>
                        <div className="bin-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            className="ml-[16px]"
                            onClick={() => handleDeleteLink(index, 1)}
                          >
                            <path d="M5.83333 17.5C5.375 17.5 4.9825 17.3367 4.65583 17.01C4.32917 16.6833 4.16611 16.2911 4.16667 15.8333V5H3.33333V3.33333H7.5V2.5H12.5V3.33333H16.6667V5H15.8333V15.8333C15.8333 16.2917 15.67 16.6842 15.3433 17.0108C15.0167 17.3375 14.6244 17.5006 14.1667 17.5H5.83333ZM14.1667 5H5.83333V15.8333H14.1667V5ZM7.5 14.1667H9.16667V6.66667H7.5V14.1667ZM10.8333 14.1667H12.5V6.66667H10.8333V14.1667Z" />
                          </svg>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
            </div>
          )}
          <div className="option">
            <label htmlFor="section1no">
              <input
                type="radio"
                name="section1"
                id="section1no"
                checked={section1Option === "no"}
                onChange={() => handleOptionChange(1, "no")}
              />
              No
            </label>
          </div>
        </div>
      </div>

      <div className="section mt-[36px]">
        <h4 className="text-[25px] tracking-[-0.25px] text-[#173d46] mb-[16px] 500px:text-[22px]">
          Do you have specific inspirations or examples of websites/apps that
          you like?
        </h4>
        <div className="options">
          <div className="option">
            <label htmlFor="section2yes">
              <input
                type="radio"
                name="section2"
                id="section2yes"
                checked={section2Option === "yes"}
                onChange={() => handleOptionChange(2, "yes")}
              />
              Yes
            </label>
          </div>
          {section2Option === "yes" && (
            <div className="links-wrapper">
              <div className="links-input-wrapper flex">
                <input
                  type="text"
                  name=""
                  id="linkInput2"
                  placeholder="Add link (optional)"
                />
                <button onClick={() => handleAddLink(2)} className="add-link">
                  Add
                </button>
              </div>
              {section1Option === "yes" &&
                Array.isArray(userLinks2) &&
                userLinks2.length > 0 && (
                  <div className="links">
                    {userLinks2.map((link, index) => (
                      <div className="link" key={index}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M7.33301 11.3337H4.66634C3.74412 11.3337 2.9579 11.0085 2.30767 10.3583C1.65745 9.7081 1.33256 8.9221 1.33301 8.00033C1.33301 7.0781 1.65812 6.29188 2.30834 5.64166C2.95856 4.99144 3.74456 4.66655 4.66634 4.66699H7.33301V6.00033H4.66634C4.11079 6.00033 3.63856 6.19477 3.24968 6.58366C2.86079 6.97255 2.66634 7.44477 2.66634 8.00033C2.66634 8.55588 2.86079 9.0281 3.24968 9.41699C3.63856 9.80588 4.11079 10.0003 4.66634 10.0003H7.33301V11.3337ZM5.33301 8.66699V7.33366H10.6663V8.66699H5.33301ZM8.66634 11.3337V10.0003H11.333C11.8886 10.0003 12.3608 9.80588 12.7497 9.41699C13.1386 9.0281 13.333 8.55588 13.333 8.00033C13.333 7.44477 13.1386 6.97255 12.7497 6.58366C12.3608 6.19477 11.8886 6.00033 11.333 6.00033H8.66634V4.66699H11.333C12.2552 4.66699 13.0415 4.9921 13.6917 5.64233C14.3419 6.29255 14.6668 7.07855 14.6663 8.00033C14.6663 8.92255 14.3412 9.70877 13.691 10.359C13.0408 11.0092 12.2548 11.3341 11.333 11.3337H8.66634Z"
                            fill="#3FB7E2"
                          />
                        </svg>
                        <a href={`${link}`} target="_blank">
                          {link}
                        </a>
                        <div className="bin-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            className="ml-[16px]"
                            onClick={() => handleDeleteLink(index, 2)}
                          >
                            <path d="M5.83333 17.5C5.375 17.5 4.9825 17.3367 4.65583 17.01C4.32917 16.6833 4.16611 16.2911 4.16667 15.8333V5H3.33333V3.33333H7.5V2.5H12.5V3.33333H16.6667V5H15.8333V15.8333C15.8333 16.2917 15.67 16.6842 15.3433 17.0108C15.0167 17.3375 14.6244 17.5006 14.1667 17.5H5.83333ZM14.1667 5H5.83333V15.8333H14.1667V5ZM7.5 14.1667H9.16667V6.66667H7.5V14.1667ZM10.8333 14.1667H12.5V6.66667H10.8333V14.1667Z" />
                          </svg>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
            </div>
          )}
          <div className="option">
            <label htmlFor="section2no">
              <input
                type="radio"
                name="section2"
                id="section2no"
                checked={section2Option === "no"}
                onChange={() => handleOptionChange(2, "no")}
              />
              No
            </label>
          </div>
        </div>
      </div>
      <div className={`error-message ${clickedNext ? "active" : ""}`}>
        <p>Please select at least one answer for each question</p>
      </div>
      <div className="buttons-wrapper large">
        <button className="cancel-btn" onClick={handlePreviousStep}>
          Previous step
        </button>

        <button className="next-step-btn" onClick={handleNextStep}>
          Next step
        </button>
      </div>
      <div className="buttons-wrapper small">
        <div className="buttons">
          <button className="cancel-btn" onClick={handlePreviousStep}>
            Previous step
          </button>
          <button className="next-step-btn" onClick={handleNextStep}>
            Next step
          </button>
        </div>
      </div>
    </div>
  );
}
