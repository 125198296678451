import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useMemo, useRef, useEffect, useState } from "react";
import * as THREE from "three";
import useResponsiveScaleAndPosition from "./useResponsiveScale";

const lerp = (start, end, alpha) => {
  return start + (end - start) * alpha;
};

const NewGeometryParticles = ({ progress }) => {
  const ethModel = useGLTF("/eth.glb");
  const brainModel = useGLTF("/brain.glb");
  const laptopModel = useGLTF("/laptop.glb");

  const { scale: ethScale, position: ethPosition } =
    useResponsiveScaleAndPosition("eth");
  const { scale: brainScale, position: brainPosition } =
    useResponsiveScaleAndPosition("brain");
  const { scale: laptopScale, position: laptopPosition } =
    useResponsiveScaleAndPosition("laptop");
  const { position: pointsPosition } = useResponsiveScaleAndPosition("points");

  const brainRef = useRef();
  const ethRef = useRef();
  const laptopRef = useRef();

  const [fadeOpacity, setFadeOpacity] = useState({
    brain: 1,
    eth: 0,
    laptop: 0,
  });

  useEffect(() => {
    if (progress < 0.33) {
      setFadeOpacity({
        brain: lerp(1, 0, progress / 0.33),
        eth: 0,
        laptop: 0,
      });
    } else if (progress >= 0.33 && progress < 0.5) {
      setFadeOpacity({
        brain: 0,
        eth: lerp(0, 1, (progress - 0.33) / 0.17),
        laptop: 0,
      });
    } else if (progress >= 0.5 && progress < 0.66) {
      setFadeOpacity({
        brain: 0,
        eth: lerp(1, 0, (progress - 0.5) / 0.16),
        laptop: 0,
      });
    } else {
      setFadeOpacity({
        brain: 0,
        eth: 0,
        laptop: lerp(0, 1, (progress - 0.66) / 0.34),
      });
    }
  }, [progress]);

  const applyOpacity = (model, opacity) => {
    if (model) {
      model.traverse((child) => {
        if (child.isMesh) {
          child.material.depthWrite = opacity === 1;
          child.material.transparent = true;
          child.material.opacity = opacity;
        }
      });
    }
  };

  useEffect(() => {
    applyOpacity(brainModel.scene, fadeOpacity.brain);
    applyOpacity(ethModel.scene, fadeOpacity.eth);
    applyOpacity(laptopModel.scene, fadeOpacity.laptop);
  }, [fadeOpacity, brainModel, ethModel, laptopModel]);
  const geometries = useMemo(() => {
    const sphereGeometry = new THREE.SphereGeometry(8, 32, 32); // Sfera
    const torusGeometry = new THREE.TorusGeometry(6, 3, 16, 100); // Torus

    const extractVertices = (geometry) => {
      const vertices = [];
      const positionAttribute = geometry.attributes.position;
      for (let i = 0; i < positionAttribute.count; i++) {
        const vertex = new THREE.Vector3().fromBufferAttribute(
          positionAttribute,
          i
        );
        vertices.push(vertex);
      }
      return vertices;
    };

    const sphereVertices = extractVertices(sphereGeometry);
    const torusVertices = extractVertices(torusGeometry);

    const numVertices = Math.min(sphereVertices.length, torusVertices.length);

    return {
      sphere: sphereVertices.slice(0, numVertices),
      torus: torusVertices.slice(0, numVertices),
      numVertices: numVertices,
    };
  }, []);
  const particlesGeometry = useMemo(() => {
    const { sphere, torus, numVertices } = geometries;

    const interpolatedVertices = [];

    let interpProgress = progress;

    const torusStaticStart = 0.45;
    const torusStaticEnd = 0.55;

    if (progress >= torusStaticStart && progress <= torusStaticEnd) {
      interpProgress = 0.5;
    } else if (progress < torusStaticStart) {
      interpProgress = (progress / torusStaticStart) * 0.5;
    } else {
      interpProgress =
        0.5 + ((progress - torusStaticEnd) / (1 - torusStaticEnd)) * 0.5;
    }

    for (let i = 0; i < numVertices; i++) {
      let start, end, alpha;

      if (interpProgress < 0.5) {
        start = sphere[i];
        end = torus[i];
        alpha = interpProgress / 0.5;
      } else {
        start = torus[i];
        end = sphere[i];
        alpha = (interpProgress - 0.5) / 0.5;
      }

      const x = lerp(start.x, end.x, alpha);
      const y = lerp(start.y, end.y, alpha);
      const z = lerp(start.z, end.z, alpha);

      interpolatedVertices.push(x, y, z);
    }

    const bufferGeometry = new THREE.BufferGeometry();
    bufferGeometry.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(interpolatedVertices, 3)
    );
    return bufferGeometry;
  }, [progress, geometries]);

  return (
    <>
      <ambientLight intensity={0.5} />
      <directionalLight intensity={1} position={[5, 5, 5]} />

      <primitive
        position={brainPosition}
        ref={brainRef}
        object={brainModel.scene}
        rotation={[0, Math.PI / 1.6, 0]}
        scale={[brainScale, brainScale, brainScale]}
      />
      <primitive
        position={ethPosition}
        ref={ethRef}
        object={ethModel.scene}
        scale={[ethScale, ethScale, ethScale]}
      />
      <primitive
        position={laptopPosition}
        ref={laptopRef}
        object={laptopModel.scene}
        scale={[laptopScale, laptopScale, laptopScale]}
      />

      <points position={pointsPosition} geometry={particlesGeometry}>
        <pointsMaterial color="#3fb7e2" size={0.15} />
      </points>
    </>
  );
};

export default NewGeometryParticles;
