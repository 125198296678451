import { useEffect, useRef } from "react";

export function useOnOutsideClick(handler, listenCapturing = false) {
  const ref = useRef(null);

  useEffect(() => {
    function handleClick(e) {
      if (e.target.closest(".closeBtn") || e.target.closest("svg")) {
        return;
      }

      if (ref.current && !ref.current.contains(e.target)) {
        handler();
      }
    }

    document.addEventListener("click", handleClick, listenCapturing);

    return () => {
      document.removeEventListener("click", handleClick, listenCapturing);
    };
  }, [handler, listenCapturing]);

  return ref;
}
