import { Link } from "react-router-dom";
import { StaggerChildren } from "./Animations";

function OfferBlueBannerService({ title, description, services }) {
  return (
    <section className="offer-blue-text bg-[#256c86] 500px:mt-[72px] 1199px:mt-[80px] mt-[96px]  500px:py-[72px] 1199px:py-[80px] py-[96px] overflow-x-hidden ">
      <div className="container text-center">
        <div className="service-title text-center">
          <StaggerChildren>
            <h3 className="500px:text-[29px] 1199px:text-[38px] text-[45px] text-[#fdfdfd]">
              {title}
            </h3>
            <p className="text-[16px] text-[#efefef] mt-[16px]">
              {description}
            </p>
          </StaggerChildren>
        </div>

        <div className="services-wrapper 500px:mt-[64px] mt-[96px] grid grid-cols-3 gap-[72px]">
          {services.map((service, index) => (
            <StaggerChildren key={index}>
              <div className=" text-left flex flex-col gap-[16px]">
                <span className="text-[52px]  500px:text-[34px] 500px:text-center  text-[#3fb7e2] text-left">
                  {service.number}
                </span>
                <h5 className="text-[#efefef] 500px:text-[19px]  500px:text-center text-[25px] normal-case text-left">
                  {service.title}
                </h5>
                <p className="text-[#efefef]  500px:text-center">
                  {service.content}
                </p>
              </div>
            </StaggerChildren>
          ))}
        </div>
        <StaggerChildren>
          <Link
            to="/contact"
            className=" inline-block 500px:mt-[64px] mt-[96px] bg-[#3fb7e2] py-[16px] px-[32px] font-[500] text-[19px] rounded-[8px] text-[#173d46] hover:text-[#fbfbfb] transition-colors duration-500"
          >
            Contact us
          </Link>
        </StaggerChildren>
      </div>
    </section>
  );
}

export default OfferBlueBannerService;
