import { createContext, useContext, useState } from "react";

const FormFeedbackContext = createContext();

export const FormFeedbackProvider = ({ children }) => {
  const [feedbackComponent, setFeedbackComponent] = useState(null);
  const [formSource, setFormSource] = useState(null);

  const setFormFeedback = (feedback, source) => {
    setFeedbackComponent(feedback);
    setFormSource(source);
  };

  return (
    <FormFeedbackContext.Provider
      value={{ feedbackComponent, formSource, setFormFeedback }}
    >
      {children}
    </FormFeedbackContext.Provider>
  );
};

export const useFormFeedback = () => {
  const context = useContext(FormFeedbackContext);
  if (!context) {
    throw new Error(
      "useFormFeedback must be used within a FormFeedbackProvider"
    );
  }
  return context;
};
