import InfiniteScroll from "react-infinite-scroll-component";
import BlogStandarLayoutTwo from "./BlogStandarLayoutTwo";
import { Helmet } from "react-helmet-async";
import BlogLoader from "../BlogDetails/BlogLoader";
function BlogStandardWrap({
  currentPosts,
  hasMore,
  fetchMoreData,
  visiblePosts,
}) {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  //Metadata
  const pageTitle = "Software & Design Insights | Oligamy Blog Articles";
  const pageDescription =
    "Stay updated with the latest design trends and software technologies. Dive into insights on React, Angular, and more in our resourceful blog.";
  return (
    <>
      <Helmet>
        <meta name="description" content={pageDescription} />
        {/* Open Graph Protocol (OGP) tags for Facebook and others */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content={window.location.href} />{" "}
        {/* Use the current page URL */}
        <meta property="og:type" content="website" />
        {/* Twitter Card tags for Twitter */}
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
      <div
        className=""
        onClick={() => {
          document.querySelector(".main-nav").className.includes("slidenav") &&
            document.querySelector(".remove").click();
        }}
      >
        <div className="blog-standard sec-mar-top">
          <div className="container">
            <InfiniteScroll
              next={fetchMoreData}
              dataLength={visiblePosts.length}
              className="blog-standard-details"
              hasMore={hasMore}
              loader={<BlogLoader />}
            >
              {visiblePosts.map((post, id) => {
                const img =
                  post?.attributes?.thumbnail?.data?.attributes?.formats?.large
                    ?.url;
                const postTag =
                  post.attributes.blog_tags.data.length > 0
                    ? post.attributes.blog_tags.data[0].attributes.Name
                    : "";

                return (
                  <div key={id} className="blog-main-post-wrapper ">
                    <BlogStandarLayoutTwo
                      key={post.id}
                      image={img}
                      date={post.attributes.date}
                      title={post.attributes.title}
                      postTag={postTag}
                      author={post.attributes.author}
                      postID={post.id}
                      handlePostSelection={() => {}}
                    />
                  </div>
                );
              })}
            </InfiniteScroll>
            <div
              className={`pagination-wrapper blog ${
                currentPosts.length === 0 ? "flex" : "hidden"
              }`}
            >
              {currentPosts.length === 0 && <h1>No posts found</h1>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogStandardWrap;
