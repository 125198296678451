import { lazy, Suspense, useEffect, useState } from "react";
import FloatingForm from "../../../common/floating-form/FloatingForm";
import Layout from "../../../App";
import { Link, NavLink } from "react-router-dom";
import About1Img from "./about1.png";
import About2Img from "./about2.png";
import { useVisibleHeader } from "../../../common/header/HeaderVisibilityContext";
import OfferContact from "../OfferContact";
import OfferBlueBanner from "../OfferBlueBanner";
import OfferProcess from "../OfferProcess";
import OfferMainHeading from "../OfferMainHeading";
import OfferInTheNews from "../OfferInTheNews";
import OfferCaseStudies from "../OfferCaseStudies";
import { SlideInFromBottom } from "../Animations";
import { useIsMobile } from "../../../../helpers/useIsMobile";
import OfferSkeleton from "../OfferSkeleton";

import Palto from "../../../../assets/img/oligamy/home-case-studies/palto.webp";
import Dredging from "../../../../assets/img/oligamy/home-case-studies/dredging-operations.webp";
import Oversite from "../../../../assets/img/oligamy/home-case-studies/oversite.webp";
import FilipPuzyr from "../../../../assets/img/oligamy/home-case-studies/filip-puzyr.webp";
import { Helmet } from "react-helmet-async";
const AiValidationTopImgComponent = lazy(() =>
  import("./AiValidationTopImgComponent")
);
function AiValidation() {
  const isMobile = useIsMobile("(max-width: 500px)");
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const caseStudiesData = [
    {
      thumbnail: Palto,
      title: "Palto",
      url: "/design/palto",
      tag1: "Landing Page",
    },
    {
      thumbnail: Dredging,
      title: "Dredging Operations",
      url: "/design/dredging-operations",
      tag1: "Desktop app",
    },
    {
      thumbnail: FilipPuzyr,
      title: "Filip Puzyr",
      url: "/design/filip-puzyr",
      tag1: "Website",
    },
    {
      thumbnail: Oversite,
      title: "Oversite",
      url: "/design/oversite-web",
      tag1: "Web app",
    },
  ];
  const processSteps = [
    {
      number: "01",
      title: "User/Customer research workshop",
      content:
        "Gain insights into user preferences and behavior to tailor AI solutions that meet their needs effectively.",
    },
    {
      number: "02",
      title: "Demystifying AI workshop",
      content:
        "Understand the fundamentals of AI technology and its applications in real-world scenarios.",
    },
    {
      number: "03",
      title: "AI business opportunities workshop",
      content:
        "Explore potential AI-driven opportunities for growth and innovation within your industry.",
    },
    {
      number: "04",
      title: "AI solution tailoring workshop",
      content:
        "Customize AI solutions to align with your specific business objectives and requirements.",
    },
    {
      number: "05",
      title: "AI adoption strategy workshop",
      content:
        "Develop a clear roadmap for integrating AI into your business operations seamlessly.",
    },
  ];

  const [isQuickNavFixed, setIsQuickNavFixed] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const el = document.querySelector(".offer-quick-nav");
      if (!el) return;
      const elOffset = el.offsetTop;

      const onScroll = () => {
        if (elOffset - 84 <= window.scrollY - 84) {
          setIsQuickNavFixed(true);
        } else {
          setIsQuickNavFixed(false);
        }
      };

      window.removeEventListener("scroll", onScroll);
      window.addEventListener("scroll", onScroll, { passive: true });
    };

    const checkScrollPosition = () => {
      if (window.scrollY === 0) {
        handleScroll();
      }
    };

    checkScrollPosition();

    const onScroll = () => {
      if (window.scrollY === 0) {
        handleScroll();
        window.removeEventListener("scroll", onScroll);
      }
    };

    window.addEventListener("scroll", onScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const { isVisible } = useVisibleHeader();

  // Metadata
  const pageDescription =
    "Validate your AI models with Oligamy Software. We specialize in AI, blockchain, and custom web/mobile development, providing innovative, high-quality software and advanced digital design services.";

  const pageTitle =
    "Oligamy Software - AI Validation Services | High-Quality AI Solutions";

  return (
    <Layout isAlternative={true}>
      <FloatingForm />

      <>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta property="og:description" content={pageDescription} />
          <meta property="og:url" content={window.location.href} />{" "}
          {/* Use the current page URL */}
          <meta property="og:type" content="website" />
          {/* Twitter Card tags for Twitter */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDescription} />
        </Helmet>
        <div className="bg-[#FDFDFD] ">
          <main
            className="page-section "
            onClick={() => {
              document
                .querySelector(".main-nav")
                .className.includes("slidenav") &&
                document.querySelector(".remove").click();
            }}
          >
            <OfferMainHeading title={"Explore Ideas"} />
            {/* desktop */}
            {!isMobile && (
              <section
                className={`offer-quick-nav z-[12] pt-[36px]  ${
                  isVisible ? "offset" : ""
                } ${isQuickNavFixed ? "isFixed" : ""}`}
              >
                <nav className="container">
                  <NavLink to={`/offer/ai-validation`} onClick={scrollTop}>
                    AI validation sprint
                  </NavLink>
                  <NavLink to={`/offer/blockchain-validation`}>
                    Blockchain validation sprint
                  </NavLink>
                </nav>
              </section>
            )}
            {/* mobile */}
            {isMobile && (
              <section
                className={`offer-quick-nav z-[12] pt-[36px]  ${
                  isVisible ? "offset" : ""
                } ${isQuickNavFixed ? "isFixed" : ""}`}
              >
                <nav className="container">
                  <NavLink to={`/offer/ai-validation`} onClick={scrollTop}>
                    AI validation sprint
                  </NavLink>
                  <NavLink to={`/offer/blockchain-validation`}>
                    Blockchain validation sprint
                  </NavLink>
                </nav>
              </section>
            )}
            <section className="container ">
              <div className="offer-title-card mt-[72px]">
                <div className="500px:min-h-[220px] 767px:min-h-[180px] 991px:min-h-[260px] 1199px:min-h-[340px] 1399px:min-h-[420px] min-h-[550px]">
                  <Suspense fallback={OfferSkeleton()}>
                    <AiValidationTopImgComponent />
                  </Suspense>
                </div>
                <SlideInFromBottom>
                  <h2 className="500px:text-[29px] 767px:text-[45px] 991px:text-[52px] text-[61px] text-[#0e2933]">
                    <span className="">AI validation sprint</span> - unleash the
                    power of AI revolution
                  </h2>
                </SlideInFromBottom>
                <SlideInFromBottom>
                  <div className="description mt-[56px] flex flex-col items-end">
                    <div className=" max-w-[670px]">
                      <p className="text-[#565656]">
                        At Oligamy Software House, we understand the
                        transformative potential of AI and its impact on
                        businesses worldwide. With our team of proven experts,
                        we're here to guide you through harnessing this
                        revolutionary technology to propel your organization
                        forward.
                      </p>
                      <Link
                        to="/contact"
                        className=" inline-block mt-[32px] bg-[#3fb7e2] py-[16px] px-[32px] font-[500] text-[19px] rounded-[8px] text-[#173d46] hover:text-[#fbfbfb] transition-colors duration-500"
                      >
                        Contact us
                      </Link>
                    </div>
                  </div>
                </SlideInFromBottom>
              </div>
            </section>
            <OfferBlueBanner
              higlightedText={
                "Harness the AI revolution potential with proven experts. "
              }
              restText={
                "Our seasoned experts bring years of experience and expertise in the field of artificial intelligence. We're committed to helping you navigate the complexities of AI adoption and leverage its full potential to drive innovation and growth in your business."
              }
            />
            <section className="container ">
              <div className="offer-text-image-right 991px:gap-[3rem] w-[100%] flex items-center justify-between 500px:mt-[72px] 1199px:mt-[80px] mt-[96px] ">
                <div className="left max-w-[555px]">
                  <SlideInFromBottom>
                    <h3 className="text-[45px] 1199px:text-[38px] 500px:text-[29px] text-left text-[#0e2933]">
                      Examples of AI usage in business
                    </h3>
                    <p className="text-[#565656] mt-[40px]">
                      Discover how leading businesses across various industries
                      are leveraging AI to streamline operations, enhance
                      decision-making processes, and drive competitive
                      advantage. From predictive analytics to natural language
                      processing, the possibilities are endless.
                    </p>
                    <Link
                      to="/contact"
                      className=" inline-block mt-[40px] bg-[#3fb7e2] py-[16px] px-[32px] font-[500] text-[19px] rounded-[8px] text-[#173d46] hover:text-[#fbfbfb] transition-colors duration-500"
                    >
                      Contact us
                    </Link>
                  </SlideInFromBottom>
                </div>
                <SlideInFromBottom>
                  <div className="right">
                    <img
                      src={About1Img}
                      alt="ai-validation"
                      className="max-w-[440px]"
                    />
                  </div>
                </SlideInFromBottom>
              </div>
            </section>
            <OfferBlueBanner
              higlightedText={
                "Augment your business with machine learning and generative AI models. "
              }
              restText={
                "Optimize costs, evaluate strategies, and fortify your organization's resilience in today's dynamic environment with the power of machine learning and generative AI models. Let us help you unlock new opportunities and stay ahead of the curve."
              }
            />
            <OfferProcess
              processSteps={processSteps}
              title={"Process"}
              description={
                "We offer a comprehensive suite of solutions designed to jumpstart your AI adoption journey in the best possible way. Our AI/ML Validation Sprint includes:"
              }
            />
            <div className="container offer-text-image-left 991px:gap-[3rem] flex flex-row-reverse items-center justify-between 500px:mt-[144px] 1199px:mt-[160px]   mt-[192px] 500px:mb-[72px] 1199px:mb-[80px] mb-[96px] ">
              <div className="left max-w-[555px] overflow-y-hidden ">
                <SlideInFromBottom>
                  <h3 className="text-[45px] 1199px:text-[38px] 500px:text-[29px] text-left text-[#0e2933]">
                    AI/ML solutions tailored to your needs
                  </h3>
                  <p className="text-[#565656] mt-[40px]">
                    Accelerate your business's digital transformation journey
                    with cutting-edge AI/ML solutions that are both efficient
                    and cost-effective. Let us help you unlock new levels of
                    efficiency and productivity.
                  </p>
                  <h5 className="text-[#0E2933] mt-[40px] text-left normal-case text-[25px] 500px:text-[19px]">
                    Shorten AI adoption time with a clear strategy
                  </h5>
                  <p className="text-[#565656] mt-[8px]">
                    By approaching AI adoption with a clear strategy and
                    roadmap, we can significantly reduce the time it takes to
                    implement AI solutions in your business. Let us guide you
                    every step of the way.
                  </p>
                </SlideInFromBottom>
              </div>
              <SlideInFromBottom>
                <div className="image">
                  <img
                    src={About2Img}
                    alt="ai-validation"
                    className="max-w-[440px]"
                  />
                </div>
              </SlideInFromBottom>
            </div>
            <SlideInFromBottom>
              <OfferCaseStudies caseStudiesData={caseStudiesData} />
            </SlideInFromBottom>
            <SlideInFromBottom>
              <OfferInTheNews category={"AI"} />
            </SlideInFromBottom>
            <SlideInFromBottom>
              <OfferContact
                text={
                  "Interested in learning more about our AI validation sprint services or starting a collaboration? Contact us today!"
                }
              />
            </SlideInFromBottom>
          </main>
        </div>
      </>
    </Layout>
  );
}

export default AiValidation;
