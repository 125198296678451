import { lazy, Suspense, useEffect, useState } from "react";
import FloatingForm from "../../../common/floating-form/FloatingForm";
import Layout from "../../../App";
import { Link, NavLink } from "react-router-dom";
import BannerImage from "./bannerImage.png";
import TextImage from "./textImage.png";
import { useVisibleHeader } from "../../../common/header/HeaderVisibilityContext";
import OfferContact from "../OfferContact";
import OfferBlueBanner from "../OfferBlueBanner";
import OfferProcess from "../OfferProcess";
import OfferMainHeading from "../OfferMainHeading";
import OfferInTheNews from "../OfferInTheNews";
import OfferCaseStudies from "../OfferCaseStudies";
import { SlideInFromBottom } from "../Animations";
import { useIsMobile } from "../../../../helpers/useIsMobile";
import OfferSkeleton from "../OfferSkeleton";
import BillonBattery from "../../../../assets/img/oligamy/home-case-studies/billon-battery.webp";
import FilipPuzyr from "../../../../assets/img/oligamy/home-case-studies/filip-puzyr.webp";
import Dredging from "../../../../assets/img/oligamy/home-case-studies/dredging-operations.webp";
import OversiteMobile from "../../../../assets/img/oligamy/home-case-studies/oversite- mobile.webp";
import { Helmet } from "react-helmet-async";

const DesignImplementationServiceTopVideoComponent = lazy(() =>
  import("./DesignImplementationServiceTopVideoComponent")
);
function DesignImplementationService() {
  const isLargerScreen = useIsMobile("(min-width: 1200px) ");
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const isMobile = useIsMobile("(max-width: 500px)");
  const caseStudiesData = [
    {
      thumbnail: BillonBattery,
      title: "Product passport",
      url: "/design/product-passport",
      tag1: "Web app",
    },
    {
      thumbnail: FilipPuzyr,
      title: "Filip Puzyr",
      url: "/design/filip-puzyr",
      tag1: "Website",
    },
    {
      thumbnail: Dredging,
      title: "Dredging Operations",
      url: "/design/dredging-operations",
      tag1: "Desktop app",
    },
    {
      thumbnail: OversiteMobile,
      title: "Oversite Mobile",
      url: "/design/oversite-mobile-app",
      tag1: "Mobile app",
    },
  ];
  const processSteps = [
    {
      number: "01",
      title: "Consultations and materials assessment",
      content:
        "We believe that initial findings determine success or failure, so we start with in-depth consultations to establish requirements.",
    },
    {
      number: "02",
      title: "Solution proposal",
      content:
        "We return to you with a solution proposal that includes a few more elements than you might anticipate.",
    },
    {
      number: "03",
      title: "Solution adjustment",
      content:
        "Based on your feedback, we adapt the proposed solution so that it is tailored to your needs and aligned with your vision.",
    },
    {
      number: "04",
      title: "Implementation and project delivery",
      content:
        "A highly skilled team of programmers brings the project to life, guaranteeing superior quality.",
    },
    {
      number: "05",
      title: "Documentation",
      content:
        "Once the design is delivered, we will provide full documentation for any future updates.",
    },
  ];

  const [isQuickNavFixed, setIsQuickNavFixed] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const el = document.querySelector(".offer-quick-nav");
      if (!el) return;
      const elOffset = el.offsetTop;

      const onScroll = () => {
        if (elOffset - 84 <= window.scrollY - 84) {
          setIsQuickNavFixed(true);
        } else {
          setIsQuickNavFixed(false);
        }
      };

      window.removeEventListener("scroll", onScroll);
      window.addEventListener("scroll", onScroll, { passive: true });
    };

    const checkScrollPosition = () => {
      if (window.scrollY === 0) {
        handleScroll();
      }
    };

    checkScrollPosition();

    const onScroll = () => {
      if (window.scrollY === 0) {
        handleScroll();
        window.removeEventListener("scroll", onScroll);
      }
    };

    window.addEventListener("scroll", onScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const { isVisible } = useVisibleHeader();

  // Metadata
  const pageDescription =
    "Bring your design ideas to life with Oligamy Software design implementation services. Specializing in digital design, custom web/mobile development, AI, and blockchain, we deliver high-quality, innovative software solutions.";

  const pageTitle =
    "Oligamy Software - Design Implementation Services | Digital Design Solutions";

  return (
    <Layout isAlternative={true}>
      <FloatingForm />

      <>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta property="og:description" content={pageDescription} />
          <meta property="og:url" content={window.location.href} />{" "}
          {/* Use the current page URL */}
          <meta property="og:type" content="website" />
          {/* Twitter Card tags for Twitter */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDescription} />
        </Helmet>
        <div className="bg-[#FDFDFD] ">
          <main
            className="page-section"
            onClick={() => {
              document
                .querySelector(".main-nav")
                .className.includes("slidenav") &&
                document.querySelector(".remove").click();
            }}
          >
            <OfferMainHeading title={"Develop products"} />

            {/* desktop */}
            {!isMobile && (
              <section
                className={`offer-quick-nav offer-quick-nav-special z-[12] pt-[36px] ${
                  isVisible ? "offset" : ""
                } ${isQuickNavFixed ? "isFixed" : ""}`}
              >
                <nav className="container flex  ">
                  <NavLink to={`/offer/ai-solutions`}>AI Solutions</NavLink>
                  <NavLink to={`/offer/blockchain-development`}>
                    Blockchain development
                  </NavLink>
                  <NavLink to={`/offer/web-development`}>
                    Web development
                  </NavLink>
                  <NavLink
                    onClick={scrollTop}
                    to="/offer/design-implementation-service"
                  >
                    Design implementation service
                  </NavLink>
                </nav>
              </section>
            )}
            {/* mobile */}
            {isMobile && (
              <section
                className={`offer-quick-nav z-[12] pt-[36px] ${
                  isVisible ? "offset" : ""
                } ${isQuickNavFixed ? "isFixed" : ""}`}
              >
                <nav className="container flex  ">
                  <NavLink
                    to="/offer/design-implementation-service"
                    onClick={scrollTop}
                  >
                    Design implementation service
                  </NavLink>
                  <NavLink to="/offer/blockchain-development" className="">
                    Blockchain development
                  </NavLink>
                  <NavLink to="/offer/ai-solutions">AI Solutions</NavLink>
                  <NavLink to="/offer/web-development">Web development</NavLink>
                </nav>
              </section>
            )}
            <section className="container ">
              <div className="offer-title-card mt-[72px]">
                <div className="500px:min-h-[220px] 767px:min-h-[180px] 991px:min-h-[260px] 1199px:min-h-[340px] 1399px:min-h-[420px] min-h-[550px] 500px:mb-[72px] 1199px:mb-[80px] mb-[96px]">
                  <Suspense fallback={OfferSkeleton()}>
                    <DesignImplementationServiceTopVideoComponent />
                  </Suspense>
                </div>

                <div className="flex flex-col justify-between">
                  <SlideInFromBottom>
                    <h2 className="500px:text-[29px] 767px:text-[45px] 991px:text-[52px] text-[61px] text-[#0e2933]">
                      <span className="leading-[120%]">
                        Design implementation service:&nbsp;
                        {isLargerScreen && <br />}
                      </span>
                      we&apos;ve got you covered
                    </h2>
                  </SlideInFromBottom>
                  <SlideInFromBottom>
                    <div className="description mt-[56px] flex flex-col items-end">
                      <div className=" max-w-[670px]">
                        <p className="text-[#565656]">
                          Already have designs for your web products but
                          struggling to find the proper implementation team? As
                          experienced software engineers, we are here to ensure
                          that your website or web app are both visually
                          appealing and highly functional. We work closely with
                          you to bring your design to life, using the latest
                          technologies and best practices.
                        </p>
                        <Link
                          to="/contact"
                          className=" inline-block mt-[32px] bg-[#3fb7e2] py-[16px] px-[32px] font-[500] text-[19px] rounded-[8px] text-[#173d46] hover:text-[#fbfbfb] transition-colors duration-500"
                        >
                          Contact us
                        </Link>
                      </div>
                    </div>
                  </SlideInFromBottom>
                </div>
              </div>
            </section>
            <OfferBlueBanner
              higlightedText={" Common sense above all. "}
              restText={
                "We make every effort to make the most of the design or even an already started development project that you bring to us. Our goal is to roll out a project of the highest quality without exposing you to additional expenses."
              }
            />
            {/* TEXT IMAGE SECTION */}
            <section className=" offer-text-image-left 991px:gap-12  flex flex-row-reverse items-center justify-between 500px:mt-[72px] 1199px:mt-[80px] mt-[96px] container">
              <div className="left max-w-[555px]  space-y-10">
                <SlideInFromBottom>
                  <h3 className="text-[45px] 1199px:text-[38px] 500px:text-[29px]  text-left text-[#0e2933]">
                    You are in good hands
                  </h3>
                </SlideInFromBottom>
                <SlideInFromBottom>
                  <div className="flex flex-col gap-2">
                    <h5 className="500px:text-[19px] text-[25px] text-left  text-[#0e2933] normal-case">
                      We understand the business value of your web design
                      project. Hence, we want to preserve it by optimizing and
                      simplifying the things you come to us with.
                    </h5>
                    <p className="text-[#565656]">
                      We have all the necessary resources and developers to
                      support any project you outsource in the Design
                      Implementation field. Whether it&apos;s UI/UX challenges,
                      responsiveness, performance optimization, or other issues,
                      we can harness the full potential of web technology for
                      you. Just leave it to us; we will take care of everything.
                    </p>
                  </div>
                  <Link
                    to="/contact"
                    className=" inline-block mt-[32px] bg-[#3fb7e2] py-[16px] px-[32px] font-[500] text-[19px] rounded-[8px] text-[#173d46] hover:text-[#fbfbfb] transition-colors duration-500"
                  >
                    Contact us
                  </Link>
                </SlideInFromBottom>
              </div>
              <SlideInFromBottom>
                <div className="image ">
                  <img
                    src={TextImage}
                    alt="design-implementation-service"
                    className="max-w-[440px] rounded-xl  h-[544px] "
                  />
                </div>
              </SlideInFromBottom>
            </section>
            {/* END TEXT IMAGE SECTION */}
            <OfferBlueBanner
              higlightedText={"Why choose us? "}
              restText={
                "Our team includes premium professionals in front-end and back-end development, quality assurance, and project management, guaranteeing that your project receives the highest level of expertise."
              }
            />
            <OfferProcess
              title={"Process"}
              description={
                "Our process is based on teamwork, openness, and effectiveness, ensuring that every phase of the implementation journey is precisely planned and carried out with utmost attention."
              }
              processSteps={processSteps}
            />

            {/* BLUE BANNER WITH IMAGE #1 */}
            <section className=" offer-blue-text bg-[#256c86] 500px:py-[72px] 1199px:py-[80px] py-[96px] ">
              <div className="offer-text-image-left 991px:gap-12  flex 992px:flex-row-reverse items-center justify-between container">
                <div className="left max-w-[555px]  space-y-10">
                  <SlideInFromBottom>
                    <h3 className="text-[45px] 1199px:text-[38px] 500px:text-[29px] text-left text-[#FDFDFD]">
                      We are there for you after the project is finished
                    </h3>
                  </SlideInFromBottom>
                  <SlideInFromBottom>
                    <p className="text-[#EFEFEF]">
                      Our dedication to your success extends beyond
                      implementation. We provide continuous support and
                      maintenance to ensure your digital products operate
                      smoothly and effectively. Whether you are a startup just
                      beginning your journey or an established company seeking
                      to enhance your digital presence, we are here to be your
                      partner every step of the way.
                    </p>
                    <Link
                      to="/contact"
                      className=" inline-block mt-[32px] bg-[#3fb7e2] py-[16px] px-[32px] font-[500] text-[19px] rounded-[8px] text-[#173d46] hover:text-[#fbfbfb] transition-colors duration-500"
                    >
                      Contact us
                    </Link>
                  </SlideInFromBottom>
                </div>{" "}
                <SlideInFromBottom>
                  <div className="image ">
                    <img
                      src={BannerImage}
                      alt="design-implementation-service"
                      className="max-w-[440px] h-[544px] rounded-2xl"
                    />
                  </div>
                </SlideInFromBottom>
              </div>
            </section>
            <SlideInFromBottom>
              <OfferCaseStudies caseStudiesData={caseStudiesData} />
            </SlideInFromBottom>
            <SlideInFromBottom>
              <OfferInTheNews category={"Software"} />
            </SlideInFromBottom>
            <SlideInFromBottom>
              <OfferContact
                text={
                  "Interested in learning more about our Web development services or starting a collaboration? Contact us today!"
                }
              />
            </SlideInFromBottom>
          </main>
        </div>
      </>
    </Layout>
  );
}

export default DesignImplementationService;
