import React, { useEffect, useState } from "react";
import { useSwiper } from "swiper/react";

export default function SwiperNavButtons() {
  const swiper = useSwiper();
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  useEffect(() => {
    const updateButtons = () => {
      setIsBeginning(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
    };

    swiper.on("slideChange", updateButtons);

    // Cleanup function
    return () => {
      swiper.off("slideChange", updateButtons);
    };
  }, [swiper]);

  return (
    <div className="progress-nav-btns min-w-[150px] max-h-[60px] flex justify-end items-center 767px:min-w-[0px] 500px:gap-[16px]">
      <button
        onClick={() => swiper.slidePrev()}
        disabled={isBeginning}
        style={{ opacity: isBeginning ? 0.5 : 1 }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          style={{ transform: "rotate(180deg)" }}
        >
          <path
            d="M26.344 24.0005L16.444 14.1005L19.272 11.2725L32 24.0005L19.272 36.7285L16.444 33.8985L26.344 23.9985L26.344 24.0005Z"
            fill="#FDFDFD"
          />
        </svg>
      </button>
      <button
        onClick={() => swiper.slideNext()}
        disabled={isEnd}
        style={{ opacity: isEnd ? 0.5 : 1 }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
        >
          <path
            d="M26.344 24.0005L16.444 14.1005L19.272 11.2725L32 24.0005L19.272 36.7285L16.444 33.8985L26.344 23.9985L26.344 24.0005Z"
            fill="#FDFDFD"
          />
        </svg>
      </button>
    </div>
  );
}
