import { useEffect, useState } from "react";
import logError from "../../../helpers/logError";
import RelatedBlogStandarLayoutTwo from "./RelatedBlogStandardLayoutTwo";
import getPosts from "../../../api/action.getPosts";

function RelatedPosts({ postBlogTags, postId }) {
  const [posts, setPosts] = useState([]);
  const [currentRelatedPosts, setCurrentRelatedPosts] = useState([]);

  // Posts fetching
  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        const rawPosts = await getPosts();
        if (isMounted) {
          if (rawPosts.error) {
            throw new Error("No posts");
          }
          // Exclude the current post by postId
          const filteredPosts = rawPosts.filter((post) => post.id !== postId);
          setPosts(filteredPosts);
        }
      } catch (err) {
        logError(err);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [postBlogTags, postId]);

  // Filter posts based on blog tags
  useEffect(() => {
    if (posts.length > 0 && postBlogTags && postBlogTags.length > 0) {
      const tagCounts = {};
      posts.forEach((post) => {
        post.attributes.blog_tags.data.forEach((tag) => {
          const tagName = tag.attributes.Name;
          if (postBlogTags.some((pt) => pt.attributes.Name === tagName)) {
            if (!tagCounts[post.id]) {
              tagCounts[post.id] = { count: 0, post };
            }
            tagCounts[post.id].count += 1;
          }
        });
      });

      const sortedPosts = Object.values(tagCounts)
        .sort(
          (a, b) =>
            b.count - a.count ||
            new Date(b.post.attributes.date) - new Date(a.post.attributes.date)
        )
        .map((entry) => entry.post)
        .slice(0, 3);

      setCurrentRelatedPosts(sortedPosts);
    }
  }, [posts, postBlogTags]);

  return (
    <div className="mt-[96px]">
      {currentRelatedPosts.length > 0 && (
        <>
          <h2 className="text-[34px] text-[#0E2933] leading-[40.8px] mb-[64px]">
            Related articles
          </h2>
          <div className="grid grid-cols-3 767px:grid-cols-1 1199px:grid-cols-2 gap-[24px]">
            {currentRelatedPosts.map((post) => {
              const img =
                post?.attributes?.thumbnail?.data?.attributes?.formats?.large
                  ?.url;
              const postTag =
                post.attributes.blog_tags.data.length > 0
                  ? post.attributes.blog_tags.data[0].attributes.Name
                  : "";

              return (
                <RelatedBlogStandarLayoutTwo
                  key={post.id}
                  image={img}
                  date={post.attributes.date}
                  title={post.attributes.title}
                  postTag={postTag}
                  author={post.attributes.author}
                  postID={post.id}
                  handlePostSelection={() => {}}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

export default RelatedPosts;
