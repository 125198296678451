import React from "react";
import ReactMarkdown from "react-markdown";

export default function CareerAccordion({
  jobOffers,
  extendedOffer,
  onhandleExtendedOfferChange,
}) {
  return (
    <div
      className="job-offers-section container mt-[192px] 500px:mt-[161px]"
      id={"job-offers"}
    >
      <div className="text-center">
        <h2 className="text-[45px] text-[#0e2933] leading-[120%] 500px:text-[29px]">
          Current job openings
        </h2>
        <p className="text-[16px] mt-[16px] text-[#565656]">
          Check our current job openings and join the Oligamy Software team
        </p>
      </div>
      <div className="job-list mt-[96px] gap-[16px] 500px:mt-[56px]">
        {jobOffers.length === 0 ? (
          <p className="text-[16px] mt-[16px] text-[#565656] text-center">
            There are no job offers at the moment.
          </p>
        ) : (
          jobOffers.map((offer, index) => (
            <div
              className={`single-job-offer ${
                index.toString() === extendedOffer ? "extended" : ""
              }`}
              key={index}
              id={offer.id.toString()}
            >
              <div className="upper">
                <div className="left max-w-[80%]">
                  <h3>{offer.attributes.title}</h3>
                  <div className="tags max-w-[100%] flex-wrap">
                    {offer.attributes.tags.split(",").map((tag, tagIndex) => (
                      <p className="single-tag-career" key={tagIndex}>
                        #{tag.trim()}
                      </p>
                    ))}
                  </div>
                </div>
                <div className="right">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="33"
                    viewBox="0 0 32 33"
                    fill="none"
                    onClick={() => onhandleExtendedOfferChange(index)}
                  >
                    <path
                      d="M27.7812 21.7188L29.2188 20.2812L16.7188 7.78125L16 7.09375L15.2812 7.78125L2.78125 20.2812L4.21875 21.7188L16 9.9375L27.7812 21.7188Z"
                      fill="#565656"
                    />
                  </svg>
                </div>
              </div>
              <div className="lower flex flex-col gap-[56px]">
                <div className="job-offer-content">
                  <ReactMarkdown>{offer?.attributes?.content}</ReactMarkdown>
                </div>
                <a
                  href="mailto:hello@oligamy.com"
                  className="place-self-center"
                >
                  <button className="join-our-team">Join our team</button>
                </a>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}
