const getPosts = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_STRAPI_URL}/api/blog-posts?populate=*`
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const responseJSON = await response.json();
  return responseJSON.data;
};

export default getPosts;
