import { lazy, Suspense, useEffect, useState } from "react";
import FloatingForm from "../../../common/floating-form/FloatingForm";
import Layout from "../../../App";
import { Link, NavLink } from "react-router-dom";
import About2Img from "./about2.png";
import { useVisibleHeader } from "../../../common/header/HeaderVisibilityContext";
import OfferContact from "../OfferContact";
import OfferBlueBanner from "../OfferBlueBanner";
import OfferProcess from "../OfferProcess";
import OfferMainHeading from "../OfferMainHeading";
import OfferInTheNews from "../OfferInTheNews";
import OfferCaseStudies from "../OfferCaseStudies";
import { SlideInFromBottom } from "../Animations";
import { useIsMobile } from "../../../../helpers/useIsMobile";
import Billon from "../../../../assets/img/oligamy/home-case-studies/billon.webp";
import WGMI from "../../../../assets/img/oligamy/home-case-studies/wgmi.webp";
import Land from "../../../../assets/img/oligamy/home-case-studies/land.webp";
import OversiteMobile from "../../../../assets/img/oligamy/home-case-studies/oversite- mobile.webp";

import OfferSkeleton from "../OfferSkeleton";
import { Helmet } from "react-helmet-async";
const BlockchainValidationTopImgComponent = lazy(() =>
  import("./BlockchainValidationTopImgComponent")
);
function BlockchainValidation() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const isMobile = useIsMobile("(max-width: 500px)");
  const caseStudiesData = [
    {
      thumbnail: Billon,
      title: "Billon App",
      url: "/design/document-reader",
      tag1: "Web app",
      tag2: "Web3",
      tag3: "Design system",
    },
    {
      thumbnail: WGMI,
      title: "WGMI",
      url: "/design/wgmi",
      tag1: "Landing Page",
      tag2: "Web app",
      tag2: "Web3",
    },
    {
      thumbnail: Land,
      title: "Landing page",
      url: "/design/metaverse",
      tag1: "Landing Page",
      tag2: "Web app",
      tag3: "Web3",
    },
    {
      thumbnail: OversiteMobile,
      title: "Oversite Mobile",
      url: "/design/oversite-mobile-app",
      tag1: "Mobile app",
    },
  ];
  const processSteps = [
    {
      number: "01",
      title: "Blockchain strategy workshop",
      content:
        "Develop a tailored blockchain strategy aligned with business goals.",
    },
    {
      number: "02",
      title: "Tokenomics validation workshop",
      content: "Fine-tune economic aspects of the project for sustainability.",
    },
    {
      number: "03",
      title: "Technology tailoring workshop",
      content: "Choose the right technology for project success.",
    },
    {
      number: "04",
      title: "Technical aspect validation",
      content:
        "Ensure feasibility, scalability, and security of the blockchain idea.",
    },
  ];

  const [isQuickNavFixed, setIsQuickNavFixed] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const el = document.querySelector(".offer-quick-nav");
      if (!el) return;
      const elOffset = el.offsetTop;

      const onScroll = () => {
        if (elOffset - 84 <= window.scrollY - 84) {
          setIsQuickNavFixed(true);
        } else {
          setIsQuickNavFixed(false);
        }
      };

      window.removeEventListener("scroll", onScroll);
      window.addEventListener("scroll", onScroll, { passive: true });
    };

    const checkScrollPosition = () => {
      if (window.scrollY === 0) {
        handleScroll();
      }
    };

    checkScrollPosition();

    const onScroll = () => {
      if (window.scrollY === 0) {
        handleScroll();
        window.removeEventListener("scroll", onScroll);
      }
    };

    window.addEventListener("scroll", onScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const { isVisible } = useVisibleHeader();

  // Metadata
  const pageDescription =
    "Ensure the integrity of your blockchain projects with Oligamy Software. We specialize in blockchain validation, AI, and custom web/mobile development, delivering high-quality, innovative solutions for secure digital systems.";

  const pageTitle =
    "Oligamy Software - Blockchain Validation Services | Secure Blockchain Solutions";

  return (
    <Layout isAlternative={true}>
      <FloatingForm />

      <>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta property="og:description" content={pageDescription} />
          <meta property="og:url" content={window.location.href} />{" "}
          {/* Use the current page URL */}
          <meta property="og:type" content="website" />
          {/* Twitter Card tags for Twitter */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDescription} />
        </Helmet>
        <div className="bg-[#FDFDFD] ">
          <main
            className="page-section "
            onClick={() => {
              document
                .querySelector(".main-nav")
                .className.includes("slidenav") &&
                document.querySelector(".remove").click();
            }}
          >
            <OfferMainHeading title={"Explore Ideas"} />
            {/* desktop */}
            {!isMobile && (
              <section
                className={`offer-quick-nav z-[12] pt-[36px] ${
                  isVisible ? "offset" : ""
                } ${isQuickNavFixed ? "isFixed" : ""}`}
              >
                <nav className="container">
                  <NavLink to={`/offer/ai-validation`}>
                    AI validation sprint
                  </NavLink>
                  <NavLink
                    onClick={scrollTop}
                    to={`/offer/blockchain-validation`}
                  >
                    Blockchain validation sprint
                  </NavLink>
                </nav>
              </section>
            )}
            {/* mobile */}
            {isMobile && (
              <section
                className={`offer-quick-nav z-[12] pt-[36px] ${
                  isVisible ? "offset" : ""
                } ${isQuickNavFixed ? "isFixed" : ""}`}
              >
                <nav className="container">
                  <NavLink
                    to={`/offer/blockchain-validation`}
                    onClick={scrollTop}
                  >
                    Blockchain validation sprint
                  </NavLink>
                  <NavLink to={`/offer/ai-validation`}>
                    AI validation sprint
                  </NavLink>
                </nav>
              </section>
            )}
            <div className="offer-title-card   container mt-[72px]">
              <div className="500px:min-h-[220px] 767px:min-h-[180px] 991px:min-h-[260px] 1199px:min-h-[340px] 1399px:min-h-[420px] min-h-[550px]">
                <Suspense fallback={OfferSkeleton()}>
                  <BlockchainValidationTopImgComponent />
                </Suspense>
              </div>
              <SlideInFromBottom>
                <h2 className="500px:text-[29px] 767px:text-[45px] 991px:text-[52px] text-[61px] text-[#0e2933]">
                  Unlock blockchain potential with Oligamy Software House
                </h2>
              </SlideInFromBottom>
              <SlideInFromBottom>
                <div className="description  mt-[56px] flex flex-col items-end">
                  <div className=" max-w-[670px]">
                    <p className="text-[#565656]">
                      At Oligamy Software House, we stand as your trusted
                      technological partner, guiding you through the intricate
                      landscape of blockchain adoption. Our Blockchain
                      Validation Sprint is meticulously designed to streamline
                      your journey towards leveraging the full potential of
                      blockchain technology. Let's delve into the process.
                    </p>
                    <Link
                      to="/contact"
                      className=" inline-block mt-[32px] bg-[#3fb7e2] py-[16px] px-[32px] font-[500] text-[19px] rounded-[8px] text-[#173d46] hover:text-[#fbfbfb] transition-colors duration-500"
                    >
                      Contact us
                    </Link>
                  </div>
                </div>
              </SlideInFromBottom>
            </div>
            <div className="500px:pt-[72px] 1199px:pt-[80px] pt-[96px]">
              <OfferProcess
                title={"Process"}
                processSteps={processSteps}
                isProcessDescribed={false}
              />
            </div>

            <OfferBlueBanner
              higlightedText={"Validating technical aspects of your idea. "}
              restText={
                "We meticulously validate the technical aspects of your blockchain idea, ensuring its feasibility, scalability, and security. Our team of experts conducts thorough assessments to mitigate risks and optimize performance."
              }
            />
            <section className="container ">
              <div className="offer-text-image-left 991px:gap-[3rem] w-[100%] flex flex-row-reverse items-center justify-between 500px:mt-[72px] 1199px:mt-[80px] mt-[96px]">
                <div className="left max-w-[555px]">
                  <SlideInFromBottom>
                    <h3 className=" text-[45px] 1199px:text-[38px] 500px:text-[29px] text-left text-[#0e2933]">
                      Efficient solutions:
                    </h3>
                  </SlideInFromBottom>
                  <SlideInFromBottom>
                    <h5 className="text-[#0e2933] mt-[40px] text-[25px] text-left normal-case  500px:text-[19px]">
                      Choosing the right technology
                    </h5>
                    <p className="text-[#565656] text-left  mt-[8px]">
                      With our guidance, you can confidently choose the right
                      technology stack for your blockchain project. We simplify
                      the complex landscape of blockchain technologies, helping
                      you select the most suitable platform that aligns with
                      your project requirements and budget.
                    </p>
                  </SlideInFromBottom>
                  <SlideInFromBottom>
                    <h5 className="text-[#0e2933] mt-[40px] text-[25px] text-left normal-case 500px:text-[19px]">
                      Simplify and cost optimize
                    </h5>
                    <p className="text-[#565656] text-left mt-[8px]">
                      We believe in simplifying complexities and optimizing
                      costs without compromising on quality. Our streamlined
                      approach ensures efficient resource utilization, helping
                      you achieve your blockchain goals within budgetary
                      constraints.
                    </p>
                  </SlideInFromBottom>
                  <SlideInFromBottom>
                    <h5 className="text-[#0e2933] mt-[40px] text-[25px] text-left normal-case 500px:text-[19px]">
                      Creating minimum viable product &#40;MVP&#41; model
                    </h5>
                    <p className="text-[#565656] text-left mt-[8px]">
                      Accelerate your time to market with our MVP development
                      services. We help you create a lean and functional MVP
                      that allows you to gather valuable feedback from users and
                      stakeholders, enabling iterative improvements and rapid
                      iterations.
                    </p>
                  </SlideInFromBottom>
                </div>
                <SlideInFromBottom>
                  <div className="image">
                    <img
                      src={About2Img}
                      alt="blockchain-validation"
                      className="max-w-[440px]"
                    />
                  </div>
                </SlideInFromBottom>
              </div>
            </section>
            <OfferBlueBanner
              higlightedText={"Shorten idea to market time. "}
              restText={
                "Our agile methodologies and streamlined processes are geared towards minimizing time to market. By leveraging our expertise, you can expedite the launch of your blockchain solution, gaining a competitive edge in the ever-evolving market landscape."
              }
            />
            <SlideInFromBottom>
              <OfferCaseStudies caseStudiesData={caseStudiesData} />
            </SlideInFromBottom>
            <SlideInFromBottom>
              <OfferInTheNews category={"Blockchain"} />
            </SlideInFromBottom>
            <SlideInFromBottom>
              <OfferContact
                text={
                  "Interested in learning more about our blockchain validation sprint services or starting a collaboration? Contact us today!"
                }
              />
            </SlideInFromBottom>
          </main>
        </div>
      </>
    </Layout>
  );
}

export default BlockchainValidation;
