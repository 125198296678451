import { useEffect, useState } from "react";
import TopPic from "../../../assets/img/oligamy/case-studies/oversite-web/top.png";

import WOW from "wowjs";

import AboutImage from "../../../assets/img/oligamy/case-studies/oversite-web/about1.png";
import AboutImage2 from "../../../assets/img/oligamy/case-studies/oversite-web/about2.png";
import AboutImage3 from "../../../assets/img/oligamy/case-studies/oversite-web/about3.png";
import AboutImage4 from "../../../assets/img/oligamy/case-studies/oversite-web/about4.png";

import AnimatedBackground from "../../../assets/img/oligamy/case-studies/oversite-web/animatedbackground.png";
import AnimatedBackground2 from "../../../assets/img/oligamy/case-studies/oversite-web/animatedbackground2.png";
import AnimatedBackground3 from "../../../assets/img/oligamy/case-studies/oversite-web/animatedbackground3.png";

import FloatingForm from "../../common/floating-form/FloatingForm";
import Layout from "../../App";
import Preloader from "../../layout/Preloader";
const SOLUTIONS = [
  {
    heading: "1. Unified Interface",
    description:
      "We developed a mega interface where users can perform all necessary actions without leaving the main screen. This design minimizes disruptions and enhances productivity by reducing the need to navigate through multiple pages.",
  },
  {
    heading: "2. Interactive Dashboard",
    description:
      "The dashboard serves as the central hub for project and employee management. It features:",
    pros: [
      "A drag-and-drop system for assigning employees to projects.",
      "Real-time updates and visual indicators for project statuses.",
      "Customizable views to filter and prioritize information.",
    ],
  },
  {
    heading: "3. Excel-like Tables",
    description:
      "To replicate the familiar Excel experience, we designed tables with:",
    pros: [
      "Advanced sorting and filtering options.",
      "Inline editing capabilities.",
      "Scrollable and resizable columns to manage extensive data effortlessly.",
      "A responsive layout that adapts to different screen sizes.",
    ],
  },
  {
    heading: "4. Responsive Design",
    description:
      "We ensured that the application delivers a consistent and efficient experience across all devices. The mobile version retains the core functionalities of the desktop version, with an adaptive design that caters to touch interactions.",
  },
];
function OversiteWeb() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  return (
    <Layout>
      <FloatingForm />
      {loading ? (
        <Preloader />
      ) : (
        <div
          onClick={() => {
            document
              .querySelector(".main-nav")
              .className.includes("slidenav") &&
              document.querySelector(".remove").click();
          }}
        >
          <section className="page-section mb-[100px]">
            <div className="position-relative">
              <div className="container max-w-[1320px]">
                <h1 className="title z-10 position-absolute 699px:text-[39px]">
                  Oversite Web Application Design
                </h1>
              </div>
              <img className="w-100 filter brightness-[30%] " srcSet={TopPic} />
            </div>
            <div className="container max-w-[1320px] ">
              <div
                className="app-data wow animated fadeIn"
                data-wow-delay="200ms"
              >
                <h3>Client: Oversite</h3>
                <h3>Year: 2024</h3>
              </div>
              <div className="about-app 991px:gap-0 gap-[24px]    mb-[72px] ">
                <div className=" text   w-full  wow animated fadeInUp">
                  <h1 className=" 991px:text-center  991px:container 991px:max-w-full 991px:mx-auto ">
                    Overview
                  </h1>
                  <p>
                    Oversite Software, a leader in HR solutions, approached us
                    to design a comprehensive web application to streamline the
                    process of matching the right people to the right projects.
                    The goal was to create an intuitive and efficient user
                    experience that could handle complex data and workflows, all
                    within a single interface.
                  </p>
                </div>
                <img
                  src={AboutImage}
                  alt="Oddsee app"
                  className="wow animated fadeInUp "
                />
              </div>
              {/* SECOND IMAGE */}
              <div className="about-app 991px:gap-0 gap-[24px]  second  ">
                <div className=" text  w-full  wow animated fadeInUp">
                  <h1 className=" 991px:text-center  991px:container 991px:max-w-full 991px:mx-auto ">
                    Challanges
                  </h1>
                  <div>
                    <ul className="dredging-text-list  ">
                      <li className="flex gap-1 ml-[5px] oversite-web-about-margin">
                        <div>1.</div>
                        <div>
                          Unified Interface: Ensuring all functions and actions
                          occur within a single mega interface, reducing page
                          navigation to a minimum.
                        </div>
                      </li>
                      <li className="flex gap-1 ml-[5px] oversite-web-about-margin">
                        <div>2.</div>
                        <div>
                          Dashboard Design: Creating a dashboard that allows
                          easy visualization and management of projects and
                          employees, with drag-and-drop functionality
                        </div>
                      </li>
                      <li className="flex gap-1 ml-[5px] oversite-web-about-margin">
                        <div>3.</div>
                        <div>
                          Excel-like Tables: Designing tables that mimic the
                          familiarity and functionality of Excel, accommodating
                          large amounts of data without overwhelming the user.
                        </div>
                      </li>
                      <li className="flex gap-1 ml-[5px] oversite-web-about-margin">
                        <div>4.</div>
                        <div>
                          Responsive Design: Ensuring seamless usability on both
                          desktop and mobile devices.
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <img
                  src={AboutImage2}
                  alt="Oddsee app"
                  className="wow animated fadeInUp "
                />
              </div>
            </div>
            {/* END SECOND IMAGE */}
            {/* BACKGROUND */}
            <div className=" 799px:mt-[80px] mt-[144px]">
              <img
                className="w-full "
                src={AnimatedBackground}
                alt="palto-software"
              />
            </div>
            {/* END BACKGROUDN */}
            {/* SOLUTIONS */}
            <div className="container max-w-[1320px] solutions 799px:mt-[80px] mt-[144px] grid grid-cols-12 gap-4">
              <div className=" 767px:col-span-12 1199px:col-span-4 col-span-6">
                <h1 className="text-[#256C86]  679px:text-[22px]   799px:text-[28px] text-[34px]">
                  Solution
                </h1>
              </div>
              <div className=" 767px:col-span-12 1199px:col-span-8 col-span-6">
                <div className="space-y-[16px]">
                  {SOLUTIONS.map((item, index) => (
                    <div
                      key={index}
                      className="  p-[24px] rounded-[16px] bg-[#EFEFEF]"
                    >
                      <h5 className="text-[25px] 679px:text-[19px] mb-2 text-[#16404F] normal-case text-left">
                        {item.heading}
                      </h5>
                      <p className="text-[#565656]">{item.description}</p>
                      {item.pros && (
                        <ul className="list-disc list-inside ml-[25px] text-[#565656] mt-1">
                          {item.pros.map((pro, idx) => (
                            <li key={idx}>{pro}</li>
                          ))}
                        </ul>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* END SOLUTIONS */}
            {/* BACKGROUND */}
            <div className=" 799px:mt-[80px] mt-[144px]">
              <img
                className="w-full "
                src={AnimatedBackground2}
                alt="palto-software"
              />
            </div>
            {/* END BACKGROUDN */}
            {/* ABOUT 2 */}
            <div className="container max-w-[1320px] 799px:mt-[80px] mt-[144px]">
              <div className="about-app 991px:gap-0 gap-[24px]    mb-[72px] ">
                <div className=" text   w-full  wow animated fadeInUp">
                  <h1 className=" 991px:text-center  991px:container 991px:max-w-full 991px:mx-auto ">
                    Process
                  </h1>
                  <div>
                    <ul className="dredging-text-list text-[#565656]  ">
                      <li className="flex gap-[4px] ml-[5px] oversite-web-about-margin">
                        <div>1.</div>
                        <div>
                          Project Acceptance: The process begins with the
                          approval of new projects within the application.
                        </div>
                      </li>
                      <li className="flex gap-[4px] ml-[5px] oversite-web-about-margin">
                        <div>2.</div>
                        <div>
                          Employee Matching: Managers can then select and assign
                          employees to these projects using the drag-and-drop
                          interface.
                        </div>
                      </li>
                      <li className="flex gap-[4px] ml-[5px] oversite-web-about-margin">
                        <div>3.</div>
                        <div>
                          Data Management: Throughout the process, managers have
                          access to detailed tables and dashboards that provide
                          insights and control over project and employee data.
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <img
                  src={AboutImage3}
                  alt="Oddsee app"
                  className="wow animated fadeInUp "
                />
              </div>
              {/* SECOND IMAGE */}
              <div className="about-app 991px:gap-0 gap-[24px]    second">
                <div className=" text   w-full  wow animated fadeInUp">
                  <h1 className=" 991px:text-center  991px:container 991px:max-w-full 991px:mx-auto ">
                    Results
                  </h1>
                  <div>
                    <p className="pb-[8px]">
                      The Oversite application successfully meets the client's
                      needs by:
                    </p>
                    <ul className="palto-text-list ml-[25px] text-[#565656]">
                      <li className="mb-[2px]">
                        A compelling hero section with a bold statement
                        highlighting the freedom and benefits of joining Palto.
                      </li>
                      <li className="mb-[2px]">
                        3D elements that add depth and modernity, creating a
                        more engaging user experience.
                      </li>
                      <li className="mb-[2px]">
                        A clean, intuitive layout ensuring a smooth user
                        experience, effortlessly guiding potential clients
                        through Palto's offerings.
                      </li>
                    </ul>
                    <p className="pb-[8px]">
                      By addressing the core challenges, we delivered a robust
                      and user-friendly HR management tool that improves
                      efficiency and productivity for Oversite Software's
                      clients.
                    </p>
                  </div>
                </div>
                <img
                  src={AboutImage4}
                  alt="Oddsee app"
                  className="wow animated fadeInUp "
                />
              </div>
            </div>

            <div className=" 799px:mt-[80px] mt-[144px]">
              <img
                className="w-full "
                src={AnimatedBackground3}
                alt="palto-software"
              />
            </div>
          </section>
        </div>
      )}
    </Layout>
  );
}

export default OversiteWeb;
