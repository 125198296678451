import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Preloader from "../../layout/Preloader";
import ReactPlayer from "react-player/lazy";
import TitleGif from "../../../assets/img/oligamy/about-us/giphy-cropped.webm";
import UnderTitleFilm from "../../../assets/img/oligamy/about-us/films/blurred-code-cropped.webm";

import WorldMapFilm from "../../../assets/img/oligamy/about-us/films/world-map.webm";

import Film2 from "../../../assets/img/oligamy/about-us/films/2.webm";
import AboutTimeline from "./AboutTimeline";

import Gif1 from "../../../assets/img/oligamy/about-us/gifs/1.mp4";
import Gif2 from "../../../assets/img/oligamy/about-us/gifs/2.mp4";
import Gif3 from "../../../assets/img/oligamy/about-us/gifs/3.mp4";
import Gif4 from "../../../assets/img/oligamy/about-us/gifs/4.mp4";
import Gif5 from "../../../assets/img/oligamy/about-us/gifs/5.mp4";
import Gif6 from "../../../assets/img/oligamy/about-us/gifs/6.webm";
import Layout from "../../App";
import { StaggerChildren } from "../offer/Animations";
import { Helmet } from "react-helmet-async";

const workflowTilesContent = [
  {
    number: "01",
    title: "Estimation",
    gifUrl: Gif1,
    content:
      "We start with precise estimation, laying the foundation for transparent collaboration by understanding the project's scope and providing accurate time and cost forecasts.",
  },
  {
    number: "02",
    title: "Workshops/Kickoff",
    gifUrl: Gif2,
    content:
      "Transitioning to workshops, teams merge knowledge, defining a shared vision, goals, and project direction during the kickoff phase.",
  },
  {
    number: "03",
    title: "Product Design",
    gifUrl: Gif3,
    content:
      "Immersing ourselves in product design, meticulous attention to detail ensures the creation of interactive, user-centric solutions that exceed expectations.",
  },
  {
    number: "04",
    title: "Continuous Delivery",
    gifUrl: Gif4,
    content:
      "Prioritizing continuous value delivery, our use of the Continuous Delivery methodology guarantees regular, automated software delivery with noticeable results at every project stage.",
  },
  {
    number: "05",
    title: "Testing",
    gifUrl: Gif5,
    content:
      "Prioritizing safety and quality, our rigorous testing processes at each stage guarantee functional and reliable solutions.",
  },
  {
    number: "06",
    title: "Development",
    gifUrl: Gif6,
    content:
      "Emphasizing continuous value delivery, our implementation of the Continuous Delivery methodology ensures regular, automated software delivery, yielding noticeable results at every project stage.",
  },
];

function AboutUs() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const [joinTeamWidth, setJoinTeamWidth] = useState("100%");

  useEffect(() => {
    const handleResize = () => {
      setJoinTeamWidth(
        window.innerWidth <= 500
          ? "210%"
          : window.innerWidth <= 767
          ? "150%"
          : "100%"
      );
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isSmallerThan1404, setIsSmallerThan1404] = useState(
    window.innerWidth < 1404
  );

  useEffect(() => {
    const handleResize = () => {
      setIsSmallerThan1404(window.innerWidth < 1404);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [caseStudyFilmWidth, setcaseStudyFilmWidth] = useState("100%");

  useEffect(() => {
    const handleResize = () => {
      setcaseStudyFilmWidth(window.innerWidth <= 767 ? "150%" : "100%");
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -50;
    const yOffsetAlt = -100;
    if (el.id === "about") {
      window.scrollTo({ top: yCoordinate + yOffsetAlt, behavior: "smooth" });
    } else {
      window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
    }
  };

  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  // Metadata
  const pageDescription =
    "Learn about Oligamy Software, a leader in blockchain, AI, and custom web/mobile development. We provide high-quality software, innovative digital design, and tailored solutions to meet your business needs.";

  const pageTitle =
    "Oligamy Software - About Us | Leading in Blockchain, AI, and Custom Development";

  return (
    <Layout isAlternative={true}>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={pageDescription} />
            <meta property="og:description" content={pageDescription} />
            <meta property="og:url" content={window.location.href} />{" "}
            {/* Use the current page URL */}
            <meta property="og:type" content="website" />
            {/* Twitter Card tags for Twitter */}
            <meta name="twitter:title" content={pageTitle} />
            <meta name="twitter:description" content={pageDescription} />
          </Helmet>
          <div
            onClick={() => {
              document
                .querySelector(".main-nav")
                .className.includes("slidenav") &&
                document.querySelector(".remove").click();
            }}
          >
            <section className="page-section-offer mb-[237px] mt-[80px]">
              <div className="about-us-title-section pt-[116px] container 500px:pt-[72px]">
                <h1 className="text-[95px] text-[#0e2933] leading-[120%] tracking-[-4.75px] 991px:text-[70px] 767px:text-[60px] 500px:text-[52px]">
                  Shaping{" "}
                  <div className="inline-block max-w-[111px] max-h-[70px] rounded-[8px] overflow-hidden translate-y-[15%]">
                    <ReactPlayer
                      url={TitleGif}
                      width="100%"
                      height="auto"
                      loop={true}
                      playing={true}
                      className="relative z-[9] "
                    />
                  </div>{" "}
                  excellence in software development
                </h1>
                <div className="lower-title w-full flex justify-end mt-[56px] 991px:justify-normal 500px:mt-[48px]">
                  <p className="text-[#565656] text-[25px] leading-[120%] max-w-[670px] 500px:text-[19px]">
                    Oligamy Software, a leader in software development based in
                    Gdańsk, Poland, is your trusted partner. Founded by two
                    highly experienced software engineers, our team of over 50
                    professionals is dedicated to excellence.
                  </p>
                </div>
                <div className="film mt-[140px] 500px:mt-[96px] rounded-[16px] overflow-hidden">
                  <ReactPlayer
                    url={UnderTitleFilm}
                    width="100%"
                    height="auto"
                    loop={true}
                    playing={true}
                    className="relative z-[9] "
                  />
                </div>
                <div className="title-list-wrapper flex justify-end">
                  <div className="title-list max-w-[826px] mt-[96px] 500px:mt-[72px]">
                    <StaggerChildren>
                      <div className="single-point">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="100"
                          height="100"
                          viewBox="0 0 100 100"
                          fill="none"
                        >
                          <path
                            d="M3.62082 1.17263L96.1731 10.7267L41.6136 86.0122L3.62082 1.17263Z"
                            stroke="#3FB7E2"
                            strokeWidth="2"
                          />
                        </svg>
                        <div className="item-text">
                          <h4>Expertise Undisputed</h4>
                          <p>
                            Our team masters every aspect of software
                            development. From thorough business analysis and
                            innovative UX/UI design to solid development and
                            perfect maintenance, we provide solutions tailored
                            to all your needs.
                          </p>
                        </div>
                      </div>
                    </StaggerChildren>
                    <StaggerChildren>
                      <div className="single-point">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="100"
                          height="100"
                          viewBox="0 0 100 100"
                          fill="none"
                        >
                          <rect
                            x="21.4423"
                            y="6.84455"
                            width="72.5981"
                            height="72.5981"
                            transform="rotate(11.5878 21.4423 6.84455)"
                            stroke="#3FB7E2"
                            strokeWidth="2"
                          />
                        </svg>
                        <div className="item-text">
                          <h4>Efficient Agile Development</h4>
                          <p>
                            We prioritize efficient Agile Development
                            methodologies and focus on short feedback cycles.
                            Our collaborative workflow guarantees continuous
                            progress updates on a weekly basis and ensures
                            prompt reviews and feedback. Everything to maximize
                            your investment and achieve your desired outcome.
                          </p>
                        </div>
                      </div>
                    </StaggerChildren>
                    <StaggerChildren>
                      <div className="single-point">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="100"
                          height="100"
                          viewBox="0 0 100 100"
                          fill="none"
                        >
                          <circle
                            cx="50"
                            cy="50"
                            r="43"
                            stroke="#3FB7E2"
                            strokeWidth="2"
                          />
                        </svg>
                        <div className="item-text">
                          <h4>Value-Centric Approach</h4>
                          <p>
                            Our primary objective is to deliver exceptional
                            value for your budget. Count on us to provide
                            precisely what you need, ensuring a partnership that
                            exceeds expectations.
                          </p>
                        </div>
                      </div>
                    </StaggerChildren>
                  </div>
                </div>
              </div>
              <AboutTimeline />
              <div className="map-section container mt-[96px]">
                <StaggerChildren>
                  <h3 className="text-[#0e2933] text-[45px] leading-[120%] max-w-[690px] text-left 500px:text-[29px]">
                    With pride, we serve international clients, delivering our
                    services on a global scale.
                  </h3>
                </StaggerChildren>
                <StaggerChildren>
                  <div className="world-map relative">
                    <div className="film">
                      <ReactPlayer
                        url={WorldMapFilm}
                        width="100%"
                        height="auto"
                        loop={true}
                        playing={true}
                        className="relative z-[9] "
                      />
                    </div>
                  </div>
                </StaggerChildren>
              </div>
              <StaggerChildren>
                <div className="quote-section container mt-[168px] flex flex-col gap-[48px] 767px:gap-[32px] 767px:mt-[96px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="130"
                    height="101"
                    viewBox="0 0 130 101"
                    fill="none"
                    className="767px:w-[73px]"
                  >
                    <path
                      d="M54.7746 2L54.7746 1L53.7746 1L11.6218 0.999997L10.6218 0.999997L10.6218 2L10.6218 45.2133L10.6218 46.2133L11.6218 46.2133L28.0327 46.2133L28.0327 53.0284C28.0327 60.9559 27.4233 66.8043 26.2505 70.6428C24.8118 74.6692 22.2646 77.342 18.5927 78.759C14.7908 80.2261 9.28361 80.9905 1.99999 80.9905L0.999994 80.9905L0.999994 81.9905L0.999992 99L0.999992 100L1.99999 100C13.3316 100 22.4359 99.0805 29.276 97.2119C36.1609 95.638 41.5565 92.4756 45.3822 87.6841C49.2031 83.205 51.6922 76.8819 52.9298 68.8136C54.1629 61.0791 54.7745 51.0612 54.7745 38.7773L54.7746 2ZM129 2.00001L129 1.00001L128 1.00001L86.3055 1L85.3055 1L85.3055 2L85.3055 45.2133L85.3055 46.2133L86.3055 46.2133L102.258 46.2133L102.258 53.0284C102.258 60.9559 101.649 66.8043 100.476 70.6429C99.0372 74.6693 96.49 77.342 92.8182 78.759C89.0128 80.2275 83.6555 80.9905 76.6836 80.9905L75.6836 80.9905L75.6836 81.9905L75.6836 99L75.6836 100L76.6836 100C87.713 100 96.6634 99.08 103.501 97.2119C110.386 95.638 115.782 92.4756 119.608 87.684C123.429 83.2049 125.918 76.8817 127.155 68.8133C128.388 61.0788 129 51.061 129 38.7773L129 2.00001Z"
                      stroke="#3FB7E2"
                      strokeWidth="2"
                    />
                  </svg>
                  <h3 className="text-[#0e2933] text-[45px] leading-[120%] text-left 767px:text-[30px] 500px:text-[22px] 500px:leading-[120%]">
                    We take pride in the dynamic growth of our team, which
                    reflects our commitment to excellence and innovation.
                    <span className="text-[#0e2933] bg-[#3fb7e2]">
                      {" "}
                      International collaboration{" "}
                    </span>
                    with clients adds inspiration and motivation to create
                    top-quality software on a global scale. Together, we're not
                    just crafting products, but also experiences.
                  </h3>
                  <div className="quote-author flex gap-[16px] items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                    >
                      <rect width="48" height="48" rx="24" fill="#2C7789" />
                      <path
                        d="M37 23.9635L34.7329 23.197L34.0528 21.8608V24.2121L35.176 24.73L32.0433 26.3614L31.9093 19.9912L22.1968 12L22.5678 15.2421L24.5412 16.2623L22.1917 16.3348L26.0458 24.5643L24.093 21.7055L21.8258 22.2648L23.5777 20.9493L22.6245 19.551L12.2885 28.3398L15.1224 29.3186L12 29.1011V34.4407L13.4272 34.9948L15.8283 31.152L15.2718 33.8036L16.0344 33.959L18.0542 30.5978L21.9959 31.6647L22.8821 33.7415L23.8302 33.6327L22.5008 27.9255L26.0354 35L27.1896 34.5028L27.0196 26.8172L23.892 25.134L27.3184 26.0403L27.5915 20.7732L27.9934 26.175L30.8685 24.5073L28.8693 26.4961L29.4413 28.1067L32.4658 28.7179L37 25.3152L36.9897 23.9635H37ZM29.0084 21.5138L29.364 21.3274L30.4254 23.6269L29.0033 21.5138H29.0084Z"
                        fill="#FDFDFD"
                      />
                    </svg>
                    <p className="text-[#565656] text-[25px] font-500 leading-[120%] 500px:text-[19px]">
                      From Oligamy Software
                    </p>
                  </div>
                </div>
              </StaggerChildren>
              <div className="workflow-section container mt-[185px] 500px:mt-[96px]">
                <StaggerChildren>
                  <div className="w-full flex flex-col items-center gap-[16px]">
                    <h2 className="text-[#032933] text-[45px] 500px:text-[29px]">
                      Our workflow
                    </h2>
                    <p className="text-[#565656] text-[16px] leading-[150%] max-w-[670px] text-center">
                      At Oligamy Software, our process is not just a structure;
                      it's a committed journey where we craft uniquely
                      innovative software.
                    </p>
                  </div>
                </StaggerChildren>
                <div className="workflow-tiles mt-[96px] flex flex-col gap-[24px] 1199px:hidden ">
                  <StaggerChildren>
                    <div className="upper">
                      {workflowTilesContent.slice(0, 3).map((tile, index) => (
                        <div className="workflow-tile" key={index}>
                          <div className="actual-content">
                            <h1>{`${tile.number}.`}</h1>
                            <h5>{tile.title}</h5>
                            <p>{tile.content}</p>
                          </div>
                          <div className="hover-giph">
                            <div className="film mt-0 h-[100%]">
                              <ReactPlayer
                                url={tile.gifUrl}
                                loop={true}
                                playing={true}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </StaggerChildren>
                  <StaggerChildren>
                    <div className="lower">
                      {workflowTilesContent.slice(3, 6).map((tile, index) => (
                        <div className="workflow-tile" key={index}>
                          <div className="actual-content">
                            <h1>{`${tile.number}.`}</h1>
                            <h5>{tile.title}</h5>
                            <p>{tile.content}</p>
                          </div>
                          <div className="hover-giph">
                            <div className="film mt-0 h-[100%]">
                              <ReactPlayer
                                url={tile.gifUrl}
                                loop={true}
                                playing={true}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </StaggerChildren>
                </div>
                <div className="workflow-tiles mt-[96px] flex-col gap-[24px] hidden 1199px:flex 767px:hidden">
                  <StaggerChildren>
                    <div className="upper">
                      {workflowTilesContent.slice(0, 2).map((tile, index) => (
                        <div className="workflow-tile" key={index}>
                          <div className="actual-content">
                            <h1>{`${tile.number}.`}</h1>
                            <h5>{tile.title}</h5>
                            <p>{tile.content}</p>
                          </div>
                          <div className="hover-giph">
                            <div className="film mt-0 h-[100%]">
                              <ReactPlayer
                                url={tile.gifUrl}
                                loop={true}
                                playing={true}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </StaggerChildren>
                  <StaggerChildren>
                    <div className="middle flex justify-center gap-[24px]">
                      {workflowTilesContent.slice(2, 4).map((tile, index) => (
                        <div className="workflow-tile" key={index}>
                          <div className="actual-content">
                            <h1>{`${tile.number}.`}</h1>
                            <h5>{tile.title}</h5>
                            <p>{tile.content}</p>
                          </div>
                          <div className="hover-giph">
                            <div className="film mt-0 h-[100%]">
                              <ReactPlayer
                                url={tile.gifUrl}
                                loop={true}
                                playing={true}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </StaggerChildren>
                  <StaggerChildren>
                    <div className="lower">
                      {workflowTilesContent.slice(4, 6).map((tile, index) => (
                        <div className="workflow-tile" key={index}>
                          <div className="actual-content">
                            <h1>{`${tile.number}.`}</h1>
                            <h5>{tile.title}</h5>
                            <p>{tile.content}</p>
                          </div>
                          <div className="hover-giph">
                            <div className="film mt-0 h-[100%]">
                              <ReactPlayer
                                url={tile.gifUrl}
                                loop={true}
                                playing={true}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </StaggerChildren>
                </div>
                <div className="workflow-tiles mt-[96px] flex-col gap-[24px] hidden 767px:flex 500px:mt-[48px]">
                  <div className="flex flex-col items-center gap-[24px]">
                    {workflowTilesContent.slice(0, 6).map((tile, index) => (
                      <StaggerChildren key={index}>
                        <div className="workflow-tile">
                          <div className="actual-content">
                            <h1>{`${tile.number}.`}</h1>
                            <h5>{tile.title}</h5>
                            <p>{tile.content}</p>
                          </div>
                        </div>
                      </StaggerChildren>
                    ))}
                  </div>
                </div>
              </div>

              <div className="join-us-section container mt-[233px] 500px:mt-[96px]">
                <StaggerChildren>
                  <div className="film-container relative ">
                    <div className="film overflow-hidden max-h-[450px] rounded-[16px] mt-0 767px:min-h-[402px] 500px:min-h-[420px]">
                      <div className="filter">
                        <ReactPlayer
                          url={Film2}
                          width={joinTeamWidth}
                          height="auto"
                          loop={true}
                          playing={true}
                          className="relative z-[9] 767px:translate-x-[-15%] 500px:translate-x-[-30%]"
                        />
                      </div>
                    </div>
                    <div className="film-inner-text absolute z-[15] max-w-[670px] text-center 500px:min-w-[300px]">
                      <h1 className="text-[#fdfdfd] text-[45px] 767px:text-[29px]">
                        Join our team!
                      </h1>
                      <p className="text-[#efefef] text-[16px] leading-[150%] mt-[32px]">
                        Thrive in a dynamic environment where your ideas matter.
                        We're awaiting your CV—let's create innovative solutions
                        for international clients together. Send your CV to{" "}
                        <a
                          href="mailto:careers@oligamy.com"
                          className="text-[#3fb7e2] underline hover:text-[#efefef] hover:underline transition-[0.3s]"
                        >
                          careers@oligamy.com
                        </a>
                      </p>
                      <Link to={"/career"} onClick={scrollTop}>
                        <button className="mt-[48px] reference-btn">
                          See job offers
                        </button>
                      </Link>
                    </div>
                  </div>
                </StaggerChildren>
              </div>
            </section>
          </div>
        </>
      )}
    </Layout>
  );
}

export default AboutUs;
