export function isFresh(createdAt) {
  const postDate = new Date(createdAt);
  const currentDate = new Date();

  const timeDifference = currentDate - postDate;

  const twoWeeksInMilliseconds = 14 * 24 * 60 * 60 * 1000;

  return timeDifference <= twoWeeksInMilliseconds;
}
