const dropdownOptions = {
  design: [
    {
      isGrouped: false,
      items: [
        {
          title: "Design page",
          url: "/design",
          routing: "top",
        },
        {
          title: "All case studies",
          url: "/design/case-studies",
          routing: "top",
        },
        {
          title: "Brief form",
          url: "/design/expectations-brief",
          routing: "top",
        },
      ],
    },
  ],
  offer: [
    {
      isGrouped: true,
      groups: [
        {
          name: "Explore & Validate Ideas",
          items: [
            {
              title: "AI Validation Sprint",
              url: "/offer/ai-validation",
              routing: "top",
            },
            {
              title: "Blockchain Validation Sprint",
              url: "/offer/blockchain-validation",
              routing: "top",
            },
          ],
        },
        {
          name: "Develop Products",
          items: [
            {
              title: "AI Solutions",
              url: "/offer/ai-solutions",
              routing: "top",
            },
            {
              title: "Blockchain Development",
              url: "/offer/blockchain-development",
              routing: "top",
            },
            {
              title: "Web Development",
              url: "/offer/web-development",
              routing: "top",
            },
            {
              title: "Design implementation service",
              url: "/offer/design-implementation-service",
              routing: "top",
            },
          ],
        },
        {
          name: "Cooperation Models",
          items: [
            {
              title: "Digital Acceleration",
              url: "/offer/digital-acceleration",
              routing: "top",
            },
            {
              title: "Dedicated Teams",
              url: "/offer/dedicated-teams",
              routing: "top",
            },
            {
              title: "Staff Augmentation",
              url: "/offer/staff-augmentation",
              routing: "top",
            },
          ],
        },
        {
          name: "Design Solutions",
          items: [
            {
              title: "UX/UI Design",
              url: "/offer/ui-ux",
              routing: "top",
            },
          ],
        },
      ],
    },
  ],
  about: [
    {
      isGrouped: false,
      items: [
        {
          title: "About us",
          url: "/about-us",
          routing: "top",
        },
        {
          title: "Career",
          url: "/career",
          routing: "top",
        },
      ],
    },
  ],
};

export default dropdownOptions;
