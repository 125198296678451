import { lazy, Suspense, useEffect, useState } from "react";

import FloatingForm from "../../../common/floating-form/FloatingForm";
import Layout from "../../../App";
import { Link, NavLink } from "react-router-dom";
import { useVisibleHeader } from "../../../common/header/HeaderVisibilityContext";

import OfferContact from "../OfferContact";
import OfferBlueBanner from "../OfferBlueBanner";
import OfferProcess from "../OfferProcess";
import OfferBlueBannerService from "../OfferBlueBannerService";
import OfferMainHeading from "../OfferMainHeading";
import OfferInTheNews from "../OfferInTheNews";
import OfferCaseStudies from "../OfferCaseStudies";
import { SlideInFromBottom } from "../Animations";
import { useIsMobile } from "../../../../helpers/useIsMobile";
import OfferSkeleton from "../OfferSkeleton";
import Palto from "../../../../assets/img/oligamy/home-case-studies/palto.webp";
import Dredging from "../../../../assets/img/oligamy/home-case-studies/dredging-operations.webp";
import Oversite from "../../../../assets/img/oligamy/home-case-studies/oversite.webp";
import FilipPuzyr from "../../../../assets/img/oligamy/home-case-studies/filip-puzyr.webp";
import { Helmet } from "react-helmet-async";
const ProductDevelopmentTopVideoComponent = lazy(() =>
  import("./ProductDevelopmentTopVideoComponent")
);
function ProductDevelopment() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const caseStudiesData = [
    {
      thumbnail: Palto,
      title: "Palto",
      url: "/design/palto",
      tag1: "Landing Page",
    },
    {
      thumbnail: Dredging,
      title: "Dredging Operations",
      url: "/design/dredging-operations",
      tag1: "Desktop app",
    },
    {
      thumbnail: FilipPuzyr,
      title: "Filip Puzyr",
      url: "/design/filip-puzyr",
      tag1: "Website",
    },
    {
      thumbnail: Oversite,
      title: "Oversite",
      url: "/design/oversite-web",
      tag1: "Web app",
    },
  ];
  const isMobile = useIsMobile("(max-width: 500px)");
  const processSteps = [
    {
      number: "01",
      title: "AI Strategy",
      content:
        "We develop tailored AI strategies aligned with your business goals through detailed analysis, identifying areas of maximum benefit.",
    },
    {
      number: "02",
      title: "AI Solution selection",
      content:
        "Leveraging our expertise, we conduct thorough comparative analyses to help you select the optimal AI solution, customized to your specific needs.",
    },
    {
      number: "03",
      title: "AI Prototype deployment",
      content:
        "We create AI prototypes for real-world testing, refining solutions to match your requirements before full deployment.",
    },
    {
      number: "04",
      title: "AI Model optimization",
      content:
        "Our specialists continuously enhance AI models, ensuring they remain effective, efficient, and adaptable to evolving business demands.",
    },
    {
      number: "05",
      title: "Support and maintenance",
      content:
        "Post-deployment, we offer ongoing support and maintenance, ensuring your AI solutions operate smoothly and deliver expected benefits.",
    },
  ];

  const services = [
    {
      number: "01",
      title: "Customer experience personalization",
      content:
        "Our expertise lies in using advanced AI algorithms to create tailored experiences for your customers, enhancing their engagement and loyalty to your brand.",
    },
    {
      number: "02",
      title: "Business process optimization",
      content:
        "We specialize in automating repetitive business tasks using intelligent AI systems, leading to significant efficiency improvements, time savings, and cost reductions.",
    },
    {
      number: "03",
      title: "Big data analysis",
      content:
        "With our cutting-edge AI tools, we analyze vast datasets to uncover valuable insights, identify trends, forecast customer behaviors, and facilitate data-driven decision-making.",
    },
    {
      number: "04",
      title: "Medical diagnostics support",
      content:
        "We develop AI solutions tailored to the medical field, aiding in disease diagnosis, analyzing medical images, and crafting personalized treatment plans to improve patient outcomes.",
    },
    {
      number: "05",
      title: "Logistics process optimization",
      content:
        "Through the application of intelligent AI algorithms, we optimize logistical operations by fine-tuning routes, managing inventory more effectively, and minimizing transportation costs.",
    },
    {
      number: "06",
      title: "Tailored AI solutions",
      content:
        "We offer customized AI solutions to address specific challenges across various industries, leveraging the power of artificial intelligence to drive innovation and achieve business objectives.",
    },
  ];

  const [isQuickNavFixed, setIsQuickNavFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const el = document.querySelector(".offer-quick-nav");
      if (!el) return;
      const elOffset = el.offsetTop;

      const onScroll = () => {
        if (elOffset - 84 <= window.scrollY - 84) {
          setIsQuickNavFixed(true);
        } else {
          setIsQuickNavFixed(false);
        }
      };

      window.removeEventListener("scroll", onScroll);
      window.addEventListener("scroll", onScroll, { passive: true });
    };

    const checkScrollPosition = () => {
      if (window.scrollY === 0) {
        handleScroll();
      }
    };

    checkScrollPosition();

    const onScroll = () => {
      if (window.scrollY === 0) {
        handleScroll();
        window.removeEventListener("scroll", onScroll);
      }
    };

    window.addEventListener("scroll", onScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const { isVisible } = useVisibleHeader();

  // Metadata
  const pageDescription =
    "Leverage AI with Oligamy Software’s advanced solutions. We specialize in AI, blockchain, and custom web/mobile development, providing high-quality software and innovative digital solutions for your business needs.";

  const pageTitle =
    "Oligamy Software - AI Solutions | Advanced AI Development Services";

  return (
    <Layout isAlternative={true}>
      <FloatingForm />

      <>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta property="og:description" content={pageDescription} />
          <meta property="og:url" content={window.location.href} />{" "}
          {/* Use the current page URL */}
          <meta property="og:type" content="website" />
          {/* Twitter Card tags for Twitter */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDescription} />
        </Helmet>
        <div className="bg-[#FDFDFD] ">
          <main
            className="page-section  "
            onClick={() => {
              document
                .querySelector(".main-nav")
                .className.includes("slidenav") &&
                document.querySelector(".remove").click();
            }}
          >
            <OfferMainHeading title={"Develop products"} />
            {/* desktop */}
            {!isMobile && (
              <section
                className={`offer-quick-nav offer-quick-nav-special z-[12] pt-[36px] ${
                  isVisible ? "offset" : ""
                } ${isQuickNavFixed ? "isFixed" : ""}`}
              >
                <nav className="container">
                  <NavLink onClick={scrollTop} to={`/offer/ai-solutions`}>
                    AI Solutions
                  </NavLink>
                  <NavLink to={`/offer/blockchain-development`}>
                    Blockchain development
                  </NavLink>
                  <NavLink to={`/offer/web-development`}>
                    Web development
                  </NavLink>
                  <NavLink to="/offer/design-implementation-service">
                    Design implementation service
                  </NavLink>
                </nav>
              </section>
            )}
            {/* mobile */}
            {isMobile && (
              <section
                className={`offer-quick-nav z-[12] pt-[36px] ${
                  isVisible ? "offset" : ""
                } ${isQuickNavFixed ? "isFixed" : ""}`}
              >
                <nav className="container">
                  <NavLink to={`/offer/ai-solutions`} onClick={scrollTop}>
                    AI Solutions
                  </NavLink>
                  <NavLink to={`/offer/web-development`}>
                    Web development
                  </NavLink>
                  <NavLink to={`/offer/blockchain-development`}>
                    Blockchain development
                  </NavLink>
                  <NavLink to="/offer/design-implementation-service">
                    Design implementation service
                  </NavLink>
                </nav>
              </section>
            )}
            <section className="container ">
              <div className="offer-title-card mt-[72px]">
                <div className="500px:min-h-[220px] 767px:min-h-[180px] 991px:min-h-[260px] 1199px:min-h-[340px] 1399px:min-h-[420px] min-h-[550px] 500px:mb-[72px] 1199px:mb-[80px] mb-[96px]">
                  <Suspense fallback={OfferSkeleton()}>
                    <ProductDevelopmentTopVideoComponent />
                  </Suspense>
                </div>

                <div className="flex flex-col justify-between">
                  <SlideInFromBottom>
                    <h2 className="500px:text-[29px] 767px:text-[45px] 991px:text-[52px] text-[61px] text-[#0e2933]">
                      Oligamy software house - Your partner in{" "}
                      <span className="leading-[120%]">
                        artificial intelligence &#40;AI&#41;
                      </span>{" "}
                      solutions.
                    </h2>
                  </SlideInFromBottom>
                  <SlideInFromBottom>
                    <div className="description mt-[56px] flex flex-col items-end">
                      <div className=" max-w-[670px]">
                        <p className="text-[#565656]">
                          Our AI team consists of mathematicians and algorithmic
                          programmers with solid academic backgrounds and years
                          of commercial experience. We specialize in natural
                          language processing, sound processing, financial and
                          biological data analysis, computer vision, and robotic
                          process automation.
                        </p>
                        <Link
                          to="/contact"
                          className=" inline-block mt-[32px] bg-[#3fb7e2] py-[16px] px-[32px] font-[500] text-[19px] rounded-[8px] text-[#173d46] hover:text-[#fbfbfb] transition-colors duration-500"
                        >
                          Contact us
                        </Link>
                      </div>
                    </div>
                  </SlideInFromBottom>
                </div>
              </div>
            </section>
            <OfferBlueBanner
              higlightedText={"We offer "}
              restText={
                "end-to-end Artificial Intelligence services, from strategy development to continuous solution optimization. Our process involves understanding domains, defining business cases, and deploying and optimizing AI solutions."
              }
            />
            <OfferProcess
              title={"Process"}
              description={
                "At Oligamy Software House, we offer comprehensive services related to the utilization of Artificial Intelligence (AI), which can revolutionize the way you conduct your business operations. Our team of experienced AI specialists acts as your partner in achieving business goals by delivering innovative and customized AI solutions. "
              }
              processSteps={processSteps}
            />
            <OfferBlueBannerService
              services={services}
              title={"AI services include"}
              description={
                "Have you ever wondered how to harness the potential of Artificial Intelligence (AI) to revolutionize your company?"
              }
            />
            <SlideInFromBottom>
              <OfferCaseStudies caseStudiesData={caseStudiesData} />
            </SlideInFromBottom>
            <SlideInFromBottom>
              <OfferInTheNews category={"AI"} />
            </SlideInFromBottom>
            <SlideInFromBottom>
              <OfferContact
                text={
                  "Interested in learning more about our blockchain development services or starting a collaboration? Contact us today!"
                }
              />
            </SlideInFromBottom>
          </main>
        </div>
      </>
    </Layout>
  );
}

export default ProductDevelopment;
