import { useEffect } from "react";
import Footer from "./common/Footer";
import Header1 from "./common/header/Header";
import { useLocation } from "react-router-dom";
import { useIsMobile } from "../helpers/useIsMobile";
import FooterMobile from "./common/footer/FooterMobile";
import FooterDesktop from "./common/footer/FooterDesktop";

const Layout = ({ children, isAlternative }) => {
  const location = useLocation();
  const isMobile = useIsMobile("(max-width: 768px)");
  useEffect(() => {
    const isChrome =
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

    if (!isChrome) {
      const timeoutId = setTimeout(() => {
        window.scrollTo(0, 0);
      }, 1090); // Adjust the timeout duration as needed
    }
  }, [location.pathname]); // Trigger the effect when the pathname changes

  return (
    <>
      <Header1 isAlternative={isAlternative} />
      {children}
      {isMobile ? <FooterMobile /> : <FooterDesktop />}
    </>
  );
};

export default Layout;
