import React, { useEffect, useReducer, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import WOW from "wowjs";
import dropdownOptions from "./dropdownOptions";
import MobileMenu from "./MobileMenu";
import { throttle } from "../../../helpers/throttle";
import { useVisibleHeader } from "./HeaderVisibilityContext";

function Header({ isAlternative }) {
  const [sidebar, setSidebar] = useState(false);
  const [isSticky, setSticky] = useState(false);
  const { isVisible, setIsVisible } = useVisibleHeader();
  const ref = useRef(null);

  useEffect(() => {
    if (sidebar) {
      setIsVisible(true);
    }
  }, [sidebar]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1200) {
        setSidebar(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  /*---------header top Sticky event----------*/
  const handleStickyHeader = () => {
    if (ref.current) {
      setSticky(window.pageYOffset > 0);
    }
  };

  /*---------menu button event----------*/
  const handleSidbarMenu = () => {
    if (sidebar === false || sidebar === 0) {
      setSidebar(1);
    } else {
      setSidebar(false);
    }
  };

  /*---------add event scroll top----------*/
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const handleNavClick = () => {
    setIsDropdownVisible(false);
    if (window.innerWidth < 1199) {
      handleSidbarMenu();
    }
    scrollTop();
  };

  /*---------Navbar on scroll up only display----------*/
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);

  useEffect(() => {
    if (!sidebar) {
      const handleScroll = throttle(() => {
        const currentScrollPos = window.scrollY;
        if (currentScrollPos > prevScrollPos) {
          setIsVisible(false);
        } else {
          setIsVisible(true);
        }
        setPrevScrollPos(currentScrollPos);
      }, 2000);

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [prevScrollPos, sidebar]);

  /*---------Dropdown menu display----------*/

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [hoveredOptionName, setHoveredOptionName] = useState(""); // Initialize with null or any default value

  useEffect(() => {
    // Function to handle mouse enter event
    const handleMouseEnter = (event) => {
      setIsDropdownVisible(true);
      const { target } = event;
      // Check if the hovered element has an ID
      if (target.id) {
        // Remove the last three characters from the ID
        const cleanedId = target.id.slice(0, -3);
        setHoveredOptionName(cleanedId);
      }
    };

    // Function to handle mouse leave event
    const handleMouseLeave = () => {
      setIsDropdownVisible(false);
    };

    // Query all elements with class .has-child and .navbar-dropdown
    const elementsWithChildClass = document.querySelectorAll(".has-child");
    const navbarDropdowns = document.querySelectorAll(".navbar-dropdown");

    // Attach event listeners to each element
    elementsWithChildClass.forEach((element) => {
      element.addEventListener("mouseenter", handleMouseEnter);
      element.addEventListener("mouseleave", handleMouseLeave);
    });

    navbarDropdowns.forEach((element) => {
      element.addEventListener("mouseenter", handleMouseEnter);
      element.addEventListener("mouseleave", handleMouseLeave);
    });

    return () => {
      elementsWithChildClass.forEach((element) => {
        element.removeEventListener("mouseenter", handleMouseEnter);
        element.removeEventListener("mouseleave", handleMouseLeave);
      });
      navbarDropdowns.forEach((element) => {
        element.removeEventListener("mouseenter", handleMouseEnter);
        element.removeEventListener("mouseleave", handleMouseLeave);
      });
    };
  }, []); // Empty dependency array ensures the effect runs only once

  /*---------Coloring the navbar white when it is on top and dropdown is visible----------*/

  useEffect(() => {
    if (window.scrollY === 0) {
      const navbarDropdown = document.querySelector(".navbar-dropdown");
      const mobileMenuList = document.querySelector(".mobile-menu-list");

      // Define a function to handle changes in the class name
      const handleClassChange = () => {
        const isDropdownVisible =
          navbarDropdown.classList.contains("dropdown-visible") ||
          mobileMenuList.classList.contains("opened");
        setSticky(isDropdownVisible);
      };

      // Initialize MutationObserver
      const observer = new MutationObserver(handleClassChange);

      // Observe changes to the class attribute of the navbar dropdown and mobile menu list
      observer.observe(navbarDropdown, {
        attributes: true,
        attributeFilter: ["class"],
      });
      observer.observe(mobileMenuList, {
        attributes: true,
        attributeFilter: ["class"],
      });

      // Clean up function to disconnect the observer when component unmounts
      return () => {
        observer.disconnect();
      };
    }
  }, [window.scrollY]); // Empty dependency array ensures that this effect runs only once after initial render

  /*---------Disable scrolling when sidebar === true----------*/

  useEffect(() => {
    if (sidebar) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }

    // Clean up the effect
    return () => {
      document.body.style.overflow = "auto"; // Ensure scrolling is re-enabled when component unmounts
    };
  }, [sidebar]);

  /*---------Using reducer mange the active or inactive menu----------*/
  const initialState = { activeMenu: "" };
  const [state, dispatch] = useReducer(reducer, initialState);

  function reducer(state, action) {
    // switch (action.type) {
    //   case "homeOne":
    //     return { activeMenu: "homeOne" };
    //   case "service":
    //     return { activeMenu: "service" };
    //   case "project":
    //     return { activeMenu: "project" };
    //   case "blog":
    //     return { activeMenu: "blog" };
    //   case "page":
    //     return { activeMenu: "page" };
    //   case "contact":
    //     return { activeMenu: "contact" };
    //   case "home":
    //     return { activeMenu: "home" };
    //   case "technologies":
    //     return { activeMenu: "technologies" };
    //   case "about":
    //     return { activeMenu: "about" };
    //   case "offer":
    //     return { activeMenu: "offer" };
    //   case "softwareDev":
    //     return { activeMenu: "softwareDev" };
    //   case "design":
    //     return { activeMenu: "design" };
    //   default:
    //     return { activeMenu: "" };
    // }
  }

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -50;
    const yOffsetAlt = -100;
    if (el.id === "about") {
      window.scrollTo({ top: yCoordinate + yOffsetAlt, behavior: "smooth" });
    } else {
      window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
    }
  };

  useEffect(() => {
    dispatch({ type: "homeOne" });
    new WOW.WOW({
      live: false,
    }).init();
    window.addEventListener("scroll", handleStickyHeader);
    return () => {
      window.removeEventListener("scroll", () => handleStickyHeader);
    };
  }, []);

  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [menuColor, setMenuColor] = useState("#fdfdfd");

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handleClickOutsideSidebar = () => {
    if (sidebar) {
      setSidebar(false);
    }
  };

  // Header highlights

  // Get all sections that have an ID defined
  const sections = document.querySelectorAll(
    "section[id], footer[id], div[id]"
  );

  function navHighlighter() {
    // Get current scroll position
    let scrollY = window.pageYOffset;

    // Now we loop through sections to get height, top and ID values for each
    sections.forEach((current) => {
      const sectionHeight = current.offsetHeight;
      const sectionTop = current.offsetTop - 150;
      const sectionId = current.getAttribute("id");

      // Select the appropriate navigation elements based on your structure
      const navSelector =
        ".main-nav ul li a#link" +
        sectionId +
        ", .navbar-dropdown ul li a#link" +
        sectionId;

      // Select the appropriate navigation element based on the structure
      const el = document?.querySelector(navSelector);

      if (el) {
        if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
          el.classList.add("active");
        } else {
          el.classList.remove("active");
        }
      }
    });
  }

  const onScroll = () => throttle(navHighlighter, 1000);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    scrollTop();
    setTimeout(() => {
      navHighlighter();
      const linkContactElement = document.getElementById("linkcontact");
      if (linkContactElement) {
        linkContactElement.classList.remove("active");
      }
    }, 500);
  }, [location.pathname]);

  return (
    <>
      <header
        className={`${"header-area position_top"} ${isSticky ? "stick" : ""} ${
          isVisible ? "isShown" : "isCollapsed"
        } ${isAlternative ? "alternative" : ""}`}
        ref={ref}
      >
        <div className="navbar-upper container">
          <div className="site-logo">
            <div className="logo">
              <Link to={"/"} onClick={scrollTop}>
                <svg
                  className="header-1_logo"
                  viewBox="0 0 154 49"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M53.3731 25.3236L48.533 23.7012L47.081 20.8728V25.8498L49.479 26.9461L42.7909 30.3993L42.5049 16.9153L21.7695 0L22.5615 6.86259L26.7746 9.02222L21.7585 9.1757L29.9866 26.5953L25.8175 20.5439L20.9774 21.7279L24.7175 18.9434L22.6825 15.9835L0.616013 34.587L6.66614 36.659L0 36.1985V47.501L3.04706 48.674L8.17317 40.5397L6.98515 46.1526L8.61318 46.4814L12.9253 39.3667L21.3404 41.625L23.2325 46.021L25.2565 45.7908L22.4185 33.71L29.9646 48.6849L32.4287 47.6325L32.0657 31.364L25.3885 27.8012L32.7037 29.7196L33.2867 18.5707L34.1447 30.0047L40.2828 26.4747L36.0148 30.6843L37.2358 34.0937L43.6929 35.3873L53.3731 28.1849L53.3511 25.3236H53.3731ZM36.3118 20.1383L37.0708 19.7437L39.3368 24.611L36.3008 20.1383H36.3118Z"
                    stroke="none"
                  />
                  <path
                    d="M67.5149 22.388C66.6959 21.9408 66.0202 21.2496 65.5902 20.4161C65.1193 19.4607 64.894 18.4036 64.9145 17.3262V12.8743C64.894 11.8172 65.1193 10.7398 65.5902 9.78434C66.0202 8.93055 66.6754 8.23938 67.5149 7.79215C69.3782 6.85704 71.569 6.85704 73.4323 7.79215C74.2718 8.23938 74.927 8.95088 75.357 9.78434C75.8279 10.7398 76.0531 11.7969 76.0327 12.8743V17.3262C76.0531 18.3833 75.8279 19.4404 75.357 20.4161C74.927 21.2699 74.2718 21.9611 73.4323 22.4083C71.569 23.3231 69.3782 23.3231 67.5149 22.388ZM72.4085 20.8227C72.9614 20.5178 73.3913 20.0299 73.6575 19.481C73.9647 18.8305 74.108 18.119 74.108 17.3872V12.793C74.108 12.0815 73.9647 11.3496 73.6575 10.6991C73.3913 10.1299 72.9409 9.66237 72.4085 9.35745C71.2005 8.72726 69.7467 8.72726 68.5182 9.35745C67.9654 9.66237 67.5354 10.1503 67.2487 10.6991C66.9416 11.3496 66.7983 12.0611 66.7983 12.793V17.3872C66.7983 18.0987 66.9416 18.8305 67.2487 19.481C67.5149 20.0502 67.9654 20.5178 68.5182 20.8227C69.7262 21.4529 71.18 21.4529 72.4085 20.8227Z"
                    strokeWidth="0.540944"
                  />
                  <path
                    d="M81.2948 20.0095C81.2948 20.3347 81.3768 20.6396 81.561 20.8836C81.7453 21.0869 82.0115 21.2088 82.2981 21.1885H83.3424V22.9774H82.0729C81.3358 23.0181 80.6192 22.7335 80.1278 22.2049C79.6363 21.5951 79.3906 20.8023 79.4316 20.0095V7.24316H81.3153V20.0095H81.2948Z"
                    strokeWidth="0.540944"
                  />
                  <path
                    d="M86.0042 7.24316H87.888V9.13371H86.0042V7.24316ZM86.0042 11.7358H87.888V22.9368H86.0042V11.7358Z"
                    strokeWidth="0.540944"
                  />
                  <path
                    d="M92.9044 22.5705C92.3106 22.2046 91.8397 21.6557 91.5735 21.0256C91.2459 20.2531 91.1026 19.4196 91.1231 18.5861V16.1467C91.1026 15.2929 91.2459 14.4595 91.5735 13.6666C91.8397 13.0365 92.2902 12.4876 92.8635 12.1014C93.4777 11.7354 94.1944 11.5525 94.911 11.5728C95.6277 11.5728 96.3238 11.7354 96.9586 12.0607C97.5728 12.4063 98.0847 12.9348 98.4123 13.565L98.1871 15.7808C98.1871 15.3336 98.0847 14.8864 97.88 14.4798C97.6752 14.0935 97.3886 13.7683 96.9995 13.565C96.59 13.3414 96.1191 13.2194 95.6277 13.2194C94.8905 13.1788 94.1739 13.4634 93.6825 13.9919C93.1911 14.6018 92.9454 15.3742 93.0068 16.1467V18.5658C92.9659 19.3383 93.1911 20.0905 93.6825 20.68C94.5834 21.5134 95.9553 21.6761 97.02 21.0459C97.3885 20.8223 97.6957 20.497 97.9004 20.1108C98.1052 19.7042 98.2076 19.2367 98.2076 18.7894L98.3509 21.1679C98.1257 21.7574 97.6957 22.2656 97.1633 22.5909C96.5286 22.9364 95.7915 23.1194 95.0748 23.0991C94.3172 23.1194 93.5596 22.9364 92.9044 22.5705ZM92.9454 27.0225C92.3106 26.7379 91.7783 26.2906 91.3893 25.7011L92.6587 24.5627C92.9659 24.9693 93.3344 25.2945 93.7849 25.5385C94.9725 26.1077 96.3648 25.9451 97.3681 25.0913C97.9209 24.4814 98.2076 23.6886 98.1461 22.8755V11.7558H100.03V22.6112C100.03 23.526 99.8456 24.4407 99.4361 25.2539C99.0675 25.9654 98.4942 26.5549 97.7776 26.9208C96.979 27.3274 96.0781 27.5307 95.1567 27.5104C94.3582 27.5104 93.5801 27.3477 92.8635 27.0021H92.9044L92.9454 27.0225Z"
                    strokeWidth="0.540944"
                  />
                  <path
                    d="M103.941 22.2046C103.204 21.5541 102.815 20.5987 102.876 19.6229C102.815 18.6878 103.163 17.7933 103.838 17.1631C104.637 16.533 105.661 16.228 106.664 16.3093H110.145L110.309 17.9153H106.684C106.152 17.8746 105.62 18.0373 105.19 18.3625C104.842 18.6878 104.657 19.1553 104.678 19.6432C104.637 20.1718 104.903 20.7003 105.333 21.0052C105.906 21.3508 106.582 21.5134 107.258 21.4728C107.954 21.4931 108.671 21.4118 109.346 21.1882C109.817 21.0052 110.063 20.7206 110.063 20.3547L110.288 21.8794C110.063 22.1639 109.797 22.3876 109.49 22.5502C109.141 22.7331 108.773 22.8754 108.404 22.9568C107.954 23.0584 107.503 23.0991 107.032 23.0991C105.927 23.1804 104.821 22.8551 103.92 22.2046H103.941ZM110.063 15.8215C110.104 15.1303 109.858 14.4391 109.408 13.9106C108.896 13.4227 108.22 13.1788 107.524 13.2194C107.032 13.2194 106.541 13.3007 106.07 13.443C105.62 13.5853 105.19 13.8089 104.821 14.0935L103.449 13.1584C103.9 12.6299 104.494 12.2233 105.149 11.9794C105.886 11.6948 106.684 11.5525 107.483 11.5525C108.302 11.5321 109.121 11.6948 109.879 12.0404C110.534 12.3453 111.066 12.8332 111.394 13.4634C111.762 14.1545 111.947 14.927 111.906 15.7198V22.9161H110.063V15.8011V15.8215Z"
                    strokeWidth="0.540944"
                  />
                  <path
                    d="M115.264 11.7354H117.168V22.9364H115.264V11.7354ZM122.164 16.1467C122.205 15.3946 121.98 14.6627 121.509 14.0732C121.038 13.565 120.362 13.3007 119.645 13.3414C118.396 13.2194 117.27 14.1342 117.147 15.3742C117.147 15.5369 117.147 15.6792 117.147 15.8418L116.922 13.687C117.229 13.0365 117.721 12.4876 118.335 12.1014C118.929 11.7354 119.625 11.5525 120.321 11.5728C121.038 11.5525 121.734 11.7354 122.328 12.1014C122.901 12.4673 123.351 13.0161 123.597 13.6666C123.904 14.4595 124.048 15.2929 124.027 16.1264V22.9161H122.123V16.1467H122.164ZM129.064 16.1467C129.105 15.3946 128.88 14.6627 128.409 14.0732C127.917 13.565 127.242 13.3007 126.525 13.3414C125.849 13.3007 125.194 13.565 124.723 14.0529C124.273 14.6221 124.027 15.3336 124.088 16.0654L123.536 14.1749C123.843 13.4227 124.334 12.7519 124.989 12.2437C125.604 11.7964 126.361 11.5525 127.119 11.5728C127.856 11.5728 128.573 11.7354 129.207 12.1014C129.801 12.4673 130.252 13.0161 130.538 13.6666C130.866 14.4391 131.03 15.2929 130.989 16.1264V22.9161H129.084V16.1467H129.064Z"
                    strokeWidth="0.540944"
                  />
                  <path
                    d="M132.811 11.7357H134.736L138.319 21.8186L137.295 23.8718L132.791 11.7357H132.811ZM142.803 11.7357L138.012 25.559C137.868 26.0063 137.643 26.3925 137.316 26.7381C137.029 27.043 136.681 27.2666 136.271 27.3886C135.78 27.5309 135.268 27.5919 134.756 27.5919H134.306V25.803H134.756C135.186 25.8233 135.596 25.7217 135.944 25.4777C136.292 25.1931 136.558 24.8069 136.701 24.38L140.878 11.7153H142.762L142.803 11.7357Z"
                    strokeWidth="0.540944"
                  />
                  <path
                    d="M68.4964 48.3945C67.1655 48.1302 65.937 47.5407 64.9132 46.6665L66.1008 45.2029C66.7151 45.7314 67.4112 46.1583 68.1893 46.4226C68.9878 46.7072 69.8273 46.8495 70.6668 46.8292C71.6906 46.8902 72.6939 46.6462 73.5743 46.1177C74.25 45.6704 74.6391 44.898 74.5981 44.1052C74.6186 43.6173 74.4548 43.1091 74.1476 42.7431C73.82 42.3976 73.4105 42.1333 72.9601 41.991C72.3458 41.7877 71.7315 41.6454 71.0968 41.5438H70.9535L70.7078 41.5031C69.8069 41.3608 68.9469 41.1372 68.0869 40.8729C67.4112 40.6493 66.8379 40.2427 66.3875 39.6939C65.8756 39.0027 65.6299 38.1489 65.6913 37.2748C65.6708 36.4007 65.8961 35.5265 66.3465 34.7947C66.797 34.0832 67.4727 33.5343 68.2507 33.2294C69.2131 32.8432 70.2368 32.6602 71.2811 32.7009C71.8339 32.7009 72.4072 32.7619 72.9396 32.8838C73.4924 33.0058 74.0453 33.1888 74.5572 33.4327C75.11 33.697 75.6423 34.0019 76.1338 34.3475L75.0486 35.8315C74.4548 35.4046 73.82 35.059 73.1444 34.815C72.5506 34.5914 71.9158 34.4694 71.2811 34.4694C70.2983 34.4085 69.3359 34.6727 68.5169 35.2013C67.8617 35.6688 67.4931 36.4413 67.5341 37.2544C67.5136 37.7423 67.6774 38.2302 68.0255 38.5961C68.3736 38.9214 68.8036 39.1857 69.254 39.328C69.9297 39.5312 70.6054 39.6939 71.3016 39.8158H71.7315C72.571 39.9785 73.3901 40.2224 74.1886 40.5477C74.8438 40.8119 75.3966 41.2388 75.8266 41.808C76.2976 42.4992 76.5228 43.3123 76.4818 44.1255C76.5023 44.9996 76.2771 45.8534 75.8062 46.5852C75.3148 47.2967 74.6186 47.8253 73.82 48.1505C72.8167 48.5368 71.752 48.7197 70.6873 48.6791C69.9502 48.6791 69.2336 48.6181 68.4964 48.4758V48.3945Z"
                    strokeWidth="0.540944"
                  />
                  <path
                    d="M81.2117 48.0487C80.5155 47.6624 79.9627 47.0932 79.6146 46.3817C79.2256 45.5483 79.0413 44.6538 79.0618 43.739V41.9095C79.0413 41.015 79.2256 40.1205 79.6146 39.3074C79.9627 38.6162 80.5155 38.0267 81.2117 37.6608C82.7883 36.9086 84.6516 36.9086 86.2282 37.6608C86.9243 38.0267 87.4772 38.6162 87.8253 39.3074C88.2143 40.1205 88.3986 41.015 88.3781 41.9095V43.7593C88.3986 44.6538 88.2143 45.5482 87.8253 46.3614C87.4772 47.0729 86.9243 47.6421 86.2282 48.008C84.6516 48.7805 82.7883 48.7805 81.2117 48.008V48.0487ZM85.7572 46.0565C86.2691 45.4263 86.5353 44.6131 86.4739 43.7797V41.9298C86.5353 41.1166 86.2691 40.3035 85.7572 39.6733C84.6106 38.6162 82.8497 38.6162 81.7031 39.6733C81.1912 40.3035 80.925 41.1166 80.9865 41.9298V43.7797C80.925 44.5928 81.1912 45.406 81.7031 46.0565C82.8293 47.1339 84.6311 47.1339 85.7572 46.0565Z"
                    strokeWidth="0.540944"
                  />
                  <path
                    d="M90.6508 37.2542H96.2816V38.8601H90.6508V37.2542ZM91.8384 35.7295C91.777 34.9367 92.0227 34.1236 92.5141 33.4934C93.0464 32.9648 93.7835 32.7006 94.5411 32.7616H96.2816V34.5505H94.7049C94.4183 34.5301 94.1521 34.6318 93.9678 34.8554C93.7835 35.1197 93.7017 35.4246 93.7221 35.7295V48.4755H91.8384V35.7498V35.7295Z"
                    strokeWidth="0.540944"
                  />
                  <path
                    d="M97.7148 37.2545H102.793V38.9214H97.7148V37.2545ZM99.5167 47.7643C99.0457 47.1138 98.8205 46.3413 98.8819 45.5485V34.002H100.766V45.5689C100.745 45.8738 100.827 46.199 101.011 46.443C101.196 46.6463 101.462 46.7479 101.728 46.7479H102.793V48.5368H101.523C100.766 48.5978 100.029 48.3335 99.5167 47.805V47.7643Z"
                    strokeWidth="0.540944"
                  />
                  <path
                    d="M104.861 37.2544H106.847L109.181 45.7924L112.17 37.2544H113.358L116.347 45.7924L118.682 37.2544H120.668L117.126 48.4554H115.61L112.744 40.4053L109.877 48.4554H108.362L104.82 37.2544H104.861Z"
                    strokeWidth="0.540944"
                  />
                  <path
                    d="M123.575 47.7237C122.838 47.0731 122.449 46.1177 122.511 45.1419C122.449 44.2068 122.797 43.3124 123.473 42.6822C124.292 42.0723 125.295 41.7674 126.298 41.8487H129.759L129.923 43.4547H126.339C125.807 43.414 125.275 43.5766 124.845 43.9019C124.497 44.2271 124.292 44.6947 124.333 45.1826C124.292 45.7111 124.558 46.2397 124.988 46.5446C125.561 46.8902 126.237 47.0528 126.913 47.0121C127.609 47.0325 128.326 46.9512 129.001 46.7276C129.472 46.5446 129.718 46.26 129.718 45.8941L129.943 47.4187C129.718 47.683 129.452 47.9269 129.145 48.0896C128.796 48.2725 128.428 48.4148 128.039 48.4961C127.588 48.5978 127.138 48.6384 126.667 48.6384C125.561 48.7197 124.456 48.3945 123.555 47.744L123.575 47.7237ZM129.697 41.3405C129.738 40.6493 129.493 39.9582 129.042 39.4296C128.53 38.9417 127.855 38.6978 127.158 38.7385C126.667 38.7385 126.176 38.8198 125.705 38.9621C125.254 39.1044 124.824 39.328 124.456 39.6126L123.084 38.6775C123.534 38.1489 124.128 37.7424 124.783 37.4984C125.52 37.2138 126.319 37.0715 127.118 37.0715C127.937 37.0512 128.756 37.2138 129.513 37.5594C130.168 37.8643 130.701 38.3522 131.028 38.9824C131.397 39.6736 131.581 40.446 131.54 41.2389V48.4351H129.697V41.3202V41.3405Z"
                    strokeWidth="0.540944"
                  />
                  <path
                    d="M134.898 37.2544H136.782V48.4554H134.898V37.2544ZM140.508 39.0433C140.14 38.9214 139.771 38.8604 139.382 38.8604C138.686 38.8197 137.99 39.0637 137.458 39.5109C136.987 39.9988 136.741 40.6696 136.782 41.3608L136.557 39.206C136.905 38.5758 137.417 38.0269 138.031 37.6407C138.645 37.2748 139.341 37.0918 140.038 37.0918C140.508 37.0918 140.979 37.1528 141.43 37.3154C141.839 37.4577 142.208 37.6813 142.515 37.9659L141.327 39.5719C141.123 39.3279 140.857 39.1653 140.549 39.0433H140.488H140.508Z"
                    strokeWidth="0.540944"
                  />
                  <path
                    d="M146.119 48.0286C145.382 47.6424 144.808 47.0528 144.419 46.321C144.01 45.4672 143.805 44.5524 143.825 43.6173V42.357C143.805 41.3812 143.989 40.4054 144.378 39.511C144.726 38.7588 145.279 38.149 145.996 37.7221C146.774 37.2952 147.675 37.0919 148.555 37.1325C149.395 37.1122 150.234 37.3358 150.951 37.783C151.647 38.2506 152.179 38.9011 152.466 39.6736C152.835 40.6087 153.019 41.6251 152.998 42.6416V43.6173H145.156V42.052H151.279V41.991C151.299 41.1372 151.053 40.3038 150.541 39.6126C150.05 39.0434 149.333 38.7385 148.596 38.7791C147.757 38.7385 146.958 39.0637 146.405 39.6736C145.853 40.3851 145.566 41.2795 145.627 42.1943V43.6173C145.566 44.4914 145.873 45.3452 146.446 45.9958C147.081 46.5853 147.921 46.8699 148.781 46.8292C149.272 46.8292 149.763 46.7276 150.234 46.5243C150.705 46.3413 151.135 46.0567 151.504 45.6908L152.773 46.9105C152.241 47.4391 151.606 47.8456 150.91 48.1506C150.234 48.4352 149.518 48.5775 148.801 48.5978C147.859 48.6385 146.938 48.4352 146.098 48.0286H146.119Z"
                    strokeWidth="0.540944"
                  />
                </svg>
              </Link>
            </div>
          </div>
          <div className="main-menu 1199px:hidden">
            <nav className={sidebar === 1 ? "main-nav slidenav" : "main-nav"}>
              <ul>
                <li>
                  <NavLink
                    exact={true}
                    id="linkhero"
                    onClick={() => {
                      handleNavClick();
                    }}
                    to={`/`}
                  >
                    Home
                  </NavLink>
                </li>
                <li className="has-child" id="design-li">
                  <NavLink
                    exact={true}
                    id="linkdesign"
                    onClick={() => {
                      handleNavClick();
                      dispatch({ type: "design" });
                    }}
                    to={`${process.env.PUBLIC_URL}/design`}
                  >
                    Design
                  </NavLink>
                  <i id="chevron" className="bi bi-chevron-down" />
                </li>
                <li className="has-child" id="offer-li">
                  <HashLink
                    id="linkoffer"
                    onClick={() => {
                      handleNavClick();
                      dispatch({ type: "offer" });
                    }}
                    smooth
                    to="/#offer"
                    scroll={(el) => scrollWithOffset(el)}
                  >
                    Offer
                  </HashLink>
                  <i id="chevron" className="bi bi-chevron-down" />
                </li>
                <li className="has-child" id="about-li">
                  <NavLink
                    id="linkaboutus"
                    onClick={() => {
                      handleNavClick();
                    }}
                    to={`/about-us`}
                  >
                    About
                  </NavLink>
                  <i id="chevron" className="bi bi-chevron-down" />
                </li>
                <li>
                  <NavLink
                    onClick={handleNavClick}
                    to={`${process.env.PUBLIC_URL}/design/blog`}
                  >
                    Blog
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={handleNavClick} to="/contact">
                    Contact
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>

          <div className="nav-right hidden 1199px:block">
            <div className="mobile-menu">
              <Link to="#" onClick={handleSidbarMenu} className={"cross-btn"}>
                <span className="cross-top" />
                <span className="cross-middle" />
                <span className="cross-bottom" />
              </Link>
            </div>
          </div>
        </div>
        <div
          className={`navbar-dropdown ${
            isDropdownVisible ? "dropdown-visible" : "dropdown-hidden"
          } 1199px:hidden`}
        >
          <div className="container flex justify-end">
            {dropdownOptions[hoveredOptionName]?.map((option, index) => (
              <ul
                className={`min-w-[486px] ${option.isGrouped ? "grouped" : ""}`}
                key={`dropdown-${index}`}
              >
                {option.isGrouped ? (
                  <div className="groups-wrapper" key={`group-${index}`}>
                    {option.groups.map((group, groupIndex) => (
                      <div
                        className="group"
                        key={`group-${index}-${groupIndex}`}
                      >
                        {group.name && (
                          <li
                            className="group-name"
                            key={`group-name-${index}-${groupIndex}`}
                          >
                            {group.name}
                          </li>
                        )}
                        {group.items.map((item, itemIndex) => (
                          <li
                            key={`group-item-${index}-${groupIndex}-${itemIndex}`}
                          >
                            <NavLink
                              id={`link${item.title}`}
                              exact={true}
                              onClick={handleNavClick}
                              to={`${process.env.PUBLIC_URL}${item.url}`}
                            >
                              {item.title}
                              <svg
                                width="12"
                                height="10"
                                viewBox="0 0 12 10"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M7.35938 0.390625L6.64062 1.10938L10.0312 4.5H0V5.5H10.0312L6.64062 8.89062L7.35938 9.60938L11.6094 5.35938L11.9531 5L11.6094 4.64062L7.35938 0.390625Z" />
                              </svg>
                            </NavLink>
                          </li>
                        ))}
                      </div>
                    ))}
                  </div>
                ) : (
                  <React.Fragment key={`non-grouped-${index}`}>
                    {option.items.map((item, itemIndex) => (
                      <li key={`non-grouped-item-${index}-${itemIndex}`}>
                        <NavLink
                          id={`link${item.title}`}
                          exact={true}
                          onClick={handleNavClick}
                          to={`${process.env.PUBLIC_URL}${item.url}`}
                        >
                          {item.title}
                          <svg
                            width="12"
                            height="10"
                            viewBox="0 0 12 10"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7.35938 0.390625L6.64062 1.10938L10.0312 4.5H0V5.5H10.0312L6.64062 8.89062L7.35938 9.60938L11.6094 5.35938L11.9531 5L11.6094 4.64062L7.35938 0.390625Z" />
                          </svg>
                        </NavLink>
                      </li>
                    ))}
                  </React.Fragment>
                )}
              </ul>
            ))}
          </div>
        </div>
      </header>
      <div
        onClick={handleClickOutsideSidebar}
        className={`mobile-blur ${sidebar ? "open" : ""}`}
      ></div>
      <MobileMenu
        sidebar={sidebar}
        handleNavClick={handleNavClick}
        scrollWithOffset={scrollWithOffset}
      />
    </>
  );
}

export default Header;
