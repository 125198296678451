import React, { useEffect, useRef, useState } from "react";
import ProgressBar from "./ProgressBar";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { submitBriefData } from "../../../api/action.submitBriefData";
import { Link } from "react-router-dom";

const questionMapping = {
  step1: {
    question: "What is the goal of your project?",
    number: 1,
  },
  step2: [
    {
      question:
        "Do you already have any guidelines regarding the appearance (e.g. logo, colors) of the project?",
      number: 2,
    },
    {
      question:
        "Do you have specific inspirations or examples of websites/apps that you like?",
      number: 3,
    },
  ],
  step3: {
    question:
      "Do you have any special features or interactions you expect from the design?",
    number: 4,
  },
  step4: [
    {
      question:
        "Do you have content for your website/application, including texts, images, and multimedia, or do you need help with its creation (copywriting, graphic design, animations/multimedia) and promotion (SEO, Social Media)?",
      number: 5,
    },
    {
      question: "Do you need content promotion?",
      number: 6,
    },
  ],
  step5: {
    question: "Describe your needs if you want",
    number: 7,
  },
};

function SummaryScreen({
  progress,
  userResponses,
  handleAbandonFormToggle,
  isProgressVisible,
  handlePreviousStep,
}) {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  // Exporting to pdf

  const [pdfHidden, setpdfHidden] = useState(false);
  const downloadPDF = () => {
    const hiddenContainer = document.createElement("div");
    hiddenContainer.style.width = "1024px";
    hiddenContainer.style.height = "auto";
    hiddenContainer.style.position = "absolute";
    hiddenContainer.style.top = "-10000px";
    hiddenContainer.style.left = "-10000px";

    const clonedNode = pdfRef.current.cloneNode(true);
    hiddenContainer.appendChild(clonedNode);
    document.body.appendChild(hiddenContainer);

    const elementsToHide = hiddenContainer.querySelectorAll(
      ".generate-pdf, .go-back-btn, .progress-bar-container, .lower-text"
    );
    elementsToHide.forEach((element) => {
      element.style.display = "none";
    });

    const contactInfo = hiddenContainer.querySelector(".contact-info");
    if (contactInfo) {
      contactInfo.style.display = "flex";
      contactInfo.style.flexDirection = "row";
      contactInfo.style.alignItems = "start";
      contactInfo.style.justifyContent = "space-between";
    }

    html2canvas(hiddenContainer, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 20;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      pdf.save("Oligamy-Intro-Brief.pdf");

      document.body.removeChild(hiddenContainer);
    });
  };

  const handlePDFGeneration = () => {
    setpdfHidden(true);
    setTimeout(() => {
      downloadPDF();
      setpdfHidden(false);
    }, 1000);
  };

  const pdfRef = useRef();
  // POST to api

  const briefData = {
    question_one: questionMapping.step1.question,
    question_two: questionMapping.step2[0].question,
    question_three: questionMapping.step2[1].question,
    question_four: questionMapping.step3.question,
    question_five: questionMapping.step4[0].question,
    question_six: questionMapping.step4[1].question,
    question_seven: questionMapping.step5.question,
    answer_one: Object.keys(userResponses.step1)
      .map((key) => {
        if (key === "textareaValue") {
          return userResponses.step1[key];
        }
        return Array.isArray(userResponses.step1[key])
          ? userResponses.step1[key].join(", ")
          : userResponses.step1[key];
      })
      .join(", "),
    answer_two: Array.isArray(userResponses.step2.section1Option)
      ? userResponses.step2.section1Option.join(", ")
      : userResponses.step2.section1Option,
    answer_three: Array.isArray(userResponses.step2.section2Option)
      ? userResponses.step2.section2Option.join(", ")
      : userResponses.step2.section2Option,
    answer_four: Object.keys(userResponses.step3)
      .map((key) => {
        if (key === "textareaValue") {
          return userResponses.step3[key];
        }
        return Array.isArray(userResponses.step3[key])
          ? userResponses.step3[key].join(", ")
          : userResponses.step3[key];
      })
      .join(", "),
    answer_five: Array.isArray(userResponses.step4.section1Option)
      ? userResponses.step4.section1Option.join(", ")
      : userResponses.step4.section1Option,
    answer_six: Array.isArray(userResponses.step4.section2Option)
      ? userResponses.step4.section2Option.join(", ")
      : userResponses.step4.section2Option,
    ...(userResponses.step5.textareaValue !== "" && {
      answer_seven: userResponses.step5.textareaValue,
      email: userResponses.step5.emailValue,
    }),
  };

  const handleSubmit = async () => {
    const response = await submitBriefData(briefData);

    if (response.success) {
      // Handle successful form submission
      // setUserResponses({
      //   step1: { selectedOptions: [], textareaValue: null },
      //   step2: { section1Option: [], section2Option: [] },
      //   step3: { selectedOptions: [], textareaValue: null },
      //   step4: { section1Option: [], section2Option: [] },
      //   step5: { textareaValue: null },
      // });
    } else {
      // Handle form submission errors
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const [responsesArray, setResponsesArray] = useState([]);

  useEffect(() => {
    const newArray = Object.entries(userResponses).map(([step, values]) => ({
      step,
      ...values,
    }));

    const textareaValue = newArray[4]?.textareaValue?.trim();

    if (textareaValue === "" || /^\s+$/.test(textareaValue)) {
      // If textareaValue is empty or consists only of blank spaces, remove the element at index 4
      newArray.splice(4, 1);
    }

    setResponsesArray(newArray);
  }, [userResponses]);

  return (
    <section className="page-section" ref={pdfRef}>
      <div className="container max-w-[1320px] brief summary">
        <Link
          to={`${process.env.PUBLIC_URL}/`}
          className={"go-back-link inline-block"}
          onClick={scrollTop}
        >
          <div
            className={`go-back-btn mt-[136px] flex items-center whitespace-nowrap gap-[16px] opacity-${
              pdfHidden ? "0" : "1"
            }`}
            // onClick={handleAbandonFormToggle}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              className={"min-w-[12px] min-h-[12px]"}
            >
              <path
                d="M5.99935 11.3337L0.666016 6.00033L5.99935 0.666992L6.94935 1.60033L3.21602 5.33366H11.3327V6.66699H3.21602L6.94935 10.4003L5.99935 11.3337Z"
                fill="#6C6C6C"
              />
            </svg>
            <p className={`text-[#6c6c6c]`}>Go to homepage</p>
          </div>
        </Link>
        <div className="upper mt-[24px]">
          <div className="flex justify-between items-center">
            <h1 className="text-[61px] font-[500] text-[#0e2933] tracking-[-2.44px] 500px:text-[39px] 500px:font-400 500px:tracking-[-1.56px] leading-[110%] 799px:text-[40px">
              Customer expectations brief
            </h1>
            <button
              className={`generate-pdf 500px:hidden opacity-${
                pdfHidden ? "0" : "1"
              }`}
              onClick={handlePDFGeneration}
            >
              Generate PDF
            </button>
          </div>
          <h4 className="mt-[16px] text-[#173d46] text-[24px] tracking-[-0.25px] font-[400] 500px:text-[22px] 500px:leading-[120%]">
            Your form has been successfully submitted to Oligamy Software!
          </h4>
          <div className={`opacity-${pdfHidden ? "0" : "1"}`}>
            <ProgressBar
              progress={progress}
              isProgressVisible={isProgressVisible}
            />
          </div>
          <button
            className={`generate-pdf hidden 500px:block w-full mt-[24px] opacity-${
              pdfHidden ? "0" : "1"
            }`}
            onClick={downloadPDF}
          >
            Generate PDF
          </button>
        </div>
        <div className="lower mt-[72px]">
          <div className="header flex justify-between items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="128"
              height="49"
              viewBox="0 0 128 49"
              fill="none"
            >
              <g clipPath="url(#clip0_2771_76226)">
                <path
                  d="M52.7391 24.8769L47.9565 23.2831L46.5217 20.5046V25.3938L48.8913 26.4708L42.2826 29.8631L42 16.6169L21.5109 0L22.2935 6.74154L26.4565 8.86308L21.5 9.01385L29.6304 26.1262L25.5109 20.1815L20.7283 21.3446L24.4239 18.6092L22.413 15.7015L0.608696 33.9769L6.58696 36.0123L0 35.56V46.6631L3.01087 47.8154L8.07609 39.8246L6.90217 45.3385L8.51087 45.6615L12.7717 38.6723L21.087 40.8908L22.9565 45.2092L24.9565 44.9831L22.1522 33.1154L29.6087 47.8262L32.0435 46.7923L31.6848 30.8108L25.087 27.3108L32.3152 29.1954L32.8913 18.2431L33.7391 29.4754L39.8043 26.0077L35.587 30.1431L36.7935 33.4923L43.1739 34.7631L52.7391 27.6877L52.7174 24.8769H52.7391ZM35.8804 19.7831L36.6304 19.3954L38.8696 24.1769L35.8696 19.7831H35.8804Z"
                  fill="#2C7789"
                />
                <path
                  d="M37.4788 46.2537C37.044 46.0168 36.6853 45.6507 36.457 45.2091C36.207 44.703 36.0875 44.143 36.0983 43.5722V41.2137C36.0875 40.6537 36.207 40.083 36.457 39.5768C36.6853 39.1245 37.0331 38.7584 37.4788 38.5214C38.4679 38.0261 39.6309 38.0261 40.6201 38.5214C41.0657 38.7584 41.4136 39.1353 41.6418 39.5768C41.8918 40.083 42.0114 40.643 42.0005 41.2137V43.5722C42.0114 44.1322 41.8918 44.6922 41.6418 45.2091C41.4136 45.6614 41.0657 46.0276 40.6201 46.2645C39.6309 46.7491 38.4679 46.7491 37.4788 46.2537ZM40.0766 45.4245C40.3701 45.263 40.5983 45.0045 40.7396 44.7137C40.9027 44.3691 40.9788 43.9922 40.9788 43.6045V41.1707C40.9788 40.7937 40.9027 40.4061 40.7396 40.0614C40.5983 39.7599 40.3592 39.5122 40.0766 39.3507C39.4353 39.0168 38.6636 39.0168 38.0114 39.3507C37.7179 39.5122 37.4896 39.7707 37.3375 40.0614C37.1744 40.4061 37.0983 40.783 37.0983 41.1707V43.6045C37.0983 43.9814 37.1744 44.3691 37.3375 44.7137C37.4788 45.0153 37.7179 45.263 38.0114 45.4245C38.6527 45.7584 39.4244 45.7584 40.0766 45.4245Z"
                  fill="#2C7789"
                />
                <path
                  d="M44.7942 44.9935C44.7942 45.1659 44.8377 45.3274 44.9355 45.4566C45.0333 45.5643 45.1746 45.6289 45.3268 45.6182H45.8812V46.5659H45.2072C44.8159 46.5874 44.4355 46.4366 44.1746 46.1566C43.9138 45.8335 43.7833 45.4135 43.8051 44.9935V38.2305H44.8051V44.9935H44.7942Z"
                  fill="#2C7789"
                />
                <path
                  d="M47.2949 38.2305H48.2949V39.232H47.2949V38.2305ZM47.2949 40.6105H48.2949V46.5443H47.2949V40.6105Z"
                  fill="#2C7789"
                />
                <path
                  d="M50.9564 46.3504C50.6412 46.1566 50.3912 45.8658 50.2499 45.5319C50.076 45.1227 49.9999 44.6812 50.0108 44.2396V42.9473C49.9999 42.495 50.076 42.0535 50.2499 41.6335C50.3912 41.2996 50.6303 41.0089 50.9347 40.8042C51.2608 40.6104 51.6412 40.5135 52.0217 40.5242C52.4021 40.5242 52.7717 40.6104 53.1086 40.7827C53.4347 40.9658 53.7064 41.2458 53.8803 41.5796L53.7608 42.7535C53.7608 42.5166 53.7064 42.2796 53.5977 42.0642C53.489 41.8596 53.3369 41.6873 53.1304 41.5796C52.913 41.4612 52.663 41.3966 52.4021 41.3966C52.0108 41.375 51.6303 41.5258 51.3695 41.8058C51.1086 42.1289 50.9782 42.5381 51.0108 42.9473V44.2289C50.989 44.6381 51.1086 45.0366 51.3695 45.3489C51.8477 45.7904 52.576 45.8766 53.1412 45.5427C53.3369 45.4242 53.4999 45.2519 53.6086 45.0473C53.7173 44.8319 53.7717 44.5842 53.7717 44.3473L53.8477 45.6073C53.7282 45.9196 53.4999 46.1889 53.2173 46.3612C52.8803 46.5442 52.489 46.6412 52.1086 46.6304C51.7064 46.6412 51.3043 46.5442 50.9564 46.3504ZM50.9782 48.7089C50.6412 48.5581 50.3586 48.3212 50.1521 48.0089L50.826 47.4058C50.989 47.6212 51.1847 47.7935 51.4238 47.9227C52.0543 48.2242 52.7934 48.1381 53.326 47.6858C53.6195 47.3627 53.7717 46.9427 53.739 46.5119V40.6212H54.739V46.3719C54.739 46.8566 54.6412 47.3412 54.4238 47.7719C54.2282 48.1489 53.9238 48.4612 53.5434 48.655C53.1195 48.8704 52.6412 48.9781 52.1521 48.9673C51.7282 48.9673 51.3151 48.8812 50.9347 48.6981H50.9564L50.9782 48.7089Z"
                  fill="#2C7789"
                />
                <path
                  d="M56.815 46.1567C56.4237 45.8121 56.2172 45.3059 56.2498 44.789C56.2172 44.2936 56.402 43.8197 56.7607 43.4859C57.1846 43.1521 57.728 42.9905 58.2606 43.0336H60.1085L60.1954 43.8844H58.2715C57.9889 43.8628 57.7063 43.949 57.478 44.1213C57.2933 44.2936 57.1954 44.5413 57.2063 44.7997C57.1846 45.0797 57.3259 45.3597 57.5541 45.5213C57.8585 45.7044 58.2172 45.7905 58.5759 45.769C58.9454 45.7797 59.3259 45.7367 59.6846 45.6182C59.9346 45.5213 60.065 45.3705 60.065 45.1767L60.1846 45.9844C60.065 46.1351 59.9237 46.2536 59.7606 46.3397C59.5759 46.4367 59.3802 46.5121 59.1846 46.5551C58.9454 46.609 58.7063 46.6305 58.4563 46.6305C57.8693 46.6736 57.2824 46.5013 56.8041 46.1567H56.815ZM60.065 42.7751C60.0867 42.409 59.9563 42.0428 59.7172 41.7628C59.4454 41.5044 59.0867 41.3751 58.7172 41.3967C58.4563 41.3967 58.1954 41.4397 57.9454 41.5151C57.7063 41.5905 57.478 41.709 57.2824 41.8597L56.5541 41.3644C56.7933 41.0844 57.1085 40.869 57.4563 40.7397C57.8476 40.589 58.2715 40.5136 58.6954 40.5136C59.1302 40.5028 59.565 40.589 59.9672 40.7721C60.315 40.9336 60.5976 41.1921 60.7715 41.5259C60.9672 41.8921 61.065 42.3013 61.0433 42.7213V46.5336H60.065V42.7644V42.7751Z"
                  fill="#2C7789"
                />
                <path
                  d="M62.8262 40.6104H63.837V46.5442H62.8262V40.6104ZM66.4892 42.9473C66.511 42.5489 66.3914 42.1612 66.1414 41.8489C65.8914 41.5796 65.5327 41.4396 65.1523 41.4612C64.4892 41.3966 63.8914 41.8812 63.8262 42.5381C63.8262 42.6242 63.8262 42.6996 63.8262 42.7858L63.7066 41.6442C63.8697 41.2996 64.1305 41.0089 64.4566 40.8042C64.7718 40.6104 65.1414 40.5135 65.511 40.5242C65.8914 40.5135 66.261 40.6104 66.5762 40.8042C66.8805 40.9981 67.1197 41.2889 67.2501 41.6335C67.4131 42.0535 67.4892 42.495 67.4783 42.9366V46.5335H66.4675V42.9473H66.4892ZM70.1523 42.9473C70.174 42.5489 70.0544 42.1612 69.8044 41.8489C69.5436 41.5796 69.1849 41.4396 68.8044 41.4612C68.4457 41.4396 68.0979 41.5796 67.8479 41.8381C67.6088 42.1396 67.4783 42.5166 67.511 42.9042L67.2175 41.9027C67.3805 41.5042 67.6414 41.1489 67.9892 40.8796C68.3153 40.6427 68.7175 40.5135 69.1197 40.5242C69.511 40.5242 69.8914 40.6104 70.2283 40.8042C70.5436 40.9981 70.7827 41.2889 70.9349 41.6335C71.1088 42.0427 71.1957 42.495 71.174 42.9366V46.5335H70.1631V42.9473H70.1523Z"
                  fill="#2C7789"
                />
                <path
                  d="M72.1417 40.6109H73.1635L75.0656 45.9524L74.5222 47.0401L72.1309 40.6109H72.1417ZM77.4461 40.6109L74.9026 47.9339C74.8265 48.1709 74.7069 48.3755 74.533 48.5586C74.3809 48.7201 74.1961 48.8386 73.9787 48.9032C73.7178 48.9786 73.4461 49.0109 73.1743 49.0109H72.9352V48.0632H73.1743C73.4026 48.0739 73.62 48.0201 73.8048 47.8909C73.9896 47.7401 74.1309 47.5355 74.2069 47.3093L76.4243 40.6001H77.4243L77.4461 40.6109Z"
                  fill="#2C7789"
                />
                <path
                  d="M83.1307 46.5121C82.4242 46.3721 81.772 46.0598 81.2285 45.5967L81.8589 44.8213C82.185 45.1013 82.5546 45.3275 82.9676 45.4675C83.3916 45.6183 83.8372 45.6936 84.2829 45.6829C84.8263 45.7152 85.3589 45.5859 85.8263 45.3059C86.185 45.069 86.3916 44.6598 86.3698 44.2398C86.3807 43.9813 86.2937 43.7121 86.1307 43.5183C85.9568 43.3352 85.7394 43.1952 85.5003 43.1198C85.1742 43.0121 84.8481 42.9367 84.5111 42.8829H84.435L84.3046 42.8613C83.8263 42.7859 83.3698 42.6675 82.9133 42.5275C82.5546 42.409 82.2502 42.1936 82.0111 41.9029C81.7394 41.5367 81.6089 41.0844 81.6416 40.6213C81.6307 40.1583 81.7503 39.6952 81.9894 39.3075C82.2285 38.9306 82.5872 38.6398 83.0003 38.4783C83.5111 38.2736 84.0546 38.1767 84.6089 38.1983C84.9024 38.1983 85.2068 38.2306 85.4894 38.2952C85.7829 38.3598 86.0763 38.4567 86.3481 38.586C86.6416 38.726 86.9242 38.8875 87.185 39.0706L86.6089 39.8567C86.2937 39.6306 85.9568 39.4475 85.5981 39.3183C85.2829 39.1998 84.9459 39.1352 84.6089 39.1352C84.0872 39.1029 83.5763 39.2429 83.1416 39.5229C82.7937 39.7706 82.5981 40.1798 82.6198 40.6106C82.6089 40.869 82.6959 41.1275 82.8807 41.3213C83.0655 41.4936 83.2937 41.6336 83.5329 41.709C83.8916 41.8167 84.2502 41.9029 84.6198 41.9675H84.8481C85.2937 42.0536 85.7285 42.1829 86.1524 42.3552C86.5003 42.4952 86.7937 42.7213 87.022 43.0229C87.272 43.389 87.3916 43.8198 87.3698 44.2506C87.3807 44.7136 87.2611 45.1659 87.0111 45.5536C86.7503 45.9306 86.3807 46.2106 85.9568 46.3829C85.4242 46.5875 84.859 46.6844 84.2937 46.6629C83.9024 46.6629 83.522 46.6306 83.1307 46.5552V46.5121Z"
                  fill="#2C7789"
                />
                <path
                  d="M89.8804 46.3293C89.5108 46.1247 89.2174 45.8231 89.0326 45.4462C88.8261 45.0047 88.7282 44.5308 88.7391 44.0462V43.077C88.7282 42.6031 88.8261 42.1293 89.0326 41.6985C89.2174 41.3323 89.5108 41.02 89.8804 40.8262C90.7174 40.4277 91.7065 40.4277 92.5434 40.8262C92.913 41.02 93.2065 41.3323 93.3913 41.6985C93.5978 42.1293 93.6956 42.6031 93.6848 43.077V44.057C93.6956 44.5308 93.5978 45.0047 93.3913 45.4354C93.2065 45.8123 92.913 46.1139 92.5434 46.3077C91.7065 46.717 90.7174 46.717 89.8804 46.3077V46.3293ZM92.2934 45.2739C92.5652 44.94 92.7065 44.5093 92.6739 44.0677V43.0877C92.7065 42.657 92.5652 42.2262 92.2934 41.8923C91.6848 41.3323 90.75 41.3323 90.1413 41.8923C89.8695 42.2262 89.7282 42.657 89.7608 43.0877V44.0677C89.7282 44.4985 89.8695 44.9293 90.1413 45.2739C90.7391 45.8447 91.6956 45.8447 92.2934 45.2739Z"
                  fill="#2C7789"
                />
                <path
                  d="M94.8926 40.6106H97.8817V41.4614H94.8926V40.6106ZM95.523 39.803C95.4904 39.383 95.6208 38.9522 95.8817 38.6183C96.1643 38.3383 96.5556 38.1983 96.9578 38.2306H97.8817V39.1783H97.0447C96.8926 39.1676 96.7513 39.2214 96.6534 39.3399C96.5556 39.4799 96.5121 39.6414 96.523 39.803V46.5553H95.523V39.8137V39.803Z"
                  fill="#2C7789"
                />
                <path
                  d="M98.6426 40.6108H101.338V41.4938H98.6426V40.6108ZM99.5991 46.1785C99.3491 45.8338 99.2295 45.4246 99.2621 45.0046V38.8877H100.262V45.0154C100.251 45.1769 100.295 45.3492 100.393 45.4785C100.49 45.5862 100.632 45.64 100.773 45.64H101.338V46.5877H100.664C100.262 46.62 99.8708 46.48 99.5991 46.2V46.1785Z"
                  fill="#2C7789"
                />
                <path
                  d="M102.436 40.6104H103.49L104.729 45.1334L106.316 40.6104H106.947L108.534 45.1334L109.773 40.6104H110.827L108.947 46.5442H108.142L106.621 42.2796L105.099 46.5442H104.294L102.414 40.6104H102.436Z"
                  fill="#2C7789"
                />
                <path
                  d="M112.37 46.1567C111.978 45.8121 111.772 45.3059 111.804 44.789C111.772 44.2936 111.957 43.8197 112.315 43.4859C112.75 43.1628 113.283 43.0013 113.815 43.0444H115.652L115.739 43.8951H113.837C113.554 43.8736 113.272 43.9597 113.044 44.1321C112.859 44.3044 112.75 44.5521 112.772 44.8105C112.75 45.0905 112.891 45.3705 113.12 45.5321C113.424 45.7151 113.783 45.8013 114.141 45.7797C114.511 45.7905 114.891 45.7474 115.25 45.629C115.5 45.5321 115.631 45.3813 115.631 45.1874L115.75 45.9951C115.631 46.1351 115.489 46.2644 115.326 46.3505C115.141 46.4474 114.946 46.5228 114.739 46.5659C114.5 46.6198 114.261 46.6413 114.011 46.6413C113.424 46.6844 112.837 46.5121 112.359 46.1674L112.37 46.1567ZM115.62 42.7751C115.641 42.409 115.511 42.0428 115.272 41.7628C115 41.5044 114.641 41.3751 114.272 41.3967C114.011 41.3967 113.75 41.4397 113.5 41.5151C113.261 41.5905 113.033 41.709 112.837 41.8597L112.109 41.3644C112.348 41.0844 112.663 40.869 113.011 40.7397C113.402 40.589 113.826 40.5136 114.25 40.5136C114.685 40.5028 115.12 40.589 115.522 40.7721C115.87 40.9336 116.152 41.1921 116.326 41.5259C116.522 41.8921 116.62 42.3013 116.598 42.7213V46.5336H115.62V42.7644V42.7751Z"
                  fill="#2C7789"
                />
                <path
                  d="M118.381 40.6106H119.381V46.5444H118.381V40.6106ZM121.359 41.5583C121.163 41.4936 120.968 41.4613 120.761 41.4613C120.392 41.4398 120.022 41.569 119.74 41.806C119.49 42.0644 119.359 42.4198 119.381 42.786L119.261 41.6444C119.446 41.3106 119.718 41.0198 120.044 40.8152C120.37 40.6213 120.74 40.5244 121.109 40.5244C121.359 40.5244 121.609 40.5567 121.848 40.6429C122.066 40.7183 122.261 40.8367 122.424 40.9875L121.794 41.8383C121.685 41.709 121.544 41.6229 121.381 41.5583H121.348H121.359Z"
                  fill="#2C7789"
                />
                <path
                  d="M124.337 46.3185C123.946 46.1138 123.642 45.8015 123.435 45.4138C123.218 44.9615 123.109 44.4769 123.12 43.9815V43.3138C123.109 42.7969 123.207 42.28 123.413 41.8062C123.598 41.4077 123.892 41.0846 124.272 40.8585C124.685 40.6323 125.163 40.5246 125.631 40.5462C126.076 40.5354 126.522 40.6538 126.903 40.8908C127.272 41.1385 127.555 41.4831 127.707 41.8923C127.903 42.3877 128 42.9262 127.99 43.4646V43.9815H123.826V43.1523H127.076V43.12C127.087 42.6677 126.957 42.2262 126.685 41.86C126.424 41.5585 126.044 41.3969 125.653 41.4185C125.207 41.3969 124.783 41.5692 124.49 41.8923C124.196 42.2692 124.044 42.7431 124.076 43.2277V43.9815C124.044 44.4446 124.207 44.8969 124.511 45.2415C124.848 45.5538 125.294 45.7046 125.75 45.6831C126.011 45.6831 126.272 45.6292 126.522 45.5215C126.772 45.4246 127 45.2738 127.196 45.08L127.87 45.7262C127.587 46.0062 127.25 46.2215 126.881 46.3831C126.522 46.5338 126.142 46.6092 125.761 46.62C125.261 46.6415 124.772 46.5338 124.326 46.3185H124.337Z"
                  fill="#2C7789"
                />
              </g>
              <defs>
                <clipPath id="clip0_2771_76226">
                  <rect width="128" height="49" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <div>
              <p className="text-[#2c7789]">Oligamy Software</p>
              <a
                href={"mailto:hello@oligamy.com"}
                className={"underlineless text-[#2c7789]"}
              >
                hello@oligamy.com
              </a>
            </div>
          </div>
          <div className="summary mt-[24px]">
            <h5 className="text-left text-[#2c7789] normal-case text-[18px] font-500">
              Thank you for completing the brief! We will reach out to you soon.
            </h5>
            <p className="text-[#878787] text-[14px] leading-[150%] mt-[8px] description">
              What you see now is a summary of your responses. If you wish, you
              can generate a PDF file for your records. Feel free to reach out
              with any additional questions or information. If you need further
              assistance or want to discuss details, don't hesitate to contact
              us at <a href="mailto:hello@oligamy.com">hello@oligamy.com</a>. We
              are here to help!
            </p>
          </div>
          <div className="summary-tile mt-[48px]">
            <div className="upper w-full">
              <h2 className="ml-[16px] text-[#fdfdfd] text-[24px] font-600">
                Answer database
              </h2>
            </div>
            <div className="answers mt-[16px]">
              {responsesArray.map((response, index) => {
                if (index === 1 || index === 3) {
                  // Render two .answer divs for indices 1 and 3
                  return (
                    <React.Fragment key={index}>
                      <div className="answer" key={index}>
                        <div className="upper">
                          <h3 className="">
                            {questionMapping[response.step][0].number}.{" "}
                            {questionMapping[response.step][0].question}
                          </h3>
                        </div>
                        {Array.isArray(response.section1Option) ? (
                          response.section1Option.map((link, index) => (
                            <div
                              className="lower mt-[8px] flex items-center gap-[8px]"
                              key={index}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  d="M7.33301 11.3337H4.66634C3.74412 11.3337 2.9579 11.0085 2.30767 10.3583C1.65745 9.7081 1.33256 8.9221 1.33301 8.00033C1.33301 7.0781 1.65812 6.29188 2.30834 5.64166C2.95856 4.99144 3.74456 4.66655 4.66634 4.66699H7.33301V6.00033H4.66634C4.11079 6.00033 3.63856 6.19477 3.24968 6.58366C2.86079 6.97255 2.66634 7.44477 2.66634 8.00033C2.66634 8.55588 2.86079 9.0281 3.24968 9.41699C3.63856 9.80588 4.11079 10.0003 4.66634 10.0003H7.33301V11.3337ZM5.33301 8.66699V7.33366H10.6663V8.66699H5.33301ZM8.66634 11.3337V10.0003H11.333C11.8886 10.0003 12.3608 9.80588 12.7497 9.41699C13.1386 9.0281 13.333 8.55588 13.333 8.00033C13.333 7.44477 13.1386 6.97255 12.7497 6.58366C12.3608 6.19477 11.8886 6.00033 11.333 6.00033H8.66634V4.66699H11.333C12.2552 4.66699 13.0415 4.9921 13.6917 5.64233C14.3419 6.29255 14.6668 7.07855 14.6663 8.00033C14.6663 8.92255 14.3412 9.70877 13.691 10.359C13.0408 11.0092 12.2548 11.3341 11.333 11.3337H8.66634Z"
                                  fill="#878787"
                                />
                              </svg>
                              <p className="text-[14px] text-[#878787] font-500 leading-[150%]">
                                {link}
                              </p>
                            </div>
                          ))
                        ) : (
                          <div className="lower mt-[8px] flex items-center gap-[8px]">
                            <div className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                                fill="none"
                              >
                                <path
                                  d="M6.36641 12.5001L2.56641 8.70007L3.51641 7.75007L6.36641 10.6001L12.4831 4.4834L13.4331 5.4334L6.36641 12.5001Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                            <p className="text-[14px] text-[#878787] font-500 leading-[150%]">
                              {response.section1Option}
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="answer" key={index + "_extra"}>
                        <div className="upper">
                          <h3 className="">
                            {questionMapping[response.step][1].number}.{" "}
                            {questionMapping[response.step][1].question}
                          </h3>
                        </div>
                        {Array.isArray(response.section2Option) ? (
                          response.section2Option.map((link, index) => (
                            <div
                              className="lower mt-[8px] flex items-center gap-[8px]"
                              key={index}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  d="M7.33301 11.3337H4.66634C3.74412 11.3337 2.9579 11.0085 2.30767 10.3583C1.65745 9.7081 1.33256 8.9221 1.33301 8.00033C1.33301 7.0781 1.65812 6.29188 2.30834 5.64166C2.95856 4.99144 3.74456 4.66655 4.66634 4.66699H7.33301V6.00033H4.66634C4.11079 6.00033 3.63856 6.19477 3.24968 6.58366C2.86079 6.97255 2.66634 7.44477 2.66634 8.00033C2.66634 8.55588 2.86079 9.0281 3.24968 9.41699C3.63856 9.80588 4.11079 10.0003 4.66634 10.0003H7.33301V11.3337ZM5.33301 8.66699V7.33366H10.6663V8.66699H5.33301ZM8.66634 11.3337V10.0003H11.333C11.8886 10.0003 12.3608 9.80588 12.7497 9.41699C13.1386 9.0281 13.333 8.55588 13.333 8.00033C13.333 7.44477 13.1386 6.97255 12.7497 6.58366C12.3608 6.19477 11.8886 6.00033 11.333 6.00033H8.66634V4.66699H11.333C12.2552 4.66699 13.0415 4.9921 13.6917 5.64233C14.3419 6.29255 14.6668 7.07855 14.6663 8.00033C14.6663 8.92255 14.3412 9.70877 13.691 10.359C13.0408 11.0092 12.2548 11.3341 11.333 11.3337H8.66634Z"
                                  fill="#878787"
                                />
                              </svg>
                              <p className="text-[14px] text-[#878787] font-500 leading-[150%]">
                                {link}
                              </p>
                            </div>
                          ))
                        ) : (
                          <div className="lower mt-[8px] flex items-center gap-[8px]">
                            <div className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                                fill="none"
                              >
                                <path
                                  d="M6.36641 12.5001L2.56641 8.70007L3.51641 7.75007L6.36641 10.6001L12.4831 4.4834L13.4331 5.4334L6.36641 12.5001Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                            <p className="text-[14px] text-[#878787] font-500 leading-[150%]">
                              {response.section2Option}
                            </p>
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  );
                } else {
                  // Render a single .answer div for other indices
                  return (
                    <div className="answer" key={index}>
                      <div className="upper">
                        <h3 className="">
                          {questionMapping[response.step].number}.{" "}
                          {questionMapping[response.step].question}
                        </h3>
                      </div>
                      <div className="lower flex flex-col gap-[8px]">
                        {response.selectedOptions?.map(
                          (option, optionIndex) => (
                            <div
                              className="flex items-center gap-[8px]"
                              key={optionIndex}
                            >
                              <div className="icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="17"
                                  viewBox="0 0 16 17"
                                  fill="none"
                                >
                                  <path
                                    d="M6.36641 12.5001L2.56641 8.70007L3.51641 7.75007L6.36641 10.6001L12.4831 4.4834L13.4331 5.4334L6.36641 12.5001Z"
                                    fill="white"
                                  />
                                </svg>
                              </div>
                              <p className="">{option}</p>
                            </div>
                          )
                        )}
                      </div>
                      {response.textareaValue && (
                        <div className="lower mt-[8px]">
                          {/* Render your textareaValue here */}
                          <p className="">{response.textareaValue}</p>
                        </div>
                      )}
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <div className="lower-text flex justify-between items-center mt-[56px] gap-[83px] 500px:flex-col 500px:items-start 500px:gap-[24px]">
            <h1
              className={`text-[#2c7789] text-[24px] font-500 leading-[120%] opacity-${
                pdfHidden ? "0" : "1"
              }`}
            >
              Get in touch with us! We are ready to answer your <br />
              questions and provide any necessary information.
            </h1>
            <a href="mailto:hello@oligamy.com">
              <button
                className={`generate-pdf opacity-${pdfHidden ? "0" : "1"}`}
              >
                Contact us
              </button>
            </a>
          </div>
          <div className="contact-info">
            <div className="left max-w-[198px]">
              <p>Oligamy Software sp. z o.o.</p>
              <p>
                38 Nowiec Street <br />
                80-293, Gdańsk, Poland
              </p>
            </div>
            <div className="middle">
              <a
                href={"mailto:hello@oligamy.com"}
                className={"underlineless text-[#2c7789]"}
              >
                hello@oligamy.com
              </a>
              <p>www.oligamy.com</p>
            </div>
            <div className="right">
              <p>NIP 5833433323</p>
              <p>Regon 389455609</p>
              <p>KRS 0000911398</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SummaryScreen;
