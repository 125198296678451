"use client";
import React from "react";
import FooterDesktopContent from "./FooterDesktopContent";

const FooterDesktop = () => {
  return (
    <footer
      className=" bg-[#173d46] min-h-[635px]  w-full  h-auto p-0 "
      style={{ clipPath: "polygon(0% 0, 100% 0%, 100% 100%, 0 100%)" }}
    >
      <div className="fixed bottom-0  h-auto  w-full">
        <FooterDesktopContent />
      </div>
    </footer>
  );
};

export default FooterDesktop;
